import moment from 'moment';
import { Subject } from 'rxjs';
import { ConversationModule } from '../shared/entity/conversation.module';
import API from '../shared/environement/api-routes';
import Interceptor from '../shared/environement/interceptor';
const conversationNotificationSubject$ = new Subject();

const createConversation = async (
	ambassador: any,
	user: any,
	type: ConversationModule.CONVERSATION_TYPE
): Promise<Array<ConversationModule.Conversation>> => {
	try {
		const result = await Interceptor(
			`${API.CONVERSATION}/read/ambassador/${ambassador.id}/provider/${user.tenants[0].provider.id}`,
			{
				method: 'POST',
				data: {
					name: ambassador.email,
					ambassador: ambassador.id,
					tenant: user.tenants[0].provider.id,
					type,
				},
			}
		);
		const response: ConversationModule.ConversationApiResponse = result.data;
		return [mapConversationApiResponse(response, ambassador, user)];
	} catch (e) {
		throw e;
	}
};

const mapConversationApiResponse = (
	response: ConversationModule.ConversationApiResponse,
	ambassador: any,
	user: any
): ConversationModule.Conversation => {
	const datetime = getConversationDatetime(response.updatedAt);
	return {
		id: response.id,
		avatar: ambassador.avatars ? ambassador.avatars[0] : '',
		name: response.name,
		description: getConversationDescription(response.type) || '',
		time: datetime.time,
		date: datetime.date,
		notification: response.unreadMessages || 0,
		isChecked: response.unreadMessages! > 0 || false,
		type: response.type,
		ambassador: ambassador.id,
		tenant: user.tenants[0].provider.id,
	};
};

const getConversations = async (userId: string) => {
	try {
		const result = await Interceptor.get(`${API.CONVERSATION}/user/${userId}`, {});
		const response: Array<ConversationModule.ConversationGetResponse> = result.data;
		return response.map((conversation) => mapConversationGetResponse(conversation));
	} catch (error) {
		return Promise.reject(error);
	}
};

const mapConversationGetResponse = (
	response: ConversationModule.ConversationGetResponse
): ConversationModule.Conversation => {
	const datetime = getConversationDatetime(response.updatedAt);
	return {
		id: response.id,
		avatar: response?.ambassador?.avatars ? response?.ambassador?.avatars[0] : '',
		name: response.name,
		description: getConversationDescription(response.type) || '',
		time: datetime.time,
		date: datetime.date,
		notification: response.unreadMessages || 0,
		isChecked: (response.unreadMessages && response.unreadMessages > 0) || false,
		type: response.type,
		ambassador: response.ambassador.id || '',
		tenant: response.tenant || '',
	};
};

const getConversationDatetime = (datetime: Date) => {
	const tmpDate = moment(datetime);
	const today = moment();
	let date = '';
	let time = '';
	if (tmpDate.day === today.day) {
		date = '';
		time = tmpDate.format('hh:mm');
	} else {
		date = tmpDate.format('DD/MM/yy');
		time = '';
	}
	return { date, time };
};

const getConversationDescription = (type: string) => {
	switch (type) {
		case ConversationModule.CONVERSATION_TYPE.SUPPORT:
			return 'System update complete';
		case ConversationModule.CONVERSATION_TYPE.AMBASSADOR:
			return 'Your Crendentialling Ambassador';
	}
};

const markConversationsAsRead = async (conversations: Array<ConversationModule.Conversation>) => {
	const data = conversations.map((conversation) => {
		return {
			conversationId: conversation.id,
			from: conversation.ambassador,
		};
	});

	try {
		const result = await Interceptor(`${API.CONVERSATION}/read`, {
			method: 'PUT',
			data,
		});

		return result.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteConversations = async (conversations: Array<ConversationModule.Conversation>) => {
	const data = conversations.map((conversation) => conversation.id);
	try {
		const result = await Interceptor(`${API.CONVERSATION}`, {
			method: 'DELETE',
			data,
		});
		return result.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const conversationService = {
	getConversations,
	createConversation,
	conversationNotificationSubject$,
	deleteConversations,
	markConversationsAsRead,
};
