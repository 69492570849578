import React, { useEffect, useState } from 'react';
import {
  IonRow,
  IonText,
  IonImg,
  IonCol
} from '@ionic/react';

import './AccordionPayerActions.css'
import { PayerEntity } from '../../shared/entity/payerEntity';
import ChevronDownBlue from '../../img/icon-chevron-down-blue.svg'
import ChevronUpBlue from '../../img/icon-chevron-up-blue.svg'
import StatusTextComponent from '../StatusTextComponent/StatusTextComponent';
const AccordionBoardActions: React.FC<PayerEntity> = ({ logo, status, estimatedDaysToEnrollment, children, effectiveDate, name, quantity, collapse,state }) => {
  const [isOpen, setOpen] = useState(false);
  const [arrowClick, setArrowClick] = useState(false);
  useEffect(() => {
    if (quantity === 1) {
      setOpen(true);
    } else if (!arrowClick) {
      setOpen(collapse!)
    }
  })
  function openPayer() {
    setArrowClick(true);
    setOpen(!isOpen)
  }
  return (
    <IonRow className={`accordion-wrapper full-row-cols border-bottom-payer-accordeon-test padding-row-accordion-payer`}>
    <IonRow id="row" onClick={() => openPayer()}
      className={`uppercase-title  ${isOpen ? "open" : ""}`}
      >
      <IonRow  id="row" onClick={() => openPayer()} className={`card-row-accordeon-payer`}>      
         <IonRow className='accordeon-payer-width' id="row" onClick={() => openPayer()}>
         <IonImg id="row" className="row-logo-payer ion-padding-end" src={logo} />
         </IonRow>
          <IonRow className='accordeon-payer-width accordeon-payer-sapce-between' id="row" onClick={() => openPayer()}>
          <StatusTextComponent label={status!} />
             {isOpen ? <IonImg id="row" className="row-icon" src={ChevronUpBlue} /> 
             : <IonImg id="row" className="row-icon" src={ChevronDownBlue} />} 
             </IonRow>  
      </IonRow>
      <IonText className="title-text-payer">{name}</IonText>
    </IonRow>
   {isOpen?
   <IonRow className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
   <IonCol id="row" size="12">
   {children}
           </IonCol>
   </IonRow>:null 
  }
  </IonRow>
  )
};

export default AccordionBoardActions;
