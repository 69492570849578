export default class WalletBenefits {
	static label(value: any) {
		const item = this.forSelect.find((item) => item === value);
		if (!item) {
			return null;
		}
		return item;
	}
	static get forSelect() {
		return [
			`One-stop-shop for all your healthcare provider credentials in one place, including Medical Licenses,
     Board Certifications, DEA, Medicare, Medicaid, NPI, driver's license, and passport`,
			`Ongoing monitoring of your healthcare profile, including Board Actions, OIG, and SAM`,
			`Login and password tracker for CAQH, NPESS, Medical License Boards and others`,
			`Reminders for expirations and renewals`,
			`Calendar view of all upcoming actions`,
			`Task center to address profile related issues or needs`,
			`Ability to share credentials with trusted individuals`,
			`Chat feature with our Ambassadors for questions or assistance`,
		];
	}
	static get values() {
		return this.forSelect.map((item) => item);
	}
}
