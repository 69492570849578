import { App } from '@capacitor/app';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonTitle,
	IonToast,
	IonToggle,
	IonToolbar,
	isPlatform,
	ToastOptions,
} from '@ionic/react';
import { AppTrackingStatusResponse, AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { logInOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import AppVersionModal from '../../components/AppVersion/AppVersion';
import eyeOff from '../../img/icons/eyeOff.svg';
import eye from '../../img/icons/icon-eye.svg';
//icons
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import arrowBack from '../../img/icons/white_back_arrow.svg';
import loginLogo from '../../img/nuco-logo-splash.png';
import { userActions, walletActions } from '../../redux/actions';
import { LoginConstants } from '../../redux/constants';
import { appVersionService } from '../../services/appVersion.service';
import { pushNotificationService } from '../../services/pushNotifications.service';
import { userService } from '../../services/user.service';
import { UserEntity } from '../../shared/entity/userEntity';
import './Login.css';
import WelcomeLoginPage from './WelcomeLoginPage';
const LoginPage: React.FC<RouteComponentProps> = ({ history }) => {
	const dispatch = useDispatch();
	const npi = useSelector<any, UserEntity>((state) => state.user!.npi);
	const userRedux = useSelector<any, UserEntity>((state) => state.user!);
	const resolved = useSelector<any, any>((state) => state.login.resolved!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [show, setShow] = useState(false);
	const [showAppVersionModal, setShowAppVersionModal] = useState(false);
	const [remember, setRemember] = useState<boolean>(false);
	const [user, setUser] = useState<UserEntity>({
		email: '',
		password: '',
	});
	const { email, password } = user;
	const [showLoading, setShowLoading] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [welcomeScreen, setWelcomeScreen] = useState(true);
	useEffect(() => {
		let isSubscribed = true;
		const verifyComponent = async () => {
			const token: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
			if (resolved) {
				verify(false);
			} else {
				if (resolved === false && token !== 'x' && isSubscribed) {
					setShowLoading(false);
					showToast({
						color: 'danger',
						header: 'Failure',
						message: LoginConstants.LOGIN_USER_WRONG_DATA,
					});
				}
			}
		};
		verifyComponent().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, [resolved]);

	useEffect(() => {
		requestPermission();
		let isSubscribed = true;
		const verify = async () => {
			const remember: boolean = JSON.parse(localStorage.getItem('remember')!);
			const email: string = localStorage.getItem('nucoEmail')!;
			if (remember && isSubscribed) {
				setUser({
					email: email ? JSON.parse(email) : '',
					password: '',
				});
				setRemember(remember);
				if (email) {
					setWelcomeScreen(false);
				}
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, []);

	useEffect(() => {
		let isSubscribed = true;
		const verifyMethod = async () => {
			if (isSubscribed) {
				const stage: string = JSON.parse(localStorage.getItem('NuCoUserStage')!);
				if (stage) {
					verify(true);
				}
			}
		};
		verifyMethod().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, []);
	const navigateToApp = (status: boolean) => {
		setShowLoading(false);
		if (status) {
			rememberMe();
			history.push('/tabs/wallet');
		} else {
			const backAction: boolean = JSON.parse(sessionStorage.getItem('backAction')!);
			if (!backAction) {
				const stage: string = JSON.parse(localStorage.getItem('NuCoUserStage')!);
				switch (stage) {
					case 'confirm':
						history.push('/confirm-email');
						break;
					case 'verify':
						history.push('/verify-identity');
						break;
					case 'building':
						history.push('/building-wallet');
						break;
					case 'payment':
						history.push('/payment-wallet');
						break;
					case 'started':
						history.push('/lets-get-started');
						break;
					case 'startSubscription':
						history.push('/start-subscription');
						break;
					default:
						history.push('/');
				}
			}
		}
	};
	const savePushNotificationToken = async (userId: string) => {
		pushNotificationService.checkPermissions(userId).then(async (e) => {
			const token = localStorage.getItem('pushNotificationToken');
			if (token) {
				await pushNotificationService.saveUserToken({ token, userId });
			}
		});
	};
	const verify = async (preventLogin: boolean) => {
		const token: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		const stage: string = JSON.parse(localStorage.getItem('NuCoUserStage')!);
		if (token && token !== 'x') {
			const res = await userService.userAuthorization(token);
			if (existsProviderOnUser(res)) {
				const result = await checkStage(token, res);
				localStorage.setItem('provider-stage', JSON.stringify(res?.data?.tenants?.[0]?.provider?.stage));
				if (result) {
					const appVersionResult = allowedPlatformForPlugin()
						? await appVersionService.validateAppVersion((await App.getInfo()).version)
						: false;
					if (appVersionResult) {
						setShowAppVersionModal(true);
					} else {
						navigateToApp(preventLogin ? false : true);
					}
				} else {
					navigateToApp(false);
				}
			} else {
				nonProviderOnUserMessage();
			}
		}
		if (stage && stage === 'started') {
			navigateToApp(false);
		}
	};

	const requestPermission = async (): Promise<AppTrackingStatusResponse> => {
		const response = await AppTrackingTransparency.requestPermission();
		return response;
	};
	const existsProviderOnUser = (res: any) => {
		if (res?.data?.tenants?.[0]?.provider == null) {
			return false;
		}
		return true;
	};
	const nonProviderOnUserMessage = () => {
		setShowLoading(false);
		setToast({
			color: 'danger',
			header: 'Failure',
			message: LoginConstants.LOGIN_FAILURE_NON_PROVIDER,
		});
		showToast(toast);
	};
	const checkStage = async (token: string, res: any) => {
		let result = false;
		if (token) {
			try {
				if (
					res?.data?.tenants?.[0]?.provider?.stage === '0_new' ||
					res?.data?.tenants?.[0]?.provider?.stage === '0_manuallyIdentityMatch' ||
					res?.data?.tenants?.[0]?.provider?.stage === '1_walletReady'
				) {
					const stage: string = JSON.parse(localStorage.getItem('NuCoUserStage')!);
					if (!stage) {
						localStorage.setItem('NuCoUserStage', JSON.stringify('building'));
					}
					userRedux.email = res.data.email;
					userRedux.id = res.data.tenants[0].tenant.id;
					userRedux.contactId = res.data.tenants[0].provider?.contactInfo?.id;
					dispatch(walletActions.authorization(JSON.parse(localStorage.getItem('NuCoUserToken')!)));
					result = false;
					await savePushNotificationToken(res.data.tenants[0].provider.id);
				} else {
					await savePushNotificationToken(res.data.tenants[0].provider.id);
					result = true;
				}
			} catch (e) {
				console.log(e);
			}
		}

		return result;
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setUser((inputs) => ({ ...inputs, [name]: value }));
	};
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		sessionStorage.setItem('backAction', 'false');
		if (!executeRecaptcha) {
			return;
		}
		const recaptchaToken = await executeRecaptcha('submit');

		if (email && password) {
			setShowLoading(true);
			dispatch(userActions.login(user, recaptchaToken));
		}
	};
	const rememberMe = () => {
		const remember: boolean = JSON.parse(localStorage.getItem('remember')!);
		if (remember) {
			localStorage.setItem('nucoEmail', JSON.stringify(user.email));
		} else {
			localStorage.removeItem('nucoEmail');
		}
	};
	const handleChangeRemeberMe = () => {
		if (!remember) {
			setToast({
				color: 'success',
				header: 'Success',
				message: 'Your session will be stored',
			});
			localStorage.setItem('remember', JSON.stringify(!remember));
			if (user.email) {
				localStorage.setItem('nucoEmail', JSON.stringify(user.email));
				showToast(toast);
			}
		} else {
			localStorage.removeItem('remember');
			localStorage.removeItem('nucoEmail');
		}
		setRemember(!remember);
	};
	const showToast = (toast: ToastOptions) => {
		if (toast.message) {
			setToastIsShown(true);
			setToast(toast);
		}
	};
	const validForm = () => {
		if (password?.length! >= 8 && email?.length! > 0) {
			return false;
		} else {
			return true;
		}
	};
	const allowedPlatformForPlugin = () => {
		return isPlatform('ios') || isPlatform('android');
	};
	const chnageWelcomeScreen = () => {
		setWelcomeScreen(false);
	};
	return (
		<IonPage className="loginPage">
			{!welcomeScreen ? (
				<>
					<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550 ion-toolbar-login">
						<IonRow className="title-img-header">
							<img src={loginLogo} className="logo-img" />
						</IonRow>
					</IonToolbar>

					<IonContent fullscreen className="background-sign-in ion-padding">
						{showAppVersionModal && <AppVersionModal isModalOpen={showAppVersionModal} />}
						<IonToolbar className="no-background">
							<IonTitle className="ion-text-center title-sign-in" size="large">
								<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => setWelcomeScreen(true)}></IonIcon>
								Welcome back!
							</IonTitle>
						</IonToolbar>

						{npi ? (
							<IonRow className="ion-padding-bottom  subtitle-sign-up-npi">
								Looks like you already have an account! Please sign in with: {userRedux.email}
							</IonRow>
						) : (
							<IonRow className="subtitle-sign-up ion-padding-bottom color-light-green">
								Sign in to access your Wallet
							</IonRow>
						)}

						<form name="form">
							<IonItem className="input-inside ion-margin-top ion-margin-bottom">
								<IonInput
									required
									className="ion-padding"
									name="email"
									type="email"
									placeholder="User"
									value={email}
									onIonChange={handleChange}></IonInput>
							</IonItem>
							<IonItem className="input-inside ion-margin-top ion-margin-bottom">
								<IonInput
									required
									className="ion-padding"
									name="password"
									type={show ? 'text' : 'password'}
									placeholder="Password"
									value={password}
									onIonChange={handleChange}></IonInput>
								<IonIcon icon={show ? eye : eyeOff} onClick={() => setShow(!show)}></IonIcon>
							</IonItem>
							<div className="ion-text-end title-forgot-password">
								<Link
									className="title-forgot-password"
									to={{ pathname: 'https://devportal.nucocred.com/auth/forgot-password' }}
									target="_blank">
									Forgot your password?
								</Link>
								{/* change prod vs dev */}
								{/* <Link
							className="title-forgot-password"
							to={{ pathname: 'https://portal.nucocred.com/auth/forgot-password' }}
							target="_blank">
							Forgot your password?
						</Link> */}
							</div>
							<IonItem className="ion-no-padding no-background" lines="none">
								<IonToggle
									className="ion-no-margin"
									onIonChange={() => handleChangeRemeberMe()}
									checked={remember}
									color="secondary"></IonToggle>
								<IonLabel className="login-label__terms">Remember me</IonLabel>
							</IonItem>

							<IonRow className="auto-center ion-padding add-padding">
								<IonButton
									onClick={handleSubmit}
									color={validForm() ? 'medium' : 'secondary'}
									shape="round"
									type="button"
									disabled={validForm()}
									className="sign-in-width">
									<IonIcon slot="start" icon={logInOutline} />
									Sign In
								</IonButton>
							</IonRow>
						</form>
					</IonContent>
				</>
			) : (
				<WelcomeLoginPage welcom={chnageWelcomeScreen} />
			)}
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</IonPage>
	);
};
export default LoginPage;
