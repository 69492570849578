import { ABMSEntity } from '../../shared/entity/abmsEntity';
import { ActionEntity } from '../../shared/entity/actionEntity';
import { AbmsConstants } from '../constants/abmsConstants';

const initialUser = {
	abms: [] as ABMSEntity[],
	info: '',
};
const abmsReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case AbmsConstants.SET_ABMS:
			return action.payload;
		default:
			return state;
	}
};
export default abmsReducer;
