import { Dispatch } from 'redux';
import { userService } from '../../services/user.service';
import { UserEntity } from '../../shared/entity/userEntity';
import { LoginConstants } from '../constants/loginConstants';

export const userActions = {
	login,
	logout,
	register,
};

function login(user: UserEntity, recaptchaToken: any) {
	return async (dispatch: Dispatch) => {
		dispatch(request(user));

		await userService.userSignIn(user, recaptchaToken).then(
			(res) => {
				user.token = res?.data;
				localStorage.setItem('NuCoUserToken', JSON.stringify(user.token));
				dispatch(success(user));
			},
			(error) => {
				localStorage.removeItem('NuCoUserToken');
				dispatch(failure(error.response.data));
			}
		);
	};

	function request(payload: UserEntity) {
		return { type: LoginConstants.LOGIN_REQUEST, payload };
	}
	function success(payload: UserEntity) {
		return { type: LoginConstants.LOGIN_SUCCESS, payload };
	}
	function failure(error: any) {
		return { type: LoginConstants.LOGIN_FAILURE, error };
	}
}

function logout() {
	localStorage.setItem('NuCoUserToken', '"x"');
	return { type: LoginConstants.LOGOUT };
}

function register(user: any) {
	return (dispatch: Dispatch) => {
		dispatch(request(user));

		userService.register(user).then(
			(user) => {
				dispatch(success(user));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request(user: any) {
		return { type: LoginConstants.REGISTER_REQUEST, user };
	}
	function success(user: any) {
		return { type: LoginConstants.REGISTER_SUCCESS, user };
	}
	function failure(error: any) {
		return { type: LoginConstants.REGISTER_FAILURE, error };
	}
}
