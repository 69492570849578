import { ActionEntity } from "../../shared/entity/actionEntity";
import { TabsConstants } from "../constants/tabsConstants";

const initialUser: {wallet: boolean, selected: boolean} = {wallet:false,selected: false};
const tabsReducer = (state: {wallet: boolean, selected: boolean} = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case TabsConstants.WALLET:
            return {
                ...state,
                    wallet: action.payload,
            }   
        case TabsConstants.SELECTED:
            return {
                ...state,
                    selected: action.payload,
            }  
        default:
            return state;
    }

}
export default tabsReducer;