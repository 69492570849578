import {
	IonButton,
	IonCard,
	IonContent,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import arrowBack from '../../img/icons/backArrow.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import { userProfileActions } from '../../redux/actions/userActions';
import { onBoardingService } from '../../services/onBoarding.service';
import { NpiResultEntity } from '../../shared/entity/npiResult';
import { ToastOptions } from '../../shared/entity/toastOptions';
import './whichIsYou.css';

const WhichIsYou: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const npiResults = useSelector<any, any>((state) => state.npiResults!);
	let login = useSelector<any, any>((state) => state.login);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [results, setResults] = useState<NpiResultEntity[]>([]);
	const [toast, setToast] = useState<ToastOptions>({});
	const [selected, setSelected] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const navigate = (route: string) => {
		props.history.push(route);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		const card = results.find((element: NpiResultEntity) => element.selected === true);
		onBoardingService.npiInUse(card!.npi!).then(
			(res) => {
				if (res.data.provider !== null) {
					dispatch(userProfileActions.setUserEmail(res.data.provider.email));
					login.token = 'x';
					navigate('/');
				} else {
					navigate('/create-account');
				}
			},
			(error) => {
				setToast({
					color: 'danger',
					header: 'Failure',
					message: error.response.data,
				});
				console.log(error);
				showToast(toast);
			}
		);

		setShowLoading(true);
	};

	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};

	const selectedCard = (e: any, card: NpiResultEntity) => {
		setSelected(true);
		const tmp: NpiResultEntity[] = [];
		results.forEach((element) => {
			if (element === card) {
				element.selected = true;
				tmp.push(element);
			} else {
				element.selected = false;
				tmp.push(element);
			}
		});
		setResults(tmp);
		dispatch(userProfileActions.setUserNpi(card));
	};

	useEffect(() => {
		setResults(npiResults);
	}, []);

	return (
		<IonPage
			className="
      loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>

			<IonContent className="ion-padding profile-page max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>
						Which is you?
					</IonTitle>
				</IonToolbar>
				<IonRow className="ion-justify-content-center ion-padding-bottom">
					<IonRow className="results-label">{results.length} results found</IonRow>
				</IonRow>

				<IonRow className="cards-container">
					{results?.map((element, index) => (
						<IonCard
							onClick={(e) => selectedCard(e, element)}
							className={!element.selected ? 'result-card' : 'result-card selected'}
							key={index}>
							<IonRow className={'row-card'}>
								<IonText className="result-card-title">{element.name}</IonText>
								<IonText className="result-card-sub-title">{element.location}</IonText>
								<IonText className="result-card-npi">NPI : {element.npi}</IonText>
							</IonRow>
							{element.selected ? (
								<IonRow className="pt-saveTermsCard">
									{' '}
									<IonButton
										className={'buttonCardWIY'}
										disabled={!selected}
										color="secondary"
										shape="round"
										onClick={handleSubmit}>
										{' '}
										Next{' '}
									</IonButton>
								</IonRow>
							) : null}
						</IonCard>
					))}
				</IonRow>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={1000}
			/>
		</IonPage>
	);
};

export default WhichIsYou;
