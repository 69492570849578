import {
	IonButton,
	IonCheckbox,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonLoading,
	IonModal,
	IonRow,
	IonText,
	IonToast,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import './ExpirationReminder.css';
import ExpirationReminderHelpMeModal from './ExpirationReminderHelpMe';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CloseSchedule from '../../img/close-schedule.svg';
import { PendingActionsActions } from '../../redux/actions/pendingActions';
import { filesService, taskService } from '../../services';
import AddressStates from '../../shared/addressStates';
import { ProviderTaskEntity } from '../../shared/entity/ProviderTaskEntity';
import { PendingEntity } from '../../shared/entity/pendingTaskEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
const ExpirationReminderModal: React.FC<any> = (props: any) => {
	const dispatch = useDispatch();
	const pendingTasks = useSelector<ReduxStateEntity, PendingEntity>((state) => state.pendingTasks!);
	const { provider, clearFields } = props;
	const [openHelpModal, setOpenHelpModal] = useState(false);
	const [notification, setNotification] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const user = useSelector<ReduxStateEntity, UserEntity>((state) => state.user!);
	const [toastMessageError, setToastMessageError] = useState('');
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [medicalLicenseDocument, setMedicalLicenseDocument] = useState<any>();
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && provider) {
				setNotification(provider.skipPushNotificationReminder);
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, []);
	useEffect(() => {
		if (provider) {
			getMedicalLicenseTask();
		}
	}, [provider]);
	async function getMedicalLicenseTask() {
		let medicalLicenseDocument: any;
		if (provider && provider.documentType) {
			if (provider.documentType.key === 'medical-license') {
				medicalLicenseDocument = provider.documentType;
			}
		}
		if (medicalLicenseDocument) {
			let foundTask = provider?.childStepTasks.find((task: any) => task.documentType === medicalLicenseDocument.id);
			foundTask = await filesService.getDocument(provider?.provider?.tenant, foundTask?.documents?.[0]);
			setMedicalLicenseDocument(foundTask?.data);
		}
	}
	async function btnClick(action: string) {
		setShowLoading(true);
		if (action != 'saveTask') {
			const result = await taskService.skipNotificationReminder(user.id!, provider.id, notification);
			if (!result.data) {
				setShowLoading(false);
				setToastMessageError('Something went wrong,please try again.');
				setShowToastAttachments(true);
				return;
			}
		}
		if (action === 'clear') {
			const data: ProviderTaskEntity[] = [...pendingTasks.providerTasks];
			const result = data.findIndex(function (element) {
				return element.id === provider?.id!;
			});
			data[result].status = 'done';
			dispatch(PendingActionsActions.updatePendingActions(data));
			setShowLoading(false);
			clearFields();
		} else if (action === 'saveTask') {
			saveRenewTask();
		} else {
			setShowLoading(false);
			setOpenHelpModal(true);
		}
	}
	async function saveRenewTask() {
		let data: any = {
			status: 'done',
			id: provider?.id,
		};
		const updateTask = await filesService.updateTasksStatus(provider?.provider?.tenant, provider.id, data);
		if (updateTask) {
			data = {
				organizations: [provider?.provider?.organizations?.[0]],
				payers: [],
				provider: provider?.provider?.id,
				locations: [],
				attachments: [],
				estimatedEndDate: moment().add(1, 'days').toISOString(),
				assignedTo: provider?.provider?.ambassadors?.[0],
				notes: null,
				instructions: null,
				taskTemplate: '65e751c29187ff79b779dc37',
				priority: 'low',
				title: 'FL Medical License Renewal',
			};
			await taskService.createTask(provider?.provider?.tenant, data);
			setShowLoading(false);
			btnClick('clear');
		} else {
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	}
	return openHelpModal ? (
		<ExpirationReminderHelpMeModal provider={provider} clearFields={clearFields} />
	) : provider?.documentType?.key === 'medical-license' ? (
		<IonModal id="exp-reminder-modal" isOpen={true} backdropDismiss={false}>
			<IonContent className="exp-reminder-modal">
				<IonRow className="exp-reminder-title">{provider?.title}</IonRow>
				<IonIcon className="close-btn-schedule" id="copy" icon={CloseSchedule} onClick={() => clearFields}></IonIcon>
				<IonRow className="exp-reminder-med-license">
					Expiration Date: <h4>{` ${moment(medicalLicenseDocument?.expirationDate).format('MMM DD, YYYY')}`}</h4>
				</IonRow>
				<IonRow className="exp-reminder-med-license">
					Medical License State:&nbsp; <h4> {` ${AddressStates.label(medicalLicenseDocument?.state)}`} </h4>
				</IonRow>
				<IonRow className="exp-reminder-text-med">
					Your Medical License for the state of &nbsp;
					<IonText className="exp-reminder-med-license">
						{AddressStates.label(medicalLicenseDocument?.state)}
					</IonText>{' '}
					will expire on {moment(provider?.expirationDate).format('MMM DD, YYYY')}. Should you want our team to help you
					with the renewal process, please click on the "Renew" button below. Should you be renewing the license on your
					own or will not be renewing, please click on the "No Action Needed" button below.
				</IonRow>
				<IonRow className="auto-center ion-padding ">
					<IonButton
						onClick={() => btnClick('saveTask')}
						shape="round"
						type="button"
						color="success"
						className="sign-in-width">
						Renew
					</IonButton>
				</IonRow>
				<IonRow className="auto-center ion-padding ">
					<IonButton onClick={() => clearFields()} shape="round" type="button" color="medium" className="sign-in-width">
						No Action Needed
					</IonButton>
				</IonRow>
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
			</IonContent>
		</IonModal>
	) : (
		<IonModal id="exp-reminder-modal" isOpen={true} backdropDismiss={false}>
			<IonContent className="exp-reminder-modal">
				<IonRow className="exp-reminder-title">Expiration Reminder</IonRow>
				<IonIcon className="close-btn-schedule" id="copy" icon={CloseSchedule} onClick={() => clearFields}></IonIcon>
				<IonRow className="exp-reminder-text">
					Your {provider?.title} will expire in {provider?.scheduledReminder?.days} days.
				</IonRow>
				<IonRow className="exp-reminder-text">How do you want to proceed?.</IonRow>
				<IonRow className="auto-center ion-padding ">
					<IonButton onClick={() => btnClick('openModal')} shape="round" type="button" className="sign-in-width">
						I need help
					</IonButton>
				</IonRow>
				<IonRow className="auto-center ion-padding ">
					<IonButton onClick={() => btnClick('clear')} shape="round" type="button" className="sign-in-width">
						I'll do it my own
					</IonButton>
				</IonRow>
				<IonRow className="auto-center ion-padding">
					<IonItem className="no-bg-item-reminder ">
						<IonCheckbox
							slot="start"
							onIonChange={() => setNotification(!notification)}
							checked={notification}></IonCheckbox>
						<IonLabel className="hide-reminder-label">
							By checking this you’ll no longer receive this notifications on 90, 60, 30, 10 and 5 days before your
							document expiration{' '}
						</IonLabel>
					</IonItem>
				</IonRow>
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
			</IonContent>
		</IonModal>
	);
};

export default ExpirationReminderModal;
