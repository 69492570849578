import { IonCol, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import ChevronDownBrown from '../../img/icon-chevron-down-black.svg';
import ChevronUpBrown from '../../img/icon-chevron-up-black.svg';
import { AccordionFullTitleEntity } from '../../shared/entity/accordionFullTitle.module';
import StatusTextComponent from '../StatusTextComponent/StatusTextComponent';
import './AccordionDea.css';

const AccordionDea: React.FC<AccordionFullTitleEntity> = ({ title, active, children, lastItem, open }) => {
	const [isOpen, setOpen] = useState(false);
	useEffect(() => {
		if (open) {
			setOpen(open);
		}
	});
	return (
		<IonRow
			className={`accordion-wrapper full-row-cols ${lastItem ? 'border-top-dea border-bottom-dea' : 'border-top-dea'}`}>
			<IonRow id="row" onClick={() => setOpen(!isOpen)} className={`uppercase-title  ${isOpen ? 'open' : ''}`}>
				<IonRow id="row" className={`card-row`}>
					<IonCol id="row" size="7">
						<IonText id="row" className="row-text-title-aco-dea">
							{title}
						</IonText>
					</IonCol>
					<IonCol id="row" size="4" className="center-text-col">
						<StatusTextComponent label={active!} />
					</IonCol>
					<IonCol id="row" size="1" className="center-icon-col">
						{isOpen ? (
							<IonImg id="row" className="row-icon" src={ChevronUpBrown} />
						) : (
							<IonImg id="row" className="row-icon" src={ChevronDownBrown} />
						)}
					</IonCol>
				</IonRow>
			</IonRow>
			{isOpen ? (
				<IonGrid className="full-width-accordion-dea">
					<IonRow className="">{children}</IonRow>
				</IonGrid>
			) : null}
		</IonRow>
	);
};

export default AccordionDea;
