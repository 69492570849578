import { ActionEntity } from "../../shared/entity/actionEntity";
import { BoardActionsEntity } from "../../shared/entity/boardActionsEntity";
import { MedicareEntity } from "../../shared/entity/medicareEntity";
import { MedicareConstants } from "../constants";
import { BoardActionsConstants } from "../constants/boardActionsConstants";

const initialUser: MedicareEntity[] = [];
const medicareReducer = (state: MedicareEntity[] = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case MedicareConstants.SET_MEDICARE:
                return action.payload;          
        default:
            return state;
    }
}
export default medicareReducer;