export default class years {
	static get forSelect() {
		let years = [];
		const cont = 1900;
		let currentYear = new Date().getFullYear();

		while (cont <= currentYear) {
			years.push(currentYear);
			currentYear--;
		}

		return years;
	}
}
