import { IonIcon, IonImg, IonLoading, IonMenuToggle, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import editImgIcon from '../../../img/edit-image.svg';
import IconChangeImage from '../../../img/icon-change-img.svg';
import defaultImg from '../../../img/icons/default-img.svg';
import interrogation from '../../../img/icons/interrogation.svg';
import { DriverLicenseActions } from '../../../redux/actions';
import { filesService } from '../../../services/files.service';
import AddressStates from '../../../shared/addressStates';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { DriverLicenseEntity } from '../../../shared/entity/driverLicenseEntity';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import './DriversLicense.css';

const DriversLicense: React.FC<CardInfoEntity> = ({ title, zoom, props }) => {
	const navigate = (route: string) => {
		props?.history.push('/tabs' + route);
	};
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const driverLicense = useSelector<ReduxStateEntity, DriverLicenseEntity>((state) => state.driverLicense!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__drivers-license');
	const [expDate, setExpDate] = useState<string>();
	const [documentDescription, setDocumentDescription] = useState(driverLicense?.info);
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {
		if (data) {
			dispatch(DriverLicenseActions.setLicense(data, driverLicense));
		}
	}, [data]);
	useEffect(() => {
		if (driverLicense && driverLicense?.expirationDate) {
			let date = driverLicense.expirationDate.split('-');
			if (date[1].length == 1) {
				let newDate = date[0] + '-0' + date[1] + '-' + date[2];
				driverLicense.expirationDate = newDate;
				setExpDate(newDate);
			} else {
				setExpDate(driverLicense.expirationDate);
			}
		}
	});
	useEffect(() => {
		if (driverLicense?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'driver-license').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(DriverLicenseActions.setLicense(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [driverLicense]);
	useEffect(() => {
		if (driverLicense?.info !== '') {
			setDocumentDescription(driverLicense.info);
		}
	}, [driverLicense, documentDescription]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__drivers-license dlRotate');
		} else {
			setBack('card card__drivers-license dlNoRotate');
		}
	};
	const [showDriversLicenseModal, setShowDriversLicenseModal] = useState(false);
	const [showBackImg, setShowBackImg] = useState(false);
	return (
		<div className={back}>
			{!flip ? (
				<IonRow className="card-face-drv-license">
					<IonRow>
						{driverLicense && !flip ? (
							<IonRow className="article-header">
								<IonRow className="category-title">{title}</IonRow>
								<IonIcon
									id="about"
									className="icon-about-sam-exclusion"
									icon={interrogation}
									onClick={() => onFlip()}></IonIcon>
							</IonRow>
						) : null}
						{driverLicense && !flip ? (
							<IonRow className="content-card">
								<IonRow className="card__drivers-license-header">
									<IonRow
										id="pic"
										className={driverLicense.frontPictureUrl ? 'img-driver-license' : 'img-driver-license-default'}>
										{driverLicense.frontPictureUrl ? (
											<IonImg
												id="pic"
												onClick={() => setShowDriversLicenseModal(true)}
												src={driverLicense.frontPictureUrl}
											/>
										) : (
											<IonRow id="pic" className="default-row">
												<IonIcon onClick={() => navigate('/upload')} id="pic" icon={defaultImg}></IonIcon>
												<IonText id="pic">Driver License</IonText>
											</IonRow>
										)}
										{driverLicense.frontPictureUrl &&
											zoom &&
											(isPlatform('mobile') ? (
												<IonRow className="container-edit-dr-license" onClick={() => navigate('/upload')}>
													<IonRow className="edit-driv-license-image">
														<IonIcon icon={editImgIcon}></IonIcon>
													</IonRow>
												</IonRow>
											) : (
												<IonMenuToggle className="container-edit-dr-license" onClick={() => navigate('/upload')}>
													<IonRow className="edit-driv-license-image">
														<IonIcon icon={editImgIcon}></IonIcon>
													</IonRow>
												</IonMenuToggle>
											))}
									</IonRow>
								</IonRow>

								<IonRow className="card__drivers-license-body">
									<IonText className="span detail">Details</IonText>
									<IonRow className="border-driv-license"></IonRow>
									<IonText className="span margin-top-drv-content">Number:</IonText>
									<IonText className="span margin-bottom-drv-content">
										{driverLicense.number ? driverLicense.number : 'Not Available'}
										<CopyClipboard text={driverLicense.number} />
									</IonText>
									<IonText className="span">State:</IonText>
									<IonText className="span margin-bottom-drv-content">
										{driverLicense.state ? AddressStates.label(driverLicense.state) : 'Not Available'}
									</IonText>
									<IonText className="span">Expiration:</IonText>
									<IonText className="span margin-bottom-drv-content">
										{expDate ? moment(moment(expDate).format('MMM-DD-YYYY')).format('MMM DD YYYY') : 'Not Available'}
									</IonText>
									<IonText className="span">Home Address:</IonText>
									<IonText className="span margin-bottom-drv-content">
										{driverLicense.address ? driverLicense.address : 'Not Available'}
									</IonText>
								</IonRow>
							</IonRow>
						) : null}
					</IonRow>
				</IonRow>
			) : null}
			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground="driver-license-background"
					cssTextColor="about-white"
				/>
			) : null}
			{driverLicense.frontPictureUrl ? (
				<Modal
					showCloseIcon={false}
					open={showDriversLicenseModal}
					onClose={() => setShowDriversLicenseModal(false)}
					modalId="modal"
					center>
					{showBackImg ? (
						[
							driverLicense.backPictureUrl ? (
								<IonImg key={1} className="modal-img-license" src={driverLicense.backPictureUrl} />
							) : (
								<IonRow key={2} className={'img-driver-license-default'}>
									<IonRow key={3} className="default-row">
										<IonIcon
											onClick={() => {
												setShowDriversLicenseModal(false);
												navigate('/upload');
											}}
											id="pic"
											icon={defaultImg}></IonIcon>
									</IonRow>
								</IonRow>
							),
						]
					) : (
						<IonImg className="modal-img-license" src={driverLicense.frontPictureUrl} />
					)}
					<IonRow className="ion-no-border modal-cred-footer">
						<img
							onClick={() => setShowBackImg(!showBackImg)}
							className="auto-center"
							src={IconChangeImage}
							style={{ width: 60 }}
						/>
					</IonRow>
				</Modal>
			) : null}
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</div>
	);
};

export default DriversLicense;
