export module TaskModule {
  export interface TeenantTaskResponse {
    ambassador: Array<AmbasadorTask>;
    provider: Array<ProviderTask>;
  }

  export interface Task {
    createdAt?: string;
    createdBy?: string;
    estimatedEndDate?: string;
    id?: string;
    priority?: string;
    role?: string;
    status?: string;
    tenant?: string;
    title?: string;
    type?: string;
    updatedAt?: string;
    updatedBy?: string;
    _id?: string;
  }

  export interface ProviderTask extends Task {}

  export interface AmbasadorTask extends Task {}

  export enum TaskStatus {
    DONE = 'done',
    PENDING = 'pending',
  }
}