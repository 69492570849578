import API from '../shared/environement/api-routes';

import Interceptor from '../shared/environement/interceptor';
export const taskService = {
	userTask,
	documentTaskfindByKey,
	documentTaskRelationToMany,
	documentTaskcredentialingOrg,
	updateTask,
	skipNotificationReminder,
	getProviderTasks,
	createTask,
};
async function userTask(tenant: string, token: string, taskId: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/task/${taskId}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function documentTaskfindByKey(tenant: string, key: string, token: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/document-type/key`, {
			params: { key },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function documentTaskRelationToMany(tenant: string, token: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/provider/autocomplete`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function documentTaskcredentialingOrg(tenant: string, token: string, documentType: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/credentialing-organization/autocomplete`, {
			params: { documentType },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function updateTask(tenantId: string, taskId: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/task/${taskId}`, {
			method: 'PUT',
			data: { id: taskId, data },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function skipNotificationReminder(tenantId: string, taskId: string, notification: boolean) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/task/${taskId}/skip-push-notification-reminder`, {
			method: 'PUT',
			data: { id: taskId, skipPushNotificationReminder: notification },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function getProviderTasks(tenant: string, provider: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/provider/${provider}/task`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function createTask(tenantId: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/task`, {
			method: 'POST',
			data: { data },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
