import { ActionEntity } from "../../shared/entity/actionEntity";
import { DriverLicenseEntity } from "../../shared/entity/driverLicenseEntity";

import { DriverLicenseConstants } from "../constants";




const initialUser: DriverLicenseEntity = {}
const driverLicenseReducer = (state: DriverLicenseEntity = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case DriverLicenseConstants.SET_DRIVER_LICENSE:
                return action.payload;          
        default:
            return state;
    }
}
export default driverLicenseReducer;