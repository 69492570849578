import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './stepper.css';

const step1 = (step: number) => {
	let cls = 'step step1 active';
	if (step !== 1) {
		cls = 'step step1 completed';
	} else {
		cls = 'step step1 active';
	}
	return cls;
};
const step2 = (step: number) => {
	let cls = 'step step2';
	if (step === 1) {
		cls = 'step step2';
	}
	if (step === 2) {
		cls = 'step step2 active';
	}
	if (step === 3) {
		cls = 'step step2 completed';
	}
	if (step === 4) {
		cls = 'step step2 completed';
	}
	return cls;
};
const step3 = (step: number) => {
	let cls = 'step step3';
	if (step === 3) {
		cls = 'step step3 active';
	}
	if (step === 4) {
		cls = 'step step3 completed';
	}
	return cls;
};
const step4 = (step: number) => {
	let cls = 'step step4';
	if (step === 4) {
		cls = 'step step4 completed';
	}
	if (step === 5) {
		cls = 'step step4 completed';
	}
	return cls;
};

const Stepper: React.FC<{ step: number; hideLastStep: boolean; useStep4: boolean }> = (props) => {
	const dispatch = useDispatch();
	const { step, hideLastStep, useStep4 } = props;
	const [open, setOpen] = useState(false);
	return (
		<div className="stepper">
			{!useStep4 && (
				<>
					<div className={step1(step)}>
						<div className="step-label"></div>
						<div className="step-description">ID Picture</div>
					</div>
					<div className={!hideLastStep ? step2(step) : step3(step)}>
						<div className="step-label"></div>
						<div className="step-description">Selfie</div>
					</div>
					<div className={!hideLastStep ? step3(step) : step4(step)}>
						<div className="step-label"></div>
						<div className="step-description">Graduation Year</div>
					</div>
				</>
			)}
			{useStep4 && !hideLastStep ? (
				<div className={step4(step)}>
					<div className="step-label"></div>
					<div className="step-description">SSN</div>
				</div>
			) : null}
		</div>
	);
};

export default Stepper;
