import { IonIcon, IonImg, IonMenuToggle, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ActivateProfileDescImage from '../../../img/activate-profile-desc.svg';
import IconAllClear from '../../../img/icon-all-clear-black.png';
import interrogation from '../../../img/icons/interrogation.svg';
import baLogo from '../../../img/logos/board-actions-logo.svg';
import { BoardActionsActions } from '../../../redux/actions/boardActionsActions';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import { BoardActionsEntity } from '../../../shared/entity/boardActionsEntity';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionBoardActions from '../../AccordionBoardActions/AccordionBoardActions';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import './BoardActions.css';
const BoardActions: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard }) => {
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const boardActionsData = useSelector<any, any>((state) => state.boardActions!);
	const boardActions = useSelector<any, BoardActionsEntity[]>((state) => boardActionsData.boardActions!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__board-actions background-ba');
	const [documentDescription, setDocumentDescription] = useState(boardActionsData?.info);
	const [meesageCard, setMessageCard] = useState(false);
	const history = useHistory();
	const [exclusionFound, setExclusionFound] = useState(false);
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && data) {
				dispatch(WalletActivationActions.setWalletActivation(data));
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, [data]);
	useEffect(() => {
		if (data) {
			const type = data.tenants[0].provider.type.name;
			if (type === 'MD' || type === 'DO') {
				dispatch(BoardActionsActions.setLicense(data, boardActionsData));
			} else {
				setMessageCard(true);
			}
		}
	}, [data]);
	useEffect(() => {
		if (boardActionsData?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'exclusion').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								res.data.documentFields.forEach((element: any) => {
									if (element.key === 'bdacdesc') {
										setDocumentDescription(element.description);
										dispatch(BoardActionsActions.setLicense(data, { info: element.description }));
									}
								});
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [boardActionsData]);
	useEffect(() => {
		if (boardActionsData?.info !== '') {
			setDocumentDescription(boardActionsData.info);
		}
	}, [boardActionsData, documentDescription]);
	useEffect(() => {
		if (data) {
			if (data?.tenants[0]?.provider?.professionalLicenses) {
				data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
					if (element.type?.key === 'exclusion' && element.exclusionType === 'boardAction') {
						setExclusionFound(true);
					}
				});
			}
		}
	}, [data]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__board-actions background-ba');
		} else {
			setBack('card card__board-actions dlNoRotate background-ba');
		}
	};

	const AccordionContent = () => {
		return (
			<IonRow className="content-board-actions-front">
				{boardActions.length > 1 ? (
					boardActions?.map((element, index) => (
						<AccordionBoardActions
							key={index}
							title={element.actionTaken}
							num={index + 1}
							date={moment(element.orderDate).format('MMM DD YYYY')}>
							<IonRow
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
								key={index}>
								<IonText className="title-content-text">Reporting Entity: </IonText>
								<IonText className="content-text-board-actions">{element.reportingEntity}</IonText>
								<IonText className="title-content-text">Date of Order: </IonText>
								<IonText className="content-text-board-actions">
									{moment(element.orderDate).format('MMM DD YYYY')}
								</IonText>
								<IonText className="title-content-text">Basis: </IonText>
								<IonText className="content-text-board-actions">{element.description}</IonText>
							</IonRow>
						</AccordionBoardActions>
					))
				) : (
					<IonRow
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}>
						<IonText className="order-date-title-actions">
							{moment(boardActions[0]?.orderDate).format('MMM DD YYYY')}
						</IonText>
						<IonText className="span title uppercase-title">{boardActions[0]?.actionTaken}</IonText>
						<IonText className="title-content-text">Reporting Entity: </IonText>
						<IonText className="content-text-board-actions">{boardActions[0]?.reportingEntity}</IonText>
						<IonText className="title-content-text">Date of Order: </IonText>
						<IonText className="content-text-board-actions">
							{moment(boardActions[0]?.orderDate).format('MMM DD YYYY')}
						</IonText>
						<IonText className="title-content-text">Basis: </IonText>
						<IonText className="content-text-board-actions">{boardActions[0]?.description}</IonText>
					</IonRow>
				)}
			</IonRow>
		);
	};
	const AllClearContent = () => {
		return (
			<IonRow className="accordion-wrapper full-row-cols all-clear-content-board">
				{exclusionFound ? (
					<>
						<IonRow className="padding-content">
							<IonImg src={IconAllClear}></IonImg>
							<IonText className="all-clear-title-board"> All Clear</IonText>
						</IonRow>
						<IonRow className="padding-content">
							<IonText className="all-clear-subtitle">No state medical board actions reported.</IonText>
						</IonRow>
					</>
				) : (
					<NoInfoCard textColor="text-color-black" blackIcon={true} cardType="boact" />
				)}
			</IonRow>
		);
	};
	const PaymentDescriptionContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<div className="ion-padding">
					<IonImg src={baLogo} className="ion-margin img-board-payment"></IonImg>
				</div>
				<div className="ion-padding">
					{isPlatform('mobile') ? (
						<IonImg
							src={ActivateProfileDescImage}
							className="ion-margin"
							onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
					) : (
						<IonMenuToggle>
							<IonImg
								src={ActivateProfileDescImage}
								className="ion-margin"
								onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
						</IonMenuToggle>
					)}
				</div>
			</IonRow>
		);
	};
	return (
		<div className={back}>
			{walletActivation?.payCompleted && walletActivation?.approved && (
				<IonImg className="baLogo" src={baLogo}></IonImg>
			)}
			{!walletActivation?.payCompleted && !walletActivation?.approved ? (
				<IonRow className={`card___face ${!flip ? 'wrapper-zoom-board' : 'wrapper-zoom-flip'}`}>
					{!flip ? (
						<IonRow className="article-header__board-actions">
							<IonRow className="category-title">
								<IonText className="ion-padding-right">{title}</IonText>
							</IonRow>
						</IonRow>
					) : null}
					{!flip && (
						<>
							<PaymentDescriptionContent />
						</>
					)}
				</IonRow>
			) : (
				<IonRow className={`card___face ${!flip && !meesageCard ? 'wrapper-zoom-board' : 'wrapper-zoom-flip'}`}>
					{!flip ? (
						<IonRow className="article-header__board-actions">
							<IonRow className="category-title">
								<IonText className="ion-padding-right">{title}</IonText>
								{!flip && boardActions.length > 0 ? (
									<IonText className="bubble-board-board-actions">
										<span>{boardActions.length}</span>
									</IonText>
								) : null}
							</IonRow>
							<IonIcon
								id="about"
								className="icon-about-board-actions"
								icon={interrogation}
								onClick={() => onFlip()}></IonIcon>
						</IonRow>
					) : null}
					{meesageCard ? (
						<IonText className="bdac-message">This card is only applicable for MDs and DOs</IonText>
					) : !flip ? (
						<IonRow className="content-card-board-actions">
							{boardActions.length > 0 ? <AccordionContent /> : <AllClearContent />}
						</IonRow>
					) : null}
				</IonRow>
			)}
			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground="board-act-background"
					cssTextColor="about-black"
				/>
			) : null}
		</div>
	);
};

export default BoardActions;
