import { Clipboard } from '@capacitor/clipboard';
import { IonIcon, IonToast, ToastOptions } from '@ionic/react';
import { useState } from 'react';
import copyBlack from '../../img/icons/copy-black.svg';
import copy from '../../img/icons/copy.svg';
import './CopyToClipboard.css';

const CopyClipboard: React.FC<any> = (props) => {
	const { text, blackIcon } = props;
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const writeToClipboard = async (npi: string) => {
		try {
			let data: string = JSON.stringify(npi);
			if (data) {
				data = JSON.parse(data);
			}
			await Clipboard.write({
				string: data,
			}).catch((e) => {
				console.log(e);
			});
			setToast({
				color: 'success',
				header: 'Success',
				message: 'copied to clipboard',
			});
			showToast(toast);
		} catch (error) {
			return Promise.reject(error);
		}
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	return (
		<div className="mleft-clip-board">
			<IonIcon
				id="copy"
				className="copy"
				icon={!blackIcon ? copy : copyBlack}
				onClick={() => writeToClipboard(text)}></IonIcon>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
		</div>
	);
};

export default CopyClipboard;
