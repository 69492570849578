import { IonIcon, IonImg, IonMenuToggle, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import medicaresymbol from '../../../img/Medicare-Symbol.svg';
import ActivateProfileDescImage from '../../../img/activate-profile-desc-white.svg';
import interrogation from '../../../img/icons/light-blue-interrogation.svg';
import { MedicaidCardActions } from '../../../redux/actions/medicaidCardAction';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import AddressStates from '../../../shared/addressStates';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { MedicareCardEntity } from '../../../shared/entity/medicareCardEntity';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionMedicaidActions from '../../AccordionMedicaidActions/AccordionMedicaidAction';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import './MedicaidPending.css';
const MedicaidPending: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard }) => {
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const mcaidData = useSelector<ReduxStateEntity, any>((state) => state.mcaid!);
	const mcaid = useSelector<ReduxStateEntity, MedicareCardEntity[]>((state) => mcaidData.mcaid!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__medicaid-pending');
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [documentDescription, setDocumentDescription] = useState(mcaidData?.info);
	const history = useHistory();
	useEffect(() => {
		if (data) {
			dispatch(MedicaidCardActions.setLicense(data, mcaidData));
			dispatch(WalletActivationActions.setWalletActivation(data));
		}
	}, [data]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__medicaid-pending');
		} else {
			setBack('card card__medicaid-pending mcaidNoRotate');
		}
	};
	useEffect(() => {
		if (mcaidData?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'medicaid').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(MedicaidCardActions.setLicense(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [mcaidData]);
	useEffect(() => {
		if (mcaidData?.info !== '') {
			setDocumentDescription(mcaidData.info);
		}
	}, [mcaidData, documentDescription]);
	const AllClearContent = () => {
		return <NoInfoCard textColor="text-color-white" />;
	};
	const AccordionContent = () => {
		return (
			<IonRow className="ion-margin margin-top-mcaid-content">
				{mcaid.map((element, index) =>
					index < 4 && !zoom ? (
						<AccordionMedicaidActions
							key={index}
							title={AddressStates.label(element.state)!}
							classCss={3 === index || mcaid.length === 1 ? 'border-top-bottom-medicaid' : 'card-row-medicaid '}
							quantity={mcaid.length}
							collapse={false}
							status={element.status}>
							<IonRow className="mcaid-row-margin">
								<IonRow className="row-medicaid-data">
									<IonText className="title-accordion-mcaid-content">Medicaid Number:</IonText>
									<IonRow>
										<IonText className="data-accordion-mcaid-content">{element.number}</IonText>
										<CopyClipboard text={element.number} />
									</IonRow>
								</IonRow>
								<IonRow className="row-content-data-medicaid">
									<IonRow className="flex-col-row-medicaid">
										<IonText className="title-accordion-mcaid-content">Effective Date:</IonText>
										<IonText className="value-text-dates-medicaid badge">
											{moment(element.effectiveDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
									<IonRow className="flex-col-row-medicaid">
										<IonText className="title-accordion-mcaid-content">Renewal Date:</IonText>
										<IonText className="value-text-dates-medicaid badge">
											{moment(element.renewalDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
								</IonRow>
							</IonRow>
						</AccordionMedicaidActions>
					) : zoom ? (
						<AccordionMedicaidActions
							key={index}
							title={AddressStates.label(element.state)!}
							classCss={
								mcaid.length - 1 === index || mcaid.length === 1 ? 'border-top-bottom-medicaid' : 'card-row-medicaid '
							}
							quantity={mcaid.length}
							collapse={false}
							status={element.status}>
							<IonRow className="mcaid-row-margin">
								<IonRow className="row-medicaid-data">
									<IonText className="title-accordion-mcaid-content">Medicaid Number:</IonText>
									<IonRow>
										<IonText className="data-accordion-mcaid-content">{element.number}</IonText>
										<CopyClipboard text={element.number} />
									</IonRow>
								</IonRow>
								<IonRow className="row-content-data-medicaid">
									<IonRow className="flex-col-row-medicaid">
										<IonText className="title-accordion-mcaid-content">Effective Date:</IonText>
										<IonText className="value-text-dates-medicaid badge">
											{moment(element.effectiveDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
									<IonRow className="flex-col-row-medicaid">
										<IonText className="title-accordion-mcaid-content">Renewal Date:</IonText>
										<IonText className="value-text-dates-medicaid badge">
											{moment(element.renewalDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
								</IonRow>
							</IonRow>
						</AccordionMedicaidActions>
					) : null
				)}
			</IonRow>
		);
	};
	const PaymentDescriptionContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<div className="ion-padding">
					<IonImg src={medicaresymbol} className="ion-margin img-board-payment"></IonImg>
				</div>
				<div className="ion-padding">
					{isPlatform('mobile') ? (
						<IonImg
							src={ActivateProfileDescImage}
							className="ion-margin"
							onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
					) : (
						<IonMenuToggle>
							<IonImg
								src={ActivateProfileDescImage}
								className="ion-margin"
								onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
						</IonMenuToggle>
					)}
				</div>
			</IonRow>
		);
	};

	return (
		<div className={back}>
			{!flip ? (
				!walletActivation?.payCompleted && !walletActivation?.approved ? (
					<div className={`${!zoom ? 'wrapper-mcaid' : 'wrapper-zoom-mcaid'}`}>
						<IonRow className="mcaid-content-card">
							<IonRow className="article-header-medicaid-pending">
								<IonIcon className="card-medicaid-symbol" icon={medicaresymbol}></IonIcon>
								<IonRow className="row-title-medicaid">{title}</IonRow>
							</IonRow>

							<PaymentDescriptionContent />
						</IonRow>
					</div>
				) : (
					<div className={`${!zoom ? 'wrapper-mcaid' : 'wrapper-zoom-mcaid'}`}>
						<IonRow className="mcaid-content-card">
							<IonRow className="article-header-medicaid-pending">
								<IonIcon className="card-medicaid-symbol" icon={medicaresymbol}></IonIcon>
								<IonRow className="row-title-medicaid">
									{title}
									{!flip && mcaid.length > 0 ? (
										<IonText className="bubble-mcaid">
											<span>{mcaid.length}</span>
										</IonText>
									) : null}
								</IonRow>
								<IonIcon
									id="about"
									className="icon-about-medicare"
									icon={interrogation}
									onClick={() => onFlip()}></IonIcon>
							</IonRow>
						</IonRow>
						{walletActivation?.approved && !flip && mcaid.length === 0 ? (
							<IonRow className="center-no-info-card">
								<NoInfoCard textColor="text-color-white" blackIcon={false} cardType="mdcaid" />{' '}
							</IonRow>
						) : null}
						{!flip ? mcaid.length > 0 ? <AccordionContent /> : null : null}
					</div>
				)
			) : null}

			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground="card card__medicaid-pending mcaidBack"
					cssTextColor="about-white"
				/>
			) : null}
		</div>
	);
};

export default MedicaidPending;
