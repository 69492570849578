import {
	IonContent,
	IonHeader,
	IonIcon,
	IonLabel,
	IonLoading,
	IonMenu,
	IonMenuToggle,
	IonPage,
	IonRouterOutlet,
	IonRow,
	IonTitle,
	IonToast,
	IonToolbar,
	useIonToast,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Route, RouteComponentProps } from 'react-router-dom';
import activeActions from '../../img/main-menu/icon-tab-actions-active.svg';
import actions from '../../img/main-menu/icon-tab-actions.svg';
import activeAmbassador from '../../img/main-menu/icon-tab-ambassador-active.svg';
import ambassador from '../../img/main-menu/icon-tab-ambassador.svg';
import activeHistory from '../../img/main-menu/icon-tab-history-active.svg';
import history from '../../img/main-menu/icon-tab-history.svg';
import activeWallet from '../../img/main-menu/icon-tab-my-wallet-active.svg';
import wallet from '../../img/main-menu/icon-tab-my-wallet.svg';
import activeSettings from '../../img/main-menu/icon-tab-settings-active.svg';
//css
import settings from '../../img/main-menu/icon-tab-settings.svg';
import CAQH from '../../pages/caqh/CAQH';
import ChangePassword from '../../pages/change-password/change-password';
import Chat from '../../pages/chat/chat';
import DeleteAccount from '../../pages/delete-account/DeleteAccount';
import DriversLicenseUpload from '../../pages/drivers-license/DriversLicense';
import History from '../../pages/history/History';
import MissingDocument from '../../pages/missingDocument/MissingDocument';
import MyProfile from '../../pages/my-profile/MyProfile';
import MyWallet from '../../pages/my-wallet-web-version/MyWalletWebVersion';
import PassportFile from '../../pages/passportFile/passportFile';
import PendingActions from '../../pages/pending-actions/PendingActions';
import PrivacyAndSecurity from '../../pages/privacy-and-security/PrivacyAndSecurity';
import Questionnaire from '../../pages/questionnaire/Questionnaire';
import Settings from '../../pages/settings/Settings';
import { notificationActions } from '../../redux/actions/notificationActions';
import { PendingActionsActions } from '../../redux/actions/pendingActions';
import { tabsActions } from '../../redux/actions/tabsActions';
import { walletActions } from '../../redux/actions/walletActions';
import { conversationService } from '../../services/conversation.service';
import { messageService } from '../../services/message.service';
import { pushNotificationService } from '../../services/pushNotifications.service';
import { PendingEntity } from '../../shared/entity/pendingTaskEntity';
import { PushNotificationModule } from '../../shared/entity/pushNotification.module';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import '../../theme/main-style.css';
import DEALicense from '../Cards/DEALicense/DEALicense';
import DriversLicense from '../Cards/DriversLicense/DriversLicense';
//import MainMenu from '../MainMenu/MainMenu';
import { motion } from 'framer-motion';
import ActivateWalletPage from '../../pages/activateWallet/activateWalletPage';
import CAQHLogin from '../../pages/caqh-login/CAQHLogin';
import PaymentPage from '../../pages/paymentPage/paymentPage';
import VerifyIdentity from '../../pages/verifyIdentity/verifyIdentity';
import { PropsDataEntity } from '../../shared/entity/propsData.module';
import ABMS from '../Cards/ABMS/ABMS';
import BoardActions from '../Cards/BoardActions/BoardActions';
import DigitalSignature from '../Cards/DigitalSignature/DigitalSignature';
import LEIE from '../Cards/LEIE/LEIE';
import MedicaidPending from '../Cards/MedicaidCard/MedicaidPending';
import MedicalLicense from '../Cards/MedicalLicense/MedicalLicense';
import MedicarePending from '../Cards/MedicareCard/MedicarePending';
import NPPES from '../Cards/NPPES/NPPES';
import Passport from '../Cards/Passport/passport';
import PayerActions from '../Cards/Payer/Payer';
import ActivatePayors from '../Cards/Payer/PayerActivation';
import SamExclusions from '../Cards/SamExclusions/SamExclusions';
import ShareWallet from '../Cards/ShareWallet/ShareWallet';
import './MainMenu.css';
const MainMenu: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const [pendingTaskCount, setPendingTaskCount] = useState(0);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const [showLoading, setShowLoading] = useState(false);
	const [settingsIcon, setSettingsIcon] = useState(settings);
	const [walletIcon, setWalletIcon] = useState(activeWallet);
	const [historyIcon, setHistoryIcon] = useState(history);
	const [ambassadorIcon, setAmbassadorIcon] = useState(ambassador);
	const [profileIcon, setProfileIcon] = useState(actions);
	const resolved = useSelector<any, any>((state) => state.login.resolved!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [selectedClass, setSelectedClass] = useState('wallet');
	const [toast, setToast] = useState<ToastOptions>({});
	const notificationConversationCount = useSelector<any, any>(
		(state) => state.notification.conversationNotificationCount!
	);
	const [cardSelected, setCardSelected] = useState(0);
	const CARDS_INFO = [
		{
			id: '2234514754c26442953e982',
			name: 'dea-license',
			title: 'DEA License',
			zoom: false,
			props: props,
			class: 'dea-card',
		},
		{
			id: 'sdf3',
			name: 'drivers-license',
			title: "Driver's License",
			zoom: false,
			props: props,
			class: 'drv-license-card',
		},

		{
			id: 'sdfsd7878sdf',
			name: 'nppes',
			title: 'National Provider Identifier (NPI)',
			zoom: false,
			class: 'nppes-card',
		},

		{
			id: '611ff514754c26442953e982',
			name: 'passport',
			title: 'Passport',
			zoom: false,
			props: props,
			class: 'passport-card ',
		},
		{
			id: '21234523423S9431',
			name: 'caqh-login',
			title: 'Passwords',
			zoom: false,
			class: 'passwords-card',
		},
		{
			id: '145119745',
			name: 'sam-exclusion',
			title: 'SAM Exclusions',
			zoom: false,
			props: props,
			class: 'sam-exclusion-card',
		},
		{
			id: '21234523423S94312',
			name: 'signature-card',
			title: 'Digital Signature',
			zoom: false,
		},
		{
			id: '212345147SS982',
			name: 'abms',
			title: 'ABMS',
			zoom: false,
			class: 'abms-card',
		},
		{
			id: '21234523423S982',
			name: 'board-actions',
			title: 'Board Actions',
			zoom: false,
			class: 'board-actions-card',
		},
		{
			id: '12313',
			name: 'medical-license',
			title: 'Medical License(s)',
			zoom: false,
			class: 'medical-license-card',
		},
		{
			id: '21234523423S98243',
			name: 'leie',
			title: 'LEIE (OIG)',
			zoom: false,
			class: 'leie-card',
		},
		{
			id: '45718745',
			name: 'medicare-pending',
			title: 'Medicare',
			zoom: false,
			class: 'medicare-card',
		},

		{
			id: '14599745',
			name: 'medicaid-pending',
			title: 'Medicaid',
			zoom: false,
			class: 'medicaid-card',
		},

		{
			id: '21234523423S943',
			name: 'payer-actions',
			title: 'My Payors',
			zoom: false,
			class: 'payors-card',
		},
	];

	const [cards, setCards] = useState(CARDS_INFO);

	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const pendingTasks = useSelector<ReduxStateEntity, PendingEntity>((state) => state.pendingTasks!);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [present, dismiss] = useIonToast();

	const [openedApp, setOpenedApp] = useState<any>(null);
	useEffect(() => {
		if (pendingTasks?.providerTasks?.length > 0) {
			getPendingTask();
		}
	}, [pendingTasks]);
	const navigate = (route: string, pushToHistory: boolean) => {
		switch (route) {
			case '/settings':
				setSettingsIcon(activeSettings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setProfileIcon(actions);
				setSelectedClass('settings');

				break;
			case '/wallet':
				setWalletIcon(activeWallet);
				setSettingsIcon(settings);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setProfileIcon(actions);
				setSelectedClass('wallet');
				dispatch(tabsActions.wallet(true));
				break;
			case '/history':
				setHistoryIcon(activeHistory);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setAmbassadorIcon(ambassador);
				setProfileIcon(actions);
				setSelectedClass('history');
				break;
			case '/chat':
				setAmbassadorIcon(activeAmbassador);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setProfileIcon(actions);
				setSelectedClass('chat');
				break;
			case '/actions':
				setProfileIcon(activeActions);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setSelectedClass('actions');
				break;
			default:
				setProfileIcon(actions);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setSelectedClass('wallet');
				break;
		}
		if (pushToHistory) {
			props.history.push('/tabs' + route);
		}
	};

	useEffect(() => {
		pushNotificationService.pushNotificationsSubject$.subscribe((notification: any) => {
			showNotification('Just Now', notification.body, notification);
		});
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};
		window.addEventListener('online', handleStatusChange);
		window.addEventListener('offline', handleStatusChange);
		return () => {
			window.removeEventListener('offline', handleStatusChange);
		};
	}, []);

	useEffect(() => {
		if (!isOnline) {
			showToast({
				color: 'warning',
				header: 'Warning',
				message: 'Bad Internet Connection',
			});
		}
	}, [isOnline]);
	useEffect(() => {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		if (tmpUserToken && props.history.location.pathname !== '/tabs/wallet') {
			props.history.push('/');
		}
		if (resolved) {
			showToast({
				color: 'success',
				header: 'Success',
				message: 'Welcome',
			});
		}
		setShowLoading(true);

		if (tmpUserToken) {
			console.log('GET WALLET');
			dispatch(walletActions.authorization(tmpUserToken));
		}
	}, []);
	useEffect(() => {
		navigate(props.location.pathname.replace('/tabs', ''), false);
	});
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && data) {
				dispatch(PendingActionsActions.setPendingActions(data));
				setShowLoading(false);
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
			setShowLoading(false);
		};
	}, [data]);
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	const showShareCard = () => {
		setOpenedApp({
			id: '21234523423S94314',
			name: 'share',
			title: 'Share Your Wallet',
			zoom: true,
		});
	};
	const CardTypeZoomed: React.FC<PropsDataEntity> = ({ data }) => {
		switch (data.name) {
			case 'medical-license':
				return <MedicalLicense title={data.title} zoom={data.zoom} />;
			case 'drivers-license':
				return <DriversLicense title={data.title} zoom={data.zoom} props={data.props} />;
			case 'passport':
				return <Passport title={data.title} zoom={data.zoom} props={data.props} />;
			case 'nppes':
				return <NPPES title={data.title} zoom={data.zoom} />;
			case 'dea-license':
				return <DEALicense title={data.title} zoom={data.zoom} showShareCard={showShareCard} props={data.props} />;
			case 'abms':
				return <ABMS title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'board-actions':
				return <BoardActions title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'payer-actions':
				return <PayerActions title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'medicare-pending':
				return <MedicarePending title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'medicaid-pending':
				return <MedicaidPending title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'sam-exclusion':
				return <SamExclusions title={data.title} zoom={data.zoom} props={data.props} showShareCard={showShareCard} />;
			case 'leie':
				return <LEIE title={data.title} zoom={data.zoom} />;
			case 'caqh-login':
				return <CAQHLogin title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'share':
				return <ShareWallet title={data.title} zoom={data.zoom} props={data.props} />;
			case 'signature-card':
				return (
					<DigitalSignature title={data.title} zoom={data.zoom} showShareCard={showShareCard} props={data.props} />
				);
			default:
				return null;
		}
	};
	function getPendingTask() {
		let tasksNumber: number = 0;
		pendingTasks.providerTasks.forEach((element) => {
			if (element.status !== 'done') {
				tasksNumber++;
			}
		});
		setPendingTaskCount(tasksNumber);
	}
	const showNotification = (title: string, message: string, notification: any) => {
		const handlePreResult = handleNotificationCountType(notification);
		handlePreResult.pre();
	};

	const handleNotificationCountType = (notification: any) => {
		switch (notification.data.type) {
			case PushNotificationModule.NOTIFICATION_TYPES.MESSAGE:
				messageService.messageNotificationSubject$.next(notification);
				conversationService.conversationNotificationSubject$.next(notification);
				return { pre: doMessageNotificationAction, dismiss: onDismissMessageNotificationAction };
			case PushNotificationModule.NOTIFICATION_TYPES.IDENTITY_VERIFY:
				messageService.messageNotificationSubject$.next(notification);
				return { pre: () => {}, dismiss: () => {} };
			default:
				return { pre: () => {}, dismiss: () => {} };
		}
	};

	const doMessageNotificationAction = () => {
		localStorage.setItem('notificationConversationCount', notificationConversationCount + 1);
		dispatch(notificationActions.addConversationNotificationCount());
	};

	const onDismissMessageNotificationAction = () => {
		props.history.push('/chat');
	};
	function optionTitle() {
		switch (props.location.pathname) {
			case '/tabs/wallet':
				return 'My Wallet';
			case 'actions':
				return 'My Tasks';
			case 'chat':
				return 'Ambassador';
			case 'history':
				return 'Calendar';

			default:
				return 'Settings';
		}
	}
	function showCardData() {
		let index = 0;
		if (openedApp) {
			index = cards.findIndex(function (element) {
				return element.name === openedApp.name;
			});
		}

		return (
			<motion.div initial={{ x: '100%' }} animate={{ x: 0 }} transition={{ duration: 1 }}>
				<div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
					<div style={{ margin: '20px' }}>
						<CardTypeZoomed data={cards[index]} />
					</div>
				</div>
			</motion.div>
		);
	}
	function handleValueFromMyWallet(value: number) {
		cards[value].zoom = true;
		setOpenedApp(cards[value]);
	}

	return (
		<IonPage className="tabsPage">
			<IonMenu side="end" contentId="main-content" className="menu-class-cred ">
				<IonHeader>
					<IonToolbar>
						<div className="header-cred-menu">
							<IonTitle>{optionTitle()}</IonTitle>
							<IonMenuToggle>
								<span>close</span>
							</IonMenuToggle>
						</div>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-padding">{showCardData()}</IonContent>
			</IonMenu>
			<IonContent id="main">
				<IonRouterOutlet>
					<Route
						path={props.match.url + '/wallet'}
						render={(props) => <MyWallet handleValueFromMyWallet={handleValueFromMyWallet} {...props} />}
					/>
					{/* <Route path={props.match.url + '/wallet'} component={MyWallet} /> */}
					<Route path={props.match.url + '/history'} component={History} />
					<Route path={props.match.url + '/settings'} component={Settings} />
					<Route path={props.match.url + '/chat'} component={Chat} />
					<Route path={props.match.url + '/actions'} component={PendingActions} />
					<Route path={props.match.url + '/profile'} component={MyProfile} />
					<Route path={props.match.url + '/delete-account'} component={DeleteAccount} />
					<Route path={props.match.url + '/privacy'} component={PrivacyAndSecurity} />
					<Route path={props.match.url + '/change-password'} component={ChangePassword} />
					{/* <Route path={props.match.url + '/upload'} component={DriversLicense} /> */}
					<Route path={props.match.url + '/upload'} render={(props) => <DriversLicenseUpload prop={props} />} />
					<Route path={props.match.url + '/upload-passport'} component={PassportFile} />
					<Route path={props.match.url + '/missing-document'} render={(props) => <MissingDocument prop={props} />} />
					<Route path={props.match.url + '/activate-payors'} render={(props) => <ActivatePayors prop={props} />} />
					<Route path={props.match.url + '/collect-document'} render={(props) => <CAQH prop={props} />} />
					<Route path={props.match.url + '/dea-license'} render={(props) => <DEALicense props={props} />} />
					<Route path={props.match.url + '/onb-questionnaire'} render={(props) => <Questionnaire prop={props} />} />
					<Route
						path={props.match.url + '/activate-wallet'}
						render={(props) => <PaymentPage prop={props} activateStep={true} />}
					/>
					<Route
						path={props.match.url + '/payment-wallet'}
						render={(props) => <ActivateWalletPage prop={props} activateStep={true} />}
					/>
					<Route
						path={props.match.url + '/verify-identity'}
						render={(props) => <VerifyIdentity prop={props} nextStep={true} />}
					/>
				</IonRouterOutlet>
				<div className="div-menu-left">
					<IonRow
						className={` option-menu ${selectedClass == 'wallet' ? 'selected-tab' : ''}`}
						onClick={() => navigate('/wallet', true)}>
						<IonIcon icon={walletIcon} className="icon-size-menu" />
						<IonLabel>My Wallet</IonLabel>
					</IonRow>

					<IonRow
						className={` option-menu ${selectedClass == 'actions' ? 'selected-tab' : ''}`}
						onClick={() => navigate('/actions', true)}>
						<IonIcon icon={profileIcon} className="icon-size-menu" />
						{pendingTaskCount > 0 && (
							<div id="row" className="container-tasks-quantity">
								<span id="row">{pendingTaskCount}</span>
							</div>
						)}

						<IonLabel>My Tasks</IonLabel>
					</IonRow>
					<IonRow
						className={` option-menu ${selectedClass == 'chat' ? 'selected-tab' : ''}`}
						onClick={() => navigate('/chat', true)}>
						<IonIcon icon={ambassadorIcon} className="icon-size-menu" />
						<IonLabel>Ambassador</IonLabel>
					</IonRow>
					<IonRow
						className={` option-menu ${selectedClass == 'history' ? 'selected-tab' : ''}`}
						onClick={() => navigate('/history', true)}>
						<IonIcon icon={historyIcon} className="icon-size-menu" />
						<IonLabel>Calendar</IonLabel>
					</IonRow>
					<IonRow
						className={` option-menu ${selectedClass == 'settings' ? 'selected-tab' : ''}`}
						onClick={() => navigate('/settings', true)}>
						<IonIcon icon={settingsIcon} className="icon-size-menu" />
						<IonLabel>Settings</IonLabel>
					</IonRow>
				</div>
			</IonContent>

			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>

			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</IonPage>
	);
};
export default MainMenu;
