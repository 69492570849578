import { IonButton, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { add } from 'ionicons/icons';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ChevronDownBlue from '../../img/icon-chevron-down-blue.svg';
import ChevronUpBlue from '../../img/icon-chevron-up-blue.svg';
import { WorkHistoryEntity } from '../../shared/entity/workHistoryEntity';
import './AccordionWorkHistory.css';
interface ContainerProps {
	workHistory: WorkHistoryEntity;
	updateWorkHistory: (position: number, history: WorkHistoryEntity) => void;
	id: number;
}
const AccordionWorkHistory: React.FC<ContainerProps> = ({ workHistory, updateWorkHistory, id }) => {
	const [dateMessageError, setDateMessageError] = useState('');
	const [isOpen, setOpen] = useState(false);
	const [inputs, setInputs] = useState({
		historyTitle: '',
		historyStartDate: '',
		historyEndDate: '',
		historyDescription: '',
	});
	const inputPropsStartDate = {
		name: 'historyStartDate',
	};
	const inputPropsEndDate = {
		name: 'historyEndDate',
	};
	useEffect(() => {
		setInputs({
			historyTitle: workHistory.title,
			historyStartDate: workHistory.startDate,
			historyEndDate: workHistory.endDate,
			historyDescription: workHistory.description,
		});
	}, []);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};
	const handleChangeDate = (e: any, name: string) => {
		const { _d } = e;
		const datetoSave = _d;
		setInputs((inputs) => ({ ...inputs, [name]: datetoSave }));
	};
	//update a work history
	function updateHistory() {
		if (
			inputs.historyEndDate &&
			inputs.historyStartDate &&
			moment(inputs.historyEndDate).isAfter(inputs.historyStartDate)
		) {
			setDateMessageError('');
			const req: WorkHistoryEntity = {
				title: inputs.historyTitle,
				description: inputs.historyDescription,
				startDate: inputs.historyStartDate,
				endDate: inputs.historyEndDate,
				rowId: workHistory.rowId,
			};
			updateWorkHistory(id, req);
		} else {
			setDateMessageError('End date must be after start date');
		}
	}
	return (
		<div className="accordion-wrapper full-row-cols ion-margin-top">
			<IonRow id="row" className="row-title-history" style={{ width: '100%' }} onClick={() => setOpen(!isOpen)}>
				<div>
					<IonText className="name-history">{workHistory.title}</IonText>
					<IonText className="date-history">
						{moment(inputs.historyStartDate).format('MMM DD-YYYY')}
						{' - '}
						{moment(inputs.historyEndDate).format('MMM-DD-YYYY')}
					</IonText>
				</div>
				{isOpen ? (
					<IonImg id="row" className="row-icon" src={ChevronUpBlue} onClick={() => setOpen(!isOpen)} />
				) : (
					<IonImg id="row" className="row-icon" src={ChevronDownBlue} onClick={() => setOpen(!isOpen)} />
				)}
			</IonRow>
			<IonRow className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
				<IonItem className="input-inside ion-margin-top ion-margin-bottom item-width">
					<IonLabel position="stacked">Title</IonLabel>
					<IonInput
						className="ion-padding"
						name="historyTitle"
						type="text"
						value={inputs.historyTitle}
						onIonChange={handleChange}></IonInput>
				</IonItem>
				<div className="flex-div ion-justify-content-between item-width">
					<div className={`input-date-border-style ion-margin-top ion-margin-bottom startDateStyle`}>
						<IonLabel position="stacked">Start Date</IonLabel>
						<Datetime
							className="input-font-size-tasks test"
							timeFormat={false}
							inputProps={inputPropsStartDate}
							renderInput={(props) => {
								return (
									<input
										{...props}
										value={inputs.historyStartDate ? moment(inputs.historyStartDate).format('MMM-DD-YYYY') : ''}
									/>
								);
							}}
							onChange={(e) => handleChangeDate(e, 'historyStartDate')}
						/>
					</div>
					<div className={`input-date-border-style ion-margin-top ion-margin-bottom endDateStyle`}>
						<IonLabel position="stacked">End Date</IonLabel>
						<Datetime
							className="input-font-size-tasks test"
							timeFormat={false}
							inputProps={inputPropsEndDate}
							renderInput={(props) => {
								return (
									<input
										{...props}
										value={inputs.historyEndDate ? moment(inputs.historyEndDate).format('MMM-DD-YYYY') : ''}
									/>
								);
							}}
							onChange={(e) => handleChangeDate(e, 'historyEndDate')}
						/>
						{dateMessageError !== '' ? <IonLabel className="error-date">{dateMessageError}</IonLabel> : null}
					</div>
				</div>
				<IonItem className="input-inside ion-margin-top ion-margin-bottom item-width">
					<IonLabel position="stacked">Description</IonLabel>
					<IonTextarea
						name="historyDescription"
						onIonChange={handleChange}
						value={inputs.historyDescription}
						rows={4}></IonTextarea>
				</IonItem>
				<div className="flex-div ion-justify-content-center item-width">
					<IonButton color="medium" onClick={() => updateHistory()}>
						<IonIcon slot="start" icon={add} />
						Update
					</IonButton>
				</div>
			</IonRow>
			<IonRow style={{ width: '100%' }} className="line-payer"></IonRow>
		</div>
	);
};

export default AccordionWorkHistory;
