import { ActionEntity } from '../../shared/entity/actionEntity';
import { ProviderSignatureEntity } from '../../shared/entity/providerSignatureEntity';
import { ProviderSignatureConstants } from '../constants/providerSignatureConstants';

const initialUser: ProviderSignatureEntity = {
	providerEmail: '',
	username: '',
	datetime: '',
	providerSignature: [],
	lastUpdated: '',
	id: '',
	provider: '',
};
const providerSignatureReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case ProviderSignatureConstants.SET_SIGNATURE:
			return action.payload;
		default:
			return state;
	}
};
export default providerSignatureReducer;
