import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import arrowBack from '../../img/icons/backArrow.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import { onBoardingService } from '../../services/onBoarding.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import './createAccount.css';
const CreateAccount: React.FC<RouteComponentProps> = (props) => {
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [show, setShow] = useState(false);
	const [showNew, setShowNew] = useState(false);
	const [valid, setValid] = useState(false);
	const [error, setError] = useState(false);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [showLoading, setShowLoading] = useState(false);
	const htmlInputRef = useRef<HTMLFormElement | null>(null);
	const [emailError, setEmailError] = useState(false);
	const [inputs, setInputs] = useState({
		email: '',
		newPassword: '',
		confirmPassword: '',
	});
	const { executeRecaptcha } = useGoogleReCaptcha();

	const navigate = (route: string) => {
		props.history.push(route);
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
		switch (name) {
			case 'email':
				user.email = value;
				break;
			case 'confirmPassword':
				if (value === inputs.newPassword) {
					setValid(true);
					setError(false);
				} else {
					setValid(false);
					setError(true);
				}
				break;

			case 'newPassword':
				if (inputs.confirmPassword.length > 0 && value === inputs.confirmPassword) {
					setValid(true);
					setError(false);
				} else {
					if (inputs.confirmPassword.length > 0) {
						setValid(false);
						setError(true);
					}
				}
				break;
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (!executeRecaptcha) {
			return;
		}
		if (validateFormFields(e.target[0].value, e.target[1].value, e.target[2].value)) {
			const recaptchaToken = await executeRecaptcha('submit');

			onBoardingService.createAccount(e.target[0].value, e.target[1].value, user.npi!, recaptchaToken).then(
				(res) => {
					if (res?.data) {
						localStorage.setItem('NuCoUserToken', JSON.stringify(res?.data));
						localStorage.setItem('NuCoUserStage', JSON.stringify('confirm'));
						localStorage.setItem('NewAccountEmail', JSON.stringify(e.target[0].value));
						user.token = JSON.parse(localStorage.getItem('NuCoUserToken')!);
						navigate('/confirm-email');
					} else {
						setToast({
							color: 'danger',
							header: 'Failure',
							message: 'Oops, something went wrong, try again',
						});
						showToast(toast);
					}
				},
				(error) => {
					setToast({
						color: 'danger',
						header: 'Failure',
						message: error.response.data,
					});
					console.log(error);
					showToast(toast);
				}
			);
			setShowLoading(true);
		}
	};

	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	const validForm = () => {
		if (inputs.newPassword?.length < 8 && inputs.newPassword?.length > 0) {
			return true;
		} else {
			return false;
		}
	};
	//verify the form is filled correctly
	const validateFormFields = (email: String, password: String, confirmPassword: String) => {
		if (password?.length < 8) {
			return false;
		} else if (password !== confirmPassword) {
			return false;
		}
		return validEmail(email);
	};
	const validEmail = (email: String) => {
		const re = /(.+)@(.+){2,}\.(.+){2,}/;
		const isValid = re.test(email.toLowerCase());
		setEmailError(!isValid);
		return isValid;
	};
	return (
		<IonPage className="loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>

			<IonContent className="ion-padding max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>
						Create Account
					</IonTitle>
				</IonToolbar>
				<IonRow className="user-title-onboarding">
					<IonText className="user-title-onboarding-name">{user.name}</IonText>
					<IonText className="user-title-onboarding-npi">{user.npi}</IonText>
				</IonRow>

				<form ref={htmlInputRef} name="form" onSubmit={handleSubmit} autoComplete="on">
					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Email</IonLabel>
						<IonRow className="row">
							<IonInput
								required
								className="ion-padding"
								name="email"
								type="text"
								onIonChange={handleChange}
								autocomplete={'email'}></IonInput>
						</IonRow>
						{emailError && (
							<IonText color="danger">
								<small>{'Enter a valid email address'}</small>
							</IonText>
						)}
					</IonItem>
					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Password</IonLabel>
						<IonRow className="row">
							<IonInput
								required
								className="ion-padding"
								name="newPassword"
								type={showNew ? 'text' : 'password'}
								onIonChange={handleChange}></IonInput>
							<IonIcon icon={showNew ? eye : eyeOff} onClick={() => setShowNew(!showNew)}></IonIcon>
						</IonRow>
						{validForm() && (
							<IonText color="danger">
								<small>{'password must have atleast 8 characters'}</small>
							</IonText>
						)}
					</IonItem>
					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Confirm Password</IonLabel>
						<IonRow className="row">
							<IonInput
								required
								className="ion-padding"
								name="confirmPassword"
								type={show ? 'text' : 'password'}
								onIonChange={handleChange}></IonInput>
							<IonIcon icon={show ? eye : eyeOff} onClick={() => setShow(!show)}></IonIcon>
						</IonRow>
						{!valid && error && (
							<IonText color="danger">
								<small>{'passwords must match'}</small>
							</IonText>
						)}
					</IonItem>

					<IonRow className="save-row-create-account">
						{' '}
						<IonButton
							className="onBoardingSaveButtom"
							disabled={!htmlInputRef.current?.checkValidity()}
							color="secondary"
							shape="round"
							type="submit">
							{' '}
							Next{' '}
						</IonButton>
					</IonRow>
				</form>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={1000}
			/>
		</IonPage>
	);
};

export default CreateAccount;
