import { Dispatch } from 'redux';
import { PasswordEntity } from '../../shared/entity/passwordEntity';
import { PasswordConstant } from '../constants/passwordConstants';

export const PasswordActions = {
	setPasswords,
};

function setPasswords(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: PasswordEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.documentFields) {
					let username = element.type.documentFields.filter(function (el: any) {
						return el.key.toString().indexOf('username') > -1;
					});
					let password = element.type.documentFields.filter(function (el: any) {
						return el.key.toString().indexOf('password') > -1;
					});
					if (username.length > 0 && password.length > 0) {
						const exclusion: PasswordEntity = {
							title:
								element.type.key === 'medical-license'
									? element.issuedBy?.name
										? element.issuedBy.name
										: ''
									: element.type.name,
							userName: element.username,
							userPassword: element.password,
							description: element.type.description,
							id: element.id,
							permissionToLogin: element.permissionToLogin,
						};
						exclusions.push(exclusion);
					}
				}
			});
		}
		if (exclusions) {
			exclusions.sort(function (a, b) {
				return a.title.toUpperCase() < b.title.toUpperCase()
					? -1
					: a.title.toUpperCase() > b.title.toUpperCase()
					? 1
					: 0;
			});

			dispatch(set({ passwords: exclusions, info: document?.info || '', fieldDesc: document?.fieldDesc || '' }));
		}
	};
}

function set(payload: any) {
	return { type: PasswordConstant.SET_PASSWORDS, payload };
}
