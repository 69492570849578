import { IonIcon, IonRow } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AboutThat from '../../components/AboutThat/AboutThat';
import PasswordsCAQH from '../../components/Cards/PasswordsCAQH/PasswordsCAQH';
import NoInfoCard from '../../components/NoInfoCard/NoInfoCard';
import interrogation from '../../img/icons/light-blue-interrogation.svg';
import { PasswordActions } from '../../redux/actions/passwordActions';
import { walletActions } from '../../redux/actions/walletActions';
import { filesService } from '../../services/files.service';
import { CardInfoEntity } from '../../shared/entity/cardInfo.module';
import { PasswordEntity } from '../../shared/entity/passwordEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './CAQHLogin.css';
const CAQHLogin: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard }) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const passwordsData = useSelector<ReduxStateEntity, any>((state) => state.passwords!);
	const passwords = useSelector<ReduxStateEntity, PasswordEntity[]>((state) => passwordsData.passwords!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card-password card-password-pending');
	const [documentDescription, setDocumentDescription] = useState(passwordsData?.info);
	const [fieldDescription, setFieldDescription] = useState(passwordsData?.fieldDesc);
	const [modalPassword, setModalPassword] = useState(false);
	const modal = useRef<HTMLIonModalElement>(null);
	const input = useRef<HTMLIonInputElement>(null);
	const [passEdited, setPassEdited] = useState('');

	useEffect(() => {
		if (data) {
			dispatch(PasswordActions.setPasswords(data, passwordsData));
		}
	}, [data]);
	useEffect(() => {
		if (passwordsData?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'passwordCardDescription').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(
									PasswordActions.setPasswords(data, {
										info: res.data.description,
										fieldDesc: passwordsData?.fieldDesc,
									})
								);
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING PASSWORD TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [passwordsData]);
	useEffect(() => {
		if (passwordsData?.fieldDesc === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'caqh-login').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								res.data.documentFields.forEach((element: any) => {
									if (element.key === 'permissionToLogin') {
										setFieldDescription(element.description);
										dispatch(
											PasswordActions.setPasswords(data, { info: passwordsData?.info, fieldDesc: element.description })
										);
									}
								});
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING FIELD DESCRIPTION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [passwordsData]);
	useEffect(() => {
		if (passwordsData?.info !== '') {
			setDocumentDescription(passwordsData.info);
		}
	}, [passwordsData, documentDescription]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card-password card-password-pending');
		} else {
			setBack('card-password card-password-pending mcareNoRotate');
		}
	};
	function onEditPassword(id: string, element: any) {
		dispatch(walletActions.updateWallet(data, id, element));
		dispatch(PasswordActions.setPasswords(data, passwords));
	}
	return (
		<div className={back}>
			{!flip ? (
				<div className="wrapper-passwords">
					<IonRow className="article-header">
						<IonRow className="row-title-password">{title}</IonRow>
						<IonIcon
							id="about"
							className="icon-about-abms-actions"
							icon={interrogation}
							onClick={() => onFlip()}></IonIcon>
					</IonRow>
					{!passwords || passwords.length === 0 ? (
						<NoInfoCard textColor="text-color-white" cardType="password" />
					) : (
						<div className="margin-top-pass-card">
							{passwords?.map((element, index) =>
								zoom && index > 4 ? (
									<PasswordsCAQH
										key={index}
										title={element.title}
										description={fieldDescription}
										userName={element.userName}
										userPassword={element.userPassword}
										edit={onEditPassword}
										id={element.id}
										passEdited={passEdited}
										permissionToLogin={element.permissionToLogin}
									/>
								) : index < 4 ? (
									<PasswordsCAQH
										key={index}
										title={element.title}
										description={fieldDescription}
										userName={element.userName}
										userPassword={element.userPassword}
										edit={onEditPassword}
										id={element.id}
										passEdited={passEdited}
										permissionToLogin={element.permissionToLogin}
									/>
								) : null
							)}
						</div>
					)}
				</div>
			) : null}
			{flip ? (
				<AboutThat onFlip={onFlip} description={documentDescription} title={title} cssTextColor="about-white" />
			) : null}
		</div>
	);
};
export default CAQHLogin;
