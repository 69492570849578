import { IonIcon, IonImg, IonMenuToggle, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import medicaresymbol from '../../../img/Medicare-Symbol.svg';
import ActivateProfileDescImage from '../../../img/activate-profile-desc-white.svg';
import interrogation from '../../../img/icons/light-blue-interrogation.svg';
import { MedicareCardActions } from '../../../redux/actions/medicareCardAction';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import AddressStates from '../../../shared/addressStates';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { MedicareCardEntity } from '../../../shared/entity/medicareCardEntity';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionMedicareActions from '../../AccordionMedicareActions/AccordionMedicareActions';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import './MedicarePending.css';
const MedicarePending: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard }) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const mcareData = useSelector<ReduxStateEntity, any>((state) => state.mcare!);
	const mcare = useSelector<ReduxStateEntity, MedicareCardEntity[]>((state) => mcareData.mcare!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__medicare-pending');
	const [documentDescription, setDocumentDescription] = useState(mcareData?.info);
	const history = useHistory();
	useEffect(() => {
		if (data) {
			dispatch(MedicareCardActions.setLicense(data, mcareData));
			dispatch(WalletActivationActions.setWalletActivation(data));
		}
	}, [data]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__medicare-pending');
		} else {
			setBack('card card__medicare-pending mcareNoRotate');
		}
	};
	useEffect(() => {
		if (mcareData?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'medicare').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(MedicareCardActions.setLicense(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [mcareData]);
	useEffect(() => {
		if (mcareData?.info !== '') {
			setDocumentDescription(mcareData.info);
		}
	}, [mcareData, documentDescription]);
	const AccordionContent = () => {
		return (
			<IonRow className="ion-margin margin-top-mcare-content">
				{mcare.map((element, index) =>
					index < 4 && !zoom ? (
						<AccordionMedicareActions
							key={index}
							title={AddressStates.label(element.state)!}
							classCss={3 === index || mcare.length === 1 ? 'border-top-bottom-medicare' : 'card-row-medicare'}
							quantity={mcare.length}
							collapse={false}
							status={element.status}>
							<IonRow className="medicare-padding">
								<IonRow className="row-medicare-data">
									<IonText className="mcare-content-card-title">Medicare Ptan Number</IonText>
									<IonRow>
										<IonText className="mcare-content-number">{element.number}</IonText>
										<CopyClipboard text={element.number} />
									</IonRow>
								</IonRow>
								<IonRow className="row-content-data-mcare">
									<IonRow className="flex-col-row">
										<IonText className="mcare-content-card-title">Effective Date:</IonText>
										<IonText className="value-text-dates-medicare badge-medicare">
											{moment(element.effectiveDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
									<IonRow className="flex-col-row">
										<IonText className="smcare-content-card-title">Renewal Date:</IonText>
										<IonText className="value-text-dates-medicare badge-medicare">
											{moment(element.renewalDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
								</IonRow>
							</IonRow>
						</AccordionMedicareActions>
					) : zoom ? (
						<AccordionMedicareActions
							key={index}
							title={AddressStates.label(element.state)!}
							classCss={
								mcare.length - 1 === index || mcare.length === 1 ? 'border-top-bottom-medicare' : 'card-row-medicare'
							}
							quantity={mcare.length}
							collapse={false}
							status={element.status}>
							<IonRow className="medicare-padding">
								<IonRow className="row-medicare-data">
									<IonText className="mcare-content-card-title">Medicare Ptan Number</IonText>
									<IonRow>
										<IonText className="mcare-content-number">{element.number}</IonText>
										<CopyClipboard text={element.number} />
									</IonRow>
								</IonRow>
								<IonRow className="row-content-data-mcare">
									<IonRow className="flex-col-row">
										<IonText className="mcare-content-card-title">Effective Date:</IonText>
										<IonText className="value-text-dates-medicare badge-medicare">
											{moment(element.effectiveDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
									<IonRow className="flex-col-row">
										<IonText className="mcare-content-card-title">Renewal Date:</IonText>
										<IonText className="value-text-dates-medicare badge-medicare">
											{moment(element.renewalDate).format('MMM DD YYYY')}
										</IonText>
									</IonRow>
								</IonRow>
							</IonRow>
						</AccordionMedicareActions>
					) : null
				)}
			</IonRow>
		);
	};
	const PaymentDescriptionContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<div className="ion-padding">
					<IonImg src={medicaresymbol} className="ion-margin img-board-payment"></IonImg>
				</div>
				<div className="ion-padding">
					{isPlatform('mobile') ? (
						<IonImg
							src={ActivateProfileDescImage}
							className="ion-margin"
							onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
					) : (
						<IonMenuToggle>
							<IonImg
								src={ActivateProfileDescImage}
								className="ion-margin"
								onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
						</IonMenuToggle>
					)}
				</div>
			</IonRow>
		);
	};

	return (
		<div className={back}>
			{!walletActivation?.payCompleted && !walletActivation?.approved ? (
				<IonRow className={`card___face ${!flip ? 'wrapper-zoom-mcare' : ''}`}>
					{!flip ? (
						<IonRow className="article-header__medicare-pending">
							<IonIcon className="card-medicaresymbol" icon={medicaresymbol}></IonIcon>
							<IonRow className="category-title">
								<IonText className="ion-padding-right">{title}</IonText>
							</IonRow>
						</IonRow>
					) : null}
					<PaymentDescriptionContent />
				</IonRow>
			) : (
				<IonRow className={`card___face ${!flip ? 'wrapper-zoom-mcare' : ''}`}>
					{!flip ? (
						<IonRow className="article-header__medicare-pending">
							<IonIcon className="card-medicaresymbol" icon={medicaresymbol}></IonIcon>

							<IonRow className="category-title">
								<IonText className="ion-padding-right">{title}</IonText>
								{!flip && mcare.length > 0 ? (
									<IonText className="bubble-mcare">
										<span>{mcare.length}</span>
									</IonText>
								) : null}
							</IonRow>
							<IonIcon
								id="about"
								className="icon-about-medicare"
								icon={interrogation}
								onClick={() => onFlip()}></IonIcon>
						</IonRow>
					) : null}
					{walletActivation?.approved && !flip && mcare.length === 0 ? (
						<IonRow className="center-no-info-card">
							<NoInfoCard textColor="text-color-white" blackIcon={false} cardType="mdcr" />{' '}
						</IonRow>
					) : null}
					{!flip ? mcare.length > 0 ? <AccordionContent /> : null : null}
				</IonRow>
			)}

			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground="card__medicare-pending mcareBack"
					cssTextColor="about-white"
				/>
			) : null}
		</div>
	);
};

export default MedicarePending;
