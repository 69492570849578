import { IonLoading, IonToast, ToastOptions } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
//icons
//css
import './paymentCard';

import {
	CardCvcElement,
	CardElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { onBoardingService } from '../../services/onBoarding.service';
import { paymentService } from '../../services/paymentService';
interface ContainerProps {
	redirectToVerify: () => void;
	amount: string;
	dismiss: boolean;
	clearModal: () => void;
	handleCardDetailsChange: (event: any) => void;
	payment: boolean;
	cardName: string;
	zip: string;
	region: string;
	progressPayment: (value: boolean, completed: boolean) => void;
	errorOnPayment: () => void;
	selectedPaymentSecond: boolean;
	selectedPayment: number;
}
const PaymentCard: React.FC<ContainerProps> = ({
	redirectToVerify,
	amount,
	dismiss,
	clearModal,
	handleCardDetailsChange,
	payment,
	cardName,
	region,
	zip,
	progressPayment,
	errorOnPayment,
	selectedPayment,
	selectedPaymentSecond,
}) => {
	const [toastIsShown, setToastIsShown] = useState(false);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const [toast, setToast] = useState<ToastOptions>({});
	const [showLoading, setShowLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	useEffect(() => {
		if (dismiss) {
			const cardElement = elements!.getElement(CardElement);
			if (cardElement) {
				cardElement.blur();
				clearModal();
			}
		}
	}, [dismiss]);
	useEffect(() => {
		if (payment) {
			handleSubmit();
		}
	}, [payment]);

	const handleSubmit = async () => {
		let result: any;
		let provider: any;
		try {
			progressPayment(true, false);
			if (!elements || !stripe) {
				showToast({
					color: 'danger',
					header: 'Failure',
					message: 'Something went wrong.',
				});
				progressPayment(false, false);
				errorOnPayment();
				return;
			}

			//create payment intent
			const fullname = data.tenants[0].provider.fullName || '';
			const email: any = data.tenants[0].provider.user.email || '';
			provider = data.tenants[0].provider.id;
			const address = {
				country: region,
				postal_code: zip,
			};
			const totalAmount = calculateTotal();
			const paymentIntentResponse = await paymentService.paymentIntent(
				totalAmount,
				fullname,
				email,
				provider,
				address,
				data.tenants[0].tenant.id
			);
			const { error: backendError, clientSecret, customer } = paymentIntentResponse.data;
			console.log('PAYMENT INTENT ERROR', backendError);
			if (backendError) {
				showToast({
					color: 'danger',
					header: 'Failure',
					message: backendError.message || 'Something went wrong.',
				});
				progressPayment(false, false);
				errorOnPayment();
				return;
			}
			if (clientSecret === undefined || customer === undefined) {
				showToast({
					color: 'danger',
					header: 'Failure',
					message: 'Something went wrong.',
				});
				progressPayment(false, false);
				errorOnPayment();
				return;
			}
			const cardElement = elements!.getElement(CardNumberElement);
			console.log('PAYMENT CARD ELEMENT ', cardElement);
			if (!cardElement) {
				// Manejar el error o mostrar un mensaje de error al usuario
				showToast({
					color: 'danger',
					header: 'Failure',
					message: 'Something went wrong with your card,please try again.',
				});
				progressPayment(false, false);
				errorOnPayment();
				return;
			}
			const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
				payment_method: {
					card: cardElement,
				},
			});
			console.log('PAYMENT CONFIRMATION ERROR', stripeError);
			if (stripeError) {
				showToast({
					color: 'danger',
					header: 'Failure',
					message: stripeError.message || 'Something went wrong!',
				});
				progressPayment(false, false);
				errorOnPayment();
				return;
			}
			const customerId = customer?.[0]?.id || customer.id;
			result = await paymentService.confirmPayment(customerId, true, paymentIntent?.id);
		} catch (error: any) {
			showToast({
				color: 'danger',
				header: 'Failure',
				message: 'Payment not applied',
			});
			progressPayment(false, false);
			errorOnPayment();
			console.log(error);
		}
		if (result.data === 'succeeded') {
			try {
				const npi = data?.tenants?.[0]?.provider?.npi || '';
				const graduationYear: any = data?.tenants?.[0]?.provider?.graduationYear || '';
				const yearOfGraduation = data?.tenants?.[0]?.provider?.yearOfGraduation;
				const birthDate = data?.tenants?.[0]?.provider?.birthDate;
				const socialSecurity = data?.tenants?.[0]?.provider?.socialSecurity;
				const professionalLicenses = data?.tenants?.[0]?.provider?.professionalLicenses;
				const type = data?.tenants?.[0]?.provider?.type?.id;
				const validateFields = await onBoardingService.buildWalletValidateFields(
					npi,
					type,
					graduationYear || yearOfGraduation,
					birthDate,
					socialSecurity,
					professionalLicenses,
					data.tenants[0].tenant.id
				);
				if (!validateFields?.data) {
					onBoardingService.sendEmailRequestWalletActivationManually(provider, data.tenants[0].tenant.id);
				} else {
					onBoardingService.buildWallet(provider, data.tenants[0].tenant.id);
				}
				showToast({
					color: 'success',
					header: 'success',
					message: 'Payment completed.',
				});
				progressPayment(false, true);
				localStorage.removeItem('payCompleted');
				const timer = setTimeout(() => {
					redirectToVerify();
				}, 3000);
				return () => clearTimeout(timer);
			} catch (error: any) {
				onBoardingService.sendEmailRequestWalletActivationManually(provider, data.tenants[0].tenant.id);
				showToast({
					color: 'success',
					header: 'success',
					message: 'Payment completed.',
				});
				progressPayment(false, true);
				localStorage.removeItem('payCompleted');
				const timer = setTimeout(() => {
					redirectToVerify();
				}, 3000);
				return () => clearTimeout(timer);
			}
		}
	};
	const showToast = (toast: ToastOptions) => {
		setToast(toast);
		const timer = setTimeout(() => {
			setToastIsShown(true);
		}, 1000);
		return () => clearTimeout(timer);
	};
	function calculateTotal() {
		if (selectedPaymentSecond) {
			switch (selectedPayment) {
				case 2:
					return '3999';
				case 1:
					return '2707';
				default:
					return '4999';
			}
		} else {
			return amount;
		}
	}
	return (
		<div>
			<form id="payment-form" name="form">
				<div className="card-number-payment">
					<CardNumberElement onChange={(event) => handleCardDetailsChange(event)} />
				</div>
				<div className="container-exp-cvc">
					<div className="exp-payment">
						<CardExpiryElement onChange={(event) => handleCardDetailsChange(event)} />
					</div>
					<div className="cvc-payment">
						<CardCvcElement onChange={(event) => handleCardDetailsChange(event)} />
					</div>
				</div>
				<IonToast
					isOpen={toastIsShown}
					onDidDismiss={() => setToastIsShown(false)}
					message={toast?.message}
					position="top"
					color={toast?.color}
					header={toast?.header}
					duration={3000}
				/>
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
			</form>
		</div>
	);
};

export default PaymentCard;
