import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { OnBoardingPhotoConstants } from "../constants/onBoardingPhotoConstants";


export const onBoardingPhotoActions = {
    setPhoto,
    setSelfie
   
};

function setPhoto(photo: any,type:string){  
    return async (dispatch : Dispatch) => {
        const data = {
            photo: photo,
            license: type
        }
        dispatch(success(data));
    };
    function success(payload : any) { return { type: OnBoardingPhotoConstants.UPLOAD_PHOTO_SUCCESS, payload } }  
}
function setSelfie(photo: any){  
    return async (dispatch : Dispatch) => {
        dispatch(success(photo));
    };
    function success(payload : any) { return { type: OnBoardingPhotoConstants.UPLOAD_SELFIE_SUCCESS, payload } }  
}


