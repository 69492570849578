import { ActionEntity } from "../../shared/entity/actionEntity";
import { NppesEntity } from "../../shared/entity/nppesEntity";
import { NppesConstants } from "../constants/nppesConstants";



const initialUser: NppesEntity = {};
const nppesReducer = (state: NppesEntity = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case NppesConstants.SET_NPPES:
                return action.payload;          
        default:
            return state;
    }
}
export default nppesReducer;