import { IonApp, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Route } from 'react-router-dom';
import MainTabs from './components/MainTabs/MainTabs';
import TermsConditionOnBoarding from './pages/TermsCondition/termsCondition';
import BuildingWallet from './pages/buildingYourWallet/buildingWallet';
import ConfirmEmail from './pages/confirmEmail/confirmEmail';
import CreateAccount from './pages/createAccount/createAccount';
import Started from './pages/letsGetStarted/started';
import ProviderType from './pages/providerType/providerType';
import VerifyIdentity from './pages/verifyIdentity/verifyIdentity';
import WhichIsYou from './pages/whichIsYou/whichIsYou';
/* OverAll Css*/
import './theme/main-style.css';
/* Theme variables */
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import MainMenu from './components/MainMenu/MainMenu';
import { recaptchaSiteKey } from './config/staging';
import LoginPage from './pages/Login/Login';
import './theme/variables.css';

const App: React.FC = () => (
	<IonApp>
		<IonReactRouter>
			<Route
				path="/tabs"
				render={(props) => (isPlatform('mobile') ? <MainTabs {...props} /> : <MainMenu {...props} />)}
			/>
			<Route path="/lets-get-started" render={(props) => <Started {...props} />} />
			<Route path="/types" render={(props) => <ProviderType {...props} />} />
			<Route path="/terms" render={(props) => <TermsConditionOnBoarding {...props} />} />
			<Route path="/which-is-you" render={(props) => <WhichIsYou {...props} />} />
			<Route path="/create-account" render={(props) => <CreateAccount {...props} />} />
			<Route path="/confirm-email" render={(props) => <ConfirmEmail {...props} />} />
			<Route path={'/verify-identity'} render={(props) => <VerifyIdentity prop={props} nextStep={false} />} />
			<Route path="/building-wallet" render={(props) => <BuildingWallet {...props} />} />
			{/* <Route path="/payment-wallet" render={(props) => <PaymentPage {...props} />} /> */}
			{/* <Route path="/activate-wallet" render={(props) => <ActivateWalletPage {...props} />} /> */}
			{/* <Route path="/start-subscription" render={(props) => <StartSubscription {...props} />} /> */}
			<Route exact path="/" render={(props) => <LoginPage {...props} />} />
		</IonReactRouter>
	</IonApp>
);
const AppPageWrapper = (props: any) => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
			{/* Envuelve toda tu aplicación */}
			<App {...props} />

			{/* Contenedor para colocar visualmente el ReCaptcha donde lo necesitas */}
			<div className="recaptcha-container">
				{/* Aquí podrías colocar manualmente el ReCaptcha o usar el hook para accionarlo */}
				<GoogleReCaptcha onVerify={function (token: string): void | Promise<void> {}} />
			</div>
		</GoogleReCaptchaProvider>
	);
};

export default AppPageWrapper;
