import { Dispatch } from 'redux';
import { PassportEntity } from '../../shared/entity/passportEntity';
import { PassportConstants } from '../constants';

export const PassportActions = {
	setPassport,
};

function setPassport(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			const passport = data.tenants[0].provider.professionalLicenses.find(
				(element: any) => element.type?.key === 'passport'
			);
			if (passport) {
				const passportDoc: PassportEntity = {
					number: passport.number,
					state: passport.nationality,
					expirationDate: passport.expirationDate,
					issuedDate: passport.issueDate,
					frontPictureUrl: passport.attachments[0]?.downloadUrl,
					backPictureUrl: passport.attachments[1]?.downloadUrl,
					description: passport.type.description,
					id: passport.id,
					attachments: passport.attachments,
					info: document?.info || '',
				};
				dispatch(set(passportDoc));
			}
		}
	};

	function set(payload: any) {
		return { type: PassportConstants.SET_PASSPORT, payload };
	}
}
