import {
	IonButton,
	IonCard,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonLoading,
	IonPage,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Stepper from '../../components/stepper/stepper';
import identityCard from '../../img/card-onboarding.png';
import arrowBack from '../../img/icons/white_back_arrow.svg';
import imgEnterSSN from '../../img/img-full-number-ssn.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import ssn_security_image from '../../img/ssn-security-icon.svg';
import ssn_security_circle from '../../img/ssn_security_circle.svg';
import verifyLast from '../../img/verify-last-step.svg';
import { filesService } from '../../services/files.service';
import { onBoardingService } from '../../services/onBoarding.service';
import { privacyService } from '../../services/privacy.service';
import { pushNotificationService } from '../../services/pushNotifications.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import years from '../../shared/years';
import Selfie from '../selfieUpload/selfie';
import Upload from '../uploadOnBoarding/upload';
import './verifyIdentity.css';
interface ContainerProps {
	nextStep: boolean;
	prop?: RouteComponentProps;
}
const VerifyIdentity: React.FC<ContainerProps> = (props) => {
	const { nextStep, prop } = props;
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const resolved = useSelector<any, any>((state) => state.login);
	const [step, setStep] = useState(nextStep ? 4 : 0);
	const [licenseStep, setLicenseStep] = useState('driver-license');
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [showLoading, setShowLoading] = useState(false);
	const [inputs, setInputs] = useState({
		graduationYear: '',
		code1: '',
		code2: '',
		code3: '',
	});
	const code1InputRef = useRef<HTMLIonInputElement | null>(null);
	const code2InputRef = useRef<HTMLIonInputElement | null>(null);
	const code3InputRef = useRef<HTMLIonInputElement | null>(null);
	const [step4, setStep4] = useState(nextStep);
	useEffect(() => {
		if (prop?.history?.location?.state !== null) {
			setInputs({
				graduationYear: '',
				code1: '',
				code2: '',
				code3: '',
			});
		}
	}, [prop?.history?.location?.state]);
	useEffect(() => {
		if (data) {
			const tenantId = data?.tenants[0]?.tenant.id;
			const terms = data?.tenants[0].provider?.professionalLicenses.find(
				(element: any) => element.type?.key === 'terms-of-service'
			);
			if (!terms) {
				filesService.documentType(tenantId, 'terms-of-service').then(
					(res) => {
						const dataTerms = {
							data: {
								acceptanceDate: new Date(),
								attachments: [],
								boardCertified: false,
								certificationExpirationDate: false,
								currentlyPracticing: false,
								individualCoverage: false,
								isAccepted: true,
								isAuthorized: false,
								isDatesVerified: false,
								isGrantAccess: false,
								isPrimary: false,
								isPrimarySource: false,
								isPrimarySourceVerified: false,
								priorOccuranceActsCoverage: false,
								selfInsured: false,
								type: res.data.id,
								unlimitedCoverage: false,
							},
						};
						privacyService.setPrivacyTermsAgreement(tenantId, dataTerms).then(
							(res) => {},
							(error) => {}
						);
					},
					(error) => {}
				);
			}
		}
	}, [data]);
	const navigate = (route: string) => {
		let data: any = null;
		if (prop?.history?.location?.state !== null && nextStep) {
			data = prop?.history?.location?.state as any;
			prop?.history.push({
				pathname: route,
				state: {
					optionSelected: data?.optionSelected,
					providerType: data?.providerType === 'NP' || data?.providerType === 'RN',
				},
			});
		} else {
			prop?.history.push(route);
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setShowLoading(true);
		setToast({ message: '' });
		try {
			const { code1, code2, code3 } = inputs;
			if (step4) {
				if (!Number(code1) || !Number(code2) || !Number(code3)) {
					setShowLoading(false);
					setToast({ color: 'danger', header: 'Failure', message: 'Please enter a valid social security number.' });
					return;
				}
			}
			const tenantId = data.tenants[0].tenant.id;
			const providerId = data.tenants[0].provider.id;
			const dl = data.tenants[0].provider?.professionalLicenses.find(
				(element: any) => element?.type?.key === 'driver-license'
			);
			const pp = data.tenants[0].provider?.professionalLicenses.find(
				(element: any) => element?.type?.key === 'passport'
			);

			const tmp = data.tenants[0].provider?.professionalLicenses
				.filter((element: any) => element?.type?.key !== 'driver-license' && element?.type?.key !== 'passport')
				.map((element: any) => element.id);

			if (dl) tmp.push(dl.id);
			if (pp) tmp.push(pp.id);

			const dataRequest: any = {
				data: !step4
					? {
							graduationYear: inputs.graduationYear,
							yearOfGraduation: inputs.graduationYear,
					  }
					: {
							socialSecurity: code1 + code2 + code3,
					  },
				id: providerId,
			};

			const res = await onBoardingService.updateProviderOnbLastStep(tenantId!, providerId, dataRequest);

			if (res?.data) {
				localStorage.setItem('payCompleted', JSON.stringify(false));
				if (step4) {
					setShowLoading(false);
					navigate('/tabs/payment-wallet');
				} else {
					localStorage.setItem('NuCoUserStage', JSON.stringify('building'));
					await savePushNotificationToken(data.tenants[0].provider.id);
					setShowLoading(false);
					navigate('/building-wallet');
				}
			}
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const handleErrorMessage = (error: any) => {
		setShowLoading(false);
		console.log('ERROR VERIFYING IDENTITY:', error);
		showToast({ message: 'Something went wrong,please try again.' });
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
		if (value.length === 3 && name === 'code1') {
			setInputFocus(name);
		}
		if (value.length === 2 && name === 'code2') {
			setInputFocus(name);
		}
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	const handleBack = () => {
		if (step4) {
			navigate('/tabs/activate-wallet');
		} else if (step > 0) {
			setStep(0);
		} else {
			prop?.history.goBack();
			resolved.resolved = null;
			sessionStorage.setItem('backAction', 'true');
		}
	};
	const savePushNotificationToken = async (userId: string) => {
		pushNotificationService.checkPermissions(userId).then(async (e) => {
			const token = localStorage.getItem('pushNotificationToken');
			if (token) {
				await pushNotificationService.saveUserToken({ token, userId });
			}
		});
	};

	const setInputFocus = (currentInput: String) => {
		setTimeout(() => {
			if ('code1' === currentInput) {
				code2InputRef.current?.setFocus();
			} else if ('code2' === currentInput) {
				code3InputRef.current?.setFocus();
			}
		}, 100);
	};
	const SsnSection = () => {
		return (
			<div className="main-container-vi">
				<div className="center-container-vi">
					<div className="image-container-vi">
						<IonImg className="img-ssn-security-circle" src={ssn_security_circle} />
						<IonImg className="img-ssn-security-lock" src={ssn_security_image} />
					</div>
					<div className="container-ssn-security-text">
						<IonText className="text-line">Your Social Security Number</IonText>
						<IonText className="text-bold">is the key to activate your profile</IonText>
						<IonText className="text-line">and monitoring by our system.</IonText>
					</div>
				</div>
			</div>
		);
	};
	return (
		<IonPage
			className="
      loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550 building-wallet-background">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>
			<IonContent className="ion-padding profile-page max-550 building-wallet-background">
				<IonToolbar className="no-background">
					<IonRow className="verifiy-center">
						<IonRow>
							<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => handleBack()}></IonIcon>
						</IonRow>
						<IonRow>
							<IonText className="ion-text-center verifyTitle">
								{step === 0 ? 'Account Activated!' : 'Identity Verification'}
							</IonText>
						</IonRow>
						{step === 0 && (
							<IonRow>
								<IonText className="ion-text-center account-activate-desc">
									Now, before we pull in your (sometimes personal) information from primary sources - let’s make sure
									your really you.
								</IonText>
							</IonRow>
						)}
					</IonRow>
				</IonToolbar>
				{step4 && step === 4 && <SsnSection></SsnSection>}
				{step !== 0 ? (
					<form name="form" onSubmit={handleSubmit}>
						<IonCard className={step4 ? 'verifyCard-st4' : 'verifyCard'}>
							<IonRow className={`${!step4 ? 'stepsRow' : ''} `}>
								{!step4 && step === 1 ? <Upload type={licenseStep}></Upload> : null}
								{!step4 && step === 2 ? <Selfie></Selfie> : null}
								{!step4 && step === 3 ? (
									<IonRow className="upload-onboarding-main-container ion-align-items-center">
										<IonCardTitle className="uploadTitle">Step 3</IonCardTitle>
										<IonCardSubtitle className="uploadSubTitle">
											What year did you graduate medical school?
										</IonCardSubtitle>
										<IonImg src={verifyLast}></IonImg>
										<IonItem className="input-inside ion-margin-top ion-margin-bottom verify-identity-select-width">
											<IonSelect
												interface="action-sheet"
												name="graduationYear"
												className="select-state-verify-identity"
												onIonChange={handleChange}>
												{years.forSelect?.map((element, index) => (
													<IonSelectOption key={index} value={element}>
														{element}
													</IonSelectOption>
												))}
											</IonSelect>
										</IonItem>
									</IonRow>
								) : null}
								{step4 && step === 4 ? (
									<IonRow
										className={`upload-onboarding-main-container ion-align-items-center
									${isPlatform('mobile') ? 'row-input-code-verify-st4' : 'row-input-code-verify'}
									`}>
										<IonCardSubtitle className="uploadSubTitle">
											<IonImg src={imgEnterSSN}></IonImg>
										</IonCardSubtitle>
										<IonRow className="row-input-code-verify">
											<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify-identity">
												<IonRow className="row">
													<IonInput
														ref={code1InputRef}
														required
														name="code1"
														type="password"
														maxlength={3}
														inputmode="numeric"
														pattern="[0-9]*"
														onIonChange={handleChange}></IonInput>
												</IonRow>
											</IonItem>
											<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify-identity">
												<IonRow className="row">
													<IonInput
														ref={code2InputRef}
														required
														name="code2"
														type="password"
														maxlength={2}
														inputmode="numeric"
														pattern="[0-9]*"
														disabled={!inputs.code1}
														onIonChange={handleChange}></IonInput>
												</IonRow>
											</IonItem>
											<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify-identity">
												<IonRow className="row">
													<IonInput
														ref={code3InputRef}
														required
														name="code3"
														type="password"
														disabled={!inputs.code2}
														maxlength={4}
														inputmode="numeric"
														pattern="[0-9]*"
														onIonChange={handleChange}></IonInput>
												</IonRow>
											</IonItem>
										</IonRow>
										{toast.message && <IonText className="errorIdentity">{toast.message}</IonText>}
									</IonRow>
								) : null}
								{!step4 && step === 1 ? (
									<IonRow className="save-row-verify-identity">
										{' '}
										<IonButton
											color="secondary"
											shape="round"
											onClick={() => {
												setStep(2);
											}}>
											{' '}
											Next{' '}
										</IonButton>
									</IonRow>
								) : null}
								{!step4 && step === 2 ? (
									<IonRow className="save-row-verify-identity">
										{' '}
										<IonButton
											color="secondary"
											shape="round"
											onClick={() => {
												setStep(3);
											}}>
											{' '}
											Next{' '}
										</IonButton>
									</IonRow>
								) : null}
								{!step4 && step === 3 ? (
									<IonRow className="save-row-verify-identity">
										{' '}
										<IonButton color="secondary" shape="round" type="submit">
											{' '}
											Next{' '}
										</IonButton>
									</IonRow>
								) : null}

								{step != 4 && <Stepper step={step} hideLastStep={false} useStep4={step4}></Stepper>}
							</IonRow>
						</IonCard>
						{step4 && step === 4 ? (
							<IonRow
								className={` ${
									isPlatform('mobile') ? 'save-row-verify-identity-ssn-mobile' : 'save-row-verify-identity-ssn'
								}`}>
								{' '}
								<IonButton color="secondary" shape="round" className="btn-save-ssn-width" type="submit">
									{' '}
									Next{' '}
								</IonButton>
							</IonRow>
						) : null}
					</form>
				) : (
					<div className="page-identify">
						<IonImg className="img-identify" src={identityCard}></IonImg>
						<IonCard className="card-identify">
							<IonText className="text-identify">Select your prefered ID option</IonText>
							<IonButton
								onClick={() => {
									setStep(1);
									setLicenseStep('driver-license');
								}}
								className="button-identify "
								color="secondary"
								shape="round">
								Driver License
							</IonButton>
							<IonButton
								onClick={() => {
									setStep(1);
									setLicenseStep('passport');
								}}
								className="button-identify"
								color="secondary"
								shape="round">
								Passport
							</IonButton>
						</IonCard>
					</div>
				)}
				<IonLoading
					cssClass="spinner"
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					spinner="crescent"
					translucent={true}
				/>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
		</IonPage>
	);
};
export default VerifyIdentity;
