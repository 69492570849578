import {
	IonButton,
	IonContent,
	IonIcon,
	IonImg,
	IonLoading,
	IonModal,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Timer from '../../components/timer/timer';
import closeIcon from '../../img/arrow-back-outline.svg';
import CheckedPayment from '../../img/checked-payment-green.svg';
import unselectPayment from '../../img/circle-payment-unselected.png';
import Clock from '../../img/clock-white.svg';
import IconReview from '../../img/icon-reviews-wallet.svg';
import arrowBack from '../../img/icons/white_back_arrow.svg';
import paymentCheck from '../../img/payment-check-option.png';
import PlanInactive from '../../img/plan_active.svg';
import PlanSelected from '../../img/plan_selected.svg';
import SaveMoney from '../../img/saveMoney.svg';
import { ToastOptions } from '../../shared/entity/toastOptions';
import WalletBenefits from '../../shared/walletBenefits';
import './paymentPage.css';
interface ContainerProps {
	prop?: RouteComponentProps;
	activateStep?: boolean;
}
const PaymentPage: React.FC<ContainerProps> = (props) => {
	const { prop, activateStep } = props;
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [showLoading, setShowLoading] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState(0);
	const [openModal, isModalOpen] = useState(false);
	const [dismissModal, isDismissModal] = useState(false);
	const [benefits, setBenefits] = useState(false);
	const providerType: string = JSON.parse(localStorage.getItem('nucoProvider')!);
	const timeExpiredPrice = providerType === 'NP' || providerType === 'RN' ? '$49.99' : activateStep ? '$75' : '$94';

	const navigate = (route: string) => {
		prop?.history.push({
			pathname: activateStep ? '/tabs/verify-identity' : '/activate-wallet',
			state: { optionSelected: selectedPayment, providerType: providerType === 'NP' || providerType === 'RN' },
		});
		setSelectedPayment(0);
	};
	async function stopBenefit() {
		await sleep(1000);
		setBenefits(true);
	}
	function sleep(ms: any) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	function goBack() {
		prop?.history.push('/tabs/wallet');
	}
	return (
		<IonPage className="loginPage">
			<IonContent fullscreen className="background-sign-in max-550 ion-padding">
				<IonToolbar className="no-background ion-margin-top" onClick={() => goBack()}>
					<IonRow>
						<IonIcon className="backArrowProfile" icon={arrowBack}></IonIcon>
					</IonRow>
					<IonTitle className="ion-text-center title-payment" size="large">
						Activate your account
					</IonTitle>
				</IonToolbar>
				<form className="" name="form">
					<IonRow className="ion-padding-bottom ion-padding-top flex-center-subs-amount subs-gap">
						<IonText className="text-credential-payment">
							Turn on credential managment to have all your existing data automatically imported directly from primary
							resources.
						</IonText>
						{!activateStep && (
							<div className="flex flex-col">
								<div className="ion-align-items-center flex flex-col position-container-img">
									{!benefits && <img src={SaveMoney} alt="" />}
									<div className="container-save-money">
										<img src={Clock} alt="" className="clock-discount" />
										<Timer stopBenefit={stopBenefit} />
									</div>
								</div>
							</div>
						)}
						{!activateStep &&
							(!benefits ? (
								<IonText className="monitoring-text">
									Set up monitoring within the next 5 minutes and <span className="span-monitoring">save 20 %</span>.
								</IonText>
							) : (
								<IonText className="monitoring-text">
									Your time has expired. The only option available is full price{' '}
									<span className="span-monitoring">{timeExpiredPrice} per year</span>.
								</IonText>
							))}
						{!benefits && (
							<div className="width-container" onClick={() => setSelectedPayment(selectedPayment === 1 ? 0 : 1)}>
								<img
									src={selectedPayment === 1 ? PlanSelected : PlanInactive}
									alt=""
									className="width-container-full"
								/>
								<div className="container-data-payment">
									<div className="data-payment">
										<img
											src={selectedPayment === 1 ? CheckedPayment : unselectPayment}
											className=" icon-option-payment flex"
										/>
										<span className="amount-weigth-payment">6 months</span>
									</div>
									<div className="data-payment-month flex-col">
										<span className="amount-weigth-payment">
											{providerType === 'NP' || providerType === 'RN' ? '$27.07' : activateStep ? '$51' : '$51'}{' '}
										</span>
										<span className="amount-payment-month">
											{providerType === 'NP' || providerType === 'RN' ? '$4.51' : activateStep ? '$8.5' : '$8.5 '} per
											month
										</span>
									</div>
								</div>
							</div>
						)}
						{!benefits && (
							<div className="width-container" onClick={() => setSelectedPayment(selectedPayment === 2 ? 0 : 2)}>
								<img
									src={selectedPayment === 2 ? PlanSelected : PlanInactive}
									alt=""
									className="width-container-full"
								/>
								<div className="container-data-payment">
									<div className="data-payment">
										<img
											src={selectedPayment === 2 ? CheckedPayment : unselectPayment}
											className=" icon-option-payment flex"
										/>
										<span className="amount-weigth-payment">12 months</span>
									</div>
									<div className="data-payment-month flex-col">
										<span className="amount-weigth-payment">
											{providerType === 'NP' || providerType === 'RN' ? '$39.99' : activateStep ? '$75' : '$75'}{' '}
										</span>
										<span className="amount-payment-month">
											{providerType === 'NP' || providerType === 'RN' ? '$3.33 ' : activateStep ? '$6.25' : '$6.25 '}{' '}
											per month
										</span>
									</div>
								</div>
							</div>
						)}
						{benefits && (
							<div className="width-container" onClick={() => setSelectedPayment(selectedPayment === 3 ? 0 : 3)}>
								<img
									src={selectedPayment === 3 ? PlanSelected : PlanInactive}
									alt=""
									className="width-container-full"
								/>
								<div className="container-data-payment">
									<div className="data-payment">
										<img
											src={selectedPayment === 3 ? CheckedPayment : unselectPayment}
											className=" icon-option-payment flex"
										/>
										<span className="amount-weigth-payment">12 months</span>
									</div>
									<div className="data-payment-month flex-col">
										<span className="amount-weigth-payment">
											{providerType === 'NP' || providerType === 'RN' ? '$49.99' : '$94'}{' '}
										</span>
										<span className="amount-payment-month">
											{providerType === 'NP' || providerType === 'RN' ? '$4.16' : '$7.83 '} per month
										</span>
									</div>
								</div>
							</div>
						)}
					</IonRow>
					{!activateStep && selectedPayment > 0 && (
						<IonRow className="ion-padding auto-center">
							<span className="monitoring-text-get">What you get?</span>
							<div className="list-container-payment ">
								{WalletBenefits.forSelect.map((element, index) => (
									<div className="container-benefits-payment" key={index}>
										<div className="container-benefits-pay-icon">
											<img src={paymentCheck} alt="" />
										</div>
										&nbsp;
										<span className="description-payment">{element}</span>
									</div>
								))}
							</div>
						</IonRow>
					)}
					<IonRow className="auto-center ion-padding ">
						<IonButton
							onClick={() => navigate(activateStep ? '/tabs/payment-wallet' : 'activate-wallet')}
							color={'secondary'}
							shape="round"
							type="button"
							disabled={selectedPayment === 0}
							className="sign-in-width-subs">
							{'Activate Now'}
						</IonButton>
					</IonRow>
					{!activateStep && (
						<div className="reviews-payment">
							{isPlatform('ios') ? (
								<a
									href="https://apps.apple.com/app/1596109270?id=1596109270&action=read-review#see-all/reviews"
									className="reviews-text-color">
									<img src={IconReview} alt="" />
								</a>
							) : (
								<a href="https://play.google.com/store/apps/details?id=nuco.cred.io" className="reviews-text-color">
									<img src={IconReview} alt="" />
								</a>
							)}
						</div>
					)}

					<IonModal id="pay-version-modal" isOpen={openModal} backdropDismiss={false}>
						<IonContent className="pay-version-modal">
							<div style={{ display: 'flex' }}>
								<IonImg src={closeIcon} className="close-modal-img" onClick={() => isDismissModal(!dismissModal)} />
							</div>

							<div className="style-div-save ion-margin-top">
								<div className="div-half-size">
									<div className="line line-left-payment"></div>
								</div>
								<div className="div-btn-size">
									<span>Pay with card</span>
								</div>
								<div className="div-half-size">
									<div className="line line-rigth-payment"></div>
								</div>
							</div>
						</IonContent>
					</IonModal>
				</form>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={1000}
			/>
		</IonPage>
	);
};

export default PaymentPage;
