import { IonCol, IonTitle, IonToolbar } from "@ionic/react";
import { HistoryCardModule as Module } from "../../../shared/entity/historyCard.module";
import HistoryCard from "./HistoryCard";
import './HistoryCard.css';

const HistoryContent: React.FC<Module.HistoryContent> = (props) => {

  const historyContent: Module.HistoryContent = props;

  return (
    <IonCol className="historyContent">
      { historyContent.title  && <IonToolbar className="no-background">
          <IonTitle className="historyContentTitle" size="large">{historyContent.title}</IonTitle>
        </IonToolbar>
      }
      <HistoryCard showTimeline={historyContent.showTimeline} rows={historyContent.record}/>
    </IonCol>
  );
}

export default HistoryContent;