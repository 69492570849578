export module BubbleModule {

  export interface Bubble {
    date?: string;
    time?: string;
    text?: string;
    document?: string;
    isRead?: boolean;
    position?: string;
    css?: string;
  }

  export interface BubbleList {
    bubbles: Array<Bubble>;
  }

  export enum BUBBLE_POSITION {
    LEFT = 'left',
    RIGHT = 'right'
  }

}