import { Dispatch } from 'redux';
import { LeieActionsEntity } from '../../shared/entity/leie.Entity';
import { LeieFieldsEntity } from '../../shared/entity/leieFieldsEntity';
import { LeieConstants } from '../constants';

export const LeieActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: LeieActionsEntity = {
			possibleFound: [],
			exclusionFound: [],
			previousMatches: [],
			info: document?.info || '',
		};
		let possibleFound: LeieFieldsEntity[] = [];
		let exclusionFound: LeieFieldsEntity[] = [];
		let previousMatches: LeieFieldsEntity[] = [];
		data.tenants[0].provider?.professionalLicenses?.forEach((element: any) => {
			if (element.type?.key === 'exclusion' && element.exclusionType === 'LEIE') {
				const name = element.firstName + ' ' + element.middleName + ' ' + element.lastName;
				const exclusion: LeieFieldsEntity = {
					name: name,
					npiNumber: element.npi,
					reportingEntity: element.reportingEntity,
					description: element.description,
					orderDate: element.updatedAt,
					exclusionDate: element.exclusionDate,
					actionTaken: element.actionTaken,
					dob: element.dob,
					address1: element.address1,
					attachments: element.attachments?.[0],
					oigExclusionType: element.oigExclusionType,
				};
				if (!element.exclusionMatch && !element.ambassadorVerified) {
					possibleFound.push(exclusion);
				}
				if (!element.exclusionMatch && element.ambassadorVerified) {
					exclusionFound.push(exclusion);
				}
				if (element.exclusionMatch && element.ambassadorVerified) {
					previousMatches.push(exclusion);
				}
			}
		});
		exclusions.possibleFound = possibleFound;
		exclusions.exclusionFound = exclusionFound;
		exclusions.previousMatches = previousMatches;
		if (exclusions) {
			dispatch(set(exclusions));
		}
	};

	function set(payload: any) {
		return { type: LeieConstants.SET_LEIE, payload };
	}
}
