import { RefresherEventDetail } from '@ionic/core';
import { IonContent, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HistoryContent from '../../components/Cards/History/HistoryContent';
import IconParticipation from '../../img/history/icon-history-confirmed-participation-active-small.svg';
import { walletActions } from '../../redux/actions';
import { HistoryCardModule } from '../../shared/entity/historyCard.module';
import { TaskModule } from '../../shared/entity/task.module';
import { UserEntity } from '../../shared/entity/userEntity';

const History: React.FC = () => {
	const dispatch = useDispatch();
	const valueSelected = '';
	const allValues = [
		{
			key: 'my-activity',
			value: 'My activity',
		},
		{
			key: 'nuco-activity',
			value: 'NuCo activity',
		},
		{
			key: 'third-party',
			value: '3rd party access to my info',
		},
		{
			key: 'high-level',
			value: 'High-level stages',
		},
	];
	const [showLoading, setShowLoading] = useState(false);
	const userRedux = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const [userHistoryTasks, setUserHistoryTasks] = useState<TaskModule.TeenantTaskResponse>();
	const [pendingHistoryRecord, setPendingHistoryRecord] = useState<Array<HistoryCardModule.HistoryContent>>();
	const [finishedHistoryRecord, setFinishedHistoryRecord] = useState<Array<HistoryCardModule.HistoryContent>>();

	useEffect(() => {
		setShowLoading(true);
		let isApiSubscribed = true;
		if (!userHistoryTasks && userRedux) {
			(async () => {
				try {
					if (!userRedux.id) {
						const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
						await dispatch(walletActions.authorization(tmpUserToken));
					}
					if (isApiSubscribed) {
						const result: any = { provider: data?.tenants?.[0].provider?.tasks };
						setUserHistoryTasks(result);
					}
					setShowLoading(false);
				} catch (e) {
					console.log(e);
					setShowLoading(false);
				}
			})();
		} else {
			if (!pendingHistoryRecord && !finishedHistoryRecord) {
				filterHistoryTasks();
			}
			setShowLoading(false);
		}
		return () => {
			isApiSubscribed = false;
			setShowLoading(false);
		};
	});

	const filterHistoryTasks = () => {
		if (userHistoryTasks) {
			const filteredFinishedTasks = userHistoryTasks.provider.filter((task) => {
				return task.status === TaskModule.TaskStatus.DONE;
			});
			const filteredPendingTasks = userHistoryTasks.provider.filter((task) => {
				return task.status === TaskModule.TaskStatus.PENDING;
			});

			mapFinishedTasksToComponent(filteredFinishedTasks);
			mapPendingTasksToComponent(filteredPendingTasks);
		}
	};

	const mapPendingTasksToComponent = (list: Array<TaskModule.ProviderTask>) => {
		const d30 = createHistoryContentDays('Next 30 days', []);
		const d60 = createHistoryContentDays('Next 60 days', []);
		const d90 = createHistoryContentDays('Next 90 days', []);

		list.forEach((task) => {
			const historyRow: HistoryCardModule.HistoryDetailRow = {
				id: task.id,
				date: `due ${moment(task.estimatedEndDate).format('MMMM DD')}`,
				topic: task.title,
				status: getStatusText(task.status),
			};
			if (validateDateRange(task.estimatedEndDate!, 30)) {
				d30.record.push(historyRow);
			} else if (validateDateRange(task.estimatedEndDate!, 60)) {
				d60.record.push(historyRow);
			} else if (validateDateRange(task.estimatedEndDate!, 90)) {
				d90.record.push(historyRow);
			}
		});
		const pendingHistoryRecordContent: Array<HistoryCardModule.HistoryContent> = [];
		if (d30.record.length > 0) {
			pendingHistoryRecordContent?.push(d30);
		}
		if (d60.record.length > 0) {
			pendingHistoryRecordContent?.push(d60);
		}
		if (d90.record.length > 0) {
			pendingHistoryRecordContent?.push(d90);
		}
		setPendingHistoryRecord(pendingHistoryRecordContent);
	};

	const mapFinishedTasksToComponent = (list: Array<TaskModule.ProviderTask>) => {
		const newList: Array<HistoryCardModule.HistoryDetailRow> = list.map((task) => {
			console.log('ocpm', IconParticipation);
			return {
				id: task.id,
				date: moment(task.updatedAt).format('MMMM DD'),
				topic: task.title,
				description: task.title,
				icon: IconParticipation,
			} as HistoryCardModule.HistoryDetailRow;
		});

		setFinishedHistoryRecord([
			{
				record: newList,
				showTimeline: true,
			},
		]);
	};

	const getStatusText = (status: string = '') => {
		switch (status) {
			case TaskModule.TaskStatus.PENDING: {
				return 'in progress';
			}
		}
	};

	const createHistoryContentDays = (
		title: string,
		record: Array<HistoryCardModule.HistoryDetailRow>
	): HistoryCardModule.HistoryContent => {
		return {
			title,
			record,
		};
	};

	const validateDateRange = (date: string, days: number) => {
		const futureDate = moment(new Date()).add(days, 'd');
		return date && moment(date).isSameOrBefore(futureDate);
	};

	const hasPendingRows = (): boolean => {
		return pendingHistoryRecord! && pendingHistoryRecord.length > 0;
	};

	const hasFinishedRows = (): boolean => {
		return finishedHistoryRecord! && finishedHistoryRecord.length > 0;
	};
	function doRefresh(event: CustomEvent<RefresherEventDetail>) {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		dispatch(walletActions.authorization(tmpUserToken));
		event.detail.complete();
	}
	return (
		<IonPage className="default-top-page top-page">
			<IonContent className="max-550 ion-padding">
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						Activity
					</IonTitle>
				</IonToolbar>

				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						Credentialing Calendar
					</IonTitle>
				</IonToolbar>

				<div className="ion-padding-bottom ion-text-center color-light-gray">
					A record of everything related to your account
				</div>
				{/*
				THIS IS GOING TO BE USED IN ANOTHER RELEASE
			 <SelectCustom width="80%" valueSelected={valueSelected} vals={allValues}></SelectCustom> */}

				{hasPendingRows() &&
					pendingHistoryRecord?.map((history, i) => {
						return (
							<div key={i}>
								<HistoryContent showTimeline={false} title={history.title} record={history.record} />
							</div>
						);
					})}

				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						History
					</IonTitle>
				</IonToolbar>

				{hasFinishedRows() &&
					finishedHistoryRecord?.map((history, i) => {
						return (
							<div key={i}>
								<HistoryContent showTimeline={true} title={history.title} record={history.record} />
							</div>
						);
					})}
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
			</IonContent>
		</IonPage>
	);
};

export default History;
