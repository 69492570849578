import { Dispatch } from 'redux';
import { MedicaidCardEntity } from '../../shared/entity/medicaidCardEntity';
import { MedicaidCardConstant } from '../constants/medicaidCardConstants';

export const MedicaidCardActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: MedicaidCardEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'medicaid') {
					const exclusion: MedicaidCardEntity = {
						state: element.state,
						number: element.number,
						renewalDate: element.renewalDate,
						effectiveDate: element.effectiveDate,
						description: element.type?.description,
						status: element.status,
					};
					exclusions.push(exclusion);
				}
			});

			if (exclusions) {
				dispatch(set({ mcaid: exclusions, info: document?.info || '' }));
			}
		}
	};

	function set(payload: any) {
		return { type: MedicaidCardConstant.SET_MEDICAID_CARD, payload };
	}
}
