import {
	IonButton,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonRow,
	IonText,
	IonToast,
	IonToggle,
} from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filesService } from '../../services';
import { PasswordEntity } from '../../shared/entity/passwordEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './AccordionUpdatePassword.css';
const AccordionPassword: React.FC<PasswordEntity> = (props) => {
	const dispatch = useDispatch();
	const { title, description, userName, userPassword, id, edit, permissionToLogin, close } = props;
	const [show, setShow] = useState(false);
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');
	const userLogin = useSelector<ReduxStateEntity, UserEntity>((state) => state.user!);
	const [showLoading, setShowLoading] = useState(false);
	const [user, setUser] = useState<UserEntity>({
		email: userName,
		password: userPassword,
		permissionLogin: permissionToLogin,
	});
	const { email, password, permissionLogin } = user;
	const validForm = () => {
		if (password?.length! >= 8 && email?.length! > 0) {
			return false;
		} else {
			return true;
		}
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setUser((inputs) => ({ ...inputs, [name]: value }));
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setShowLoading(true);
		let fields: any = {
			password: password,
			username: email,
			permissionToLogin: permissionLogin,
		};

		filesService.updateDocuments(userLogin.id!, fields, id).then(
			(res) => {
				if (res?.data) {
					actionSaveForm();
				} else {
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			},
			(error) => {
				console.log(error);
				setShowLoading(false);
				setToastMessageError('Something went wrong,please try again.');
				setShowToastAttachments(true);
			}
		);
	};
	function sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	const actionSaveForm = async () => {
		await sleep(1000);
		setShowLoading(false);
		setToastMessageError('Data updated successfully');
		setShowToastAttachments(true);
		edit?.(id, user);
	};
	return (
		<IonRow className="full-row-cols ion-margin-top">
			<form name="form" onSubmit={handleSubmit}>
				<IonText className="edit-pass-labels">Username</IonText>
				<IonItem className="acc-password-input ion-margin-top ion-margin-bottom">
					<IonInput
						required
						name="email"
						type="text"
						placeholder="User"
						value={email}
						onIonChange={handleChange}></IonInput>
				</IonItem>
				<IonText className="edit-pass-labels">Password</IonText>
				<div className="content-password-update">
					<IonItem className="acc-password-input ion-margin-top ion-margin-bottom">
						<IonInput
							required
							name="password"
							type={show ? 'text' : 'password'}
							placeholder="Password"
							value={password}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonIcon
						id="showPassword"
						className="password-icon"
						icon={show ? eye : eyeOff}
						onClick={() => setShow(!show)}></IonIcon>
				</div>
				{password && password?.length < 8 && (
					<IonText color="danger">
						<small>{'password must be at least 8 characters'}</small>
					</IonText>
				)}
				<IonRow className="row-title-input">
					<IonLabel position="stacked" className="permission-to-log-in">
						Permission to log-in
					</IonLabel>
					<IonText className="label-authorize-pass">{description}</IonText>
				</IonRow>
				<IonItem id="toggle" className="ion-no-padding no-background" lines="none">
					<IonToggle
						name="permissionLogin"
						id="toggle"
						className="ion-no-margin toggle-margin-pass"
						checked={permissionLogin}
						onIonChange={(e) => setUser({ ...user, permissionLogin: e.detail.checked })}></IonToggle>
					<IonLabel className="on-off-label">Yes</IonLabel>
				</IonItem>
				<IonRow className="auto-center ion-padding add-padding">
					<IonButton
						onClick={() => {
							setUser({
								email: userName,
								password: userPassword,
							});
							{
								close && close();
							}
						}}
						shape="round"
						fill="clear"
						className="btn-passwords-width cancel-ion-button">
						Cancel
					</IonButton>
					<IonButton shape="round" type="submit" disabled={validForm()} className="btn-passwords-width">
						Save
					</IonButton>
				</IonRow>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
				<IonLoading
					cssClass="spinner"
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					spinner="crescent"
					translucent={true}
				/>
			</form>{' '}
		</IonRow>
	);
};

export default AccordionPassword;
