import { IonContent, IonFooter, IonHeader, IonLoading, IonPage, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { first } from 'rxjs';
import BubbleList from '../../components/chat/bubbleList/bubbleList';
import InputSender from '../../components/chat/inputSender/inputSender';
import ChatToolbar from '../../components/chat/toolbar/chatToolbar';
import Avatar from '../../img/avatar.jpeg';
import { conversationActions } from '../../redux/actions/conversationActions';
import { notificationActions } from '../../redux/actions/notificationActions';
import { conversationService } from '../../services/conversation.service';
import { messageService } from '../../services/message.service';
import { BubbleModule } from '../../shared/entity/bubble.module';
import { ConversationModule } from '../../shared/entity/conversation.module';
import { MessageModule } from '../../shared/entity/message.module';
import './chat.css';

const Chat: React.FC<RouteComponentProps> = ({ history }) => {
	const [ambassador, setAmbassador] = useState<any>(null);
	const tenant = useSelector<any, any>((state) => state.wallet.data!);
	const conversation = useSelector<any, ConversationModule.Conversation>((state) => state.conversation.conversation!);
	const user = useSelector<any, any>((state) => state.wallet.data!);
	const [messages, setMessages] = useState<Array<MessageModule.MessageGetResponse>>([]);
	const [bubbles, setBubbles] = useState<Array<BubbleModule.Bubble>>([]);
	const notificationConversationCount = useSelector<any, any>(
		(state) => state.notification.conversationNotificationCount!
	);
	const [isLoading, setIsLoading] = useState(false);
	const chatContainerRef = useRef<HTMLIonContentElement | null>(null);
	const dispatch = useDispatch();
	const [notificationSubscription, setNotificationSubscription] = useState(0);

	useEffect(() => {
		if (ambassador === null) {
			validateAmbassador();
		} else {
			createConversation();
		}
	}, [ambassador]);
	useEffect(() => {
		setTimeout(() => {
			chatContainerRef.current?.scrollToBottom(0);
		}, 100);
	});
	const createConversation = async () => {
		let response: Array<ConversationModule.Conversation> = [];
		try {
			setIsLoading(true);
			const type =
				ambassador && ambassador.id
					? ConversationModule.CONVERSATION_TYPE.AMBASSADOR
					: ConversationModule.CONVERSATION_TYPE.SUPPORT;
			response = await conversationService.createConversation(ambassador, user, type);
			dispatch(conversationActions.selectedConversation(response[0]));
			await getMessagesFromConversation(response[0]);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		messageService.messageNotificationSubject$.pipe(first()).subscribe((notification: any) => {
			onReceiveNewMessage(notification);
		});
		setNotificationSubscription(1);
	}, [bubbles]);

	const validateAmbassador = () => {
		if (!ambassador) {
			setAmbassador(user?.tenants[0]?.provider?.ambassadors[0]);
		}
	};

	const getMessagesFromConversation = async (conversationParam: ConversationModule.Conversation) => {
		try {
			setIsLoading(true);
			const response = await messageService.getMessages(
				conversationParam.id!,
				conversationParam.ambassador,
				user.tenants[0].provider.id
			);
			setMessages(response.messages);
			setBubbles(response.bubbles);
			setTimeout(() => {
				setIsLoading(false);
				chatContainerRef.current?.scrollToBottom(0);
				updateUnreadMessages(conversationParam);
			}, 100);
		} catch (e) {
			console.log('error messages', e);
			setIsLoading(false);
		}
	};

	const showChatNotes = () => {
		if (conversation && conversation.type === ConversationModule.CONVERSATION_TYPE.AMBASSADOR) {
			return `${conversation?.name} is your assigned Credientialling ambassador. This is the start of the chat history.`;
		}
		return '';
	};

	const updateUnreadMessages = async (conversationParam: ConversationModule.Conversation) => {
		try {
			const updateUnreadMessages = await messageService.updateUnreadMessages(
				conversationParam.id!,
				conversationParam.ambassador
			);
			if (updateUnreadMessages.data) {
				const notificationResult = notificationConversationCount - (conversationParam.notification || 0);
				localStorage.setItem('notificationConversationCount', notificationResult.toString());
				dispatch(notificationActions.setConversationNotificationCount(notificationResult));
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onMessageToSend = (values: any) => {
		const message: MessageModule.Message = {
			conversationId: conversation.id!,
			from: user?.tenants[0]?.provider.id,
			to: conversation.ambassador,
			status: MessageModule.MESSAGE_STATUS.UNREAD,
			text: values.text,
			attachment: values.attachment,
			fromName: user.tenants[0].provider.fullName || '',
		};
		if (values.text) {
			message.contentType = 'text/plain';
		} else if (document) {
			message.contentType = 'image/png';
		}
		try {
			const date = moment();
			bubbles.push({
				date: 'Today, ',
				time: date.format('hh:mm A'),
				text: values.text,
				document: values.attachment,
				isRead: false,
				position: BubbleModule.BUBBLE_POSITION.RIGHT,
				css: 'chatBubbleConversation',
			});
			messageService.sendMessage(message);
			setBubbles([...bubbles]);
			setTimeout(() => {
				chatContainerRef.current?.scrollToBottom(0);
			}, 100);
		} catch (e) {
			console.log(e);
		}
	};

	const onReceiveNewMessage = (notification: any) => {
		const message = JSON.parse(notification.data.message);
		const newMessage = {
			date: 'Today, ',
			time: moment(message.dateCreated).format('hh:mm A'),
			text: message.text,
			document: message?.attachment,
			isRead: true,
			position: BubbleModule.BUBBLE_POSITION.LEFT,
			css: 'chatBubbleConversation',
		};
		const index = bubbles.findIndex(
			(message: any) =>
				message.date === newMessage.date && message.text === newMessage.text && message.time === newMessage.time
		);
		if (index === -1) {
			bubbles.push(newMessage);
		}
		setBubbles([...bubbles]);
		setTimeout(() => {
			chatContainerRef.current?.scrollToBottom(0);
		}, 100);
	};

	const onConversationBack = () => {
		history.goBack();
	};
	return (
		<IonPage className={`chat ion-align-items-center  ${!isPlatform('mobile') ? 'position-chat-to-menu-left' : ''}`}>
			{!isLoading ? (
				<>
					<IonHeader className="max-550">
						<div>
							<IonRow className="chatToolbar">
								<ChatToolbar
									onBack={() => {
										onConversationBack();
									}}
									avatar={Avatar}
									name={conversation?.name}
								/>
							</IonRow>
							<IonText className="conversationNotesText">{showChatNotes()}</IonText>
						</div>
					</IonHeader>
					<IonContent ref={chatContainerRef} scrollEvents={true} className="max-550">
						<IonRow>
							<BubbleList bubbles={bubbles} />
						</IonRow>
					</IonContent>
					<IonFooter className="chatFooter max-550">
						<InputSender
							onSubmit={(values: { text: string; document: any }) => {
								onMessageToSend(values);
							}}
						/>
					</IonFooter>
				</>
			) : (
				<IonLoading
					cssClass="spinner"
					isOpen={isLoading}
					onDidDismiss={() => setIsLoading(false)}
					spinner="crescent"
					translucent={true}
					duration={1000}
				/>
			)}
		</IonPage>
	);
};

export default Chat;
