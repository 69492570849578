import { Dispatch } from 'redux';
import { SamFieldsEntity } from '../../shared/entity/SamFieldsEntity';
import { SamEntity } from '../../shared/entity/samEntity';
import { SamConstants } from '../constants/';

export const SamActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: SamEntity = {
			possibleFound: [],
			exclusionFound: [],
			previousMatches: [],
			info: document?.info || '',
		};
		let possibleFound: SamFieldsEntity[] = [];
		let exclusionFound: SamFieldsEntity[] = [];
		let previousMatches: SamFieldsEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'exclusion' && element.exclusionType === 'SAM') {
					const name = element.firstName + ' ' + element.middleName + ' ' + element.lastName;
					const exclusion: SamFieldsEntity = {
						name: name,
						npiNumber: element.npi,
						reportingEntity: element.reportingEntity,
						description: element.description,
						orderDate: element.orderDate,
						exclusionDate: element.exclusionDate,
						actionTaken: element.actionTaken,
						cardDetail: element.type.description,
						attachments: element.attachments?.[0],
						samExclusionType: element.samExclusionType,
					};
					if (!element.exclusionMatch && !element.ambassadorVerified) {
						possibleFound.push(exclusion);
					}
					if (!element.exclusionMatch && element.ambassadorVerified) {
						exclusionFound.push(exclusion);
					}
					if (element.exclusionMatch && element.ambassadorVerified) {
						previousMatches.push(exclusion);
					}
				}
			});

			exclusions.possibleFound = possibleFound;
			exclusions.exclusionFound = exclusionFound;
			exclusions.previousMatches = previousMatches;
			if (exclusions) {
				dispatch(set(exclusions));
			}
		}
	};

	function set(payload: any) {
		return { type: SamConstants.SET_SAM, payload };
	}
}
