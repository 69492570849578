import { Dispatch } from 'redux';
import { ConversationModule } from '../../shared/entity/conversation.module';
import { ConversationConstants } from '../constants/conversationConstants';

const selectedConversation = (conversation: ConversationModule.Conversation) => {
	return async (dispatch: Dispatch) => {
		const data = {
			payload: { conversation },
			type: ConversationConstants.SELECTED_CONVERSATION,
		};
		dispatch(data);
	};
};

const checkConversation = (conversation: ConversationModule.Conversation) => {
	return async (dispatch: Dispatch) => {
		const data = {
			payload: { conversation },
			type: ConversationConstants.CHECK_CONVERSATION,
		};
		dispatch(data);
	};
};

const uncheckConversation = (index: number) => {
	return async (dispatch: Dispatch) => {
		const data = {
			payload: { index },
			type: ConversationConstants.UNCHECK_CONVERSATION,
		};
		dispatch(data);
	};
};

const cleanCheckedConversations = () => {
	return async (dispatch: Dispatch) => {
		const data = {
			type: ConversationConstants.CLEAN_CHECKED_CONVERSATIONS,
		};
		dispatch(data);
	};
};

export const conversationActions = {
	selectedConversation,
	checkConversation,
	cleanCheckedConversations,
	uncheckConversation,
};
