import { 
    IonContent,
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonRow,
    IonIcon,
    IonText,
    IonToast,
    IonLoading
   } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from "redux";
  import HeaderProfile from '../../components/HeaderProfile/HeaderProfile'
import { userProfileActions } from '../../redux/actions/userActions';
  import './change-password.css';
  import eye from '../../img/icons/icon-eye.svg'
import eyeOff from '../../img/icons/eyeOff.svg'
import arrowBack from '../../img/icons/backArrow.svg'
import { userService } from '../../services/user.service';
import { ToastOptions } from '../../shared/entity/toastOptions';

  
  
  const ChangePassword: React.FC<RouteComponentProps> = (props) => {
    const dispatch = useDispatch();
    const data = useSelector<any,any>((state)=> state.wallet.data!);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showOld, setShowOld] = useState(false);
    const [valid, setValid] = useState(false);
    const [error, setError] = useState(false);
    const [toastIsShown, setToastIsShown] = useState(false);
    const [toast, setToast] = useState<ToastOptions>({});
    const [showLoading, setShowLoading] = useState(false);
    const [inputs, setInputs] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if(data){
          dispatch(userProfileActions.setUser(data));
        }
      }
    ,[data]);
    const showToast = (toast:ToastOptions) => {
      setToastIsShown(true);
      setToast(toast);
    }
     const handleSubmit = async (e: any  ) => {
        e.preventDefault();
      
          const req = {
            newPassword: inputs.newPassword,
            oldPassword: inputs.oldPassword
        }
        
         await userService.changePassword(req).then(
          res => { 
            setToast({
              color: 'success',
              header: 'Success',
              message: 'Password Updated'
            })
            showToast(toast);
            setInputs({
              oldPassword: '',
              newPassword: '',
              confirmPassword: ''
          })
            props.history.push('/tabs/settings'); 
          },
          error => {
           
            setToast({
              color: 'danger',
              header: 'Failure',
              message: error.response.data
            })
            console.log(error);
            showToast(toast);
          }
      ) 
          
        
      setShowLoading(true); 
        
      }
      const handleChange = (e:any) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));  
        switch(name){
          case 'confirmPassword':
            if(value === inputs.newPassword) {
              setValid(true);
              setError(false);
            } 
            else{
                setValid(false);
                setError(true);
            }  
            break;

          case 'newPassword' :
            if(inputs.confirmPassword.length>0 && value === inputs.confirmPassword) {
              setValid(true);
              setError(false);
            } 
            else{
              if(inputs.confirmPassword.length>0) {
                setValid(false);
                setError(true);
              }
            }  
            break;
        }
          
    }
    return (
      <IonPage className="ion-padding-bottom ion-padding-start ion-padding-end top-page">
        
     
        
        <IonContent className="ion-padding profile-page max-550">
         
            <IonToolbar className="no-background">
                <IonTitle className="ion-text-center" size="large"><IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()} ></IonIcon>Change Password</IonTitle>
            </IonToolbar>
       
            <form name="form" onSubmit={handleSubmit}>
          <IonItem className="input-inside ion-margin-top ion-margin-bottom">
              <IonLabel position="stacked">Old Password</IonLabel>
              <IonRow className="row">
              <IonInput required className="ion-padding" name="oldPassword" type={showOld?'text':'password'} value={inputs.oldPassword} onIonChange={handleChange}></IonInput>
              <IonIcon icon={showOld?eye:eyeOff} onClick={()=>setShowOld(!showOld)}></IonIcon>
              </IonRow>
             
          </IonItem>
          <IonItem className="input-inside ion-margin-top ion-margin-bottom">
              <IonLabel position="stacked">New Password</IonLabel>
              <IonRow className="row">
              <IonInput required className="ion-padding" name="newPassword" type={showNew?'text':'password'} value={inputs.newPassword} onIonChange={handleChange}></IonInput>
              <IonIcon icon={showNew?eye:eyeOff} onClick={()=>setShowNew(!showNew)}></IonIcon>
              </IonRow>
          </IonItem>
          <IonItem className="input-inside ion-margin-top ion-margin-bottom">
              <IonLabel position="stacked">Confirm Password</IonLabel>
              <IonRow className="row">
              <IonInput required className="ion-padding" name="confirmPassword" type={show?'text':'password'} value={inputs.confirmPassword} onIonChange={handleChange}></IonInput>
              <IonIcon icon={show?eye:eyeOff} onClick={()=>setShow(!show)}></IonIcon>
              </IonRow>
              {!valid && error &&(
                <IonText color="danger">
                  <small>{'passwords must match'}</small>
                </IonText>
              )}
          </IonItem>
       
          
          <IonRow className="saveRow"> <IonButton color="secondary" shape="round" type="submit"> Save </IonButton></IonRow>
         
                 
          </form>
           
        </IonContent>
        <IonToast
                        isOpen={toastIsShown}
                        onDidDismiss={() => setToastIsShown(false)}
                        message={toast?.message}
                        position="top"
                        color={toast?.color}
                        header={toast?.header}
                        duration={3000}
                    />
                    <IonLoading
        cssClass='spinner'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        spinner='crescent'
        translucent={true}
        duration={3000}
      />
      </IonPage>
    );
  };
  
  export default ChangePassword;
  