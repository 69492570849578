import React from 'react'
import { 
    IonText
   } from '@ionic/react';
import './StatusTextComponent.css'
import LabelsText from '../../shared/labels';

interface ContainerProps {
   label: string;
}

const StatusTextComponent: React.FC <ContainerProps> = ({label}) => {
    function getColorClass() {
        switch (label) {
          case 'active':
            return "active-bg-color";
          case 'inactive':
            return "inactive-bg-color"; 
          default :
            return "expired-bg-color";
        }
      }
    return (  <IonText className={`status-text-style ${getColorClass()} label-width`}>{LabelsText.label(label)}</IonText>
  );
};

export default StatusTextComponent;