import { IonRow, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { UserPhoto } from '../../../hooks/usePhotoGallery';
import IconUpload from '../../../img/addimage_icon.svg';
import './ImagesLicense.css';
const ImagesLicense: React.FC<any> = (props: any) => {
	const { photos, takePhoto } = props;
	const [pictures, setPictures] = useState<UserPhoto[]>(photos);
	useEffect(() => {
		setPictures(photos);
	}, [photos]);
	function existImage(type: string) {
		return pictures.findIndex(function (element: any) {
			return element.type === type;
		});
	}

	return (
		<IonRow className="ion-no-border row-pics-images">
			{existImage('front') < 0 ? (
				<div className="upload-media-container-img-lic" onClick={() => takePhoto('front')}>
					<div className="upload-area-img-lic">
						<img src={IconUpload} />
						<h3>Front</h3>
					</div>
				</div>
			) : (
				<div onClick={() => takePhoto('front')}>
					<div className="image-lic-selected">
						<img className="img-width-license" src={pictures[existImage('front')].base64String} />
					</div>
					<IonRow className="container-edit-dr-lic-text">
						<IonRow className="edit-dr-lic-text">
							<IonText>EDIT PICTURE</IonText>
						</IonRow>
					</IonRow>
				</div>
			)}
			{existImage('back') < 0 ? (
				<div className="upload-media-container-img-lic" onClick={() => takePhoto('back')}>
					<div className="upload-area-img-lic">
						<img src={IconUpload} />
						<h3>Back</h3>
					</div>
				</div>
			) : (
				<div onClick={() => takePhoto('back')}>
					<div className="image-lic-selected">
						<img className="img-width-license" src={pictures[existImage('back')].base64String} />
					</div>
					<IonRow className="container-edit-dr-lic-text">
						<IonRow className="edit-dr-lic-text">
							<IonText>EDIT PICTURE</IonText>
						</IonRow>
					</IonRow>
				</div>
			)}
		</IonRow>
	);
};

export default ImagesLicense;
