import { ActionEntity } from "../../shared/entity/actionEntity";
import { AmaConstants } from "../constants";

const initialUser = {};
const amaReducer = (state = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case AmaConstants.SET_AMA:
                return action.payload;          
        default:
            return state;
    }
}
export default amaReducer;