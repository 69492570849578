import { Dispatch } from 'redux';
import { MedicareCardEntity } from '../../shared/entity/medicareCardEntity';
import { MedicareCardConstant } from '../constants/medicareCardConstants';

export const MedicareCardActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: MedicareCardEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'medicare') {
					const exclusion: MedicareCardEntity = {
						state: element.state,
						number: element.ptannumber,
						renewalDate: element.renewalDate,
						effectiveDate: element.effectiveDate,
						description: element.description,
						status: element.status,
					};
					exclusions.push(exclusion);
				}
			});

			if (exclusions) {
				dispatch(set({ mcare: exclusions, info: document?.info || '' }));
			}
		}
	};

	function set(payload: any) {
		return { type: MedicareCardConstant.SET_MEDICARE_CARD, payload };
	}
}
