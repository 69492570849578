import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import arrowBack from '../../img/icons/backArrow.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import { onBoardingActions } from '../../redux/actions/onBoardingActions';
import { onBoardingService } from '../../services/onBoarding.service';
import AddressStates from '../../shared/addressStates';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import './started.css';

const Started: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	let login = useSelector<any, any>((state) => state.login);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [valid, setValid] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [inputs, setInputs] = useState({
		name: '',
		lastName: '',
		npi: '',
		state: '',
	});

	const navigate = (route: string) => {
		props.history.push(route);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (inputs.npi !== '') {
			onBoardingService.npiInUse(inputs.npi).then(
				(res) => {
					if (res.data.provider !== null) {
						login.token = 'x';
						navigate('/');
					} else {
						onBoardingService.lookUpByNpi(inputs.npi).then(
							(res) => {
								if (res.data?.results?.length > 0) {
									navigate('/create-account');
								} else {
									setToast({
										color: 'danger',
										header: 'Failure',
										message: 'Npi Not Found',
									});
									showToast(toast);
								}
							},
							(error) => {
								setToast({
									color: 'danger',
									header: 'Failure',
									message: error.response.data,
								});
								console.log(error);
								showToast(toast);
							}
						);
					}
				},
				(error) => {
					setToast({
						color: 'danger',
						header: 'Failure',
						message: error.response.data,
					});
					console.log(error);
					showToast(toast);
				}
			);
		} else {
			if (inputs.name !== '' && inputs.lastName !== '' && inputs.state !== '' && inputs.npi === '') {
				onBoardingService.lookUpNppes(inputs.name, inputs.lastName, inputs.state).then(
					(res) => {
						if (res.data.results) {
							dispatch(onBoardingActions.setNpiResults(res.data.results));
						}

						navigate('/which-is-you');
					},
					(error) => {
						setToast({
							color: 'danger',
							header: 'Failure',
							message: error.response.data,
						});
						console.log(error);
						showToast(toast);
					}
				);
			}
		}
		setShowLoading(true);
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setInputs((inputs) => ({ ...inputs, [name]: value }));
		switch (name) {
			case 'name':
				user.firstName = value;
				break;
			case 'lastName':
				user.lastName = value;
				break;
			case 'state':
				user.state = value;
				break;
			case 'npi':
				user.npi = value;
				break;
			default:
				break;
		}
		validate();
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	const validate = () => {
		if (user.npi && user.npi !== '') {
			setValid(true);
		} else {
			if (user.firstName && user.lastName && user.state && !user.npi) {
				setValid(true);
			} else {
				setValid(false);
			}
		}
	};
	return (
		<IonPage
			className="
      loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>

			<IonContent className="ion-padding profile-page max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>Tell
						us about yourself
					</IonTitle>
				</IonToolbar>
				<IonRow className="ion-justify-content-center ion-padding-bottom">
					<IonRow>Let’s start building your profile</IonRow>
				</IonRow>

				<form name="form" onSubmit={handleSubmit}>
					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">NPI</IonLabel>
						<IonInput
							className="ion-padding"
							name="npi"
							type="text"
							value={inputs.npi}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonRow className="or">
						<IonText>OR</IonText>
					</IonRow>
					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">First Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="name"
							type="text"
							value={inputs.name}
							onIonChange={handleChange}></IonInput>
					</IonItem>

					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Last Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="lastName"
							type="text"
							value={inputs.lastName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="input-inside ion-margin-top ion-margin-bottom">
						<IonSelect
							interface="action-sheet"
							placeholder="State"
							name="state"
							className="select-state-started"
							onIonChange={handleChange}>
							{AddressStates.forSelect?.map((element, index) => (
								<IonSelectOption key={index} value={element.value}>
									{element.label}
								</IonSelectOption>
							))}
						</IonSelect>
					</IonItem>
					<IonRow className="save-row-started">
						{' '}
						<IonButton className="onBoardingSaveButtom" disabled={!valid} color="secondary" shape="round" type="submit">
							{' '}
							Next{' '}
						</IonButton>
					</IonRow>
				</form>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={3000}
			/>
		</IonPage>
	);
};

export default Started;
