import {
	IonButton,
	IonCheckbox,
	IonContent,
	IonFooter,
	IonIcon,
	IonItem,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Remarkable } from 'remarkable';
import arrowBack from '../../img/icons/backArrow.svg';
import { privacyService } from '../../services/privacy.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import './PrivacyAndSecurity.css';

const PrivacyAndSecurity: React.FC<RouteComponentProps> = (props) => {
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [terms, setTerms] = useState();
	const [agree, setAgree] = useState<boolean>(false);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	var md = new Remarkable();
	const [showLoading, setShowLoading] = useState(true);

	const navigate = (route: string) => {
		props.history.push('/tabs' + route);
	};
	useEffect(() => {
		privacyService.getPrivacyTerms(data?.tenants[0].tenant.id).then(
			(res) => {
				setTerms(res.data.description);
				setShowLoading(false);
			},
			(error) => {
				setToast({
					color: 'danger',
					header: 'Failure',
					message: 'Oops an Error Ocurred, try again',
				});
				showToast(toast);
			}
		);
	}, []);
	const onAgree = () => {
		setAgree(!agree);
		console.log(agree);
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};

	return (
		<IonPage className="top-page">
			<IonToolbar className="no-background ion-padding max-550">
				<IonTitle className="ion-text-center" size="large">
					<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon> My
					Profile
				</IonTitle>
			</IonToolbar>
			{terms && (
				<IonContent className="ion-padding max-550">
					<IonRow className="ion-padding-top ion-padding-bottom">
						<div dangerouslySetInnerHTML={{ __html: md.render(terms!) }}></div>
					</IonRow>
					<IonItem className="ion-padding-top no-background" lines="none">
						<IonText className="label__privacy-sec">I agree</IonText>
						<IonCheckbox
							onIonChange={onAgree}
							checked={agree}
							className="checkbox__privacy-sec"
							color="primary"
							slot="start"></IonCheckbox>
					</IonItem>
					<IonFooter className="ion-no-border ion-padding ion-text-center">
						<IonButton
							onClick={() => navigate('/settings')}
							className="btn-color-nuco splash-btn ion-margin-left"
							shape="round">
							OK
						</IonButton>
					</IonFooter>
				</IonContent>
			)}

			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</IonPage>
	);
};

export default PrivacyAndSecurity;
function setToastIsShown(arg0: boolean) {
	throw new Error('Function not implemented.');
}

function setToast(toast: any) {
	throw new Error('Function not implemented.');
}
