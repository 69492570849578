import { useEffect, useState } from 'react';
import {
    IonRow,
    IonText,
    IonItem,
    IonToggle,
    IonLabel,
    IonButton,
    IonInput,
    IonImg,
    IonModal,
    IonCol,
    IonCheckbox,
    IonIcon,
    IonDatetime,
} from '@ionic/react';
import './ShareWallet.css'
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import scanImage from '../../../img/scan-share.png';
import WalletCardsNames from '../../../shared/walletCardsNames';
import ChevronDownBlue from '../../../img/icon-chevron-down-black.svg'
import IconDate from '../../../img/icon-date.svg'
import IconDateNotSelected from '../../../img/date-not-selected.svg'
import Datetime from 'react-datetime';
import moment from 'moment';
const ShareWallet: React.FC<CardInfoEntity> = ({ title, zoom, props, showShareCard }) => {
    const [back, setBack] = useState('card-share card-share-actions');
    const [shareWhatOptions, setShareWhatOptions] = useState<any[]>([]);
    const [shareWhatText, setShareWhatText] = useState<string>();
    const [accessTime, setAccessTime] = useState<string>("");
    const [btnSelected, setBtnSelected] = useState<number>(1);
    const [showModal, setShowModal] = useState(false);
    const [showModalDate, setShowModalDate] = useState(false);
  
    useEffect(() => {
        let cont = 0;
        let text = "";
        shareWhatOptions.forEach((element) => {
            if (element.checked) {
                cont++;
                if (text === "") {
                    text = element.label;
                }
            }
        });
        if (cont > 1) {
            setShareWhatText(text + '....');
        } else {
            setShareWhatText(text);
        }
    });
    useEffect(() => {
        let data: any[] = [];
        WalletCardsNames.values.map((element) => {
            data.push(element);
        });
        setShareWhatOptions(data);

    }, []);
    //handle checked options
    function addOrRemoveShareOptions(value: string, checked: boolean) {
        let data = [...shareWhatOptions];
        data.map((element) => {
            if (element.value === value) {
                element.checked = checked;
            }
        });
        setShareWhatOptions(data);
    }
    //cancel modal share
    function cancelSelectionShare(updateList: boolean) {
        if (!updateList) {
            let data = [...shareWhatOptions];
            data.map((element) => {
                element.checked = element.isChecked;
            });
        }
        setShowModal(false);
    }
    //show modal to select share options
    function showModalShare() {
        let data = [...shareWhatOptions];
        data.map((element) => {
            element.isChecked = element.checked;
        });
        setShareWhatOptions(data);
        setShowModal(true);
    }
    return (
        zoom ?
            <div className={`${back}`}>
                <IonRow className={`"wrapper-zoom-share"`}>
                    <div><IonRow className="header-share-actions">
                        <IonRow className='share-title'>
                            <IonText>{title}</IonText>
                        </IonRow>
                    </IonRow>
                        <IonRow className="header-share-actions ion-padding-top">
                            <IonRow className='share-sub-title'>
                                <IonText>Give access to anyone who needs your credenitials.</IonText>
                            </IonRow>
                        </IonRow>
                        <IonRow className="header-share-actions combo-style">
                            <IonRow className='share-sub-title'>
                                <IonText>Information to Share</IonText>
                            </IonRow>
                            <IonRow id='row' className="row-select-share ion-margin-top" onClick={() => showModalShare()}>
                                <IonText id='row' className={`${shareWhatText !== "" ? 'color-text-share' : ''}`}>
                                    {shareWhatText !== "" ? shareWhatText : 'Select what to share'}</IonText>
                                <IonIcon src={ChevronDownBlue}></IonIcon>
                            </IonRow>
                        </IonRow>
                        <IonModal isOpen={showModal} cssClass='modal-share-what'
                            showBackdrop={true}
                            backdropDismiss={false}
                        >
                            <IonRow className="title-modal">
                                <IonText>Select what to share</IonText>
                            </IonRow>

                            {shareWhatOptions.map((option, i) => (
                                <IonRow key={option.value}>
                                    <IonCol size='2'>
                                        <IonCheckbox color="primary" className='modal-check-color' checked={option.checked}
                                            onIonChange={e => addOrRemoveShareOptions(option.value, e.detail.checked)}
                                        />
                                    </IonCol>
                                    <IonCol size='10' className="modal-flex-column">
                                        <IonText className="title-modal-option">{option.label}</IonText>
                                        <IonText className="sub-title-modal-option">{option.subTitle}</IonText>
                                    </IonCol>
                                </IonRow>

                            ))}
                            <IonRow className="row-btn-modal">
                                <IonCol size='6' className="border-right-modal row-cancel" onClick={() => cancelSelectionShare(false)}>

                                    <IonRow className="modal-content-center" >
                                        <IonButton className="btn-modal-color" >Cancel</IonButton>
                                    </IonRow>
                                </IonCol>
                                <IonCol size='6' onClick={() => cancelSelectionShare(true)}>
                                    <IonRow className="modal-content-center">
                                        <IonButton className="btn-modal-color">Ok</IonButton>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonModal>

                        <IonRow className="header-share-actions combo-style">
                            <IonRow className='share-sub-title'>
                                <IonText>Access Expiration</IonText>
                            </IonRow>
                        </IonRow>
                        <IonRow>
                        <IonItem className="ion-margin-top item-date">
                            <IonIcon src={accessTime !== "" ? IconDate : IconDateNotSelected} className="icon-date-padding"></IonIcon>
                            <IonDatetime name='row' 
                            displayFormat="D MMM YYYY" min={moment().toISOString()} placeholder={"No date selected"}
                            onIonChange={()=>setAccessTime("change")}
                            ></IonDatetime>
                         </IonItem>
                         <IonIcon className="item-date-right-icon" src={ChevronDownBlue}></IonIcon>
                        </IonRow>
                        <IonRow className="header-share-actions combo-style">
                            <IonRow className='share-sub-title'>
                                <IonText>Include User names and passwords:</IonText>
                            </IonRow>
                            <IonRow>
                                <IonItem id="toggle" className="ion-no-padding no-background" lines="none">
                                    <IonToggle id="toggle" className="ion-no-margin toggle-share" checked={true}></IonToggle>
                                    <IonLabel className="on-off-label-share">Yes</IonLabel>
                                </IonItem>
                            </IonRow>
                        </IonRow>
                        <IonRow className='space-between-share'>
                            <IonButton id='row' className={`${btnSelected === 1 ? 'btns-share-active' : 'btns-share-inactive'}`} shape="round" type="submit" onClick={() => setBtnSelected(1)}> By Email </IonButton>
                            <IonButton id='row' className={`${btnSelected === 2 ? 'btns-share-active' : 'btns-share-inactive'}`} shape="round" type="submit" onClick={() => setBtnSelected(2)}> By Phone</IonButton>
                            <IonButton id='row' className={`${btnSelected === 3 ? 'btns-share-active' : 'btns-share-inactive'}`} shape="round" type="submit" onClick={() => setBtnSelected(3)}> Scan </IonButton>
                        </IonRow>
                        {btnSelected === 1 ? (
                            <IonRow className="header-share-actions combo-style">
                                <IonRow className='share-sub-title'>
                                    <IonText>Email</IonText>
                                </IonRow>
                                <IonInput id='row' name='row' className='email-share' placeholder='i.e your.email@gmail.com'></IonInput>
                            </IonRow>
                        ) : btnSelected === 3 ? (
                            <IonRow className='row-scan-share'>
                                <IonImg src={scanImage}></IonImg>
                            </IonRow>
                        ) : null}
                    </div>
                    <IonRow className='row-save-form'>
                            <IonLabel className='btns-share-cancel' >Cancel</IonLabel>
                           <IonButton id='row' className='btns-share-active' shape="round" type="submit">Submit</IonButton>
                        </IonRow>
                </IonRow>
            </div>
            : null
    )
};
export default ShareWallet;
