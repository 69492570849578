import {
	IonButton,
	IonContent,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import arrowBack from '../../img/icons/backArrow.svg';

import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ImagesLicense from '../../components/Cards/FrontBackImages/ImagesLicense';
import { walletActions } from '../../redux/actions/walletActions';
import { filesService } from '../../services/files.service';
import { onBoardingService } from '../../services/onBoarding.service';
import { DriverLicenseEntity } from '../../shared/entity/driverLicenseEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './DriversLicense.css';
interface ContainerProps {
	prop?: RouteComponentProps;
}
const DriversLicense: React.FC<ContainerProps> = (props) => {
	const { prop } = props;
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const driverLicense = useSelector<ReduxStateEntity, DriverLicenseEntity>((state) => state.driverLicense!);
	const [credentials, setCredentials] = useState<any>();
	const [front, setFront] = useState<any>();
	const [back, setBack] = useState<any>();
	const [newLicense, setNewLicense] = useState(true);
	const { photos, takePhoto, takeSelfie, license } = usePhotoGallery();
	const [document, setDocument] = useState();
	const [showLoading, setShowLoading] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');

	const [showToastAttachments, setShowToastAttachments] = useState(false);

	const navigate = (route: string) => {
		prop?.history?.push(route);
	};

	useEffect(() => {
		const tenantId = user.id;
		filesService.documentType(tenantId!, 'driver-license').then(
			(res) => {
				setDocument(res.data.id);
			},
			(error) => {}
		);
	}, []);
	useEffect(() => {
		if (driverLicense?.frontPictureUrl) {
			photos.push({
				base64String: driverLicense.frontPictureUrl,
				filepath: '',
				type: 'front',
			});
			setFront(getImageLicense(0));
		}
		if (driverLicense?.backPictureUrl) {
			photos.push({
				base64String: driverLicense.backPictureUrl,
				filepath: '',
				type: 'back',
			});
			setBack(getImageLicense(1));
		}
	}, []);

	//manage the back image scanned from blink Id
	useEffect(() => {
		if (photos.length > 0 && photos[0].filepath !== '') {
			const tenantId = user.id;
			let uuid = uuidv4();
			let fileName = '';
			if (photos[0].type === 'front') {
				fileName = `${uuid}` + '-driver-license.png';
				saveImageScan(fileName, uuid, tenantId!, 'front');
			} else {
				fileName = `${uuid}` + '-driver-license-back.png';
				saveImageScan(fileName, uuid, tenantId!, 'back');
			}
		}
	}, [photos]);
	function getImageLicense(position: number) {
		let image: any;
		driverLicense.attachments?.forEach((element, index) => {
			if (index === position) {
				image = element;
			}
		});
		return image;
	}
	//save the image scanned form blink id
	function saveImageScan(fileName: string, uuid: string, tenantId: string, type: string) {
		try {
			setShowLoading(true);
			filesService.credentials(tenantId!, fileName, 'documentAttachments').then(
				(res) => {
					console.log(res);
					setCredentials(res.data.uploadCredentials);
					let formData = new FormData();
					formData.append('key', res.data.uploadCredentials?.fields?.key);
					formData.append('policy', res.data.uploadCredentials?.fields?.policy);
					formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
					formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
					formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
					formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
					formData.append('file', photos[0].blob, fileName);
					filesService.upload(res.data.uploadCredentials?.url, formData).then(
						(response) => {
							if (type === 'front') {
								setFront({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: 'driverLicense.png',
									privateUrl: res.data.privateUrl,
									publicUrl: null,
									new: true,
									type: 'front',
									size: photos[0].blob.size,
								});
							} else {
								setBack({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: 'driverLicense-back.png',
									privateUrl: res.data.privateUrl,
									publicUrl: null,
									new: true,
									type: 'back',
									size: photos[0].blob.size,
								});
							}
							setShowLoading(false);
						},
						(error) => {
							console.log(error);
							setShowLoading(false);
							setToastMessageError('Something went wrong,please try again.');
							setShowToastAttachments(true);
						}
					);
				},
				(error) => {
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			);
		} catch (error) {
			console.log(error);
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	}

	const submit = (e: any) => {
		try {
			e.preventDefault();
			setShowLoading(true);
			let attach: any[] = [];
			const tenantId = user.id;
			if (!front || !back) {
				setShowLoading(false);
				return;
			}
			if (Object.keys(driverLicense).length == 0) {
				if (back) {
					driverLicense.frontPictureUrl = front.downloadUrl;
					driverLicense.backPictureUrl = back.downloadUrl;
					attach = [front, back];
				} else {
					driverLicense.frontPictureUrl = front.downloadUrl;
					attach = [front];
				}

				const dataToSend = {
					data: {
						address1: license?.address1,
						attachments: attach,
						birthDate: license?.birthDate,
						expirationDate: license?.expirationDate,
						firstName: license?.firstName,
						lastName: license?.lastName,
						middleName: license?.middleName,
						number: license?.number,
						state: license?.state,
						type: document,
					},
				};
				driverLicense.address = license?.address1;
				driverLicense.birthday = license?.birthDate;
				driverLicense.expirationDate = license?.expirationDate;
				driverLicense.name = license?.firstName;
				driverLicense.lastName = license?.lastName;
				driverLicense.middleName = license?.middleName;
				driverLicense.number = license?.number;
				onBoardingService.uploadFile(tenantId!, dataToSend).then(
					async (res) => {
						if (res?.data) {
							setShowLoading(false);
							setToastMessageError('Data updated successfully');
							setShowToastAttachments(true);
							const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
							await dispatch(walletActions.authorization(tmpUserToken));
							navigate('/tabs/wallet');
						}
					},
					(error) => {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				);
			} else {
				if (front) {
					driverLicense.frontPictureUrl = front.downloadUrl;
					attach.push(front);
				}
				if (back) {
					driverLicense.backPictureUrl = back.downloadUrl;
					attach.push(back);
				}
				const dataUpdate = {
					address1: driverLicense.address,
					attachments: attach,
					birthDate: driverLicense.birthday,
					boardCertified: false,
					certificationExpirationDate: false,
					currentlyPracticing: false,
					expirationDate: driverLicense.expirationDate,
					firstName: driverLicense.name,
					individualCoverage: false,
					isAccepted: false,
					isAuthorized: false,
					isDatesVerified: false,
					isGrantAccess: false,
					isPrimary: false,
					isPrimarySource: false,
					isPrimarySourceVerified: false,
					lastName: driverLicense.lastName,
					middleName: driverLicense.middleName,
					number: driverLicense.number,
					priorOccuranceActsCoverage: false,
					selfInsured: false,
					state: driverLicense.state,
					type: document,
					unlimitedCoverage: false,
				};
				filesService.updateDocuments(tenantId!, dataUpdate, driverLicense.id!).then(
					async (res) => {
						if (res?.data) {
							setShowLoading(false);
							setToastMessageError('Data updated successfully');
							setShowToastAttachments(true);
							await dispatch(walletActions.updateWallet(data, driverLicense.id, dataUpdate));
							navigate('/tabs/wallet');
						} else {
							setShowLoading(false);
							setToastMessageError('Something went wrong,please try again.');
							setShowToastAttachments(true);
						}
					},
					(error) => {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				);
			}
		} catch (error) {
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	};

	return (
		<IonPage
			className="top-page 
	ion-padding-bottom ion-padding-start ion-padding-end ">
			<IonContent fullscreen className=" max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => prop?.history?.goBack()}></IonIcon>{' '}
						Drivers License
					</IonTitle>
				</IonToolbar>
				<IonRow className="upload-main-container">
					<ImagesLicense photos={photos} takePhoto={takePhoto} />

					<div className="upload-content-info-dr-lic">
						<IonText className="upload-note-dr-lic">Note:</IonText>
						<IonText className="upload-note-data-dr-lic">
							Used for proof of identification with state and federal agencies, and health insurance payers
						</IonText>
					</div>
					{photos && photos.length == 2 && (
						<IonRow className="upload-file-row-dr-lic">
							<IonButton onClick={submit} color="secondary" shape="round" className="btn-save-license">
								{' '}
								Save and Close{' '}
							</IonButton>
						</IonRow>
					)}
				</IonRow>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
			</IonContent>
		</IonPage>
	);
};

export default DriversLicense;
