import { ActionEntity } from "../../shared/entity/actionEntity";
import { UserEntity } from "../../shared/entity/userEntity";
import { UserConstants } from "../constants/userConstants";

const initialUser: UserEntity = {};
const userReducer = (state: UserEntity = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case UserConstants.USER_PROFILE:
                return action.payload;
        case UserConstants.USER_PROFILE_REQUEST:
            return {
                ...state,
                    loading: true,
                    resolved: null
            }        
        case UserConstants.USER_PROFILE_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        resolved: true,
                    };
                    
        case UserConstants.USER_PROFILE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                        resolved:false,
                    };
        case UserConstants.SET_USER_NPI:
                    return {
                        ...state,
                        npi: action.payload.npi,
                        name: action.payload.name
                    };
        case UserConstants.SET_TENANT_ID:
                    return {
                        ...state,
                        id: action.payload.id
                    };
        case UserConstants.SET_EMAIL:
                    return {
                            ...state,
                            email: action.payload.email
                        };
                   
        default:
            return state;
    }

}
export default userReducer;