import { ActionEntity } from "../../shared/entity/actionEntity";
import { OnBoardingPhotoConstants } from "../constants/onBoardingPhotoConstants";

const initialUser: any = {
    arr:[],
    license: 'dl',
    selfie: null
};
const onBoardingPhotosReducer = (state: any = initialUser,action: ActionEntity) =>{
    switch(action.type){     
        case OnBoardingPhotoConstants.UPLOAD_PHOTO_SUCCESS:
            if(state.arr.length < 1){
                return { 
                    ...state,
                    arr: state.arr.concat(action.payload.photo),
                    license: action.payload.license
                }  
            }
            else{
                return { 
                    ...state,
                    arr: [action.payload.photo],
                    license: action.payload.license
                }  
            }
            case OnBoardingPhotoConstants.UPLOAD_SELFIE_SUCCESS:
                    return { 
                        ...state,
                        selfie: action.payload
                    }                                 
        default:
            return state;
    }

}
export default onBoardingPhotosReducer;