import { ActionEntity } from "../../shared/entity/actionEntity";
import { ADD_NOTIFICATION_CONVERSATION, SET_NOTIFICATION_CONVERSATION } from "../constants/notificationConstants";

const initialNotification = {
  conversationNotificationCount: 0
};

const notificationReducer = (state: any = initialNotification, action: ActionEntity) => {
  switch(action.type){
    case ADD_NOTIFICATION_CONVERSATION:
      return {
        ...state,
        conversationNotificationCount: (state.conversationNotificationCount + 1)
      };    
    case SET_NOTIFICATION_CONVERSATION:
      return {
        ...state,
        conversationNotificationCount: action.payload
      };     
    default:
      return state;
  }
}
export default notificationReducer;