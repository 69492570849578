import { IonImg, IonRow, IonText } from '@ionic/react';
import React from 'react';
import IconClockWhite from '../../img/clock-white.svg';
import IconClockBlue from '../../img/icon-no-info-black.svg';
import './NoInfoCard.css';
const NoInfoCard: React.FC<{ textColor: string; blackIcon?: boolean; cardType?: string }> = (props) => {
	const { textColor, blackIcon, cardType } = props;
	return (
		<IonRow className={`center-data-info`}>
			{cardType === 'password' ? (
				<div className="center-data-info">
					<IonRow className={`ion-margin-top ${textColor}`}>
						<IonText>
							Please access the Tasks section of the app to complete the login information for all your credentials.
							Once complete, your login credentials will appear in this card.
						</IonText>
					</IonRow>
				</div>
			) : cardType === 'tasks' ? (
				<div className="center-data-info">
					<IonRow className={`ion-margin-top desc-no-info ${textColor}`}>Congratulations!</IonRow>
					<IonRow className={`ion-margin-top ion-text-center  ${textColor}`}>
						<IonText>
							You have completed all your tasks.Please review the calendar section to view upcoming tasks or stay tuned
							to new tasks generated by our system or your Ambassador.
						</IonText>
					</IonRow>
				</div>
			) : cardType === 'abms' || cardType === 'mdlc' || cardType === 'boact' ? (
				<div className="center-data-info">
					<IonRow className="waiting-clock-no-info ion-margin-top">
						<IonImg
							className="ion-padding-top clockIcon no-info-card-bg"
							src={blackIcon ? IconClockBlue : IconClockWhite}
						/>
					</IonRow>
					<IonRow className={`ion-margin-top desc-no-info ${textColor}`}>We are working on it.</IonRow>
					<IonRow className={`ion-margin-top ion-text-center  ${textColor}`}>
						<IonText>
							Our system is collecting your information. This process can take a few minutes. Once complete, the rest of
							your wallet will be built, and you will receive an email notification.
						</IonText>
					</IonRow>
				</div>
			) : cardType === 'dea' ? (
				<div className="center-data-info">
					<IonRow className={`ion-margin-top desc-no-info ${textColor}`}>No Records Found!.</IonRow>
					<IonRow className={`ion-margin-top ion-text-center  ${textColor}`}>
						<IonText>
							Our system has not found a DEA License for you. If you feel this is an error, please access the app
							messenger and contact your Ambassador or send us an email at support@nucocred.com.
						</IonText>
					</IonRow>
				</div>
			) : cardType === 'mdcr' || cardType === 'mdcaid' ? (
				<div className="center-data-info">
					<IonRow className={`ion-margin-top desc-no-info ${textColor}`}>We are working on it.</IonRow>
					<IonRow className={`ion-margin-top ion-text-center  ${textColor}`}>
						<IonText>
							Our team will collect your information provided you have granted permission to access your CAQH account.
							Once complete, you will receive an email notification.
						</IonText>
					</IonRow>
				</div>
			) : (
				<div className="center-data-info">
					<IonRow className="waiting-clock-no-info ion-margin-top">
						<IonImg
							className="ion-padding-top clockIcon no-info-card-bg"
							src={blackIcon ? IconClockBlue : IconClockWhite}
						/>
					</IonRow>
					<IonRow className={`ion-margin-top ${textColor}`}>We are working on it.</IonRow>
					<IonRow className={`ion-margin-top ${textColor}`}>
						<IonText>Some data takes time to collect.</IonText>
					</IonRow>
					<IonRow className={`${textColor}`}>
						<IonText>If you already have this information</IonText>
					</IonRow>
					<IonRow className={`${textColor}`}>
						<IonText>handy you can enter it below.</IonText>
					</IonRow>
				</div>
			)}
		</IonRow>
	);
};

export default NoInfoCard;
