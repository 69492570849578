import { IonRow } from '@ionic/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../../redux/actions/notificationActions';
import { BubbleModule as Module } from '../../../shared/entity/bubble.module';
import Bubble from '../bubble/Bubble';
import './BubbleList.css';

const BubbleList: React.FC<Module.BubbleList> = (props: Module.BubbleList) => {
	const dispatch = useDispatch();
	const bubbleList: Module.BubbleList = props;
	useEffect(() => {
		if (hasBubbles() && window.location.pathname === '/tabs/chat') {
			dispatch(notificationActions.setConversationNotificationCount(0));
		}
	});
	const hasBubbles = () => {
		return bubbleList && bubbleList.bubbles && bubbleList.bubbles.length > 0;
	};

	return (
		<IonRow className="BubbleList">
			{hasBubbles() &&
				bubbleList.bubbles.map((row, i) => {
					return (
						<Bubble
							key={i}
							date={row.date}
							time={row.time}
							text={row.text}
							document={row.document}
							position={row.position}
							css={row.css}
						/>
					);
				})}
		</IonRow>
	);
};

export default BubbleList;
