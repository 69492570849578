import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTextarea,
	IonToast,
	IonToggle,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import AccordionWorkHistory from '../../components/AccordionWorkHistory/AccordionWorkHistory';
import Alert from '../../components/Alert/Alerts';
import arrowBack from '../../img/icon-close-modal.svg';
import { filesService } from '../../services/files.service';
import { taskService } from '../../services/task.service';
import AddressCountries from '../../shared/addressCountries';
import AddressStates from '../../shared/addressStates';
import { ProviderTaskEntity } from '../../shared/entity/ProviderTaskEntity';
import { DocumentFieldEntity } from '../../shared/entity/documentFieldEntity';
import { DocumentOptionsEntity } from '../../shared/entity/documentOptionsEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import { WorkHistoryEntity } from '../../shared/entity/workHistoryEntity';
import './CAQH.css';
interface ContainerProps {
	task?: ProviderTaskEntity;
	providerTask?: ProviderTaskEntity;
	closeTask?: (data: any, deleteData: boolean) => void;
	saveTaskLocal?: boolean;
	prop?: RouteComponentProps;
}

const CAQH: React.FC<ContainerProps> = (props) => {
	const { saveTaskLocal, prop } = props;
	const [selectRelationMany, setRelationMany] = useState<[DocumentOptionsEntity]>();
	const [formCompleteAgain, setFormCompleteAgain] = useState(true);
	const user = useSelector<ReduxStateEntity, UserEntity>((state) => state.user!);
	const wallet = useSelector<any, any>((state) => state.wallet.data!);
	const [inputs, setInputs] = useState({});
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('The file has already been added.');
	const [showAlertFormComplete, setAlertFormComplete] = useState(false);
	const [showAlertGaps, setAlertGaps] = useState(false);
	const [showAlertFinishLater, setAlertFinishLater] = useState(false);
	const [showAlertSendIt, setAlertSendIt] = useState(false);
	const [dateMessageError, setDateMessageError] = useState('');
	const [deleteLocalData, setDeleteLocalData] = useState(false);
	const [providerTask, setProviderTask] = useState<ProviderTaskEntity>();
	const [task, setTask] = useState<ProviderTaskEntity>();
	const [showLoading, setShowLoading] = useState(false);

	//submit form
	const cancel = () => {
		setAlertFormComplete(false);
		setAlertFinishLater(true);
		setDeleteLocalData(false);
	};
	//submit form
	const submit = () => {
		setShowLoading(true);
		setDeleteLocalData(true);
		let fields: any = {};
		for (const [key, value] of Object.entries(inputs)) {
			if (
				key !== 'historyDescription' &&
				key !== 'historyEndDate' &&
				key !== 'historyStartDate' &&
				key !== 'historyTitle' &&
				key !== 'historyTitle'
			) {
				fields[key] = value;
			}
		}
		fields['type'] = task?.id;
		fields['provider'] = providerTask?.provider?.id;
		fields['id'] = providerTask?.id;
		fields['tenant'] = user.id;
		fields['ignoreAmbassadorVerifiedFlag'] = true;
		fields['ambassadorVerified'] =
			providerTask?.document &&
			typeof providerTask.document === 'object' &&
			Object.keys(providerTask.document).length > 0
				? providerTask.document.ambassadorVerified
				: false;

		if (
			providerTask?.document &&
			typeof providerTask.document === 'object' &&
			Object.keys(providerTask.document).length > 0
		) {
			filesService.updateDocuments(user.id!, fields, providerTask?.document.id).then(
				(res) => {
					if (res?.data) {
						setShowLoading(false);
						actionSaveForm();
					} else {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				},
				(error) => {
					console.log(error);
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			);
		} else {
			delete fields.id;
			delete fields.key;
			delete fields.tenant;
			filesService.saveDocumentsTasks(user.id!, fields, providerTask?.id!).then(
				(res) => {
					if (res?.data) {
						setShowLoading(false);
						actionSaveForm();
					} else {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				},
				(error) => {
					console.log(error);
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			);
		}
	};
	const actionSaveForm = () => {
		setAlertFormComplete(false);
		setAlertSendIt(true);
	};
	const btnSaveAlert = [
		{
			text: `Yes. Send it.`,
			handler: submit,
			cssClass: 'submitBtnTask centerBtnText',
		},
		{
			text: `No.I'll finish later.`,
			handler: cancel,
			cssClass: 'cancelBtnTask centerBtnText',
		},
	];
	//close gaps alert
	const closeAlertGaps = () => {
		setAlertFormComplete(false);
		saveLocalStorageData(inputs, deleteLocalData);
	};
	const btnGaps = [
		{
			text: 'OK',
			handler: closeAlertGaps,
			cssClass: 'submitBtnTask centerBtnText',
		},
	];
	//close alert saveAction
	const closeAlertSaveAction = () => {
		setAlertSendIt(false);
		setAlertFinishLater(false);
		if (historyGaps()) {
			setAlertGaps(true);
		} else if (deleteLocalData) {
			saveLocalStorageData(inputs, deleteLocalData);
		}
	};
	const btnActionSaveWork = [
		{
			text: 'OK',
			handler: closeAlertSaveAction,
			cssClass: 'submitBtnTask centerBtnText',
		},
	];
	async function getRelationMany() {
		const token = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		const data = await taskService.documentTaskRelationToMany(user.id!, token);
		setRelationMany(data.data);
	}
	useEffect(() => {
		if (prop?.history?.location?.state !== null) {
			const data = prop?.history?.location?.state as any;
			setProviderTask(data?.providerTask);
			setTask(data?.task);
		}
		getRelationMany();
	}, []);
	useEffect(() => {
		getInputObjectFields();
	}, [providerTask]);
	useEffect(() => {
		if (saveTaskLocal) {
			saveLocalStorageData(inputs, deleteLocalData);
		}
	}, [saveTaskLocal]);
	useEffect(() => {
		if (Object.keys(inputs).length > 0) {
			if (formCompleteAgain && !isEmptyForm()) {
				setAlertFormComplete(true);
			}
			setFormCompleteAgain(false);
		}
	}, [inputs]);
	//get dinamic object of key value fields
	function getInputObjectFields() {
		if (task?.documentFields) {
			const localStorageData = getLocalStorageData();
			let fields: any = {};
			if (Object.keys(localStorageData).length > 0) {
				fields = localStorageData;
			} else if (providerTask?.document) {
				fields = providerTask.document;
			} else {
				task?.documentFields.forEach((element) => {
					fields[element.key] =
						element.type === 'boolean' ? false : element.type === 'files' || element.type === 'workHistory' ? [] : '';
				});
				fields['historyTitle'] = '';
				fields['historyStartDate'] = '';
				fields['historyEndDate'] = '';
				fields['historyDescription'] = '';
				fields['key'] = task?.id;
			}
			setInputs(fields);
		}
	}
	//return object of task from local storage
	function getLocalStorageData(): {} {
		let dataTask = localStorage.getItem('tasks') || '';
		let localDataTask: any[] = [];
		if (dataTask !== '') {
			localDataTask = JSON.parse(dataTask);
			if (localDataTask && localDataTask.length > 0) {
				let index = localDataTask.findIndex((o) => o.key === task?.id);
				if (index >= 0) {
					return localDataTask[index];
				}
			}
		}
		return {};
	}
	//return input of type String
	function getStringInput(element: DocumentFieldEntity, title: string, name: string) {
		const inputVal = getInputValue(name ? name : element.key);
		const inputName = name ? name : element.key;
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{title ? title : element.name}</IonLabel>
				<IonInput
					className="ion-padding input-font-size-tasks"
					name={inputName}
					value={inputVal}
					type={inputName === 'password' ? inputName : 'text'}
					required={element.required}
					onIonChange={handleChange}></IonInput>
			</IonItem>
		);
	}
	//return the value for the inout
	function getInputValue(name: string): any {
		for (const [key, value] of Object.entries(inputs)) {
			if (key === name) {
				return value;
			}
		}
		return name === 'workHistory' ? [] : '';
	}
	//return input of type enumerator
	function getEnumeratorInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonSelect name={element.key} value={inputVal} onIonChange={handleChange}>
					{element.options.map((option, i) => (
						<IonSelectOption value={option.value} key={i}>
							{option.label}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
		);
	}
	//return input of type integer
	function getIntegerInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonInput
					className="ion-padding"
					name={element.key}
					type="number"
					value={inputVal}
					onIonChange={handleChange}></IonInput>
			</IonItem>
		);
	}
	//return input of type text area
	function getTextAreaInput(element: DocumentFieldEntity, title: string, name: string) {
		const inputVal = getInputValue(name ? name : element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{title ? title : element.name}</IonLabel>
				<IonTextarea name={name ? name : element.key} onIonChange={handleChange} value={inputVal}></IonTextarea>
			</IonItem>
		);
	}
	//return input of type decimal
	function getDecimalInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);

		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonInput
					className="ion-padding"
					name={element.key}
					required={element.required}
					type="number"
					inputMode="decimal"
					min="0"
					value={inputVal === '-1' ? '0' : inputVal}
					onIonChange={handleChange}></IonInput>
			</IonItem>
		);
	}
	//return input of type date
	function getDateInput(element: DocumentFieldEntity, title: string, customName: string, customClass: string) {
		let inputVal = getInputValue(customName ? customName : element.key);
		inputVal = inputVal ? moment(inputVal).format('MMM DD YYYY') : '';
		let inputProps = {
			name: customName ? customName : element.key,
		};
		return (
			<div className={`input-date-border-style ion-margin-top ion-margin-bottom ${customClass}`}>
				<IonLabel position="stacked">{title ? title : element.name}</IonLabel>
				<Datetime
					className="input-font-size-tasks test"
					timeFormat={false}
					inputProps={inputProps}
					renderInput={(props) => {
						return <input {...props} value={inputVal ? inputVal : ''} />;
					}}
					onChange={(e) => handleChangeDate(e, customName ? customName : element.key)}
				/>
				{customName === 'historyEndDate' && dateMessageError !== '' ? (
					<IonLabel className="error-date">{dateMessageError}</IonLabel>
				) : null}
			</div>
		);
	}
	//return input of type dateTime
	function getDateTimeInput(element: DocumentFieldEntity) {
		let inputVal = getInputValue(element.key);
		inputVal = inputVal ? moment(inputVal).format('DD-MM-YYYY HH:mm:ss a') : '';
		let inputProps = {
			name: element.key,
		};
		return (
			<div className="input-date-border-style ion-margin-top ion-margin-bottom">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<Datetime
					className="input-font-size-tasks test"
					inputProps={inputProps}
					onChange={(e) => handleChangeDate(e, element.key)}
					renderInput={(props) => {
						return <input {...props} value={inputVal ? inputVal : ''} />;
					}}
				/>
			</div>
		);
	}
	//return input of type boolean
	function getBooleanInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonToggle
					id="toggle"
					name={element.key}
					className="toggle ion-margin-bottom"
					value={inputVal}
					checked={inputVal}
					onIonChange={handleChange}></IonToggle>
			</IonItem>
		);
	}
	//return input of type enumeratorMultiple
	function getEnumeratorMultipleInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonSelect name={element.key} value={inputVal} multiple={true} onIonChange={handleChange}>
					{element.options.map((option, i) => (
						<IonSelectOption value={option.value} key={i}>
							{option.label}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
		);
	}
	//return section of type workHistory
	function getWorkHistorySection(element: DocumentFieldEntity) {
		const inputVal: WorkHistoryEntity[] = getInputValue(element.key);
		return (
			<div>
				<IonLabel position="stacked">{element.name}</IonLabel>
				<div className="input-radius-tasks ion-margin-top ion-margin-bottom">
					{getStringInput(element, 'Title', 'historyTitle')}
					{getTextAreaInput(element, 'Description', 'historyDescription')}
					<div className="flex-div ion-justify-content-between">
						{getDateInput(element, 'Start Date', 'historyStartDate', 'startDateStyle')}
						{getDateInput(element, 'End Date', 'historyEndDate', 'endDateStyle')}
					</div>
					<div className="flex-div ion-justify-content-center">
						<IonButton color="medium" onClick={() => addHistory()}>
							<IonIcon slot="start" icon={add} />
							Add
						</IonButton>
					</div>
				</div>
				{inputVal.length > 0 ? <IonRow style={{ width: '100%' }} className="line-payer"></IonRow> : null}
				{inputVal.map((element, index) => (
					<AccordionWorkHistory
						key={element.rowId}
						workHistory={element}
						updateWorkHistory={updateWorkHistory}
						id={index}></AccordionWorkHistory>
				))}
			</div>
		);
	}
	//add a new history
	function addHistory() {
		var min = 1;
		var max = 100;
		var rand = min + Math.random() * (max - min);
		const req: WorkHistoryEntity = {
			title: getInputValue('historyTitle'),
			description: getInputValue('historyDescription'),
			startDate: getInputValue('historyStartDate'),
			endDate: getInputValue('historyEndDate'),
			rowId: rand,
		};
		if (req.endDate && req.startDate && moment(req.endDate).isAfter(req.startDate)) {
			setDateMessageError('');
			let oldHistory: WorkHistoryEntity[] = getInputValue('workHistory');
			oldHistory.push(req);
			setInputs({
				...inputs,
				historyTitle: '',
				historyStartDate: '',
				historyEndDate: '',
				historyDescription: '',
				workHistory: [...oldHistory],
			});
		} else {
			setDateMessageError('End date must be after start date');
		}
	}
	//update a work history
	function updateWorkHistory(position: number, history: WorkHistoryEntity) {
		let data: WorkHistoryEntity[] = getInputValue('workHistory');
		data.splice(position, 1);
		data.push(history);
		setInputs({
			...inputs,
			workHistory: [...data],
		});
	}
	const handleChange = (e: any) => {
		const { name, value } = e?.target;
		const { checked } = e?.detail;
		const { _d } = e;
		setInputs((inputs) => ({ ...inputs, [name]: checked !== undefined ? checked : value }));
	};
	const handleChangeDate = (e: any, name: string) => {
		const { _d } = e;
		const datetoSave = _d;
		setInputs((inputs) => ({ ...inputs, [name]: datetoSave }));
	};
	//return input of type enumeratorState
	function getEnumeratorStateInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonSelect name={element.key} value={inputVal} onIonChange={handleChange}>
					{AddressStates.forSelect.map((option, i) => (
						<IonSelectOption value={option.value} key={i}>
							{option.label}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
		);
	}
	//return input of type enumeratorCountry
	function getEnumeratorCountryInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonSelect name={element.key} value={inputVal} onIonChange={handleChange}>
					{AddressCountries.forSelect.map((option, i) => (
						<IonSelectOption value={option.value} key={i}>
							{option.label}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
		);
	}
	//return input of type files
	function getFileInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<div className="flex-order-column input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<div className="ion-margin-top">
					<IonButton slot="end" className="input-radius-tasks" fill="clear">
						<IonIcon icon={add} slot="start"></IonIcon>
						<input id="file" hidden type="file" onChange={(e) => onUploadFile(e.target.files, element.key)} />
						<label htmlFor="file">Upload</label>
					</IonButton>
				</div>
				{getAttachmentView(element.key)}
			</div>
		);
	}
	//return attachment view
	function getAttachmentView(name: string) {
		const fileNames = fileNameAttachment(name);
		const fields = fileNames.map((field, i) => (
			<div key={i}>
				<div className="flex-div ion-justify-content-between">
					<div className="startDateStyle">
						<IonText className="margin-name-history">{field}</IonText>
						<IonIcon slot="icon-only" icon={arrowBack} onClick={() => removeAttachmentField(field, name)} />
					</div>
				</div>
				<IonRow style={{ width: '100%' }} className="line-payer"></IonRow>
			</div>
		));
		return <div className="ion-margin-top ion-margin-bottom">{fields}</div>;
	}
	//remove a file from the attachments
	function removeAttachmentField(data: string, name: string) {
		let inputVal: any = getInputValue(name);
		let position = -1;
		for (let index = 0; index < inputVal.length; index++) {
			if (inputVal[index].name === data) {
				position = index;
				break;
			}
		}
		inputVal.splice(position, 1);
		setInputs({
			...inputs,
			name: [...inputVal],
		});
	}
	//return input of type files
	function getFileInputImg(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return (
			<div className="flex-order-column input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<div className="ion-margin-top">
					<IonButton slot="end" className="input-radius-tasks" fill="clear">
						<IonIcon icon={add} slot="start"></IonIcon>
						<input
							id="fileImg"
							hidden
							type="file"
							accept="image/*"
							onChange={(e) => onUploadFile(e.target.files, element.key)}
						/>
						<label htmlFor="fileImg">Upload</label>
					</IonButton>
				</div>
				{getAttachmentView(element.key)}
			</div>
		);
	}
	//handle the file that is selected on the device
	function onUploadFile(files: FileList | null, name: string): void {
		if (fileAlreadyExist(files, name)) {
			setShowToastAttachments(true);
		} else {
			sendFileApi(files!, name);
		}
	}
	//send a file to google service cloud
	function sendFileApi(file: FileList, name: string) {
		const tenantId = wallet.tenants[0].tenant.id;
		let uuid = uuidv4();
		let fileName = `${uuid}` + file[0].name;
		filesService.credentials(tenantId!, fileName, 'documentAttachments').then(
			(res) => {
				let formData = new FormData();
				formData.append('key', res.data.uploadCredentials?.fields?.key);
				formData.append('policy', res.data.uploadCredentials?.fields?.policy);
				formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
				formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
				formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
				formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
				formData.append('file', file[0], fileName);
				filesService.upload(res.data.uploadCredentials?.url, formData).then(
					(response) => {
						let inputVal = getInputValue(name);
						inputVal = inputVal === '' ? [] : inputVal;
						inputVal.push({
							downloadUrl: res.data.downloadUrl,
							name: file[0].name,
							privateUrl: res.data.privateUrl,
							new: true,
						});
						setInputs((inputs) => ({ ...inputs, [name]: inputVal }));
					},
					(error) => {
						console.log(error);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				);
			},
			(error) => {
				setToastMessageError('Something went wrong,please try again.');
				setShowToastAttachments(true);
			}
		);
	}
	//validates if the file is already added
	function fileAlreadyExist(files: FileList | null, name: string) {
		const inputVal: any = getInputValue(name);
		for (let index = 0; index < inputVal.length; index++) {
			if (inputVal[index].name === files?.[0].name) {
				return true;
			}
		}
		return false;
	}
	//return a list of attachments names
	function fileNameAttachment(name: string): string[] {
		const inputVal: any = getInputValue(name);
		let filesNames: string[] = [];
		for (let index = 0; index < inputVal.length; index++) {
			filesNames.push(inputVal[index].name);
		}
		return filesNames;
	}
	//return input of type relationMany
	function getRelationManyInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return selectRelationMany && selectRelationMany.length > 0 ? (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonSelect multiple={true} name={element.key} value={inputVal} onIonChange={handleChange}>
					{selectRelationMany.map((option, i) => (
						<IonSelectOption value={option.id} key={option.id}>
							{option.label}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
		) : null;
	}
	//return input of type relationOne
	function getRelationOneInput(element: DocumentFieldEntity) {
		const inputVal = getInputValue(element.key);
		return selectRelationMany && selectRelationMany.length > 0 ? (
			<IonItem className="input-inside ion-margin-top ion-margin-bottom input-font-size-tasks">
				<IonLabel position="stacked">{element.name}</IonLabel>
				<IonSelect name={element.key} value={inputVal} onIonChange={handleChange}>
					{selectRelationMany.map((option, i) => (
						<IonSelectOption value={option.id} key={i}>
							{option.label}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
		) : null;
	}
	//return view fields
	function getInput(element: DocumentFieldEntity) {
		switch (element.type) {
			case 'string':
				return getStringInput(element, '', '');
			case 'enumerator':
				return getEnumeratorInput(element);
			case 'integer':
				return getIntegerInput(element);
			case 'textArea':
				return getTextAreaInput(element, '', '');
			case 'decimal':
				return getDecimalInput(element);
			case 'date':
				return getDateInput(element, '', '', '');
			case 'datetime':
				return getDateTimeInput(element);
			case 'boolean':
				return getBooleanInput(element);
			case 'enumeratorMultiple':
				return getEnumeratorMultipleInput(element);
			case 'workHistory':
				return getWorkHistorySection(element);
			case 'enumeratorState':
				return getEnumeratorStateInput(element);
			case 'enumeratorCountry':
				return getEnumeratorCountryInput(element);
			case 'files':
				return getFileInput(element);
			case 'images':
				return getFileInputImg(element);
			case 'relationMany':
				return getRelationManyInput(element);
			case 'relationOne':
				return getRelationOneInput(element);
			default:
				return null;
		}
	}
	//verify if the form is complete
	function isEmptyForm(): boolean {
		let exists = Object.keys(inputs).length === 0 ? true : false;
		for (const [key, value] of Object.entries(inputs)) {
			if (
				key !== 'historyDescription' &&
				key !== 'historyEndDate' &&
				key !== 'historyStartDate' &&
				key !== 'historyTitle'
			) {
				if (value === '' || value === undefined) {
					exists = true;
				}
				if (Array.isArray(value) && value.length === 0) {
					exists = true;
				}
			}
		}
		return exists;
	}
	//validate if there are work history ant gaps
	function historyGaps() {
		const inputVal: WorkHistoryEntity[] = getInputValue('workHistory');
		let gaps = false;
		const newarr = inputVal.slice().sort((a, b) => {
			return moment(a.endDate).diff(b.endDate);
		});
		for (let index = 0; index < newarr.length; index++) {
			if (index === newarr.length - 1) {
				break;
			} else {
				if (moment(newarr[index].startDate).isSame(newarr[index + 1].startDate)) {
					if (moment(newarr[index + 1].startDate).diff(newarr[index].endDate, 'months') > 6) {
						gaps = true;
					}
				} else if (moment(newarr[index].endDate).isAfter(newarr[index + 1].startDate)) {
					if (moment(newarr[index + 1].startDate).diff(newarr[index].endDate, 'months') > 6) {
						gaps = true;
					}
				} else if (moment(newarr[index + 1].startDate).diff(newarr[index].endDate, 'months') > 6) {
					gaps = true;
				}
			}
		}
		return gaps;
	}
	//set alert value
	function setAlertValue(show: boolean) {
		setAlertFormComplete(show);
	}
	function saveLocalStorageData(task: any, deleteData: boolean) {
		const dataTask = localStorage.getItem('tasks') || '';
		let localDataTask: any[] = dataTask !== '' ? JSON.parse(dataTask) : [];
		if (localDataTask && localDataTask.length > 0) {
			const index = localDataTask.findIndex((o) => o.key === task.key);
			if (index >= 0) {
				localDataTask.splice(index, 1);
			}
		}
		if (!deleteData) {
			localDataTask.push(inputs);
		}
		localStorage.setItem('tasks', JSON.stringify(localDataTask));
		if (deleteData) {
			prop?.history.replace({
				pathname: '/tabs/actions',
				state: { providerTask: providerTask },
			});
		} else {
			prop?.history.replace('/tabs/actions');
		}
		setShowLoading(false);
	}

	return (
		<IonPage
			className="top-page 
		ion-padding-bottom ion-padding-start ion-padding-end">
			<IonContent className="max-550">
				<IonIcon
					className="backArrowProfile"
					icon={arrowBack}
					onClick={() => saveLocalStorageData(inputs, false)}></IonIcon>
				<div className="container-title-task">
					<IonText className="ion-text-center margin-caqh"> {providerTask?.title}</IonText>
				</div>

				{providerTask?.instructions ? (
					<div className="ion-margin-top ion-margin-bottom text-instructions">
						<ReactMarkdown children={providerTask?.instructions} />
					</div>
				) : null}
				{task?.documentFields?.map((element, index) =>
					element.key === 'ambassadorVerified' ? null : (
						<div key={element.id} className="margin-caqh">
							{getInput(element)}
						</div>
					)
				)}
				<div className="auto-center ion-padding item-width">
					<IonButton shape="round" onClick={submit}>
						{providerTask?.status === 'done' ? 'Update' : 'Save'}
					</IonButton>
				</div>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
				<Alert
					showAlert={showAlertFormComplete}
					setAlert={setAlertValue}
					headerText="Ready?"
					message={
						providerTask?.status === 'done' ? 'Do you want to update this task?' : 'Are you finished with this task?'
					}
					buttons={btnSaveAlert}
					inputs={[]}
					cssClass="custom-class-task"
				/>
				<Alert
					showAlert={showAlertFinishLater}
					setAlert={setAlertFinishLater}
					headerText="No problem!"
					message="We saved your work"
					buttons={btnActionSaveWork}
					inputs={[]}
					cssClass="custom-class-task"
				/>
				<Alert
					showAlert={showAlertSendIt}
					setAlert={setAlertSendIt}
					headerText="Thanks!"
					message="Our team will handle it from here."
					buttons={btnActionSaveWork}
					inputs={[]}
					cssClass="custom-class-task"
				/>
				<Alert
					showAlert={showAlertGaps}
					setAlert={setAlertGaps}
					headerText="Work History"
					message="Please explain all gaps more than 6 months."
					buttons={btnGaps}
					inputs={[]}
					cssClass="my-custom-class"
				/>
				<IonLoading
					cssClass="spinner"
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					spinner="crescent"
					translucent={true}
				/>
			</IonContent>
		</IonPage>
	);
};

export default CAQH;
