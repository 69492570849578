import { IonIcon, IonLabel, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import interrogation from '../../../img/icons/icon-eye.svg';
import { PasswordEntity } from '../../../shared/entity/passwordEntity';
import './PasswordsCAQH.css';

import AccordionUpdatePassword from '../../../components/AccordionUpdatePaswords/AccordionUpdatePassword';
const PasswordsCAQH: React.FC<PasswordEntity> = (props) => {
	const { title, description, userName, userPassword, edit, id, passEdited, permissionToLogin } = props;
	const [collapse, setCollapse] = useState(false);
	const [updatePassword, setUpdatePassword] = useState(false);
	useEffect(() => {
		let isApiSubscribed = true;
		const verifyComponent = async () => {
			if (id === passEdited && isApiSubscribed) {
				setCollapse(true);
			}
		};
		verifyComponent().catch(console.error);
		return () => {
			isApiSubscribed = false;
		};
	}, []);
	function collapsedCard() {
		if (!collapse) {
			setCollapse(!collapse);
			setUpdatePassword(false);
		}
	}
	function editPassword(id: any, user: any) {
		if (edit) {
			edit(id, user);
			setCollapse(!collapse);
		}
	}
	function closeCard() {
		setCollapse(!collapse);
	}
	return (
		<IonRow className={`content-pass-card ${collapse ? '' : 'card-height-sam'}`} onClick={() => collapsedCard()}>
			<IonRow id="row" className={`${collapse ? 'content-pass-cards-collapse' : 'content-pass-cards'}`}>
				<div
					onClick={() => {
						if (updatePassword) {
							setCollapse(true);
						} else {
							setCollapse(false);
						}
						setUpdatePassword(false);
					}}
					className={` ${collapse ? 'width-half' : 'width-complete'}`}>
					<IonLabel className="label-pass-title">{title}</IonLabel>
				</div>
				<div
					className="close-width"
					onClick={() => {
						if (updatePassword) {
							setCollapse(true);
						} else {
							setCollapse(false);
						}
						setUpdatePassword(false);
					}}>
					{!collapse && <IonIcon id="row" src={interrogation}></IonIcon>}
				</div>
			</IonRow>
			{collapse ? (
				<AccordionUpdatePassword
					title={title}
					description={description}
					userName={userName}
					userPassword={userPassword}
					id={id}
					edit={editPassword}
					close={closeCard}
					permissionToLogin={permissionToLogin}
				/>
			) : null}
		</IonRow>
	);
};

export default PasswordsCAQH;
