import { Dispatch } from 'redux';
import { NppesEntity } from '../../shared/entity/nppesEntity';
import { NppesConstants } from '../constants/nppesConstants';

export const NppesActions = {
	setLicense,
};

function setLicense(data: any) {
	return async (dispatch: Dispatch) => {
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			const license = data.tenants[0].provider.professionalLicenses.find((element: any) => element.type?.key === 'npi');
			if (license) {
				const nppes: NppesEntity = {
					name: data.tenants[0].provider?.fullName,
					role: data.tenants[0].provider.type?.name,
					npiNumber: license.number,
					updatedAt: license.lastUpdated,
					issued: license.issueDate,
					description: license.type.description,
				};
				dispatch(set(nppes));
			}
		}
	};

	function set(payload: any) {
		return { type: NppesConstants.SET_NPPES, payload };
	}
}
