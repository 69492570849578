import { IonButton, IonImg, IonRow, IonText } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import ReadyWalletImg from '../../img/Wallet_ready.png';
import './buildingWalletReady.css';
const BuildingWalletReady: React.FC<RouteComponentProps> = ({ history }) => {
	function handleSubmit() {
		localStorage.removeItem('provider-stage');
		localStorage.removeItem('WalletReadyPopUp');
		localStorage.removeItem('NuCoUserStage');
		localStorage.removeItem('nucoProvider');
		history.push('/');
	}
	return (
		<IonRow className="building-wallet-ready ion-padding max-550">
			<IonRow className="building-wallet-ready-main-text">
				<IonRow className="bw-column">
					<IonText className="bw-text-title padding-top-20px">Your wallet is ready!</IonText>
					<IonText className="bw-text padding-top-20px ">
						We have validated your identity and collected all publicly available credentialing information
					</IonText>
				</IonRow>
				<IonRow className="bw-img-row padding-top-20px">
					<IonImg src={ReadyWalletImg}></IonImg>
				</IonRow>
				<IonRow className="auto-center ion-padding add-padding">
					<IonButton
						color="secondary"
						shape="round"
						type="submit"
						className="sign-in-width"
						onClick={() => {
							handleSubmit();
						}}>
						Open Wallet
					</IonButton>
				</IonRow>
			</IonRow>
		</IonRow>
	);
};

export default BuildingWalletReady;
