import { ActionEntity } from "../../shared/entity/actionEntity";
import { WalletConstants } from "../constants/walletConstants";

const initialUser: any = {};
const walletReducer = (state: any = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case WalletConstants.AUTH_ME:
                return {
                    ...state,
                    data: action.payload.data
                }; 
        case WalletConstants.AUTH_FAILURE:
                return {};            
        default:
            return state;
    }

}
export default walletReducer;