import { Dispatch } from 'redux';
import { BoardActionsEntity } from '../../shared/entity/boardActionsEntity';
import { BoardActionsConstants } from '../constants/boardActionsConstants';

export const BoardActionsActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: BoardActionsEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'exclusion' && element.exclusionType === 'boardAction') {
					const exclusion: BoardActionsEntity = {
						name: data.tenants[0].provider.contactInfo.fullName,
						npiNumber: element.npi,
						reportingEntity: element.reportingEntity,
						description: element.description,
						orderDate: element.orderDate,
						exclusionDate: element.exclusionDate,
						actionTaken: element.actionTaken,
						cardDetail: element.type.description,
						monitoring: element.isGrantAccess,
						lastChecked: element.updatedAt,
					};
					exclusions.push(exclusion);
				}
			});

			if (exclusions) {
				dispatch(set({ boardActions: exclusions, info: document?.info || '' }));
			}
		}
	};

	function set(payload: any) {
		return { type: BoardActionsConstants.SET_BOARD_ACTIONS, payload };
	}
}
