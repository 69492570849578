export const LoginConstants = {
	REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

	LOGIN_FAILURE_NON_PROVIDER:
		'Your account has not been confirmed yet.  Please reach out to support@nucocred.com if you feel this is an error.',
	LOGOUT: 'USERS_LOGOUT',
	LOGIN_USER_WRONG_DATA: 'The User ID or Password you have entered does not match our records.',
	AUTH_ME: 'AUTH_ME',
};
