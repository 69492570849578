import {
	IonButton,
	IonCheckbox,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import arrowBack from '../../img/icons/backArrow.svg';
import { userProfileActions } from '../../redux/actions/userActions';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import './DeleteAccount.css';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { supportService } from '../../services/support.service';
const DeleteAccount: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const wallet = useSelector<any, any>((state) => state.wallet.data!);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [userData, setUserData] = useState(user);
	const [formErrors, setFormErrors] = useState<any>();
	const { executeRecaptcha } = useGoogleReCaptcha();

	useEffect(() => {
		if (wallet) {
			dispatch(userProfileActions.setUser(wallet));
		}
	}, [wallet]);
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (!userData.firstName) {
			setFormErrors({ firstName: 'First Name is required' });
			return;
		}
		if (!userData.userName) {
			setFormErrors({ email: 'Email is required' });
			return;
		}
		if (!userData.phone1) {
			setFormErrors({ phone: 'Phone is required' });
			return;
		}
		setShowLoading(true);
		setFormErrors(undefined);
		if (!executeRecaptcha) {
			return;
		}
		const recaptchaToken = await executeRecaptcha('submit');
		supportService
			.deleteAccount(userData, wallet?.tenants[0].provider?.npi, wallet?.tenants[0].tenant?.id, recaptchaToken)
			.then(
				async (res) => {
					if (res?.data) {
						showToast({
							color: 'success',
							header: 'Success',
							message: 'Data submitted correctly',
						});
						setShowLoading(false);
						navigate('/settings');
					} else {
						showToast({
							color: 'danger',
							header: 'Failure',
							message: 'Oops, something went wrong, try again',
						});
					}
					setShowLoading(false);
				},
				(error) => {
					setToast({
						color: 'danger',
						header: 'Failure',
						message: error.response.data,
					});
					console.log(error);
					showToast(toast);
					setShowLoading(false);
				}
			);
	};
	const navigate = (route: string) => {
		props?.history.push('/tabs' + route);
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		let valueData = '';
		switch (name) {
			case 'firstName':
				valueData = value;
				break;
			case 'middleName':
				valueData = value;
				break;
			case 'lastName':
				valueData = value;
				break;
			case 'userName':
				valueData = value;
				break;
			case 'phone1':
				valueData = value;
				break;
			case 'terms':
				valueData = e.detail.checked;
				break;
			default:
				break;
		}
		setUserData((prevUserData) => ({
			...prevUserData,
			[name]: valueData,
		}));
	};
	console.log(wallet);
	return (
		<IonPage
			className="top-page 
      ion-padding-bottom ion-padding-start ion-padding-end">
			<IonContent className="ion-padding profile-page max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>
						Account Deletion Request
					</IonTitle>
				</IonToolbar>
				<form name="form" onSubmit={handleSubmit}>
					<div className="ion-margin">
						<IonText className="ion-text-center description-delete ion-margin">
							We strive on ensuring you are receiving continued value through our ongoing monitoring process. If you
							experienced an issue or problems with our App, please feel free to email us at support@nucocred.com.
							Should you still want to continue with deleting your profile, please click the “Accept” button below to
							proceed.
						</IonText>
					</div>
					<div className="policy-delete-data">
						<IonText className="ion-text-center policy-desc-delete">Our Data Privacy Policy</IonText>
						<IonText className="ion-text-center description-delete">
							NuCo Credentialing does not sell any of your data, quite the contrary. We use your data to to ensure your
							provider profile is accurate across the fragmented and decentralized healthcare ecosystem.
						</IonText>
					</div>
					<IonText className="ion-text-center description-delete">Information</IonText>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">First Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="firstName"
							type="text"
							value={userData.firstName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					{formErrors && formErrors.firstName && <span className="">{formErrors.firstName}</span>}
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Middle Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="middleName"
							type="text"
							value={userData.middleName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Last Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="lastName"
							type="text"
							value={userData.lastName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Email</IonLabel>
						<IonInput
							className="ion-padding"
							name="userName"
							type="email"
							value={userData.userName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					{formErrors && formErrors.email && <span className="">{formErrors.email}</span>}
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Phone Number</IonLabel>
						<IonInput
							className="ion-padding"
							name="phone1"
							type="tel"
							value={userData.phone1}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					{formErrors && formErrors.phone && <span className="">{formErrors.phone}</span>}
					<IonRow className="auto-center ion-padding">
						<IonItem className="no-bg-item-reminder ">
							<IonCheckbox slot="start" onIonChange={handleChange} name="terms"></IonCheckbox>
							<IonLabel className="hide-reminder-label">
								I understand that by opting out that I can not continue to use the app.{' '}
							</IonLabel>
						</IonItem>
					</IonRow>
					<IonRow className="saveRowProfile">
						{' '}
						<IonButton color="secondary" shape="round" type="submit" disabled={!userData.terms}>
							{' '}
							Delete my Account{' '}
						</IonButton>
					</IonRow>
				</form>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</IonPage>
	);
};

export default DeleteAccount;
