import { IonIcon, IonImg, IonMenuToggle, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router';
import ActivateProfileDescImage from '../../../img/activate-profile-desc.svg';
import closeLicenseModal from '../../../img/icon-close-modal.svg';
import interrogation from '../../../img/icons/black-interrogation.svg';
import defaultLicenseImage from '../../../img/license_back.png';
import ProfileSamble from '../../../img/profile-sample.svg';
import { MedicalLicenseActions } from '../../../redux/actions/medicalLicenseActions';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import AddressStates from '../../../shared/addressStates';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { MedicalLicenseEntity } from '../../../shared/entity/medicalLicenseEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import StatusTextComponent from '../../StatusTextComponent/StatusTextComponent';
import './MedicalLicense.css';
const MedicalLicense: React.FC<CardInfoEntity> = ({ title, zoom }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__medical-license background');
	const medicalLicense = useSelector<any, MedicalLicenseEntity>((state) => state.medicalLicense.data!);
	const [documentDescription, setDocumentDescription] = useState(medicalLicense?.info);
	const [showDriversLicenseModal, setShowDriversLicenseModal] = useState(false);
	const [showBackImg, setShowBackImg] = useState(false);
	const [licenseImage, setLicenseImage] = useState('');
	useEffect(() => {
		if (data) {
			dispatch(MedicalLicenseActions.setLicense(data, medicalLicense));
			dispatch(WalletActivationActions.setWalletActivation(data));
		}
	}, [data]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__medical-license background');
		} else {
			setBack('card card__medical-license background mlNoRotate');
		}
	};
	useEffect(() => {
		if (medicalLicense?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'medical-license').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(MedicalLicenseActions.setLicense(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING MEDICAL LICENSE DESCRIPTION:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [medicalLicense]);
	useEffect(() => {
		if (medicalLicense?.info !== '') {
			setDocumentDescription(medicalLicense?.info);
		}
	}, [medicalLicense, documentDescription]);
	const AllClearContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<NoInfoCard textColor="text-color-black" blackIcon={true} cardType="mdlc" />{' '}
			</IonRow>
		);
	};
	function showLicense(image: string) {
		setLicenseImage(image);
		setShowDriversLicenseModal(true);
	}
	const PaymentDescriptionContent = () => {
		return (
			<IonRow className="center-no-info-card wrapper-zoom-abms">
				<div className="ion-padding">
					{isPlatform('mobile') ? (
						<IonImg
							src={ActivateProfileDescImage}
							className="ion-margin"
							onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
					) : (
						<IonMenuToggle>
							<IonImg
								src={ActivateProfileDescImage}
								className="ion-margin"
								onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
						</IonMenuToggle>
					)}
				</div>
			</IonRow>
		);
	};
	return (
		<div className={back}>
			{!flip ? (
				<IonRow className={!zoom ? 'card__face ' : 'card__face_zoom'}>
					<IonRow>
						{!flip ? (
							<IonRow className="article-header">
								<IonRow className="category-title">{title}</IonRow>
								{walletActivation?.payCompleted && (
									<IonIcon
										id="about"
										className="icon-about-abms-actions"
										icon={interrogation}
										onClick={() => onFlip()}></IonIcon>
								)}
							</IonRow>
						) : null}
						{!walletActivation?.payCompleted && !walletActivation?.approved && !flip ? (
							<PaymentDescriptionContent />
						) : medicalLicense && !flip ? (
							<IonRow className="content-card margin-top-med-lic-content">
								<IonRow className="card__medical-license-header ion-align-items-center ion-nowrap">
									<IonImg
										className="avatar"
										src={medicalLicense.pictureUrl ? medicalLicense.pictureUrl : ProfileSamble}
									/>
									<IonRow className="div">
										<IonText className="title-name-license">{medicalLicense.name}</IonText>
										<IonText className="subtitle-name-license">{medicalLicense.role}</IonText>
									</IonRow>
								</IonRow>
								<IonRow
									className={
										!zoom
											? 'card__medical-license-footer noZoom ion-padding-top width-med-lic'
											: 'card__medical-license-footer zoom ion-padding-top width-med-lic'
									}>
									{medicalLicense.issuedBy?.map((element, index) => (
										<IonRow className="container-med-lic-data" key={index}>
											<IonRow className="container-row-med-lic">
												<IonText className="title-license-location">{AddressStates.label(element.state)}</IonText>
												{element.status ? <StatusTextComponent label={element.status!} /> : null}
											</IonRow>
											<IonRow>
												<IonText className="title-license-number" id="row">
													Number: {element.number}
												</IonText>
												{element.number ? <CopyClipboard text={element.number} blackIcon={true} /> : null}
											</IonRow>
											<IonText className="title-license-expiration">
												Expiration:
												<IonText className="datetime">
													{element.expiration ? moment(element.expiration).format('MMM DD YYYY') : 'Not Available'}
												</IonText>
											</IonText>
										</IonRow>
									))}
								</IonRow>
							</IonRow>
						) : (
							<AllClearContent />
						)}
					</IonRow>
					<Modal
						showCloseIcon={false}
						open={showDriversLicenseModal}
						onClose={() => setShowDriversLicenseModal(false)}
						modalId="modal"
						center>
						<IonImg className="modal-img-license" src={licenseImage ? licenseImage : defaultLicenseImage} />
						<IonRow className="ion-no-border modal-cred-footer ion-margin-top">
							<img
								onClick={() => setShowDriversLicenseModal(!showDriversLicenseModal)}
								className="auto-center"
								src={closeLicenseModal}
								style={{ width: 60 }}
							/>
						</IonRow>
					</Modal>
				</IonRow>
			) : null}
			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground="med-license-background"
					cssTextColor="about-black"
				/>
			) : null}
		</div>
	);
};

export default MedicalLicense;
