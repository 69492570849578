import { ActionEntity } from '../../shared/entity/actionEntity';
import { PayerCardEntity } from '../../shared/entity/payerCardEntity';
import { PayerActionsConstants } from '../constants/payerActionsConstants';

const initialUser: PayerCardEntity = {
	payers: [],
	locations: [],
	info: '',
};
const payerActionsReducer = (state: PayerCardEntity = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case PayerActionsConstants.SET_PAYER_ACTIONS:
			return action.payload;
		default:
			return state;
	}
};
export default payerActionsReducer;
