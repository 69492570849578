import API from '../shared/environement/api-routes';
import Interceptor from '../shared/environement/interceptor';
export const paymentService = {
	paymentIntent,
	confirmPayment,
	getPayment,
	requestPayorEnrollment,
};

async function paymentIntent(
	amount: string,
	name: string,
	email: string,
	provider: string,
	address: any,
	tenant: string
) {
	try {
		return await Interceptor(API.APP_PAYMENT + `/create-payment-intent`, {
			method: 'POST',
			data: {
				paymentMethodType: 'card',
				currency: 'usd',
				amount: amount,
				name: name,
				email: email,
				provider: provider,
				address: address,
				tenant: tenant,
			},
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function confirmPayment(id: string, approved: boolean, paymentIntent: any) {
	try {
		const data = {
			id,
			approved,
			paymentIntent,
		};
		return await Interceptor(API.APP_PAYMENT + `/update`, {
			method: 'POST',
			data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function getPayment(tenant: string, provider: string) {
	try {
		return await Interceptor.get(API.APP_PAYMENT + `/tenant/${tenant}/provider/${provider}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function requestPayorEnrollment(
	tenantID: string,
	providerID: string,
	providerEmail: string,
	providerFullName: string,
	providerNPI: string,
	providerType: string
) {
	try {
		const data = {
			tenantID,
			providerID,
			providerEmail,
			providerFullName,
			providerNPI,
			providerType,
		};
		return await Interceptor(API.APP_PAYMENT + `/requestPayorEnrollment`, {
			method: 'POST',
			data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
