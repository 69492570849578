import { Dispatch } from 'redux';
import { LocationEntity } from '../../shared/entity/locationsEntity';
import { PayerCardEntity } from '../../shared/entity/payerCardEntity';
import { PayerEntity } from '../../shared/entity/payerEntity';
import { PayerActionsConstants } from '../constants/payerActionsConstants';

export const PayerActionsActions = {
	setPayers,
};

function setPayers(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let payersCard: PayerCardEntity = {
			payers: [],
			locations: [],
			info: document?.info || '',
		};
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			let payers: PayerEntity[] = [];
			let locations: LocationEntity[] = [];
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'Payer-Enrollment-Status' && element.payer !== null) {
					const exclusion: PayerEntity = {
						logo: element.payer && element.payer.logo.length > 0 ? element.payer.logo[0].downloadUrl : null,
						statusValue:
							element.status === 'pending'
								? 'Enrollment in Progress'
								: 'active'
								? 'Participating'
								: 'Not Participating',
						status: element.status,
						providerId: element.number,
						effectiveDate: element.parDate,
						expirationDate: element.estimatedCompletion,
						estimatedDaysToEnrollment: element.estimatedDaysToCompletion ? element.estimatedDaysToCompletion : 0,
						state: element.payer ? element.payer.state : null,
						name: element.payer ? element.payer.name : '',
					};
					const findLocation = locations.find((param) => {
						return param.name === element.payer.state ? element.payer.state : '';
					});
					if (!findLocation) {
						locations.push({
							name: element.payer ? element.payer.state : '',
						});
					}
					payers.push(exclusion);
				}
			});
			payersCard.locations = locations;
			payersCard.payers = payers;
			if (payersCard?.payers) {
				dispatch(set(payersCard));
			}
		}
	};

	function set(payload: any) {
		return { type: PayerActionsConstants.SET_PAYER_ACTIONS, payload };
	}
}
