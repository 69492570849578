import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { useRef, useState } from 'react';
import IconArrowSend from '../../../img/icon-arrow-send.svg';
import { InputSenderModule as Module } from '../../../shared/entity/inputSender.module';
import './inputSender.css';

const InputSender: React.FC<Module.Message> = (props: Module.Message) => {
	const message: Module.Message = props;
	const [text, setText] = useState('');
	const [document, setDocument] = useState(null);
	const inputRef = useRef(null);

	const onSubmit = () => {
		if ((!text && !document) || !message || !message.onSubmit) {
			return;
		}
		const newMessage: Module.Message = {
			text,
			document,
		};
		message.onSubmit(newMessage);
		clearInput();
	};

	const clearInput = () => {
		setText('');
		setDocument(null);
		if (inputRef && inputRef.current) {
			(inputRef.current as any).value = '';
		}
	};

	return (
		<IonRow class="InputSender">
			<IonCol className="documentContainer" size="1"></IonCol>
			<IonCol size="9">
				<input
					type="text"
					ref={inputRef}
					className="input"
					id="chatInputSender"
					onChange={(e: any) => {
						setText(e?.target?.value!);
					}}
					placeholder="Tap to type"
				/>
			</IonCol>
			<IonCol className="buttonSenderIconContainer" size="2">
				<IonIcon
					className="buttonSenderIcon"
					onClick={() => {
						onSubmit();
					}}
					icon={IconArrowSend}></IonIcon>
			</IonCol>
		</IonRow>
	);
};

export default InputSender;
