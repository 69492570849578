import { IonCol, IonImg, IonRow, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import ChevronDownWhite from '../../img/icon-chevron-down-white.svg';
import ChevronUpWhite from '../../img/icon-chevron-up-white.svg';
import { AccordionMedicaidActionsEntity } from '../../shared/entity/accordionMedicaid.module';
import StatusTextComponent from '../StatusTextComponent/StatusTextComponent';
import './AccordionMedicareActions.css';
const AccordionMedicareActions: React.FC<AccordionMedicaidActionsEntity> = ({
	title,
	active,
	children,
	classCss,
	quantity,
	collapse,
	status,
}) => {
	const [arrowClick, setArrowClick] = useState(false);
	const [isOpen, setOpen] = useState(false);
	useEffect(() => {
		if (quantity === 1) {
			setOpen(true);
		} else if (!arrowClick) {
			setOpen(collapse!);
		}
	});
	function openCard() {
		setArrowClick(true);
		setOpen(!isOpen);
	}
	return (
		<IonRow className="accordion-wrapper full-row-cols">
			<IonRow className={`uppercase-title accordion-title ${isOpen ? 'open' : ''}`}>
				<IonRow className={classCss}>
					<IonCol size="6" id="row" onClick={() => openCard()}>
						<IonText className="row-text-title-medicare-accordion" id="row" onClick={() => openCard()}>
							{title}
						</IonText>
					</IonCol>
					<IonCol size="6" className="center-text-col-med" id="row" onClick={() => openCard()}>
						<IonCol size="10">
							<IonRow className="row-expand-card" id="row" onClick={() => openCard()}>
								<StatusTextComponent label={status!} />
							</IonRow>
						</IonCol>
						<IonCol size="2">
							<IonRow className="row-expand-card">
								{isOpen ? (
									<IonImg id="row" className="row-icon" onClick={() => openCard()} src={ChevronDownWhite} />
								) : (
									<IonImg id="row" className="row-icon" src={ChevronUpWhite} onClick={() => openCard()} />
								)}
							</IonRow>
						</IonCol>
					</IonCol>
					{isOpen ? (
						<IonRow className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
							<IonRow className="">{children}</IonRow>
						</IonRow>
					) : null}
				</IonRow>
			</IonRow>
		</IonRow>
	);
};

export default AccordionMedicareActions;
