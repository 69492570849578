export namespace ConversationModule {
	export interface Conversation {
		id: string;
		avatar: string;
		name: string;
		description: string;
		time: string;
		date: string;
		notification?: number;
		isChecked: boolean;
		type: CONVERSATION_TYPE;
		ambassador: string;
		tenant: string;
	}

	export interface ConversationList {
		conversations: Array<Conversation>;
		onConversationClick: Function;
		onConversationChecked: Function;
		isEdit: boolean;
	}

	export interface ConversationAmbassador {
		avatars: Array<string>;
		id: string;
	}

	export enum CONVERSATION_TYPE {
		SUPPORT = 'support',
		AMBASSADOR = 'ambassador',
	}

	export enum CONVERSATION_STATUS {
		ACTIVE = 'ACTIVE',
	}

	export interface ConversationCreateRequest {
		name: string;
		users: Array<string>;
		type: CONVERSATION_TYPE;
	}

	export interface ConversationApiResponse {
		id: string;
		name: string;
		status: CONVERSATION_STATUS;
		ambassador?: string;
		tenant?: string;
		type: CONVERSATION_TYPE;
		unreadMessages?: number;
		updatedAt: Date;
	}

	export interface ConversationGetResponse {
		ambassador: ConversationAmbassador;
		id: string;
		name: string;
		status: CONVERSATION_STATUS;
		tenant?: string;
		type: CONVERSATION_TYPE;
		unreadMessages?: number;
		updatedAt: Date;
	}
}
