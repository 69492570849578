import {
	IonButton,
	IonContent,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import arrowBack from '../../img/icons/backArrow.svg';

import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { filesService } from '../../services/files.service';
import { onBoardingService } from '../../services/onBoarding.service';
import { PassportEntity } from '../../shared/entity/passportEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './passportFile.css';

import ImagesLicense from '../../components/Cards/FrontBackImages/ImagesLicense';
import { walletActions } from '../../redux/actions';
const PassportFile: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const passport = useSelector<ReduxStateEntity, PassportEntity>((state) => state.passport!);
	const [front, setFront] = useState<any>();
	const [back, setBack] = useState<any>();
	const [newLicense, setNewLicense] = useState(true);
	const { photos, takePhoto } = usePhotoGallery();
	const [document, setDocument] = useState();
	const [showLoading, setShowLoading] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');

	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const navigate = (route: string) => {
		props.history.push(route);
	};
	useEffect(() => {
		const tenantId = user.id;
		filesService.documentType(tenantId!, 'passport').then(
			(res) => {
				setDocument(res.data.id);
			},
			(error) => {}
		);
	}, []);
	useEffect(() => {
		if (passport?.frontPictureUrl) {
			photos.push({
				base64String: passport.frontPictureUrl,
				filepath: '',
				type: 'front',
			});
			setFront(getImageLicense(0));
		}
		if (passport?.backPictureUrl) {
			photos.push({
				base64String: passport.backPictureUrl,
				filepath: '',
				type: 'back',
			});
			setBack(getImageLicense(1));
		}
	}, []);
	useEffect(() => {
		if (photos.length > 0 && photos[0].filepath !== '') {
			setShowLoading(true);
			const tenantId = user.id;
			let uuid = uuidv4();
			let fileName = '';
			if (photos[0].type === 'front') {
				fileName = `${uuid}` + '-passport.png';
			} else {
				fileName = `${uuid}` + '-passport-back.png';
			}

			filesService.credentials(tenantId!, fileName, 'documentAttachments').then(
				(res) => {
					console.log(res);

					let formData = new FormData();
					formData.append('key', res.data.uploadCredentials?.fields?.key);
					formData.append('policy', res.data.uploadCredentials?.fields?.policy);
					formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
					formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
					formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
					formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
					formData.append('file', photos[0].blob, fileName);
					filesService.upload(res.data.uploadCredentials?.url, formData).then(
						(response) => {
							if (photos[0].type === 'front') {
								setFront({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: 'passport.png',
									privateUrl: res.data.privateUrl,
									publicUrl: null,
									new: true,
									type: 'front',
									size: photos[0].blob.size,
								});
							} else {
								setBack({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: 'passport-back.png',
									privateUrl: res.data.privateUrl,
									publicUrl: null,
									new: true,
									type: 'back',
									size: photos[0].blob.size,
								});
							}

							console.log(res);
							setShowLoading(false);
						},
						(error) => {
							console.log(error);
							setShowLoading(false);
							setToastMessageError('Something went wrong,please try again.');
							setShowToastAttachments(true);
						}
					);
				},
				(error) => {
					console.log(error);
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			);
		}
	}, [photos]);
	function getImageLicense(position: number) {
		let image: any;
		passport.attachments?.forEach((element, index) => {
			if (index === position) {
				image = element;
			}
		});
		return image;
	}
	const submit = (e: any) => {
		try {
			e.preventDefault();
			setShowLoading(true);
			let attach: any[] = [];
			const tenantId = user.id;
			if (!front || !back) {
				setShowLoading(false);
				return;
			}
			if (Object.keys(passport).length == 0) {
				if (back) {
					passport.frontPictureUrl = front.downloadUrl;
					passport.backPictureUrl = back.downloadUrl;
					attach = [front, back];
				} else {
					passport.frontPictureUrl = front.downloadUrl;
					attach = [front];
				}

				const dataUpdate = {
					data: {
						address1: null,
						attachments: attach,
						birthDate: null,
						exirationDate: null,
						firstName: null,
						lastName: null,
						middleName: null,
						number: null,
						state: null,
						type: document,
					},
				};

				onBoardingService.uploadFile(tenantId!, dataUpdate).then(
					async (res) => {
						if (res?.data) {
							setShowLoading(false);
							setToastMessageError('Data updated successfully');
							setShowToastAttachments(true);
							const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
							await dispatch(walletActions.authorization(tmpUserToken));
							navigate('/tabs/wallet');
						}
					},
					(error) => {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				);
			} else {
				if (front) {
					passport.frontPictureUrl = front.downloadUrl;
					attach.push(front);
				}
				if (back) {
					passport.backPictureUrl = back.downloadUrl;
					attach.push(back);
				}
				const dataUpdate = {
					attachments: attach,
					birthDate: passport.birthday,
					boardCertified: false,
					certificationExpirationDate: false,
					currentlyPracticing: false,
					exirationDate: passport.expirationDate,
					firstName: passport.name,
					individualCoverage: false,
					isAccepted: false,
					isAuthorized: false,
					isDatesVerified: false,
					isGrantAccess: false,
					isPrimary: false,
					isPrimarySource: false,
					isPrimarySourceVerified: false,
					lastName: passport.lastName,
					middleName: passport.middleName,
					number: passport.number,
					priorOccuranceActsCoverage: false,
					selfInsured: false,
					state: passport.state,
					nationality: passport.nationality,
					type: document,
					unlimitedCoverage: false,
				};

				filesService.updateDocuments(tenantId!, dataUpdate, passport.id!).then(
					async (res) => {
						if (res?.data) {
							setShowLoading(false);
							setToastMessageError('Data updated successfully');
							setShowToastAttachments(true);
							await dispatch(walletActions.updateWallet(data, passport!.id, dataUpdate));
							navigate('/tabs/wallet');
						} else {
							setShowLoading(false);
							setToastMessageError('Something went wrong,please try again.');
							setShowToastAttachments(true);
						}
					},
					(error) => {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				);
			}
		} catch (error) {
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	};
	return (
		<IonPage className="top-page ion-justify-content-center">
			<IonContent className="ion-padding ion-justify-content-center max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>{' '}
						Passport
					</IonTitle>
				</IonToolbar>
				<IonRow className="upload-main-container">
					<ImagesLicense photos={photos} takePhoto={takePhoto} />
					<div className="upload-content-info-passport">
						<IonText className="upload-note-passport">Note:</IonText>
						<IonText className="upload-note-data-passport">
							Used for proof of identification with state and federal agencies, and health insurance payers
						</IonText>
					</div>
					{photos && photos.length == 2 && (
						<IonRow className="upload-file-row-passport">
							<IonButton onClick={submit} color="secondary" shape="round" className="btn-save-passport">
								{' '}
								Save and Close{' '}
							</IonButton>
						</IonRow>
					)}
				</IonRow>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
			</IonContent>
		</IonPage>
	);
};

export default PassportFile;
