import { IonItem, IonLabel, IonProgressBar, IonTextarea } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import { ProviderTaskEntity } from '../../shared/entity/ProviderTaskEntity';
import './Questionnaire.css';

interface ContainerProps {
	question?: ProviderTaskEntity;
	progressCount?: number;
	tasksCompleted?: number;
	totalTasks?: number;
	closeTask?: (data: any, deleteData: boolean) => void;
	saveTaskLocal?: boolean;
	saveTask?: (task: any, value: string) => void;
	progress?: number;
	document?: any;
	prop?: any;
}
const Questionnaire: React.FC<ContainerProps> = (props) => {
	const { question, progressCount, tasksCompleted, totalTasks, saveTask, progress, document } = props;
	const [showYesInput, setShowYesInput] = useState(false);
	const [error, setError] = useState(false);
	const [description, setDescription] = useState('');
	useEffect(() => {
		if (question?.status === 'done') {
			valueAnswer();
		}
	}, [question]);
	const progressCountTasks = () => {
		return tasksCompleted + '/' + totalTasks;
	};
	const colorAnswer = () => {
		const value = document[question!.key + 'Description'];
		if (question?.status === 'done' && !value && !showYesInput) {
			return 'answer-no-questions';
		}
		return '';
	};
	const colorAnswerYes = () => {
		const value = document[question!.key + 'Description'];
		if (question?.status === 'done' && value) {
			return 'answer-yes-questions';
		}
		return '';
	};
	const valueAnswer = () => {
		const value = document[question!.key + 'Description'];

		if (value) {
			setDescription(document[question!.key + 'Description']);
			setShowYesInput(true);
		}
	};
	const handleSaveAnswer = (option: string) => {
		if (option === 'no') {
			if (!showYesInput) {
				saveTask!(question, '');
			} else {
				setShowYesInput(!showYesInput);
			}
		}
		if (option === 'yes') {
			setShowYesInput(!showYesInput);
		}
		if (option === 'save') {
			if (description === '') {
				setError(true);
			} else {
				setError(false);
				saveTask!(question, description);
			}
		}
	};

	return (
		<div className="ion-margin width-questionnaire">
			<div className="main-content-questions">
				<div className="container-progress-questions">
					<span className="title-count-tasks-questions">{progressCountTasks()}</span>
					<IonProgressBar value={progress} className="progress-bar"></IonProgressBar>
				</div>
				<div className="content-questions">
					<span className="center-text-questions">{question?.title}</span>
				</div>
			</div>
			{showYesInput && (
				<div>
					<IonItem className="input-inside ion-margin-top ion-margin-bottom ">
						<IonLabel position="stacked">Please provide an explaination:</IonLabel>
						<IonTextarea onIonChange={(e: any) => setDescription(e.target.value)} value={description}></IonTextarea>
						{error && <span>Field is required</span>}
					</IonItem>
				</div>
			)}
			<div className="btn-container-questions">
				<a className={`round-buttons-questions-no ${colorAnswer()}`} onClick={() => handleSaveAnswer('no')}>
					No
				</a>
				<span
					className={`round-buttons-questions-yes ${colorAnswerYes()}`}
					onClick={() => handleSaveAnswer(showYesInput ? 'save' : 'yes')}>
					{showYesInput ? 'Save' : 'Yes'}
				</span>
			</div>
		</div>
	);
};

export default Questionnaire;
