import API from '../shared/environement/api-routes';
import Interceptor from '../shared/environement/interceptor';
export const filesService = {
	upload,
	credentials,
	saveDocuments,
	documentType,
	credentialsAttachments,
	saveDocumentsTasks,
	updateDocuments,
	sendTaskFieldMissing,
	updateTasksStatus,
	saveDocumentSignature,
	getDocument,
};

async function upload(url: string, data: FormData) {
	try {
		return await Interceptor(url, {
			method: 'POST',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}

async function credentials(tenantId: string, fileName: string, storage: string) {
	try {
		return await Interceptor.get(
			API.TENANT + `/${tenantId}/file/credentials?filename=${fileName}&storageId=${storage}`,
			{}
		);
	} catch (error) {
		return Promise.reject(error);
	}
}
async function credentialsAttachments(tenantId: string, fileName: string, storage: string) {
	try {
		return await Interceptor.get(
			API.TENANT + `/${tenantId}/document/attachments?filename=${fileName}&storageId=${storage}`,
			{}
		);
	} catch (error) {
		return Promise.reject(error);
	}
}
async function saveDocuments(tenantId: string, document: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/document/${document}`, {
			method: 'POST',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function saveDocumentsTasks(tenantId: string, data: any, task: string) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/document`, {
			method: 'POST',
			data: { data, task: task },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function documentType(tenantId: string, key: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenantId}/document-type/key?key=${key}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function updateDocuments(tenantId: string, data: any, documentId: string) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/document/${documentId}`, {
			method: 'PUT',
			data: { id: documentId, data },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function sendTaskFieldMissing(tenantId: string, data: any, documentId: string) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/task/${documentId}/field-missing`, {
			method: 'PUT',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function updateTasksStatus(tenantId: string, task: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/task/${task}/status`, {
			method: 'PUT',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function saveDocumentSignature(tenantId: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenantId}/document`, {
			method: 'POST',
			data: { data },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function getDocument(tenantId: string, data: any) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenantId}/document/${data}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
