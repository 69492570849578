import { ActionEntity } from '../../shared/entity/actionEntity';
import { DEAEntity } from '../../shared/entity/deaEntity';
import { DeaConstants } from '../constants/deaConstants';

const initialUser = {
	dea: [] as DEAEntity[],
	info: '',
};
const deaReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case DeaConstants.SET_DEA:
			return action.payload;
		default:
			return state;
	}
};
export default deaReducer;
