import React from 'react';
import {
  IonAlert
} from '@ionic/react';
import './Alert.css'
interface ContainerProps {
showAlert:boolean,
setAlert: (show:boolean) => void,
headerText:string,
message:string,
buttons:any[],
cssClass:string,
inputs:any[]
}
const Alert: React.FC<ContainerProps> = ({ showAlert,setAlert,headerText,message,buttons,cssClass,inputs }) => {
    return (
     <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setAlert(false)}
          cssClass={cssClass}
          header={headerText}
          backdropDismiss={false}
          message={message}
          buttons={buttons}
          inputs={inputs}
        />
   
  )
};

export default Alert;
