import { ActionEntity } from "../../shared/entity/actionEntity";
import { OnBoardingConstants } from "../constants/onBoardingConstants";

const initialUser: any = [];
const onBoardingReducer = (state: any = initialUser,action: ActionEntity) =>{
    switch(action.type){     
        case OnBoardingConstants.NPI_RESULTS_SUCCESS:
                    return action.payload;
                    
                                   
        default:
            return state;
    }

}
export default onBoardingReducer;