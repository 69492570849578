import { Dispatch } from 'redux';
import { userService } from '../../services/user.service';
import { UserEntity } from '../../shared/entity/userEntity';
import { UserConstants } from '../constants/userConstants';

export const userProfileActions = {
	setUser,
	updateUser,
	changePassword,
	setUserNpi,
	setUserTenantId,
	setUserEmail,
};

function setUser(data: any) {
	return async (dispatch: Dispatch) => {
		if (data?.tenants[0]?.provider && data?.tenants[0].provider.contactInfo) {
			const user: UserEntity = {
				id: data.tenants[0].tenant._id,
				contactId: data.tenants[0].provider.contactInfo._id,
				firstName: data.tenants[0].provider.contactInfo.firstName,
				middleName: data.tenants[0].provider.contactInfo.middleName,
				lastName: data.tenants[0].provider.contactInfo.lastName,
				description: data.tenants[0].provider.contactInfo.description,
				phone1: data.tenants[0].provider.contactInfo.phone1,
				type: data.tenants[0].provider.contactInfo.type,
				phone2: data.tenants[0].provider.contactInfo.phone2,
				jobTitle: data.tenants[0].provider.contactInfo.jobTitle,
				email: data.tenants[0].provider.contactInfo.email,
				backupEmail: data.tenants[0].provider.contactInfo.backupEmail,
				photo: data.tenants[0].provider.contactInfo.photo,
				attachment: data.tenants[0].provider.contactInfo.attachment,
				tasks: data.tenants[0].provider.contactInfo.tasks,
				homeAddressStreet: data.tenants[0].provider.contactInfo.homeAddressStreet,
				homeAddressStreet2: data.tenants[0].provider.contactInfo.homeAddressStreet2,
				homeAddressCity: data.tenants[0].provider.contactInfo.homeAddressCity,
				homeAddressState: data.tenants[0].provider.contactInfo.homeAddressState,
				homeAddressZipCode: data.tenants[0].provider.contactInfo.homeAddressZipCode,
				homeAddressCountry: data.tenants[0].provider.contactInfo.homeAddressCountry,
				mailingAddressStreetName: data.tenants[0].provider.contactInfo.mailingAddressStreetName,
				mailingAddressStreetName2: data.tenants[0].provider.contactInfo.mailingAddressStreetName2,
				mailingAddressCity: data.tenants[0].provider.contactInfo.mailingAddressCity,
				mailingAddressState: data.tenants[0].provider.contactInfo.mailingAddressState,
				mailingAddressZipCode: data.tenants[0].provider.contactInfo.mailingAddressZipCode,
				mailingAddressCountry: data.tenants[0].provider.contactInfo.mailingAddressCountry,
				linkedPayer: data.tenants[0].provider.contactInfo.linkedPayer,
				linkedProvider: data.tenants[0].provider.contactInfo.linkedProvider,
				linkedUser: data.tenants[0].provider.contactInfo.linkedUser,
				linkedLocation: data.tenants[0].provider.contactInfo.linkedLocation,
				userName: data.email,
				terms: false,
			};

			dispatch(request(user));
		}
	};

	function request(payload: UserEntity) {
		return { type: UserConstants.USER_PROFILE, payload };
	}
}

function updateUser(user: UserEntity) {
	return async (dispatch: Dispatch) => {
		dispatch(request(user));
		const req = {
			id: user.contactId,
			data: user,
		};

		req.data.token = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		await userService.updateUser(req).then(
			(res) => {
				console.log(res?.data);

				dispatch(success(user));
			},
			(error) => {
				dispatch(failure(error.response.data));
			}
		);
	};

	function request(payload: UserEntity) {
		return { type: UserConstants.USER_PROFILE_REQUEST, payload };
	}
	function success(payload: UserEntity) {
		return { type: UserConstants.USER_PROFILE_SUCCESS, payload };
	}
	function failure(error: any) {
		return { type: UserConstants.USER_PROFILE_FAILURE, error };
	}
}

function changePassword(user: any) {
	return async (dispatch: Dispatch) => {
		const req = {
			newPassword: user.newPassword,
			oldPassword: user.oldPassword,
		};

		await userService.changePassword(req).then(
			(res) => {
				return Promise.resolve;
			},
			(error) => {
				return Promise.reject;
			}
		);
	};
}

function setUserNpi(card: any) {
	return async (dispatch: Dispatch) => {
		const user: UserEntity = {
			npi: card.npi,
			name: card.name,
		};

		dispatch(request(user));
	};

	function request(payload: UserEntity) {
		return { type: UserConstants.SET_USER_NPI, payload };
	}
}
function setUserTenantId(id: string) {
	return async (dispatch: Dispatch) => {
		const user: UserEntity = {
			id: id,
		};

		dispatch(request(user));
	};

	function request(payload: UserEntity) {
		return { type: UserConstants.SET_TENANT_ID, payload };
	}
}
function setUserEmail(email: string) {
	return async (dispatch: Dispatch) => {
		const user: UserEntity = {
			email: email,
		};

		dispatch(request(user));
	};

	function request(payload: UserEntity) {
		return { type: UserConstants.SET_EMAIL, payload };
	}
}
