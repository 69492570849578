import { ActionEntity } from '../../shared/entity/actionEntity';
import { MedicareCardEntity } from '../../shared/entity/medicareCardEntity';
import { MedicareCardConstant } from '../constants/medicareCardConstants';

const initialUser = {
	mcare: [] as MedicareCardEntity[],
	info: '',
};
const medicareCardReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case MedicareCardConstant.SET_MEDICARE_CARD:
			return action.payload;
		default:
			return state;
	}
};
export default medicareCardReducer;
