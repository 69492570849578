import { 
  IonRow,
  IonIcon,
  IonContent
 } from '@ionic/react';
import checkedIcon from "../../img/icons/checked.svg";
import uncheckedIcon from "../../img/icons/unchecked.svg";
import './NeedsTag.css'
import { RouteComponentProps } from 'react-router';
import moment from 'moment';  
interface ContainerProps {
name: string;
progress: string;
link: string;
icon: string;
props: RouteComponentProps;
date:string;
status:string;
onClickTag: (id:string) => void;
id:string;
}
const NeedsTag: React.FC<ContainerProps> = ({ name, progress, link, icon, props,date,status,onClickTag,id}) => {
  //validate date to set a message
  function getTaskText(){
    const text=status==='done'?'Complete':moment(moment(date).format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'))?'PRIORITY'
    :moment(moment(date).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD'))?'Today'
    :moment(moment(date).format('YYYY-MM-DD')).isSame(moment().add(1,'days').format('YYYY-MM-DD'))?'Tomorrow'
    :moment(date).format("MMM D");
    return (
      <div className='byWhen-width'><div className={`${text==='PRIORITY'?'color-red':''} by-when-style`}>{text}</div></div>
      );
  }
  return (
    
     <div className={`ion-no-border tasks-content-section`} onClick={()=>onClickTag(id)}>
        <div className={`what-we-need-width tasks-content-section`}>
          <div className={`div-task-title `}>
            {status==='done'?
            <div>
                <IonIcon icon={checkedIcon}></IonIcon>&nbsp;&nbsp;{name}
            </div>  
             
           :  <div>
           <IonIcon icon={uncheckedIcon}></IonIcon>&nbsp;&nbsp;{name}
       </div>  }
          </div>
           </div>
              {getTaskText()}
      </div>

  );
};


export default NeedsTag;