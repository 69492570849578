import { ActionEntity } from '../../shared/entity/actionEntity';
import { BoardActionsEntity } from '../../shared/entity/boardActionsEntity';
import { BoardActionsConstants } from '../constants/boardActionsConstants';

const initialUser = {
	boardActions: [] as BoardActionsEntity[],
	info: '',
};
const boardActionsReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case BoardActionsConstants.SET_BOARD_ACTIONS:
			return action.payload;
		default:
			return state;
	}
};
export default boardActionsReducer;
