import { Dispatch } from "redux";
import { NpiResultEntity } from "../../shared/entity/npiResult";
import { OnBoardingConstants } from "../constants/onBoardingConstants";


export const onBoardingActions = {
    setNpiResults
   
};

function setNpiResults(data: any){
    return async (dispatch : Dispatch) => {

        
        const users: NpiResultEntity[] = [];
        data.forEach((element: any )=> {
            const location = element.addresses.find((element: any) => element.address_purpose === 'LOCATION');
            const tmp : NpiResultEntity = {
                name: element.basic.first_name+' '+element.basic.last_name,
                npi: element.number,
                location: location.city

            }
            users.push(tmp)
        });
           
        
        dispatch(success(users));

         
    };

    function success(payload : NpiResultEntity[]) { return { type: OnBoardingConstants.NPI_RESULTS_SUCCESS, payload } }
    
}


