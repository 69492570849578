import { ActionEntity } from '../../shared/entity/actionEntity';
import { WalletActivationConstants } from '../constants/walletActivationConstants';

const initialUser = {
	date: '',
	active: '',
};
const walletActivationReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case WalletActivationConstants.SET_WalletActivation:
			return action.payload;
		default:
			return state;
	}
};
export default walletActivationReducer;
