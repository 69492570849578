import { IonCol, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { SamFieldsEntity } from '../../shared/entity/SamFieldsEntity';
import './AccordionSamActions.css';
const AccordionSamActions: React.FC<SamFieldsEntity> = (props) => {
	const { title, number, date, children, name, type, attachments, orderDate } = props;
	const [showImageModal, setShowImageModal] = useState(false);
	return (
		<div className="full-row-cols">
			<IonGrid className="full-row-cols">
				<IonRow className="row-content-exc-sam">
					<IonCol size="1">
						<IonRow
							className={`count-exclusion-sam ${type === 1 ? 'exc-possible-color-sam' : ''}
		 ${type === 2 ? 'exc-found-color-sam' : ''}${type === 3 ? 'exc-prev-match-color-sam' : ''}
		 `}>
							<span className="count-exclusion-circle-sam">{number}</span>
						</IonRow>
					</IonCol>
					<IonCol size={`${type === 3 && attachments ? '7' : '11'} `}>
						<IonRow className="full-row-cols">
							<IonText className="exclusion-name-sam">{name}</IonText>{' '}
							<IonText className="exclusion-data-sam exclusion-name-sam">
								{type === 1 ? 'Status: In Progress' : type === 2 ? 'Status:  Match Confirmed' : 'Status: No Match '}
							</IonText>
							{type == 3 ? null : <IonText className="exclusion-data-sam">Type: {title}</IonText>}
							{type == 3 ? null : <IonText className="exclusion-data-leie">Active Date: {orderDate}</IonText>}
							{type == 3 ? null : <IonText className="exclusion-data-leie">Termination Date: {orderDate}</IonText>}
						</IonRow>
					</IonCol>
					{type === 3 && attachments ? (
						<IonCol size="4" onClick={() => setShowImageModal(true)}>
							<IonRow className="full-row-cols">
								<IonImg src={attachments.downloadUrl}></IonImg>
							</IonRow>
						</IonCol>
					) : null}
					{type === 3 ? (
						<IonCol size="11" offset="1">
							<IonRow className="full-row-cols">
								<IonText className="exclusion-data-sam">Verified on {orderDate}</IonText>
							</IonRow>
						</IonCol>
					) : null}
				</IonRow>
			</IonGrid>
			<Modal showCloseIcon={true} open={showImageModal} onClose={() => setShowImageModal(false)} modalId="modal" center>
				<IonImg className="modal-img-license" src={attachments?.downloadUrl} />
			</Modal>
		</div>
	);
};
export default AccordionSamActions;
