import { IonIcon, IonImg, IonRow, IonText } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import editImgIcon from '../../../img/edit-image.svg';
import IconChangeImage from '../../../img/icon-change-img.svg';
import defaultImg from '../../../img/icons/default-img.svg';
import interrogation from '../../../img/icons/interrogation.svg';
import { PassportActions } from '../../../redux/actions/passportActions';
import { filesService } from '../../../services/files.service';
import AddressCountries from '../../../shared/addressCountries';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { PassportEntity } from '../../../shared/entity/passportEntity';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import './passport.css';
const Passport: React.FC<CardInfoEntity> = ({ title, zoom, props }) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const passport = useSelector<ReduxStateEntity, PassportEntity>((state) => state.passport!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__drivers-license passportContent');
	const [documentDescription, setDocumentDescription] = useState(passport?.info);
	const navigate = (route: string) => {
		props?.history.push('/tabs' + route);
	};
	useEffect(() => {
		if (data) {
			dispatch(PassportActions.setPassport(data, passport));
		}
	}, [data]);
	useEffect(() => {
		if (passport?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'passport').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(PassportActions.setPassport(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING PASSPORT DESCRIPTION:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [passport]);
	useEffect(() => {
		if (passport?.info !== '') {
			setDocumentDescription(passport?.info);
		}
	}, [passport, documentDescription]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__drivers-license passportContent');
		} else {
			setBack('card card__drivers-license passportContent noRotate');
		}
	};
	const [showDriversLicenseModal, setShowDriversLicenseModal] = useState(false);
	const [showBackImg, setShowBackImg] = useState(false);
	return (
		<div className={back}>
			{!flip ? (
				<IonRow className="card-face-passport">
					<IonRow>
						{passport && !flip ? (
							<IonRow className="header-passport">
								<IonRow className="passport-title">
									<IonText>{title}</IonText>
								</IonRow>
								<IonIcon
									id="about"
									className="icon-about-sam-exclusion"
									icon={interrogation}
									onClick={() => onFlip()}></IonIcon>
							</IonRow>
						) : null}
						{passport && !flip ? (
							<IonRow className="content-card">
								<IonRow className="card__drivers-license-header">
									<IonRow
										id="pic"
										className={passport.frontPictureUrl ? 'img-driver-license' : 'img-driver-license-default'}>
										{passport.frontPictureUrl ? (
											<IonImg
												id="pic"
												onClick={() => setShowDriversLicenseModal(true)}
												src={passport.frontPictureUrl}
											/>
										) : (
											<IonRow id="pic" className="default-row">
												<IonIcon onClick={() => navigate('/upload-passport')} id="pic" icon={defaultImg}></IonIcon>
												<IonText id="pic">Passport</IonText>
											</IonRow>
										)}
										{passport.frontPictureUrl && (
											<IonRow className="container-edit-passport" onClick={() => navigate('/upload-passport')}>
												<IonRow className="edit-passport-image">
													<IonIcon icon={editImgIcon}></IonIcon>
												</IonRow>
											</IonRow>
										)}
									</IonRow>
								</IonRow>
								<IonRow className="card__drivers-license-body">
									<IonText className="span detail">Details</IonText>
									<IonRow className="card-passport-number">
										<IonText className="span">
											{passport.number ? passport.number : 'Not Available'}
											<CopyClipboard text={passport.number} />
										</IonText>
									</IonRow>

									<IonText className="span">
										Nationality: {passport.state ? AddressCountries.label(passport.state) : 'Not Available'}
									</IonText>
									<IonText className="span">
										Expiration:{' '}
										{passport.expirationDate ? moment(passport.expirationDate).format('MMM DD YYYY') : 'Not Available'}
									</IonText>
									<IonText className="span">
										Issued: {passport.issuedDate ? moment(passport.issuedDate).format('MMM DD YYYY') : 'Not Available'}
									</IonText>
								</IonRow>
							</IonRow>
						) : null}
					</IonRow>
				</IonRow>
			) : null}
			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground="passport-background"
					cssTextColor="about-white"
				/>
			) : null}
			{passport.frontPictureUrl ? (
				<Modal
					showCloseIcon={false}
					open={showDriversLicenseModal}
					onClose={() => setShowDriversLicenseModal(false)}
					center>
					{showBackImg ? (
						[
							passport.backPictureUrl ? (
								<IonImg key={1} className="modal-img-license" src={passport.backPictureUrl} />
							) : (
								<IonRow key={2} className={'img-driver-license-default'}>
									<IonRow key={3} className="default-row">
										<IonIcon
											onClick={() => {
												setShowDriversLicenseModal(false);
												navigate('/upload-passport');
											}}
											id="pic"
											icon={defaultImg}></IonIcon>
									</IonRow>
								</IonRow>
							),
						]
					) : (
						<IonImg className="modal-img-license" src={passport.frontPictureUrl} />
					)}
					<IonRow className="ion-no-border modal-cred-footer">
						<img
							onClick={() => setShowBackImg(!showBackImg)}
							className="auto-center"
							src={IconChangeImage}
							style={{ width: 60 }}
						/>
					</IonRow>
				</Modal>
			) : null}
		</div>
	);
};

export default Passport;
