import { ActionEntity } from "../../shared/entity/actionEntity";
import { MedicalLicenseEntity } from "../../shared/entity/medicalLicenseEntity";
import { MedicalLicenseConstants } from "../constants/medicalLicenseConstants";


const initialUser: MedicalLicenseEntity = {};
const medicalLicenseReducer = (state: MedicalLicenseEntity = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case MedicalLicenseConstants.SET_MEDICAL_LICENSE:
                return {
                    ...state,
                    data: action.payload
                };          
        default:
            return state;
    }

}
export default medicalLicenseReducer;