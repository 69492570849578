import { ActionEntity } from "../../shared/entity/actionEntity";
import { SamEntity } from "../../shared/entity/samEntity";

import { SamConstants } from "../constants";

//import { SamConstants } from "../constants/SamConstants";


const initialUser: SamEntity[] = [];
const samsReducer = (state: SamEntity [] = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case SamConstants.SET_SAM:
                return action.payload;          
        default:
            return state;
    }
}
export default samsReducer;