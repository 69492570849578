import {
	IonDatetime,
	IonIcon,
	IonImg,
	IonItem,
	IonLoading,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PayDescImage from '../../../img/activate_payor_enrollment_btn.svg';
import ActivateText from '../../../img/activate_payor_enrollment_txt.svg';
import FindIcon from '../../../img/findPayerIcon.svg';
import interrogation from '../../../img/icons/black-interrogation.svg';
import PayorSuccessImage from '../../../img/success-con-payor.svg';
import SuccessText from '../../../img/success-payor-txt.svg';
import { walletActions } from '../../../redux/actions';
import { PayerActionsActions } from '../../../redux/actions/payerActions';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import { paymentService } from '../../../services/paymentService';
import AddressStates from '../../../shared/addressStates';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { PayerCardEntity } from '../../../shared/entity/payerCardEntity';
import { PayerEntity } from '../../../shared/entity/payerEntity';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionPayerActions from '../../AccordionPayerActions/AccordionPayerActions';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import './Payer.css';
const Payers: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard, closeCards }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const payer = useSelector<ReduxStateEntity, PayerCardEntity>((state) => state.payer!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__payer background_payer');
	const [states, setStates] = useState<string[]>([]);
	const [payers, setPayers] = useState<PayerEntity[]>(payer.payers);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [documentDescription, setDocumentDescription] = useState(payer?.info);
	const [payorActivation, setPayorActivation] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {
		if (data) {
			dispatch(PayerActionsActions.setPayers(data, payer));
			dispatch(WalletActivationActions.setWalletActivation(data));
		}
	}, [data]);
	useEffect(() => {
		if (data) {
			if (payer?.payers.length > 0) {
				setPayers(payer.payers);
			}
			if (payer?.locations.length > 0) {
				setStates([payer.locations[0].name]);
				const initialState = [payer.locations[0].name];
				payersByState(initialState);
			}
		}
	}, [payer]);
	useEffect(() => {
		if (payer?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'Payer-Enrollment-Status').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(PayerActionsActions.setPayers(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [payer]);
	useEffect(() => {
		if (payer?.info !== '') {
			setDocumentDescription(payer.info);
		}
	}, [payer, documentDescription]);
	//find payers by payer state
	function payersByState(value: string[]) {
		const isAllSelection = value.indexOf('All');
		let listOfPayers: PayerEntity[] = [];
		if (isAllSelection >= 0) {
			if (payers.length < payer.payers.length || value.length > 1) {
				value = [];
				value.push('All');
			}
			payer?.payers.map((element) => {
				listOfPayers.push(element);
			});
		} else {
			if (value.length == 0) {
				value.push(payer.locations[0].name);
			}
			value.map((state) => {
				payer?.payers.map((element) => {
					if (element.state === state) {
						listOfPayers.push(element);
					}
				});
			});
		}
		setPayers(listOfPayers);
		setStates(value);
	}

	//returns the accordeon info
	function accordeonData(element: PayerEntity, index: number) {
		return (
			<IonRow className="flex-col-payer " key={index}>
				<IonText className="text-payer">Participation (PAR) Date:</IonText>
				<IonDatetime
					className="payer-datetime text-payer-provider"
					pickerFormat="MMM/DD/YYYY"
					value={element.effectiveDate}
					readonly={true}></IonDatetime>
				<IonText className="text-payer">Provider ID:</IonText>
				<IonRow>
					<IonText className="payer-datetime text-payer-provider">{element.providerId}</IonText>
					{element.providerId ? <CopyClipboard text={element.providerId} blackIcon={true} /> : null}
				</IonRow>
			</IonRow>
		);
	}
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__payer background_payer');
		} else {
			setBack('card card__payer background_payer mlNoRotatePayer');
		}
	};
	async function activatePayorEnrollment() {
		setShowLoading(true);
		const fullname = data.tenants[0].provider.fullName || '';
		const email: any = data.tenants[0].provider.user.email || '';
		const provider = data.tenants[0].provider.id;
		const tenant = data.tenants[0].tenant.id;
		const providerNPI = data.tenants[0].provider.npi;
		const providerType = data.tenants[0].provider.type.name;
		const result = await paymentService.requestPayorEnrollment(
			tenant,
			provider,
			email,
			fullname,
			providerNPI,
			providerType
		);
		if (result.data === 'succeeded') {
			const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
			setPayorActivation(true);
			setShowLoading(false);
			await dispatch(walletActions.authorization(tmpUserToken));
			const timer = setTimeout(() => {
				if (closeCards?.()) {
					closeCards();
				}
			}, 3000);
			return () => clearTimeout(timer);
		}
	}
	return (
		<div className={back}>
			<div className={`${!zoom || flip ? 'wrapper' : 'wrapper-zoom-payer'}`}>
				{!flip && walletActivation?.payorEnrollmentStatus ? (
					<IonRow className="article-header_payer">
						<IonRow className="category-title">
							<IonText className="ion-padding-right">{title}</IonText>
							{!flip && payer?.payers.length > 0 ? (
								<IonText className="bubble-mcare">
									<span>{payer?.payers.length}</span>
								</IonText>
							) : null}
						</IonRow>
						<IonIcon id="about" className="icon-about-payer" icon={interrogation} onClick={() => onFlip()}></IonIcon>
					</IonRow>
				) : null}
				{!flip && !walletActivation?.payorEnrollmentStatus ? (
					<IonRow className="article-header_payer">
						<IonRow className="category-title">
							<IonText className="ion-padding-right">{title}</IonText>
						</IonRow>
					</IonRow>
				) : null}
				{!flip && !walletActivation?.payorEnrollmentStatus ? (
					<div className={'background_payer-activation'}>
						<div className="margin-activate-payor" onClick={() => activatePayorEnrollment()}>
							<IonImg
								className="payorsActivation-image"
								src={payorActivation ? PayorSuccessImage : PayDescImage}></IonImg>
						</div>
						<div className="margin-activate-payor-text">
							<IonImg className="payorsActivation-image" src={payorActivation ? SuccessText : ActivateText}></IonImg>
						</div>
					</div>
				) : (
					<div>
						{!flip ? (
							payer?.payers.length > 0 ? (
								<IonRow className="row-header-payer ">
									<IonItem lines="none" className="item-payer item-border-select">
										<IonSelect
											className="payer-select payer-width"
											id="dropdown"
											multiple={true}
											value={states}
											onIonChange={(e) => payersByState(e.detail.value)}>
											<IonSelectOption value="All" key="all">
												{'All'}
											</IonSelectOption>
											{payer.locations.map((option, i) => (
												<IonSelectOption value={option.name} key={i}>
													{AddressStates.label(option.name)}
												</IonSelectOption>
											))}
										</IonSelect>
									</IonItem>
									<IonIcon id="about" icon={FindIcon} onClick={() => onFlip()} className="find-payer"></IonIcon>
								</IonRow>
							) : (
								<NoInfoCard textColor="text-color-black" blackIcon={true} />
							)
						) : null}
						{!flip && payer?.payers.length > 0 ? (
							<div>
								<div className="border-bottom-payer-accordeon-test">
									<br />
								</div>
								<IonRow className="zoom payers-background">
									{payers && !flip
										? payers.map((element, index) =>
												zoom && index < 5 ? (
													<AccordionPayerActions
														key={index}
														logo={element.logo}
														status={element.status}
														effectiveDate={element.effectiveDate}
														expirationDate={element.expirationDate}
														providerId={element.providerId}
														name={element.name}
														estimatedDaysToEnrollment={element.estimatedDaysToEnrollment}
														state={element.state}
														statusValue={element.statusValue}
														quantity={payers.length}
														collapse={false}>
														{accordeonData(element, index)}
													</AccordionPayerActions>
												) : zoom ? (
													<AccordionPayerActions
														key={index}
														logo={element.logo}
														status={element.status}
														effectiveDate={element.effectiveDate}
														expirationDate={element.expirationDate}
														providerId={element.providerId}
														name={element.name}
														estimatedDaysToEnrollment={element.estimatedDaysToEnrollment}
														state={element.state}
														statusValue={element.statusValue}
														quantity={payers.length}
														collapse={false}>
														{accordeonData(element, index)}
													</AccordionPayerActions>
												) : null
										  )
										: null}
								</IonRow>
							</div>
						) : null}

						{flip ? (
							<AboutThat onFlip={onFlip} description={documentDescription} title={title} cssTextColor="about-black" />
						) : null}
					</div>
				)}
			</div>
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</div>
	);
};

export default Payers;
