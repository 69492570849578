import { combineReducers } from 'redux';
import abmsReducer from './abmsReducer';
import amaReducer from './amaReducer';
import aoaReducer from './aoaReducer';
import boardActionsReducer from './boardActionsReducer';
import conversationReducer from './conversationReducer';
import credentialingLoginReducer from './credentialingLoginReducer';
import deaReducer from './deaReducer';
import driverLicenseReducer from './driverLicenseReducer';
import leieReducer from './leieReducer';
import loginReducer from './loginReducer';
import medicaidCardReducer from './medicaidCardReducer';
import medicalLicenseReducer from './medicalLicenseReducer';
import medicareCardReducer from './medicareCardReducer';
import medicareReducer from './medicareReducer';
import notificationReducer from './notificationReducer';
import nppesReducer from './nppesReducer';
import onBoardingPhotosReducer from './onBoardingPhotosReducer';
import onBoardingReducer from './onBoardingReducer';
import passportReducer from './passportReducer';
import passwordReducer from './passwordReducer';
import payerReducer from './payerReducer';
import pendingTasksReducer from './pendingTaskReducer';
import providerSignatureReducer from './providerSignatureReducer';
import samsReducer from './samsReducer';
import tabsReducer from './tabsReducer';
import userReducer from './userReducer';
import walletActivationReducer from './wallet-activation';
import walletReducer from './walletReducer';
const reducers = combineReducers({
	login: loginReducer,
	wallet: walletReducer,
	medicalLicense: medicalLicenseReducer,
	nppes: nppesReducer,
	driverLicense: driverLicenseReducer,
	passport: passportReducer,
	boardActions: boardActionsReducer,
	aoa: aoaReducer,
	ama: amaReducer,
	credentialingLogin: credentialingLoginReducer,
	leie: leieReducer,
	medicare: medicareReducer,
	user: userReducer,
	dea: deaReducer,
	abms: abmsReducer,
	npiResults: onBoardingReducer,
	photos: onBoardingPhotosReducer,
	payer: payerReducer,
	mcaid: medicaidCardReducer,
	mcare: medicareCardReducer,
	pendingTasks: pendingTasksReducer,
	tabs: tabsReducer,
	conversation: conversationReducer,
	notification: notificationReducer,
	passwords: passwordReducer,
	samExclusion: samsReducer,
	providerSignature: providerSignatureReducer,
	walletActivation: walletActivationReducer,
});

export default reducers;
