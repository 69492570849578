import { ActionEntity } from "../../shared/entity/actionEntity";
import { CredentialingLoginEntity } from "../../shared/entity/credentialingLoginEntity";
import { CredentialingLoginConstants } from "../constants";


const initialUser: CredentialingLoginEntity[] = [];
const credentialingLoginReducer = (state: CredentialingLoginEntity[] = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case CredentialingLoginConstants.SET_CREDENTIALING_LOGIN:
                return action.payload;          
        default:
            return state;
    }
}
export default credentialingLoginReducer;