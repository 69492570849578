import API from '../shared/environement/api-routes';

import Interceptor from '../shared/environement/interceptor';
export const supportService = {
	email,
	deleteAccount,
};

async function email(data: any) {
	try {
		return await Interceptor(API.TENANT + `/${data.id}/support/email`, {
			method: 'POST',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function deleteAccount(value: any, npi: string, tenant: string, recaptcha: any) {
	try {
		const data = {
			npi: npi,
			tenant: tenant,
			firstName: value.firstName,
			userName: value.userName,
			middleName: value.middleName,
			lastName: value.lastName,
			email: value.emal,
			phone: value.phone,
			recaptchaToken: recaptcha,
		};
		return await Interceptor(API.TENANT + `/${tenant}/support/email/remove/account`, {
			method: 'POST',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
