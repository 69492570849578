import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Subject } from 'rxjs';
import { PushNotificationModule } from '../shared/entity/pushNotification.module';
import API from '../shared/environement/api-routes';
import Interceptor from '../shared/environement/interceptor';
const pushNotificationsSubject$ = new Subject();

const notifyNewNotification = (data: PushNotificationSchema) => {
	pushNotificationsSubject$.next(data);
};

const saveUserToken = async (data: PushNotificationModule.RegistrationToken) => {
	try {
		const result = await Interceptor(`${API.PUSH_NOTIFICATION_TOKEN}`, {
			method: 'POST',
			data,
		});
		return result;
	} catch (e) {
		throw e;
	}
};

const checkPermissions = async (userId: string) => {
	PushNotifications.checkPermissions()
		.then((res) => {
			if (res.receive !== 'granted') {
				PushNotifications.requestPermissions().then((res) => {
					if (res.receive === 'denied') {
						console.log('Push Notification permission denied', res);
					} else {
						console.log('Push Notification permission granted', res);
						register(userId);
					}
				});
			} else {
				register(userId);
			}
		})
		.catch((e) => {
			console.log('call permisions error', e);
		});
};

const register = (userId: string) => {
	// Register with Apple / Google to receive push via APNS/FCM
	PushNotifications.register();

	// On success, we should be able to receive notifications
	PushNotifications.addListener('registration', (token: Token) => {
		localStorage.setItem('pushNotificationToken', token.value);
		console.log('Push registration success', token);
		saveUserToken({ token: token.value, userId });
	});

	// Some issue with our setup and push will not work
	PushNotifications.addListener('registrationError', (error: any) => {
		alert('Error on registration: ' + JSON.stringify(error));
	});

	// Show us the notification payload if the app is open on our device
	PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
		console.log('pushNotificationReceived', notification);
		notifyNewNotification(notification);
	});

	// Method called when tapping on a notification
	PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
		console.log('pushNotificationReceived', notification);
	});
};

export const pushNotificationService = {
	checkPermissions,
	saveUserToken,
	pushNotificationsSubject$,
};
