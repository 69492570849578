import { Dispatch } from 'redux';
import { DEAEntity } from '../../shared/entity/deaEntity';
import { DeaConstants } from '../constants/deaConstants';

export const DeaActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: DEAEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'dea-number') {
					const exclusion: DEAEntity = {
						state: element.state,
						number: element.number,
						description: element.description,
						expirationDate: element.expirationDate,
						deaSchedule: element.deaSchedule,
						status: element.status,
						attachments: element.attachments,
						pdfData:
							element.attachments && element.attachments.length > 0
								? element.attachments[existsPDF(element.attachments)]
								: null,
						imgAttachment: element.attachments && element.attachments.length > 0 ? getImgAttachment(element) : [],
						id: element.id,
					};
					exclusions.push(exclusion);
				}
			});
			if (exclusions) {
				dispatch(set({ dea: exclusions, info: document?.info || '' }));
			}
		}
	};
	function existsPDF(data: any) {
		let result = data.findIndex(function (element: any) {
			return element.name.includes('.pdf');
		});
		return result;
	}
	function getImgAttachment(data: any) {
		let attach = data.attachments.slice();
		const result = attach.findIndex(function (element: any) {
			return element.name.includes('.pdf');
		});
		if (result >= 0) {
			attach.splice(result, 1);
		}
		return attach;
	}
	function set(payload: any) {
		return { type: DeaConstants.SET_DEA, payload };
	}
}
