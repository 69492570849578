import API from '../shared/environement/api-routes';

import Interceptor from '../shared/environement/interceptor';
export const privacyService = {
	getPrivacyTerms,
	setPrivacyTermsAgreement,
};

async function getPrivacyTerms(tenant: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/document-type/key?key=terms-of-service`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}

async function setPrivacyTermsAgreement(tenant: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenant}/document`, {
			method: 'POST',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
