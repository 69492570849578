import { ActionEntity } from '../../shared/entity/actionEntity';
import { ConversationModule } from '../../shared/entity/conversation.module';
import { ConversationConstants } from '../constants/conversationConstants';

interface ConversationReducerModel {
	conversation?: ConversationModule.Conversation;
	conversationsChecked?: Array<ConversationModule.Conversation>;
}

const initialConversation: ConversationReducerModel = {
	conversation: undefined,
	conversationsChecked: [],
};

const conversationReducer = (state = initialConversation, action: ActionEntity): ConversationReducerModel => {
	switch (action.type) {
		case ConversationConstants.SELECTED_CONVERSATION:
			return {
				...state,
				conversation: action.payload.conversation,
			};
		case ConversationConstants.CHECK_CONVERSATION:
			return {
				...state,
				conversationsChecked: state.conversationsChecked?.concat([action.payload.conversation]),
			};
		case ConversationConstants.CLEAN_CHECKED_CONVERSATIONS:
			return {
				...state,
				conversationsChecked: [],
			};
		case ConversationConstants.UNCHECK_CONVERSATION:
			const newArray: Array<ConversationModule.Conversation> = state.conversationsChecked!;
			newArray?.splice(action.payload.index, 1);
			return {
				...state,
				conversationsChecked: newArray,
			};
		default:
			return state;
	}
};
export default conversationReducer;
