import { IonImg, IonItem, IonLabel, IonMenuToggle, IonRow, IonText, IonToggle, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ActivateProfileDescImage from '../../../img/activate-profile-desc-white.svg';
import IconAllClear from '../../../img/icon-all-clear.svg';
import IconHidePreviousMatches from '../../../img/icon-hide-previous-matches.svg';
import IconShowPreviousMatches from '../../../img/icon-show-previous-matches.svg';
import interrogation from '../../../img/icons/interrogation-leie.svg';
import ImageOig from '../../../img/oig.png';
import { LeieActions } from '../../../redux/actions/leieActions';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { LeieActionsEntity } from '../../../shared/entity/leie.Entity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionLeieActions from '../../AccordionLEIEActions/AccordionLeieActions';
import './LEIE.css';
const LEIE: React.FC<CardInfoEntity> = ({ title, zoom }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const leie = useSelector<any, LeieActionsEntity>((state) => state.leie!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__leie-actions');
	const [documentDescription, setDocumentDescription] = useState(leie?.info);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [previousMatches, setPreviousmatches] = useState(false);
	useEffect(() => {
		if (data) {
			dispatch(LeieActions.setLicense(data, leie));
			dispatch(WalletActivationActions.setWalletActivation(data));
		}
	}, [data]);
	useEffect(() => {
		if (leie?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'exclusion').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								res.data.documentFields.forEach((element: any) => {
									if (element.key === 'oigdesc') {
										setDocumentDescription(element.description);
										dispatch(LeieActions.setLicense(data, { info: element.description }));
									}
								});
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, []);
	useEffect(() => {
		if (leie?.info !== '') {
			setDocumentDescription(leie.info);
		}
	}, [leie, documentDescription]);
	function lastCheckedData(): string {
		if (data?.tenants[0]?.provider?.tasks) {
			let tasks: string[] = [];
			data.tenants[0].provider.tasks.forEach((element: any) => {
				if (
					(element.assignedTo === data.tenants[0].provider.user.id &&
						element.taskTemplate === '62fbefb9dc20481cd96b3253' &&
						element.status === 'done') ||
					element.status === 'archived'
				) {
					tasks.push(element.doneAt);
				}
			});
			const newarr = tasks.slice().sort((a, b) => {
				return moment(b).diff(a);
			});
			if (newarr.length > 0) {
				return moment(newarr[0]).format('MMM DD YYYY HH:mm a').toString();
			}
		}
		return data?.tenants[0]?.provider
			? moment(data.tenants[0].provider.oigExclusionUpdatedAt).format('MMM DD YYYY HH:mm a').toString()
			: 'Search Error Ocurred';
	}
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__leie-actions');
		} else {
			setBack('card card__leie-actions dlNoRotate');
		}
	};
	const AllClearContent = () => {
		return (
			<IonRow className="full-row-cols padding-content">
				<IonRow className="all-clear-oig-row">
					<IonImg src={IconAllClear}></IonImg>
					<IonText className="all-clear-title-leie"> All Clear</IonText>
				</IonRow>
			</IonRow>
		);
	};
	const WhatIsThisContent = () => {
		return (
			<IonRow className="monitoring-row-leie">
				<IonRow
					className={`${
						leie?.possibleFound?.length! > 0
							? 'last-checked-leie'
							: 'last-checked-leie last-checked-leie-top ion-padding-top'
					}`}>
					<IonText>Last Checked:</IonText>
					<IonText className="span">{lastCheckedData()}</IonText>
				</IonRow>
				<IonRow className="last-checked-leie-act ion-padding-top">
					<IonText className="ion-padding-top">Active Monitoring:</IonText>
					<IonItem id="toggle" className="ion-no-padding no-background" lines="none">
						<IonToggle id="toggle" className="ion-no-margin" checked={true}></IonToggle>
						<IonLabel className="on-off-label-leie">On</IonLabel>
					</IonItem>
				</IonRow>
			</IonRow>
		);
	};
	const PreviousMatches = () => {
		return (
			<IonRow className="ion-margin">
				<IonRow
					className="full-row-cols padding-content previous-matches"
					onClick={() => {
						if (leie?.previousMatches?.length > 0) {
							setPreviousmatches(!previousMatches);
						}
					}}>
					<IonImg src={!previousMatches ? IconShowPreviousMatches : IconHidePreviousMatches}></IonImg>
					<IonText className="previous-matches-text">
						{leie?.previousMatches?.length == 0 ? ' No previous matches' : ' Show previous matches'}{' '}
					</IonText>
				</IonRow>
				{previousMatches && leie?.previousMatches?.length > 0 ? (
					<IonText className="previous-matches-description ion-margin">
						Below are exclusions that may match a name search. We confirmed none of these are you, and attached proof.
					</IonText>
				) : null}
				{leie?.previousMatches?.map((element, index) =>
					zoom && previousMatches ? (
						<AccordionLeieActions
							key={index}
							name={element.name}
							dob={element.dob}
							address1={element.address1}
							status={element.status}
							orderDate={moment(element.orderDate).format('MMM DD YYYY')}
							number={index + 1}
							type={3}
							attachments={element.attachments}></AccordionLeieActions>
					) : null
				)}
			</IonRow>
		);
	};
	const PossibleExclusionsFound = () => {
		return (
			<IonRow className="ion-margin">
				<div className="flex-column-leie">
					<IonText className="exclusion-posible ion-margin">Possible Exclusions Found</IonText>
					<IonText className="previous-matches-description ion-margin">
						Possible matches found. Our next step is confirm that these are not you so other agencies are not confused.
						This may take a few days.
					</IonText>
				</div>
				{leie?.possibleFound?.map((element, index) =>
					zoom ? (
						<AccordionLeieActions
							key={index}
							name={element.name}
							dob={element.dob}
							address1={element.address1}
							status={element.status}
							orderDate={moment(element.orderDate).format('MMM DD YYYY')}
							number={index + 1}
							type={1}></AccordionLeieActions>
					) : null
				)}
			</IonRow>
		);
	};
	const ExclusionsFound = () => {
		return (
			<IonRow className="ion-margin">
				<div className="flex-column-leie">
					<IonText className="exclusion-found ion-margin">Exclusions Found</IonText>
					<IonText className="previous-matches-description ion-margin">
						Exclusions prevent you from being able to work with the US Federal Government in any way including Medicare
						and Medicaid provider enrollment.
					</IonText>
				</div>
				{leie?.exclusionFound?.map((element, index) =>
					zoom ? (
						<AccordionLeieActions
							key={index}
							name={element.name}
							dob={element.dob}
							address1={element.address1}
							status={element.status}
							orderDate={moment(element.orderDate).format('MMM DD YYYY')}
							number={index + 1}
							type={2}></AccordionLeieActions>
					) : null
				)}
			</IonRow>
		);
	};
	const PaymentDescriptionContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<div className="ion-padding">
					{isPlatform('mobile') ? (
						<IonImg
							src={ActivateProfileDescImage}
							className="ion-margin"
							onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
					) : (
						<IonMenuToggle>
							<IonImg
								src={ActivateProfileDescImage}
								className="ion-margin"
								onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
						</IonMenuToggle>
					)}
				</div>
			</IonRow>
		);
	};
	return (
		<div className={back}>
			{!flip ? (
				!walletActivation?.payCompleted && !walletActivation?.approved ? (
					<IonRow className={`${zoom ? 'wrapper-zoom-leie' : ''}`}>
						<IonRow className="article-header__leie-actions">
							<IonRow className="category-title-leie">
								<IonText className="ion-padding-right">{title}</IonText>
							</IonRow>
						</IonRow>
						<IonRow className="full-row-cols padding-content">
							<IonImg src={ImageOig}></IonImg>
						</IonRow>
						<PaymentDescriptionContent />
					</IonRow>
				) : (
					<IonRow className={`${zoom ? 'wrapper-zoom-leie' : ''}`}>
						<IonRow className={`${!zoom ? 'hide-content-no-zoom' : ''}`}>
							<IonRow className="article-header__leie-actions">
								<IonRow className="category-title-leie">
									<IonText className="ion-padding-right">{title}</IonText>
								</IonRow>
								<IonRow id="about" onClick={() => onFlip()}>
									<IonImg id="about" className="icon-about-leie-oig" src={interrogation}></IonImg>
								</IonRow>
							</IonRow>
							<IonRow className="full-row-cols padding-content">
								<IonImg src={ImageOig}></IonImg>
							</IonRow>
							{leie?.possibleFound?.length > 0 ? null : <AllClearContent />}
							{leie?.exclusionFound?.length > 0 ? <ExclusionsFound /> : null}
							{leie?.possibleFound?.length > 0 ? <PossibleExclusionsFound /> : null}
							<WhatIsThisContent />
							<PreviousMatches />
						</IonRow>
					</IonRow>
				)
			) : (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssTextColor="about-white"
					cssTitleBg="leie-title-bg"
				/>
			)}
		</div>
	);
};

export default LEIE;
