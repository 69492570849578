import { IonButton, IonContent, IonImg, IonModal, IonRow, isPlatform } from '@ionic/react';
import loginLogo from '../../img/nuco-cred-wallet-logo.png';
import './AppVersion.css';

const AppVersionModal: React.FC<any> = (props: any) => {
	const { isModalOpen } = props;

	const isIos = isPlatform('ios');

	const redirectTo = () => {
		if (isIos) {
			window.open('https://apps.apple.com/app/nuco-credentialing-app/id1596109270', '_system');
		}
	};

	return (
		<IonModal id="app-version-modal" isOpen={isModalOpen}>
			<IonContent className="app-version-modal">
				<IonImg src={loginLogo} />
				<IonRow className="app-version-title">Update Available</IonRow>
				<IonRow className="app-version-text">There is a new version available of your NuCo wallet.</IonRow>
				<IonRow className="app-version-text">Please download it from your {isIos ? 'App Store' : 'Goole Play Store'} in order to have the latest updates.</IonRow>
				<IonRow className="auto-center ion-padding add-padding">
					<IonButton onClick={redirectTo} color={'secondary'} shape="round" type="button" className="sign-in-width">
						Update
					</IonButton>
				</IonRow>
			</IonContent>
		</IonModal>
	);
};

export default AppVersionModal;
