import moment from 'moment';
import { Subject } from 'rxjs';
import { BubbleModule } from '../shared/entity/bubble.module';
import { MessageModule } from '../shared/entity/message.module';
import API from '../shared/environement/api-routes';

import Interceptor from '../shared/environement/interceptor';
const messageNotificationSubject$ = new Subject();

const sendMessage = async (data: MessageModule.Message) => {
	try {
		const result = await Interceptor(`${API.MESSAGES}`, {
			method: 'POST',
			data,
		});
		const response: MessageModule.Message = result.data;
		return response;
	} catch (e) {
		throw e;
	}
};

const getMessages = async (id: string, ambassador: string, tenant: string) => {
	try {
		const result = await Interceptor.get(API.MESSAGES + `/conversation/${id}`, {});
		const response: Array<MessageModule.MessageGetResponse> = result.data;
		return { messages: response, bubbles: mapMessagesGetResponse(response, ambassador, tenant) };
	} catch (error) {
		throw error;
	}
};

const updateUnreadMessages = async (conversationId: string, ambassador: string) => {
	try {
		const result = await Interceptor(API.MESSAGES + `/${conversationId}/${ambassador}`, {
			method: 'PUT',
		});
		return result;
	} catch (error) {
		throw error;
	}
};

const mapMessagesGetResponse = (
	messages: Array<MessageModule.MessageGetResponse>,
	ambassador: string,
	tenant: string
): Array<BubbleModule.Bubble> => {
	const bubbles: Array<BubbleModule.Bubble> = [];

	messages.forEach((message: MessageModule.MessageGetResponse) => {
		const sentDate = moment(message.dateCreated.toString());
		const today = moment();

		let bubbleDate = '';
		let position = BubbleModule.BUBBLE_POSITION.LEFT;

		if (sentDate.format('MMMM DD YYYY') === today.format('MMMM DD YYYY')) {
			bubbleDate = 'Today, ';
		} else if (sentDate.format('MMMM DD YYYY') === today.subtract(1, 'day').format('MMMM DD YYYY')) {
			bubbleDate = 'Yesterday, ';
		} else {
			bubbleDate = `${sentDate.format('MMMM DD')}, `;
		}

		if (message.from === tenant) {
			position = BubbleModule.BUBBLE_POSITION.RIGHT;
		}

		bubbles.push({
			date: bubbleDate,
			time: sentDate.format('hh:mm A'),
			text: message.text,
			document: message?.attachment,
			isRead: message.status === MessageModule.MESSAGE_STATUS.READ,
			position,
			css: 'chatBubbleConversation',
		});
	});

	return bubbles;
};

export const messageService = {
	getMessages,
	sendMessage,
	updateUnreadMessages,
	messageNotificationSubject$,
};
