import { Dispatch } from "redux";
import { TabsConstants } from "../constants/tabsConstants";

export const tabsActions = {
    wallet,
    selected
};

function wallet(state : boolean){
    return async (dispatch : Dispatch) => {
        dispatch(wallet(state)); 
    };

    function wallet(payload : boolean) { return { type: TabsConstants.WALLET, payload } }
}
function selected(state : boolean){
    return async (dispatch : Dispatch) => {
        dispatch(cardSelected(state)); 
    };

    function cardSelected(payload : boolean) { return { type: TabsConstants.SELECTED, payload } }
}
