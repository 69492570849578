import { Dispatch } from 'redux';
import { ABMSEntity } from '../../shared/entity/abmsEntity';
import { AbmsConstants } from '../constants/abmsConstants';

export const AbmsActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let exclusions: ABMSEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'certificate' && element.certificateSource === 'ABMS') {
					const exclusion: ABMSEntity = {
						isPrimary: element.isPrimary,
						certifiedBoardName: element.certifiedBoardName,
						description: element.description,
						expirationDate: element.expirationDate,
						specialty: element.specialty,
						status: element.status,
						effectiveDate: element.effectiveDate,
						certificateType: element.certificateType,
					};
					exclusions.push(exclusion);
				}
			});

			if (exclusions) {
				dispatch(set({ abms: exclusions, info: document?.info || '' }));
			}
		}
	};

	function set(payload: any) {
		return { type: AbmsConstants.SET_ABMS, payload };
	}
}
