import {
	IonButton,
	IonCol,
	IonContent,
	IonIcon,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import IconUpload from '../../img/addimage_icon.svg';
import arrowBack from '../../img/icons/backArrow.svg';

import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import IconDocument from '../../img/document_icon.svg';
import { walletActions } from '../../redux/actions';
import { filesService } from '../../services/files.service';
import { DEAEntity } from '../../shared/entity/deaEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './dea-license.css';

interface ContainerProps {
	prop: RouteComponentProps;
}
const DeaLicense: React.FC<ContainerProps> = (props) => {
	const { prop } = props;
	const dispatch = useDispatch();
	const [deaLicense, setDeaLicense] = useState<DEAEntity>();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const [credentials, setCredentials] = useState<any>();
	const [front, setFront] = useState<any>();
	const [back, setBack] = useState<any>();
	const [newLicense, setNewLicense] = useState(true);
	const { photos, takePhoto, takeSelfie, license, cleanPhotos } = usePhotoGallery();
	const [document, setDocument] = useState();
	const [showLoading, setShowLoading] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');
	const [pdfData, setPdfData] = useState<any>();
	const [showToastAttachments, setShowToastAttachments] = useState(false);

	const navigate = (route: string) => {
		prop.history.replace(route);
	};

	useEffect(() => {
		if (prop?.history?.location?.state !== null) {
			const data = prop?.history?.location?.state as any;
			if (data?.deaLicense?.imgAttachment && data?.deaLicense?.imgAttachment.length > 0) {
				photos.push({
					base64String: data?.deaLicense?.imgAttachment[0].downloadUrl,
					filepath: '',
					type: 'front',
				});
				photos.push({
					base64String: data?.deaLicense?.imgAttachment[1].downloadUrl,
					filepath: '',
					type: 'back',
				});
				setBack(data?.deaLicense?.imgAttachment[1]);
				setFront(data?.deaLicense?.imgAttachment[0]);
			}
			if (data?.deaLicense?.pdfData && Object.keys(data?.deaLicense?.pdfData).length > 0) {
				setPdfData(data?.deaLicense?.pdfData);
			}
			setDeaLicense(data?.deaLicense);
		}
	}, []);
	useEffect(() => {
		const tenantId = user.id;
		filesService.documentType(tenantId!, 'dea-number').then(
			(res) => {
				setDocument(res.data.id);
			},
			(error) => {}
		);
	}, []);

	//manage the back image scanned from blink Id
	useEffect(() => {
		if (photos.length > 0 && photos[0].filepath !== '') {
			const tenantId = user.id;
			let uuid = uuidv4();
			let fileName = '';
			if (photos[0].type === 'front') {
				fileName = `${uuid}` + '-dea-license.png';
				saveImageScan(fileName, uuid, tenantId!, 'front');
			} else {
				fileName = `${uuid}` + '-dea-license-back.png';
				saveImageScan(fileName, uuid, tenantId!, 'back');
			}
		}
	}, [photos]);
	//save the image scanned form blink id
	function saveImageScan(fileName: string, uuid: string, tenantId: string, type: string, pdfFile?: FileList) {
		try {
			setShowLoading(true);
			filesService.credentials(tenantId!, fileName, 'documentAttachments').then(
				(res) => {
					console.log(res);
					setCredentials(res.data.uploadCredentials);
					let formData = new FormData();
					formData.append('key', res.data.uploadCredentials?.fields?.key);
					formData.append('policy', res.data.uploadCredentials?.fields?.policy);
					formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
					formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
					formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
					formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
					if (type === 'pdf') {
						formData.append('file', pdfFile![0], fileName);
					} else {
						formData.append('file', photos[0].blob, fileName);
					}
					filesService.upload(res.data.uploadCredentials?.url, formData).then(
						(response) => {
							if (type === 'front') {
								setFront({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: 'dea-license.png',
									privateUrl: res.data.privateUrl,
									publicUrl: null,
									new: true,
									size: photos[0].blob.size,
								});
							} else if (type === 'back') {
								setBack({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: 'dea-license-back.png',
									privateUrl: res.data.privateUrl,
									publicUrl: null,
									new: true,

									size: photos[0].blob.size,
								});
							} else {
								setPdfData({
									createdAt: new Date(),
									downloadUrl: res.data.downloadUrl,
									id: uuid,
									name: pdfFile![0].name,
									privateUrl: res.data.privateUrl,
									new: true,
									publicUrl: null,
								});
							}
							setShowLoading(false);
						},
						(error) => {
							console.log(error);
							setShowLoading(false);
							setToastMessageError('Something went wrong,please try again.');
							setShowToastAttachments(true);
						}
					);
				},
				(error) => {
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			);
		} catch (error) {
			console.log(error);
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	}
	const submit = (e: any) => {
		try {
			e.preventDefault();
			setShowLoading(true);
			if (validateFieldsAndShowError()) {
				let attach: any[] = [];
				const tenantId = user.id;
				if (front) {
					deaLicense!.frontPictureUrl = front.downloadUrl;
					attach.push(front);
				}
				if (back) {
					deaLicense!.backPictureUrl = back.downloadUrl;
					attach.push(back);
				}
				if (pdfData) {
					attach.push(pdfData);
				}
				const dataUpdate = {
					attachments: attach,
					type: document,
					unlimitedCoverage: false,
				};
				filesService.updateDocuments(tenantId!, dataUpdate, deaLicense!.id!).then(
					async (res) => {
						if (res?.data) {
							setShowLoading(false);
							setToastMessageError('Data updated successfully');
							setShowToastAttachments(true);
							await dispatch(walletActions.updateWallet(data, deaLicense!.id, dataUpdate));
							navigate('/tabs/wallet');
						} else {
							setShowLoading(false);
							setToastMessageError('Something went wrong,please try again.');
							setShowToastAttachments(true);
						}
					},
					(error) => {
						setShowLoading(false);
						setToastMessageError('Something went wrong,please try again.');
						setShowToastAttachments(true);
					}
				);
			}
		} catch (error) {
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	};
	function sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	function validateFieldsAndShowError() {
		if (!pdfData && !front && !back) {
			setShowLoading(false);
			setToastMessageError('Please select an image or a document to upload.');
			setShowToastAttachments(true);
			return false;
		}
		if ((front && !back) || (back && !front)) {
			setShowLoading(false);
			setToastMessageError('PLease upload both sides of the image.');
			setShowToastAttachments(true);
			return false;
		}
		return true;
	}

	//handle the file that is selected on the device
	function onUploadFile(files: FileList | null, name: string): void {
		if (fileAlreadyExist(files)) {
			setShowToastAttachments(true);
		} else {
			const uuid = uuidv4();
			const fileName = `${uuid}` + files![0].name;
			saveImageScan(fileName, uuid, user.id!, 'pdf', files!);
		}
	}
	//validates if the file is already added
	function fileAlreadyExist(files: FileList | null) {
		return pdfData?.name === files?.[0].name;
	}

	function existImage(type: string) {
		return photos.findIndex(function (element: any) {
			return element.type === type;
		});
	}
	return (
		<IonPage className="top-page ion-justify-content-center">
			<IonContent className="ion-padding ion-justify-content-center max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => prop.history.goBack()}></IonIcon>{' '}
						Choose what to upload
					</IonTitle>
				</IonToolbar>
				<IonRow className="upload-main-container-dea">
					<IonRow className="ion-no-border row-pics-dea">
						<IonRow className="flex-col-dea ion-margin-bottom">
							<h3>Image</h3>
							<IonText>Take separates pictures from both sides</IonText>
						</IonRow>
						<IonCol size="12" className="flex-row-dea">
							<IonCol size="6" className="flex-col-md-dea">
								{existImage('front') < 0 ? (
									<div className="upload-media-container-dea" onClick={() => takePhoto('front')}>
										<img className="ion-margin-bottom" src={IconUpload} />

										<IonText>Front</IonText>
									</div>
								) : (
									<div onClick={() => takePhoto('front')}>
										<div className="upload-media-container-no-border-dea">
											<img className="img-width-dea" src={photos[existImage('front')].base64String} />
										</div>
										<IonRow className="container-edit-dea-text">
											<IonRow className="edit-dea-text">
												<IonText>EDIT PICTURE</IonText>
											</IonRow>
										</IonRow>
									</div>
								)}
							</IonCol>
							<IonCol size="6" className="flex-col-md-dea">
								{existImage('back') < 0 ? (
									<div className="upload-media-container-dea" onClick={() => takePhoto('back')}>
										<img className="ion-margin-bottom" src={IconUpload} />

										<IonText>Back</IonText>
									</div>
								) : (
									<div onClick={() => takePhoto('back')}>
										<div className="upload-media-container-no-border-dea">
											<img className="img-width-dea" src={photos[existImage('back')].base64String} />
										</div>
										<IonRow className="container-edit-dea-text">
											<IonRow className="edit-dea-text">
												<IonText>EDIT PICTURE</IonText>
											</IonRow>
										</IonRow>
									</div>
								)}
							</IonCol>
						</IonCol>
					</IonRow>
					<div className="row-pics-dea-no-bg">
						<h3>or</h3>
					</div>
					<IonRow className="ion-no-border row-pics-dea">
						<IonRow className="flex-col-dea ion-margin-bottom">
							<h3>Document</h3>
							<IonText>Tap below to select a document from your files</IonText>
						</IonRow>
						<IonRow className="content-images-dea-upload">
							{!pdfData ? (
								<div className="upload-media-container-dea">
									<input
										id="file"
										hidden
										type="file"
										accept="application/pdf"
										onChange={(e) => onUploadFile(e.target.files, deaLicense?.id!)}
									/>
									<label htmlFor="file">
										{' '}
										<img src={IconDocument} />
									</label>
								</div>
							) : (
								<div>
									<div className="upload-media-container-no-border-pdf-dea">
										<input
											id="file"
											hidden
											type="file"
											accept="application/pdf"
											onChange={(e) => onUploadFile(e.target.files, deaLicense?.id!)}
										/>
										<label htmlFor="file">
											{' '}
											{pdfData && <IonText className="overflow-text">{pdfData.name}</IonText>}
										</label>
									</div>
									{pdfData && (
										<IonRow className="container-edit-dea-text">
											<IonRow className="edit-dea-text">
												<IonText>EDIT PICTURE</IonText>
											</IonRow>
										</IonRow>
									)}
								</div>
							)}
						</IonRow>
					</IonRow>

					<div className="flex-col-dea ion-margin">
						<IonLabel className=" upload__main-title-dea">Note:</IonLabel>
						<IonLabel>
							This is used for proof of ability to dispense if audited by DEA or any other local, state or federal
							agencies.
						</IonLabel>
					</div>
					<IonRow className="uploadFileRow-dea ion-margin-top">
						<IonText className="cancel-btn-dea" onClick={() => prop.history.goBack()}>
							Cancel
						</IonText>
						<IonButton onClick={submit} color="secondary" shape="round">
							Continue
						</IonButton>
					</IonRow>
				</IonRow>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
				<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
			</IonContent>
		</IonPage>
	);
};

export default DeaLicense;
