import { UserEntity } from '../shared/entity/userEntity';
import API from '../shared/environement/api-routes';
import Interceptor from '../shared/environement/interceptor';
export const userService = {
	logout,
	userSignIn,
	register,
	userAuthorization,
	updateUser,
	changePassword,
	userTasks,
};

async function userSignIn(user: UserEntity, recaptchaToken: any) {
	try {
		const data = {
			email: user.email,
			password: user.password,
			recaptchaToken,
		};
		return await Interceptor(API.SIGN_IN, {
			method: 'post',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem('user');
}

function register(user: any) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(user),
	};

	return fetch(`/users/register`, requestOptions).then(handleResponse);
}

async function userAuthorization(token: string) {
	try {
		return await Interceptor.get(API.AUTH_ME);
	} catch (error) {
		console.log(error);
		return Promise.reject(error);
	}
}
async function userTasks(tenant: string, token: string, provider: string) {
	try {
		return await Interceptor.get(API.TENANT + `/${tenant}/provider/${provider}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function updateUser(data: any) {
	try {
		return await Interceptor(API.TENANT + `/${data.data.id}/contact/${data.data.contactId}`, {
			method: 'PUT',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function changePassword(data: any) {
	try {
		return await Interceptor(API.CHANGE_PASSWORD, {
			method: 'PUT',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
function handleResponse(response: { text: () => Promise<any>; ok: any; status: number; statusText: any }) {
	return response.text().then((text: string) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				logout();
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}
