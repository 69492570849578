import { ActionEntity } from "../../shared/entity/actionEntity";
import { LeieActionsEntity } from "../../shared/entity/leie.Entity";
import { LeieConstants } from "../constants";

const initialUser: LeieActionsEntity[] = [];
const leieReducer = (state: LeieActionsEntity[] = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case LeieConstants.SET_LEIE:
                return action.payload;          
        default:
            return state;
    }
}
export default leieReducer;