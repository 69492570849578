import React, { useState } from 'react';
import { 
    IonRow,
    IonCol,
    IonText,
    IonImg
   } from '@ionic/react';

import './AccordionBoardActions.css'
import { AccordionABMSEntity } from '../../shared/entity/accordionABMS.module';
import ChevronDownBlue from '../../img/icon-chevron-down-blue.svg'
import ChevronUpBlue from '../../img/icon-chevron-up-blue.svg'


  const AccordionBoardActions: React.FC<AccordionABMSEntity> = ({ title, num, date, children  }) => {

    const [isOpen, setOpen] = useState(false);

    return(
        <IonRow className=" full-row-cols">
        <IonRow 
          className={`accordion-title-board-actions ${isOpen ? "open" : ""}`}
          >
       
          <IonRow id="row" onClick={() => setOpen(!isOpen)} className="card-row-board-actions">
          <IonCol size="10" offset='2' id="row">
                  <IonText id="row" className=" order-date-title-actions-accordeon">{date}</IonText>
              </IonCol>
                <IonCol id="row" size="2">
                  <div id="row" className="number-circle-board-actions">
                    <span id="row">
                        {num}
                    </span>
                  </div>
                </IonCol >
                 <IonCol size="8" id="row">
                    <IonText id="row" className="span title uppercase-title">{title}</IonText>
             </IonCol>
                <IonCol  id="row" size="2" className="center-icon-col">
                    {isOpen ? <IonImg id="row" className="row-icon" src={ChevronUpBlue} /> : <IonImg id="row" className="row-icon" src={ChevronDownBlue} />} 
                </IonCol>
            
            </IonRow>
          
        </IonRow>
      {isOpen?
        <IonRow  className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <IonCol  offset='2' size='8'>
        <IonRow className="">{children}</IonRow>
        </IonCol>
      </IonRow>:null}
      </IonRow>
    )
  };
  
  export default AccordionBoardActions;
  