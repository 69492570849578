import {
	IonButton,
	IonCardSubtitle,
	IonCardTitle,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonToast,
	ToastOptions,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import IconCamera from '../../img/icon-camera-white.svg';
import IconUpload from '../../img/icons/add_camera.svg';

import { useDispatch, useSelector } from 'react-redux';
import { onBoardingPhotoActions } from '../../redux/actions/onBoardingPhotoActions';
import { filesService } from '../../services/files.service';
import { onBoardingService } from '../../services/onBoarding.service';
import { UserEntity } from '../../shared/entity/userEntity';
import './upload.css';

const Upload: React.FC<{ type: string }> = ({ type }) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const wallet = useSelector<any, any>((state) => state.wallet.data!);
	const { photos, takePhoto, takeSelfie, license } = usePhotoGallery();
	const [front, setFront] = useState<any>();
	const [back, setBack] = useState<any>();
	const [showLoading, setShowLoading] = useState(true);
	const [document, setDocument] = useState();
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	useEffect(() => {
		if (wallet) {
			const tenantId = wallet?.tenants[0]?.tenant.id;
			filesService.documentType(tenantId, type).then(
				(res) => {
					setDocument(res.data.id);
					setShowLoading(false);
				},
				(error) => {
					handleErrorMessage('ERROR GETTING DOCUMENT TYPE DL');
					setShowLoading(false);
				}
			);
		}
	}, [wallet]);
	useEffect(() => {
		uploadPhoto();
	}, [photos]);
	const uploadPhoto = async () => {
		try {
			if (photos.length === 0 || photos[0]?.filepath === '') {
				return;
			}
			if (!document) {
				photos.length = 0;
				handleErrorMessage('ERROR GETTING DOCUMENT TYPE DL');
				return;
			}
			const tenantId = wallet?.tenants[0]?.tenant.id;
			const uuid = uuidv4();
			const fileName = `${uuid}-${type}.png`;

			const res = await filesService.credentials(tenantId!, fileName, 'documentAttachments');

			const formData = new FormData();
			formData.append('key', res.data.uploadCredentials?.fields?.key);
			formData.append('policy', res.data.uploadCredentials?.fields?.policy);
			formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
			formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
			formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
			formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
			formData.append('file', photos[0]?.blob, fileName);

			const shortType = type === 'driver-license' ? 'dl' : 'pp';
			dispatch(onBoardingPhotoActions.setPhoto(photos[0], shortType));

			await filesService.upload(res.data.uploadCredentials?.url, formData);

			const attach = [
				{
					createdAt: new Date(),
					downloadUrl: res.data.downloadUrl,
					id: uuid,
					name: `${type}.png`,
					privateUrl: res.data.privateUrl,
					publicUrl: null,
					new: true,
					size: photos[0].blob.size,
				},
			];

			const data = {
				data: {
					address1: license?.address1,
					attachments: attach,
					birthDate: license?.birthDate,
					expirationDate: license?.expirationDate,
					firstName: license?.firstName,
					lastName: license?.lastName,
					middleName: license?.middleName,
					number: license?.number,
					state: license?.state,
					type: document,
				},
			};

			const licenseId = await driverLicenseID();
			let resData: any = '';
			if (licenseId && licenseId?.[0]) {
				resData = await filesService.updateDocuments(tenantId!, data.data, licenseId[0].id!);
			} else {
				resData = await onBoardingService.uploadFile(tenantId, data);
			}
			let position = -1;
			position = await driverLicensePosition();
			if (resData?.data) {
				wallet?.tenants[0].provider?.professionalLicenses.splice(position, 1);
				wallet?.tenants[0].provider?.professionalLicenses.push(resData.data);
				setShowLoading(false);
			}
		} catch (error) {
			photos.shift();
			handleErrorMessage(error);
		}
	};
	const driverLicenseID = () => {
		const driverLicenseId = wallet?.tenants[0].provider?.professionalLicenses.filter(
			(data: any) => data.type.key === 'driver-license'
		);
		return driverLicenseId;
	};
	const driverLicensePosition = () => {
		const professionalLicenses = wallet?.tenants[0]?.provider?.professionalLicenses || [];
		const index = professionalLicenses.findIndex((data: any) => data.type.key === 'driver-license');
		return index !== -1 ? index : -1;
	};
	const handleErrorMessage = (error: any) => {
		setShowLoading(false);
		console.log('ERROR UPLOADING IMAGE:', error);
		showToast({ color: 'danger', header: 'Failure', message: 'Oops an Error Ocurred, try again.' });
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	return (
		<IonPage>
			<IonCardTitle className="uploadTitle">Step 1</IonCardTitle>
			<IonCardSubtitle className="uploadSubTitle">Take a picture of your drivers license or passport</IonCardSubtitle>
			<IonRow className="upload-onboarding-main-container ion-align-items-center">
				<IonRow className="ion-no-border row-pics">
					{!photos[0] ? (
						<div className="upload-media-container">
							<div className="upload-area">
								<img src={IconUpload} />
							</div>
							<IonButton color="primary" shape="round" onClick={() => takePhoto('front')}>
								<IonIcon slot="start" icon={IconCamera} />
								Take Photo
							</IonButton>
						</div>
					) : (
						<div className="upload-media-container">
							<img src={photos[0].base64String} />
						</div>
					)}
				</IonRow>
			</IonRow>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
			/>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
		</IonPage>
	);
};
export default Upload;
