import { Dispatch } from 'redux';
import { DriverLicenseEntity } from '../../shared/entity/driverLicenseEntity';
import { DriverLicenseConstants } from '../constants';

export const DriverLicenseActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			const license = data.tenants[0].provider.professionalLicenses.find(
				(element: any) => element.type?.key === 'driver-license'
			);
			if (license) {
				const driverLicense: DriverLicenseEntity = {
					number: license.number,
					state: license.stateOrProvince || license.state,
					expirationDate: license.expirationDate,
					address: license.address1,
					attachments: license.attachments,
					frontPictureUrl: license.attachments?.[0]?.downloadUrl,
					backPictureUrl: license.attachments?.[1]?.downloadUrl,
					description: license.type.description,
					id: license.id,
					info: document?.info || '',
				};
				dispatch(set(driverLicense));
			}
		}
	};

	function set(payload: any) {
		return { type: DriverLicenseConstants.SET_DRIVER_LICENSE, payload };
	}
}
