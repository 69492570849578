import { ActionEntity } from '../../shared/entity/actionEntity';
import { MedicaidCardEntity } from '../../shared/entity/medicaidCardEntity';
import { MedicaidCardConstant } from '../constants/medicaidCardConstants';

const initialUser = {
	mcaid: [] as MedicaidCardEntity[],
	info: '',
};
const medicaidCardReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case MedicaidCardConstant.SET_MEDICAID_CARD:
			return action.payload;
		default:
			return state;
	}
};
export default medicaidCardReducer;
