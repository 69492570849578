import { IonIcon, IonImg, IonItem, IonLabel, IonRow, IonText, IonToggle } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../../components/Alert/Alerts';
import IconAllClear from '../../../img/icon-all-clear.svg';
import IconHidePreviousMatches from '../../../img/icon-hide-previous-matches-sam.svg';
import IconShowPreviousMatches from '../../../img/icon-show-previous-matches-sam.svg';
import interrogation from '../../../img/icons/interrogation-black.svg';
import TradeMarkLogoSam from '../../../img/trademark-logo-sam.png';
import { SamActions } from '../../../redux/actions/samActions';
import { filesService } from '../../../services/files.service';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { SamEntity } from '../../../shared/entity/samEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionSamActions from '../../AccordionSamActions/AccordionSamActions';
import './SamExclusions.css';
const SamExclusions: React.FC<CardInfoEntity> = ({ title, zoom, props, showShareCard }) => {
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const samActions = useSelector<any, SamEntity>((state) => state.samExclusion!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card-sam card-sam-actions');
	const [showAlert, setAlert] = useState(false);
	const [documentDescription, setDocumentDescription] = useState(samActions?.info);
	const [previousMatches, setPreviousmatches] = useState(false);
	useEffect(() => {
		if (data) {
			dispatch(SamActions.setLicense(data, samActions));
		}
	}, [data]);
	useEffect(() => {
		if (samActions?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'exclusion').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								res.data.documentFields.forEach((element: any) => {
									if (element.key === 'samdesc') {
										setDocumentDescription(element.description);
										dispatch(SamActions.setLicense(data, { info: element.description }));
									}
								});
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [samActions]);
	useEffect(() => {
		if (samActions?.info !== '') {
			setDocumentDescription(samActions.info);
		}
	}, [samActions, documentDescription]);
	function lastCheckedData(): string {
		if (data?.tenants[0]?.provider?.tasks) {
			let tasks: string[] = [];
			data.tenants[0].provider.tasks.forEach((element: any) => {
				if (
					(element.assignedTo === data.tenants[0].provider.user.id &&
						element.taskTemplate === '62fbefb9dc20481cd96b3253' &&
						element.status === 'done') ||
					element.status === 'archived'
				) {
					tasks.push(element.doneAt);
				}
			});
			const newarr = tasks.slice().sort((a, b) => {
				return moment(b).diff(a);
			});
			if (newarr.length > 0) {
				return moment(newarr[0]).format('MMM DD YYYY HH:mm a').toString();
			}
		}
		return data?.tenants[0]?.provider
			? moment(data.tenants[0].provider.samExclusionUpdatedAt).format('MMM DD YYYY HH:mm a').toString()
			: 'Search Error Ocurred';
	}
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card-sam card-sam-actions');
		} else {
			setBack('card-sam card-sam-actions dlNoRotate');
		}
	};
	const AllClearContent = () => {
		return (
			<IonRow className="full-row-cols padding-content-sam">
				<IonRow className="accordion-wrapper-sam">
					<IonImg src={IconAllClear}></IonImg>
					<IonText className="all-clear-title-sam"> All Clear</IonText>
				</IonRow>
			</IonRow>
		);
	};
	const LastCheckedContent = () => {
		return (
			<IonRow
				className={`accordion-wrapper-sam full-row-cols ${
					samActions?.possibleFound?.length! > 0 ? 'padding-content-sam' : ''
				}`}>
				<IonRow className="all-clear-top">
					<IonText className="text-sam">Last Checked:</IonText>
					<IonText className="">{lastCheckedData()}</IonText>
				</IonRow>
				<IonRow className="">
					<IonText className="ion-padding-top text-sam">Active Monitoring:</IonText>
					<IonItem id="toggle" className="ion-no-padding no-background" lines="none">
						<IonToggle id="toggle" className="ion-no-margin" checked={false}></IonToggle>
						<IonLabel className="on-off-label-sam">On</IonLabel>
					</IonItem>
				</IonRow>
			</IonRow>
		);
	};
	const PreviousMatches = () => {
		return (
			<IonRow className="ion-margin width-samexc">
				<IonRow
					className="full-row-cols padding-content-sam previous-matches-sam content-center-sam-exc"
					onClick={() => {
						if (samActions?.previousMatches?.length > 0) {
							setPreviousmatches(!previousMatches);
						}
					}}>
					<IonImg src={!previousMatches ? IconShowPreviousMatches : IconHidePreviousMatches}></IonImg>
					<IonText className="previous-matches-text-sam">
						{samActions?.previousMatches?.length == 0 ? ' No previous matches' : ' Show previous matches'}{' '}
					</IonText>
				</IonRow>
				{previousMatches && samActions?.previousMatches?.length > 0 ? (
					<IonText className="previous-matches-description-sam ion-margin">
						Below are exclusions that may match a name search. We confirmed none of these are you, and attached proof.
					</IonText>
				) : null}
				{samActions?.previousMatches?.map((element, index) =>
					zoom && previousMatches ? (
						<AccordionSamActions
							key={index}
							name={element.name}
							dob={element.dob}
							address1={element.address1}
							status={element.status}
							orderDate={moment(element.orderDate).format('MMM DD YYYY')}
							number={index + 1}
							type={3}
							title={element.samExclusionType}
							attachments={element.attachments}></AccordionSamActions>
					) : null
				)}
			</IonRow>
		);
	};
	const PossibleExclusionsFound = () => {
		return (
			<IonRow className="ion-margin">
				<div className="flex-column-sam">
					<IonText className="exclusion-posible-sam ion-margin">Possible Exclusions Found</IonText>
					<IonText className="previous-matches-description-sam ion-margin">
						Possible matches found. Our next step is confirm that these are not you so other agencies are not confused.
						This may take a few days.
					</IonText>
				</div>
				{samActions?.possibleFound?.map((element, index) =>
					zoom ? (
						<AccordionSamActions
							key={index}
							name={element.name}
							dob={element.dob}
							address1={element.address1}
							status={element.status}
							orderDate={moment(element.orderDate).format('MMM DD YYYY')}
							number={index + 1}
							title={element.samExclusionType}
							type={1}></AccordionSamActions>
					) : null
				)}
			</IonRow>
		);
	};
	const ExclusionsFound = () => {
		return (
			<IonRow className="ion-margin">
				<div className="flex-column-sam">
					<IonText className="exclusion-found-sam ion-margin">Exclusions Found</IonText>
					<IonText className="previous-matches-description-sam ion-margin">
						Exclusions prevent you from being able to work with the US Federal Government in any way including Medicare
						and Medicaid provider enrollment.
					</IonText>
				</div>
				{samActions?.exclusionFound?.map((element, index) =>
					zoom ? (
						<AccordionSamActions
							key={index}
							name={element.name}
							dob={element.dob}
							address1={element.address1}
							status={element.status}
							orderDate={moment(element.orderDate).format('MMM DD YYYY')}
							number={index + 1}
							title={element.samExclusionType}
							type={2}></AccordionSamActions>
					) : null
				)}
			</IonRow>
		);
	};
	const cancel = () => {
		setAlert(false);
	};
	//submit form
	const submit = () => {
		props!.history.push('/tabs/ambassador');
	};
	const btnAlert = [
		{
			text: `Yes. Contact Ambassador.`,
			handler: submit,
			cssClass: 'submitBtnTask centerBtnText',
		},
		{
			text: `No problem.`,
			handler: cancel,
			cssClass: 'cancelAlertSam centerBtnText',
		},
	];

	return (
		<div className={`${back} bg-image`}>
			{!flip ? (
				<IonRow className={`${zoom ? 'wrapper-zoom-sam' : ''}`}>
					<IonRow className={`${!zoom ? 'hide-content-no-zoom-sam' : ''}`}>
						<IonRow className="header-sam-actions">
							<IonRow className="sam-title">
								<IonText>{title}</IonText>
							</IonRow>
							<IonIcon
								id="about"
								className="icon-about-sam-exclusion"
								icon={interrogation}
								onClick={() => onFlip()}></IonIcon>
						</IonRow>
						<IonRow className="full-row-cols padding-content margin-twenty-sam">
							<IonImg src={TradeMarkLogoSam}></IonImg>
						</IonRow>
						{samActions?.possibleFound?.length > 0 ? null : <AllClearContent />}
						{samActions?.exclusionFound?.length > 0 ? <ExclusionsFound /> : null}
						{samActions?.possibleFound?.length > 0 ? <PossibleExclusionsFound /> : null}
						<LastCheckedContent />
						<PreviousMatches />

						<Alert
							showAlert={showAlert}
							setAlert={setAlert}
							headerText="Is there a problem?"
							message="If there a problem with the information on this card, please tell your Credientialing Ambassador so we can investigate and fix it."
							buttons={btnAlert}
							inputs={[]}
							cssClass="my-custom-class"
						/>
					</IonRow>
				</IonRow>
			) : (
				<AboutThat
					onFlip={onFlip}
					description={documentDescription}
					title={title}
					cssBackground={`sam-background `}
					cssTitleBg="header-sam-actions"
					cssTextColor="about-black"
				/>
			)}
		</div>
	);
};
export default SamExclusions;
