import { IonContent, IonLoading, IonModal, IonRow, IonToast } from '@ionic/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { walletActions } from '../../redux/actions';
import { PendingActionsActions } from '../../redux/actions/pendingActions';
import { taskService } from '../../services';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './ExpirationReminder.css';

const ExpirationReminderHelpMeModal: React.FC<any> = (props: any) => {
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const { provider, clearFields } = props;
	const user = useSelector<ReduxStateEntity, UserEntity>((state) => state.user!);
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');
	function updateReminderTask() {
		setShowLoading(true);
		let fields: any = {
			assignedTo: data?.tenants[0]?.provider?.ambassadors[0]?.id,
		};
		taskService.updateTask(user.id!, fields, provider.id).then(
			async (res) => {
				if (res?.data) {
					setShowLoading(false);
					await dispatch(walletActions.updateAmbassadorAseginedToTask(data, provider.id, fields.assignedTo));
					await dispatch(PendingActionsActions.setPendingActions(data));
					clearFields();
				} else {
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			},
			(error) => {
				setShowLoading(false);
				setToastMessageError('Something went wrong,please try again.');
				setShowToastAttachments(true);
			}
		);
	}

	return (
		<div>
			<IonModal id="exp-reminder-modal" isOpen={true} backdropDismiss={false}>
				<IonContent className="exp-reminder-modal">
					<div className="exp-space-between">
						<IonRow className="exp-reminder-title">We’ve got your back!</IonRow>
						<IonRow className="exp-reminder-text">
							A member of our team will contact you shortly to help you in this process.
						</IonRow>
						<IonRow className="auto-center ion-padding close-help-modal-text" onClick={() => updateReminderTask()}>
							close
						</IonRow>
					</div>
				</IonContent>
			</IonModal>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
			/>
			<IonToast
				isOpen={showToastAttachments}
				onDidDismiss={() => setShowToastAttachments(false)}
				message={toastMessageError}
				duration={3000}
			/>
		</div>
	);
};

export default ExpirationReminderHelpMeModal;
