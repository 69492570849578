import {
	IonBadge,
	IonButton,
	IonButtons,
	IonFooter,
	IonIcon,
	IonLoading,
	IonPage,
	IonRouterOutlet,
	IonToast,
	IonToolbar,
	useIonToast,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Route, RouteComponentProps, useLocation } from 'react-router-dom';
import activeActions from '../../img/main-menu/icon-tab-actions-active.svg';
import actions from '../../img/main-menu/icon-tab-actions.svg';
import activeAmbassador from '../../img/main-menu/icon-tab-ambassador-active.svg';
import ambassador from '../../img/main-menu/icon-tab-ambassador.svg';
import activeHistory from '../../img/main-menu/icon-tab-history-active.svg';
import history from '../../img/main-menu/icon-tab-history.svg';
import activeWallet from '../../img/main-menu/icon-tab-my-wallet-active.svg';
import wallet from '../../img/main-menu/icon-tab-my-wallet.svg';
import activeSettings from '../../img/main-menu/icon-tab-settings-active.svg';
//css
import settings from '../../img/main-menu/icon-tab-settings.svg';
import ActivateWalletPage from '../../pages/activateWallet/activateWalletPage';
import CAQH from '../../pages/caqh/CAQH';
import ChangePassword from '../../pages/change-password/change-password';
import Chat from '../../pages/chat/chat';
import DEALicense from '../../pages/dea-license/dea-license';
import DeleteAccount from '../../pages/delete-account/DeleteAccount';
import DriversLicense from '../../pages/drivers-license/DriversLicense';
import History from '../../pages/history/History';
import MissingDocument from '../../pages/missingDocument/MissingDocument';
import MyProfile from '../../pages/my-profile/MyProfile';
import MyWallet from '../../pages/my-wallet/MyWallet';
import PassportFile from '../../pages/passportFile/passportFile';
import PaymentPage from '../../pages/paymentPage/paymentPage';
import PendingActions from '../../pages/pending-actions/PendingActions';
import PrivacyAndSecurity from '../../pages/privacy-and-security/PrivacyAndSecurity';
import Questionnaire from '../../pages/questionnaire/Questionnaire';
import Settings from '../../pages/settings/Settings';
import VerifyIdentity from '../../pages/verifyIdentity/verifyIdentity';
import { notificationActions } from '../../redux/actions/notificationActions';
import { PendingActionsActions } from '../../redux/actions/pendingActions';
import { tabsActions } from '../../redux/actions/tabsActions';
import { walletActions } from '../../redux/actions/walletActions';
import { conversationService } from '../../services/conversation.service';
import { messageService } from '../../services/message.service';
import { pushNotificationService } from '../../services/pushNotifications.service';
import { PendingEntity } from '../../shared/entity/pendingTaskEntity';
import { PushNotificationModule } from '../../shared/entity/pushNotification.module';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import '../../theme/main-style.css';
import './MainTabs.css';
const MainTabs: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	let location = useLocation();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const [showLoading, setShowLoading] = useState(false);
	const [settingsIcon, setSettingsIcon] = useState(settings);
	const [walletIcon, setWalletIcon] = useState(activeWallet);
	const [historyIcon, setHistoryIcon] = useState(history);
	const [ambassadorIcon, setAmbassadorIcon] = useState(ambassador);
	const [profileIcon, setProfileIcon] = useState(actions);
	const resolved = useSelector<any, any>((state) => state.login.resolved!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [selectedClass, setSelectedClass] = useState('wallet');
	const [toast, setToast] = useState<ToastOptions>({});
	const notificationConversationCount = useSelector<any, any>(
		(state) => state.notification.conversationNotificationCount!
	);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [present, dismiss] = useIonToast();
	console.log('MAIN TABS');
	const navigate = (route: string, pushToHistory: boolean) => {
		switch (route) {
			case '/settings':
				setSettingsIcon(activeSettings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setProfileIcon(actions);
				setSelectedClass('settings');

				break;
			case '/wallet':
				setWalletIcon(activeWallet);
				setSettingsIcon(settings);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setProfileIcon(actions);
				setSelectedClass('wallet');
				dispatch(tabsActions.wallet(true));
				break;
			case '/history':
				setHistoryIcon(activeHistory);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setAmbassadorIcon(ambassador);
				setProfileIcon(actions);
				setSelectedClass('history');
				break;
			case '/chat':
				setAmbassadorIcon(activeAmbassador);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setProfileIcon(actions);
				setSelectedClass('chat');
				break;
			case '/actions':
				setProfileIcon(activeActions);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setSelectedClass('actions');
				break;
			default:
				setProfileIcon(actions);
				setSettingsIcon(settings);
				setWalletIcon(wallet);
				setHistoryIcon(history);
				setAmbassadorIcon(ambassador);
				setSelectedClass('wallet');
				break;
		}
		if (pushToHistory) {
			props.history.push('/tabs' + route);
		}
	};
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const pendingTasks = useSelector<ReduxStateEntity, PendingEntity>((state) => state.pendingTasks!);

	useEffect(() => {
		pushNotificationService.pushNotificationsSubject$.subscribe((notification: any) => {
			showNotification('Just Now', notification.body, notification);
		});
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};
		window.addEventListener('online', handleStatusChange);
		window.addEventListener('offline', handleStatusChange);
		return () => {
			window.removeEventListener('offline', handleStatusChange);
		};
	}, []);

	useEffect(() => {
		if (!isOnline) {
			showToast({
				color: 'warning',
				header: 'Warning',
				message: 'Bad Internet Connection',
			});
		}
	}, [isOnline]);
	useEffect(() => {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		if (tmpUserToken && props.history.location.pathname !== '/tabs/wallet') {
			props.history.push('/');
		}
		if (resolved) {
			showToast({
				color: 'success',
				header: 'Success',
				message: 'Welcome',
			});
		}
		setShowLoading(true);

		if (tmpUserToken) {
			console.log('GET WALLET');
			dispatch(walletActions.authorization(tmpUserToken));
		}
	}, []);
	useEffect(() => {
		navigate(props.location.pathname.replace('/tabs', ''), false);
	});
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && data) {
				dispatch(PendingActionsActions.setPendingActions(data));
				setShowLoading(false);
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
			setShowLoading(false);
		};
	}, [data]);
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};

	const showNotification = (title: string, message: string, notification: any) => {
		const handlePreResult = handleNotificationCountType(notification);
		handlePreResult.pre();
	};

	const handleNotificationCountType = (notification: any) => {
		switch (notification.data.type) {
			case PushNotificationModule.NOTIFICATION_TYPES.MESSAGE:
				messageService.messageNotificationSubject$.next(notification);
				conversationService.conversationNotificationSubject$.next(notification);
				return { pre: doMessageNotificationAction, dismiss: onDismissMessageNotificationAction };
			case PushNotificationModule.NOTIFICATION_TYPES.IDENTITY_VERIFY:
				messageService.messageNotificationSubject$.next(notification);
				return { pre: () => {}, dismiss: () => {} };
			default:
				return { pre: () => {}, dismiss: () => {} };
		}
	};

	const doMessageNotificationAction = () => {
		localStorage.setItem('notificationConversationCount', notificationConversationCount + 1);
		dispatch(notificationActions.addConversationNotificationCount());
	};

	const onDismissMessageNotificationAction = () => {
		props.history.push('/chat');
	};
	function navigateFromMenu(route: string) {
		console.log(route);
		props.history.push('/tabs' + route);
	}
	return (
		<IonPage className="tabsPage">
			<IonRouterOutlet>
				<Route path={props.match.url + '/wallet'} component={MyWallet} />
				<Route path={props.match.url + '/history'} component={History} />
				<Route path={props.match.url + '/settings'} component={Settings} />
				<Route path={props.match.url + '/chat'} component={Chat} />
				<Route path={props.match.url + '/actions'} component={PendingActions} />
				<Route path={props.match.url + '/profile'} component={MyProfile} />
				<Route path={props.match.url + '/delete-account'} component={DeleteAccount} />
				<Route path={props.match.url + '/privacy'} component={PrivacyAndSecurity} />
				<Route path={props.match.url + '/change-password'} component={ChangePassword} />
				<Route path={props.match.url + '/upload'} render={(props) => <DriversLicense prop={props} />} />
				<Route path={props.match.url + '/upload-passport'} component={PassportFile} />
				<Route path={props.match.url + '/missing-document'} render={(props) => <MissingDocument prop={props} />} />
				<Route path={props.match.url + '/collect-document'} render={(props) => <CAQH prop={props} />} />
				<Route path={props.match.url + '/dea-license'} render={(props) => <DEALicense prop={props} />} />
				<Route path={props.match.url + '/onb-questionnaire'} render={(props) => <Questionnaire prop={props} />} />
				<Route
					path={props.match.url + '/activate-wallet'}
					render={(props) => <PaymentPage prop={props} activateStep={true} />}
				/>
				<Route
					path={props.match.url + '/payment-wallet'}
					render={(props) => <ActivateWalletPage prop={props} activateStep={true} />}
				/>
				<Route
					path={props.match.url + '/verify-identity'}
					render={(props) => <VerifyIdentity prop={props} nextStep={true} />}
				/>
			</IonRouterOutlet>
			{location.pathname != '/tabs/verify-identity' && location.pathname != '/tabs/payment-wallet' && (
				<IonFooter className="menufooter ion-no-border max-550 no-padding">
					<IonToolbar className="custom-tabs">
						<IonButtons>
							<IonButton className="btn-btn min-width-btns" slot="start" onClick={() => navigate('/settings', true)}>
								<div className={selectedClass == 'settings' ? 'selected-tab' : ''}>
									<IonIcon icon={settingsIcon} />
									<br />
									Settings
								</div>
							</IonButton>
							<IonButton onClick={() => navigate('/history', true)} className="min-width-btns">
								<div className={selectedClass == 'history' ? 'selected-tab' : ''}>
									<IonIcon icon={historyIcon} />
									<br />
									Calendar
								</div>
							</IonButton>
							<IonButton onClick={() => navigate('/wallet', true)} className="min-width-btns">
								<div className={selectedClass == 'wallet' ? 'selected-tab' : ''}>
									<IonIcon icon={walletIcon} />
									<br />
									My Wallet
								</div>
							</IonButton>
							<IonButton onClick={() => navigate('/chat', true)} className="min-width-btns">
								<div className={selectedClass == 'chat' ? 'selected-tab' : ''}>
									{notificationConversationCount > 0 && (
										<IonBadge className="notification-conversation-badge" color="danger">
											{notificationConversationCount}
										</IonBadge>
									)}
									<IonIcon icon={ambassadorIcon} />
									<br />
									Ambassador
								</div>
							</IonButton>
							<IonButton onClick={() => navigate('/actions', true)} className="min-width-btns">
								<div className={selectedClass == 'actions' ? 'selected-tab' : ''}>
									{pendingTasks && pendingTasks.providerTasks.length > 0 && (
										<IonBadge className="pending-tasks-badge" color="danger">
											{''}
										</IonBadge>
									)}
									<IonIcon icon={profileIcon} />
									<br />
									Tasks
								</div>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonFooter>
			)}
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</IonPage>
	);
};
export default MainTabs;
