import {
	IonButton,
	IonContent,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Remarkable } from 'remarkable';
import arrowBack from '../../img/icons/backArrow.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import { onBoardingService } from '../../services/onBoarding.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import './termsCondition.css';

const TermsConditionOnBoarding: React.FC<RouteComponentProps> = (props) => {
	const [showLoading, setShowLoading] = useState(false);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [terms, setTerms] = useState();
	const [agree, setAgree] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [showTerms, setShowTerms] = useState('text__privacy-sec close-terms');
	var md = new Remarkable();

	const navigate = (route: string) => {
		props.history.push(route);
	};

	useEffect(() => {
		setShowLoading(true);
		onBoardingService.getPrivacyTerms().then(
			(res) => {
				setTerms(res.data.description);
				setShowLoading(false);
			},
			(error) => {
				setToast({
					color: 'danger',
					header: 'Failure',
					message: 'Oops an Error Ocurred, try again',
				});
				showToast(toast);
			}
		);
	}, []);

	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};

	const onShowTerms = (openTmp: boolean) => {
		if (openTmp) {
			setShowTerms('text__privacy-sec open-terms');
			setOpen(openTmp);
		} else {
			setShowTerms('text__privacy-sec close-terms');
			setOpen(openTmp);
		}
	};
	return (
		<IonPage className="loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>
			<IonContent className="ion-padding max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>
						Terms and Conditions
					</IonTitle>
				</IonToolbar>
				{!showLoading ? (
					<div>
						<IonRow className="termBox ion-padding-top ion-padding-bottom">
							<IonRow className={showTerms}>
								<div dangerouslySetInnerHTML={{ __html: md.render(terms!) }}></div>

								<IonRow className="saveTerms">
									{' '}
									<IonButton
										className="onBoardingSaveButtom"
										color="secondary"
										shape="round"
										onClick={() => navigate('/lets-get-started')}>
										{' '}
										I Agree{' '}
									</IonButton>
								</IonRow>
							</IonRow>
						</IonRow>
						<IonRow className="saveTerms">
							<IonIcon
								onClick={() => onShowTerms(!open)}
								className="arrow-down-terms"
								src={open ? chevronUpOutline : chevronDownOutline}></IonIcon>
						</IonRow>
					</div>
				) : null}
			</IonContent>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
			/>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
		</IonPage>
	);
};

export default TermsConditionOnBoarding;
