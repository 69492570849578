export module PushNotificationModule {
	export interface RegistrationToken {
		userId: string;
		token: string;
	}

	export interface NotificationMessageReceived {
		body: string;
		data: any;
		id: string;
		title: string;
	}

	export enum NOTIFICATION_TYPES {
		MESSAGE = 'MESSAGE',
		WALLET_READY = 'WALLET_READY',
		IDENTITY_VERIFY = 'IDENTITY_VERIFY',
	}
}
