import { IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { HistoryCardModule as Module } from "../../../shared/entity/historyCard.module";
import IconOnboardingActive from "../../../img/history/icon-history-onboarding-active-small.svg";
import IconClock from "../../../img/history/icon-history-clock.svg";
import './HistoryCard.css';

const HistoryDetailRow: React.FC<Module.HistoryDetailRow> = (props: Module.HistoryDetailRow) => {

  const historyDetailRow:Module.HistoryDetailRow = props;

  const getIcon = (): string => {
    return historyDetailRow.icon || IconOnboardingActive;
  }

  return (
    <IonRow className="historyDetailRow">
      <IonCol size="2"> 
        <IonIcon className="icon" icon={getIcon()}></IonIcon>
        { historyDetailRow.showTimeline && <div className="timeline"></div> }
      </IonCol>
      <IonCol size="10">
        <IonRow className="topLine">
          <IonIcon icon={IconClock}></IonIcon>
          <IonText className="date">{historyDetailRow.date}</IonText>
        </IonRow>
        <IonRow>
          <IonText className="topic">{historyDetailRow.topic}</IonText>
          <IonText className="status">{historyDetailRow.status}</IonText>
          { historyDetailRow?.description && 
             <IonRow className="descriptionRow"> <IonText className="description">{historyDetailRow?.description}</IonText></IonRow>
          }
        </IonRow>
      </IonCol>
    </IonRow>
  );
}

export default HistoryDetailRow;