import { ActionEntity } from '../../shared/entity/actionEntity';
import { PasswordEntity } from '../../shared/entity/passwordEntity';
import { PasswordConstant } from '../constants/passwordConstants';

const initialUser = {
	passwords: [] as PasswordEntity[],
	info: '',
	fieldDesc: '',
};
const passwordReducer = (state: any = initialUser, action: ActionEntity) => {
	switch (action.type) {
		case PasswordConstant.SET_PASSWORDS:
			return action.payload;
		default:
			return state;
	}
};
export default passwordReducer;
