import { Dispatch } from 'redux';
import { ProviderTaskEntity } from '../../shared/entity/ProviderTaskEntity';
import { PendingEntity } from '../../shared/entity/pendingTaskEntity';
import { PendingActionsConstants } from '../constants/PendingActionsConstants';

export const PendingActionsActions = {
	setPendingActions,
	updatePendingActions,
};

function setPendingActions(data: any) {
	return async (dispatch: Dispatch) => {
		let tasks: ProviderTaskEntity[] = [];
		let questionnaire: ProviderTaskEntity = {};
		if (data?.tenants[0]?.provider?.tasks) {
			data.tenants[0].provider.tasks.forEach((element: any) => {
				if (
					(element.assignedTo.id === data.tenants[0].provider.user.id &&
						validateTypeOFTask(element) &&
						element.status === 'pending') ||
					(isQuestionFromQuestionnaire(element) && !isQuestionnaireDocument(element))
				) {
					const exclusion: ProviderTaskEntity = {
						reoccurring: element.reoccurring,
						dueDate: element.dueDate,
						linkedProvider: element.linkedProvider,
						status: element.status,
						title: element.title,
						assignedTo: element.assignedTo,
						tenant: element.tenant,
						id: element.id,
						estimatedEndDate: element.estimatedEndDate,
						key: element.key,
						instructions: element.instructions,
						documentTypesAny: element.documentTypesAny,
						taskTemplate: element.taskTemplate,
						questions: [],
						documentType: element.documentType,
						document: element.documents,
					};
					const findTaskTemplate = tasks.find((param) => {
						return param.taskTemplate === element.taskTemplate;
					});
					if (isQuestionnaireDocument(element)) {
						questionnaire = exclusion;
					}
					if (isQuestionFromQuestionnaire(element) && !isQuestionnaireDocument(element)) {
						const findTaskDocument: any = exclusion.documentType?.documentFields!.find((param: any) => {
							return param.id === element.fieldMissing;
						});
						exclusion.key = findTaskDocument.key;
						questionnaire.questions?.push(exclusion);
					}
					if (!findTaskTemplate && !isQuestionnaireDocument(element) && !isQuestionFromQuestionnaire(element)) {
						tasks.push(exclusion);
					}
				}
			});
		}
		if (Object.keys(questionnaire).length > 0) {
			tasks.push(questionnaire);
		}
		const tasksWithoutDuplicates = removeDuplicates(tasks);
		let pendingActions: PendingEntity = {
			providerTasks: sortAlphabetically(tasksWithoutDuplicates),
		};
		dispatch(set(pendingActions));
	};

	function set(payload: any) {
		return { type: PendingActionsConstants.SET_PENDING_ACTIONS, payload };
	}
	function isQuestionFromQuestionnaire(task: any) {
		return (
			(task.type === 'dataMissing' && task.documentType?.key === 'onboardingQuestionnaire') ||
			task.taskTemplate?.id === '63e410071dc95f65341f3413'
		);
	}
	function isQuestionnaireDocument(task: any) {
		return task?.taskTemplate?.id === '63e410071dc95f65341f3413';
	}
	function validateTypeOFTask(task: any) {
		return task.type === 'collectDocument' ||
			task.type === 'dataMissing' ||
			task.type === 'generic' ||
			task.type === 'scheduledReminder'
			? true
			: false;
	}
	function removeDuplicates(tasks: any) {
		const uniqueItems = tasks.reduce((unique: any, item: any) => {
			const exists = unique.some((u: any) => u.taskTemplate?.id === item.taskTemplate?.id);
			if (!exists) {
				unique.push(item);
			}
			return unique;
		}, []);
		return uniqueItems;
	}
	function sortAlphabetically(data: any) {
		const sortedItems = [...data].sort((a, b) => a.title.localeCompare(b.title));
		return sortedItems;
	}
}

function updatePendingActions(data: any) {
	return async (dispatch: Dispatch) => {
		if (data) {
			let tasks: ProviderTaskEntity[] = [];
			data.forEach((element: any) => {
				const exclusion: ProviderTaskEntity = {
					reoccurring: element.reoccurring,
					dueDate: element.dueDate,
					linkedProvider: element.linkedProvider,
					status: element.status,
					title: element.title,
					assignedTo: element.assignedTo,
					tenant: element.tenant,
					id: element.id,
					estimatedEndDate: element.estimatedEndDate,
					key: element.key,
					instructions: element.instructions,
				};
				tasks.push(exclusion);
			});
			if (tasks.length > 0) {
				let pendingActions: PendingEntity = {
					providerTasks: tasks,
				};
				dispatch(update(pendingActions));
			}
		}
	};
	function update(payload: any) {
		return { type: PendingActionsConstants.UPDATE_PENDING_ACTIONS, payload };
	}
}
