import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { personOutline } from 'ionicons/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import arrowBack from '../../img/icons/backArrow.svg';
import { walletActions } from '../../redux/actions';
import { userProfileActions } from '../../redux/actions/userActions';
import { filesService } from '../../services/files.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import './MyProfile.css';

const MyProfile: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const wallet = useSelector<any, any>((state) => state.wallet.data!);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const resolved = useSelector<any, any>((state) => state.user.resolved!);
	const loading = useSelector<any, any>((state) => state.user.loading!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const { photos, takePhoto } = usePhotoGallery();
	const [front, setFront] = useState<any>();

	useEffect(() => {
		if (wallet?.tenants[0]?.provider?.contactInfo?.photo[0]?.downloadUrl) {
			photos.push({
				base64String: wallet?.tenants[0]?.provider?.contactInfo?.photo[0]?.downloadUrl,
				filepath: '',
			});
		}
	}, []);
	useEffect(() => {
		if (photos.length > 0 && photos[0].filepath !== '') {
			const tenantId = wallet.tenants[0].tenant.id;
			let uuid = uuidv4();
			let fileName = `${uuid}` + '-profile.png';

			filesService.credentials(tenantId!, fileName, 'contactPhoto').then(
				(res) => {
					let formData = new FormData();
					formData.append('key', res.data.uploadCredentials?.fields?.key);
					formData.append('policy', res.data.uploadCredentials?.fields?.policy);
					formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
					formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
					formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
					formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
					formData.append('file', photos[0].blob, fileName);
					filesService.upload(res.data.uploadCredentials?.url, formData).then(
						(response) => {
							setFront({
								createdAt: new Date(),
								downloadUrl: res.data.downloadUrl,
								id: uuid,
								name: 'profile.png',
								privateUrl: res.data.privateUrl,
								publicUrl: null,
								new: true,
								size: photos[0].blob.size,
							});
						},
						(error) => {
							console.log(error);
						}
					);
				},
				(error) => {}
			);
		}
	}, [photos]);
	useEffect(() => {
		if (wallet) {
			dispatch(userProfileActions.setUser(wallet));
		}
	}, [wallet]);
	useEffect(() => {
		if (resolved) {
			dispatch(walletActions.authorization(JSON.parse(localStorage.getItem('NuCoUserToken')!)));
			showToast({
				color: 'success',
				header: 'Success',
				message: 'Profile Updated',
			});
		} else {
			if (resolved === false) {
				showToast({
					color: 'danger',
					header: 'Failure',
					message: 'Oops an Error Ocurred, try again',
				});
			}
		}
	}, [resolved]);
	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (front) {
			user.photo = [front];
		}
		user.updatedAt = moment().format();
		dispatch(userProfileActions.updateUser(user));
		setShowLoading(true);
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		switch (name) {
			case 'firstName':
				user.firstName = value;
				break;
			case 'middle-name':
				user.middleName = value;
				break;
			case 'last-name':
				user.lastName = value;
				break;
			case 'work-email':
				user.email = value;
				break;
			case 'personal-email':
				user.backupEmail = value;
				break;
			case 'work-phone':
				user.phone1 = value;
				break;
			case 'home-phone':
				user.phone2 = value;
				break;
			case 'user-name':
				user.userName = value;
				break;
			default:
				console.log(name, value);
				break;
		}
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	return (
		<IonPage
			className="top-page 
      ion-padding-bottom ion-padding-start ion-padding-end">
			<IonContent className="ion-padding profile-page max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon> My
						Profile
					</IonTitle>
				</IonToolbar>
				<IonRow className="saveRowProfile">
					{!photos[0] ? (
						<div className="upload-media-profile-avatar" onClick={() => takePhoto('front')}>
							<div className="upload-area">
								<IonIcon src={personOutline} />
							</div>
						</div>
					) : (
						<div className="upload-media-profile-avatar" onClick={() => takePhoto('front')}>
							<img src={photos[0].base64String} />
						</div>
					)}
				</IonRow>
				<form name="form" onSubmit={handleSubmit}>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Username</IonLabel>
						<IonInput
							className="ion-padding"
							name="user-name"
							type="email"
							value={user.userName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">First Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="firstName"
							type="text"
							value={user.firstName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Middle Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="middle-name"
							type="text"
							value={user.middleName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Last Name</IonLabel>
						<IonInput
							className="ion-padding"
							name="last-name"
							type="text"
							value={user.lastName}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Work Email</IonLabel>
						<IonInput
							className="ion-padding"
							name="work-email"
							type="email"
							value={user.email}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Personal Email</IonLabel>
						<IonInput
							className="ion-padding"
							name="personal-email"
							type="email"
							value={user.backupEmail}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Work Phone</IonLabel>
						<IonInput
							className="ion-padding"
							name="work-phone"
							type="tel"
							value={user.phone1}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonItem className="profile-border-input ion-margin-top ion-margin-bottom">
						<IonLabel position="stacked">Home Phone</IonLabel>
						<IonInput
							className="ion-padding"
							name="home-phone"
							type="tel"
							value={user.phone2}
							onIonChange={handleChange}></IonInput>
					</IonItem>
					<IonRow className="saveRowProfile">
						{' '}
						<IonButton color="secondary" shape="round" type="submit">
							{' '}
							Save{' '}
						</IonButton>
					</IonRow>
				</form>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={1000}
			/>
		</IonPage>
	);
};

export default MyProfile;
