import { Dispatch } from 'redux';
import { ADD_NOTIFICATION_CONVERSATION, SET_NOTIFICATION_CONVERSATION } from '../constants/notificationConstants';

const addConversationNotificationCount = () => {
	return async (dispatch: Dispatch) => {
		const data = {
			type: ADD_NOTIFICATION_CONVERSATION,
		};
		dispatch(data);
	};
};

const setConversationNotificationCount = (count: number) => {
	return async (dispatch: Dispatch) => {
		const data = {
			payload: count,
			type: SET_NOTIFICATION_CONVERSATION,
		};
		dispatch(data);
	};
};

export const notificationActions = {
	addConversationNotificationCount,
	setConversationNotificationCount,
};
