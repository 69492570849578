import { Dispatch } from 'redux';
import { ProviderSignatureEntity } from '../../shared/entity/providerSignatureEntity';
import { ProviderSignatureConstants } from '../constants/providerSignatureConstants';

export const SignatureActions = {
	setSignature,
};

function setSignature(data: any) {
	return async (dispatch: Dispatch) => {
		const providerSignature: ProviderSignatureEntity = data.tenants[0].provider.professionalLicenses.find(
			(element: any) => {
				return element.type?.key === 'providersSignature';
			}
		);

		if (providerSignature) {
			dispatch(set(providerSignature));
		}
	};

	function set(payload: any) {
		return { type: ProviderSignatureConstants.SET_SIGNATURE, payload };
	}
}
