import { 
  IonContent,
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonButton
 } from '@ionic/react';
import './MyWallet.css';
import Credentials from '../../components/Credentials/Credentials'
import { RouteComponentProps } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileActions } from '../../redux/actions/userActions';
import { walletActions } from '../../redux/actions';
import { RefresherEventDetail } from '@ionic/core';

const MyWallet: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const data = useSelector<any,any>((state)=> state.wallet.data!);
  const cardSelected = useSelector<any, any>((state) => state.tabs.selected!);
 
  useEffect(() => {
    if(data){
      dispatch(userProfileActions.setUser(data));
    }
  }
,[data]);
function doRefresh(event: CustomEvent<RefresherEventDetail>) {  
  const tmpUserToken: string= JSON.parse(localStorage.getItem('NuCoUserToken')!);
    dispatch(walletActions.authorization(tmpUserToken));
    event.detail.complete();
}
  return (
    <IonPage className="top-page">
       <IonToolbar className={cardSelected? "headerNone":"max-550"}>
       
        <IonTitle className="ion-text-center" size="large">Wallet</IonTitle>

        
            
        </IonToolbar>
        <IonContent className="main-content">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent>
        </IonRefresherContent>
      </IonRefresher>
      <div className="main-div">
      <Credentials {...props} />
      </div>
      
    </IonContent>
   
  </IonPage>
  );
};

export default MyWallet;
