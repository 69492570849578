import { Dispatch } from 'redux';
import { issuedByEntity } from '../../shared/entity/issuedByEntity';
import { MedicalLicenseEntity } from '../../shared/entity/medicalLicenseEntity';
import { MedicalLicenseConstants } from '../constants/medicalLicenseConstants';

export const MedicalLicenseActions = {
	setLicense,
};

function setLicense(data: any, document: any) {
	return async (dispatch: Dispatch) => {
		let lincenses: issuedByEntity[] = [];
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data?.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.type?.key === 'medical-license') {
					const license: issuedByEntity = {
						expiration: element.expirationDate,
						number: element.number,
						status: element.status,
						state: element.issuedBy?.state,
						pictureUrl: element.attachments ? element.attachments[0] : null,
						description: element.type?.description,
					};
					lincenses.push(license);
				}
			});

			if (lincenses.length > 0) {
				const medicalLicense: MedicalLicenseEntity = {
					name: data.tenants[0].provider?.contactInfo?.fullName,
					role: data.tenants[0].provider?.type?.name,
					pictureUrl: data?.tenants[0]?.provider?.contactInfo?.photo?.[0]?.downloadUrl,
					issuedBy: lincenses,
					description: lincenses[0]?.description,
					info: document?.info || '',
				};
				dispatch(set(medicalLicense));
			}
		}
	};

	function set(payload: any) {
		return { type: MedicalLicenseConstants.SET_MEDICAL_LICENSE, payload };
	}
}
