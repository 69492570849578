export default class LabelsText {
    static label(value:any) {
      const item = this.forSelect.find(
        (item) => item.value === value,
      );
      if (!item) {
        return null;
      }
      return item.label;
    }  
    static get forSelect() {
      return [
        { value: 'needsAttention', label: 'Attention' },
        { value: 'expired', label: 'Expired' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'pending', label: 'Pending' },
        { value: 'active', label: 'Active' },
      ];
    }
    static get values() {
      return this.forSelect.map((item) => item.value);
    }
  }
  