import { Dispatch } from 'redux';
import { taskService } from '../../services';
import { paymentService } from '../../services/paymentService';
import { userService } from '../../services/user.service';
import { WalletConstants } from '../constants/walletConstants';

export const walletActions = {
	authorization,
	updateWallet,
	updateAmbassadorAseginedToTask,
};

function authorization(token: string) {
	return async (dispatch: Dispatch) => {
		await userService.userAuthorization(token).then(
			async (res) => {
				const tasks = await taskService.getProviderTasks(
					res.data.tenants[0].tenant.id,
					res.data.tenants[0].provider.id
				);
				if (tasks.data) {
					res.data.tenants[0].provider.tasks = tasks.data.provider;
				}
				const paymentStatus = await paymentService.getPayment(
					res.data.tenants[0].tenant.id,
					res.data.tenants[0].provider.id
				);
				res.data.tenants[0].provider.paymentStatus = paymentStatus?.data;
				dispatch(success(res));
			},
			(error) => {
				dispatch(failure(error.response.data));
			}
		);
	};

	function success(payload: any) {
		return { type: WalletConstants.AUTH_ME, payload };
	}
	function failure(error: any) {
		return { type: WalletConstants.AUTH_FAILURE, error };
	}
}
function updateWallet(data: any, id?: string, document?: any) {
	return async (dispatch: Dispatch) => {
		if (data?.tenants[0]?.provider?.professionalLicenses) {
			data.tenants[0].provider.professionalLicenses.forEach((element: any) => {
				if (element.id === id) {
					updateDocument(element.type.key, element, document);
					return;
				}
			});
			const dataUpdated = { data: data };
			dispatch(set(dataUpdated));
		}
	};
}
function updateDocument(type: string, element: any, data: any) {
	if (type === 'driver-license' || type === 'dea-number' || type === 'passport') {
		element.attachments = data.attachments;
	} else {
		element.username = data.email;
		element.password = data.password;
		element.permissionToLogin = data.permissionLogin;
	}
}
function set(payload: any) {
	return { type: WalletConstants.AUTH_ME, payload };
}
function updateAmbassadorAseginedToTask(data: any, id?: string, assignedTo?: any) {
	return async (dispatch: Dispatch) => {
		if (data?.tenants[0]?.provider?.tasks) {
			data.tenants[0].provider.tasks.forEach((element: any) => {
				if (element.id === id) {
					element.assignedTo = assignedTo;
					return;
				}
			});
			const dataUpdated = { data: data };
			dispatch(set(dataUpdated));
		}
	};
}
