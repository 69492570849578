import { RefresherEventDetail } from '@ionic/core';
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Credentials from '../../components/CredentialsWeb/CredentialsWeb';
import { walletActions } from '../../redux/actions';
import { userProfileActions } from '../../redux/actions/userActions';
import './MyWalletWebVersion.css';
interface MyWalletProps extends RouteComponentProps {
	handleValueFromMyWallet: (value: number) => void;
}
const MyWallet: React.FC<MyWalletProps> = ({ handleValueFromMyWallet, ...props }) => {
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const cardSelected = useSelector<any, any>((state) => state.tabs.selected!);
	console.log(props);
	useEffect(() => {
		if (data) {
			dispatch(userProfileActions.setUser(data));
		}
	}, [data]);
	function doRefresh(event: CustomEvent<RefresherEventDetail>) {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		dispatch(walletActions.authorization(tmpUserToken));
		event.detail.complete();
	}
	function sendValueFromMyWallet(value: number) {
		if (handleValueFromMyWallet) {
			handleValueFromMyWallet(value);
		}
	}

	return (
		<IonPage className="top-page-wallet-web-version start-page-wallet-web">
			<IonContent className="main-content">
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<div className="main-div">
					<Credentials {...props} handleValueFromMyWallet={sendValueFromMyWallet} />
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyWallet;
