import { IonContent, IonImg, IonLoading, IonPage, IonRow, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import SuccessPayorActivation from '../../../img/activate-payors-success-img.svg';
import PayDescImage from '../../../img/activate_payor_enrollment_btn.svg';
import ActivateText from '../../../img/activate_payor_enrollment_txt.svg';
import PayorSuccessImage from '../../../img/success-con-payor.svg';
import SuccessText from '../../../img/success-payor-txt.svg';
import { walletActions } from '../../../redux/actions';
import { PayerActionsActions } from '../../../redux/actions/payerActions';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import { paymentService } from '../../../services/paymentService';
import { PayerCardEntity } from '../../../shared/entity/payerCardEntity';
import { PayerEntity } from '../../../shared/entity/payerEntity';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import './Payer.css';
interface ContainerProps {
	prop?: RouteComponentProps;
}
const PayersActivation: React.FC<ContainerProps> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [payorActivation, setPayorActivation] = useState(false);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const payer = useSelector<ReduxStateEntity, PayerCardEntity>((state) => state.payer!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__payer background_payer');
	const [states, setStates] = useState<string[]>([]);
	const [payers, setPayers] = useState<PayerEntity[]>(payer.payers);
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const [documentDescription, setDocumentDescription] = useState(payer?.info);
	const [showLoading, setShowLoading] = useState(false);
	const [image, setImage] = useState(PayDescImage);
	useEffect(() => {
		if (data) {
			dispatch(PayerActionsActions.setPayers(data, payer));
			dispatch(WalletActivationActions.setWalletActivation(data));
		}
	}, [data]);
	useEffect(() => {
		if (data) {
			if (payer?.payers.length > 0) {
				setPayers(payer.payers);
			}
			if (payer?.locations.length > 0) {
				setStates([payer.locations[0].name]);
				const initialState = [payer.locations[0].name];
				payersByState(initialState);
			}
		}
	}, [payer]);
	useEffect(() => {
		if (payer?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'Payer-Enrollment-Status').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(PayerActionsActions.setPayers(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [payer]);
	useEffect(() => {
		if (payer?.info !== '') {
			setDocumentDescription(payer.info);
		}
	}, [payer, documentDescription]);
	//find payers by payer state
	function payersByState(value: string[]) {
		const isAllSelection = value.indexOf('All');
		let listOfPayers: PayerEntity[] = [];
		if (isAllSelection >= 0) {
			if (payers.length < payer.payers.length || value.length > 1) {
				value = [];
				value.push('All');
			}
			payer?.payers.map((element) => {
				listOfPayers.push(element);
			});
		} else {
			if (value.length == 0) {
				value.push(payer.locations[0].name);
			}
			value.map((state) => {
				payer?.payers.map((element) => {
					if (element.state === state) {
						listOfPayers.push(element);
					}
				});
			});
		}
		setPayers(listOfPayers);
		setStates(value);
	}

	async function activatePayorEnrollment() {
		setShowLoading(true);
		const fullname = data.tenants[0].provider.fullName || '';
		const email: any = data.tenants[0].provider.user.email || '';
		const provider = data.tenants[0].provider.id;
		const tenant = data.tenants[0].tenant.id;
		const providerNPI = data.tenants[0].provider.npi;
		const providerType = data.tenants[0].provider.type.name;
		const result = await paymentService.requestPayorEnrollment(
			tenant,
			provider,
			email,
			fullname,
			providerNPI,
			providerType
		);
		if (result.data === 'succeeded') {
			setImage(SuccessPayorActivation);
			setShowLoading(false);
			setPayorActivation(true);
			const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
			await dispatch(walletActions.authorization(tmpUserToken));
			const timer = setTimeout(() => {
				history?.push('/tabs/wallet');
			}, 3000);

			return () => clearTimeout(timer);
		}
	}
	return (
		<IonPage
			className="top-page 
	ion-padding-bottom ion-padding-start ion-padding-end">
			<IonContent fullscreen className="max-550">
				<div className={back}>
					<div className={`${flip ? 'wrapper' : 'wrapper-zoom-payer'}`}>
						{!flip && !walletActivation?.payorEnrollmentStatus ? (
							<IonRow className="article-header_payer">
								<IonRow className="category-title">
									<IonText className="ion-padding-right">{'My Payors'}</IonText>
								</IonRow>
							</IonRow>
						) : null}
						{!flip && !walletActivation?.payorEnrollmentStatus ? (
							<div className={'background_payer-activation'}>
								<div className="margin-activate-payor" onClick={() => activatePayorEnrollment()}>
									<IonImg
										className="payorsActivation-image"
										src={payorActivation ? PayorSuccessImage : PayDescImage}></IonImg>
								</div>
								<div className="margin-activate-payor-text">
									<IonImg
										className="payorsActivation-image"
										src={payorActivation ? SuccessText : ActivateText}></IonImg>
								</div>
							</div>
						) : null}
					</div>
					<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
				</div>
			</IonContent>
		</IonPage>
	);
};

export default PayersActivation;
