import { IonImg, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';

import ChevronDownBrown from '../../img/icon-chevron-down-black.svg';
import ChevronUpBrown from '../../img/icon-chevron-up-black.svg';
import { AccordionFullTitleEntity } from '../../shared/entity/accordionFullTitle.module';
import './AccordionABMS.css';
const AccordionABMS: React.FC<AccordionFullTitleEntity> = ({ certifiedType, title, active, children, lastItem }) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<IonRow
			className={`accordion-wrapper full-row-cols ${
				lastItem ? 'border-bottom-row-abms border-top-row-abms' : 'border-top-row-abms'
			}`}>
			<IonRow className={`accordion-row-abms ${isOpen ? 'open' : ''}`} onClick={() => setOpen(!isOpen)}>
				<IonRow className="card-row-abms" style={{ width: '100%' }}>
					<IonText className="description-abms">{certifiedType}:</IonText>
					<IonRow className="flex-row-space-between">
						<IonText className="title-abms">{title}</IonText>
						{isOpen ? (
							<IonImg className="row-icon" src={ChevronUpBrown} />
						) : (
							<IonImg className="row-icon" src={ChevronDownBrown} />
						)}
					</IonRow>
				</IonRow>
			</IonRow>
			{isOpen ? <IonRow className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>{children}</IonRow> : null}
		</IonRow>
	);
};

export default AccordionABMS;
