export module MessageModule {
	export interface Message {
		from: string;
		to: string;
		contentType?: string;
		status?: MESSAGE_STATUS;
		text?: string;
		attachment?: string;
		conversationId?: string;
		fromName?: string;
	}

	export enum MESSAGE_STATUS {
		READ = 'READ',
		UNREAD = 'UNREAD',
		DELETED = 'DELETED',
	}

	export interface MessageGetResponse {
		contentType: string;
		conversationId: string;
		createdAt: Date;
		dateCreated: Date;
		from: string;
		id: string;
		status: MESSAGE_STATUS;
		text: string;
		attachment: string;
		updatedAt: string;
	}
}
