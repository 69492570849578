import { RefresherEventDetail } from '@ionic/core';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonIcon,
	IonLoading,
	IonPage,
	IonPopover,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
	useIonViewWillLeave,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ExpirationReminder from '../../components/ExpirationReminder/ExpirationReminder';
import NeedsTag from '../../components/NeedsTags/NeedsTag';
import NoInfoCard from '../../components/NoInfoCard/NoInfoCard';
import arrowBack from '../../img/icon-close-modal.svg';
import { PendingActionsActions } from '../../redux/actions/pendingActions';
import { walletActions } from '../../redux/actions/walletActions';
import { filesService, taskService } from '../../services';
import { ProviderTaskEntity } from '../../shared/entity/ProviderTaskEntity';
import { PendingEntity } from '../../shared/entity/pendingTaskEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './PendingActions.css';
const PendingAction: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const pendingTasks = useSelector<ReduxStateEntity, PendingEntity>((state) => state.pendingTasks!);
	const user = useSelector<ReduxStateEntity, UserEntity>((state) => state.user!);
	const [viewMore, setViewMore] = useState(false);
	const [documentType, setDocumentType] = useState<ProviderTaskEntity>();
	const [provider, setProvider] = useState<ProviderTaskEntity>();
	const [saveLocalStorage, setSaveLocalStorage] = useState(false);
	const [tasks, setTasks] = useState<PendingEntity>({ providerTasks: [] });
	const [moveTask, setMoveTask] = useState(false);
	const [showModalTask, setModalTask] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const [showExpReminderModal, setExpReminderModal] = useState(false);
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && data) {
				setSaveLocalStorage(false);
				dispatch(PendingActionsActions.setPendingActions(data));
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, [data]);
	useEffect(() => {
		if (pendingTasks.providerTasks.length > 0) {
			orderTasksByStatus(null);
		}
	}, [pendingTasks.providerTasks.length]);
	useEffect(() => {
		if (moveTask) {
			orderTasksByStatus(null);
		}
	}, [moveTask]);
	useEffect(() => {
		setProvider(undefined);
		setDocumentType(undefined);
		if (props.history.location.state && props.history.location.pathname === '/tabs/actions') {
			const data = props.history.location.state as any;
			if (data.providerTask) {
				closeTask(data?.providerTask, true);
			}
		}
	}, [props.history.location.state]);
	//manage the click of a task
	async function onClickTag(id: string) {
		try {
			setShowLoading(true);
			const token = JSON.parse(localStorage.getItem('NuCoUserToken')!);
			const providerTask = await taskService.userTask(user.id!, token, id);
			let documentType: any;
			if (providerTask && providerTask.data?.documentType) {
				documentType = await taskService.documentTaskfindByKey(user.id!, providerTask.data.documentType.key, token);
				setDocumentType(documentType.data);
			}
			if (providerTask.data.taskTemplate?.type === 'dataMissing') {
				props.history.push({
					pathname: '/tabs/missing-document',
					state: { providerTask: providerTask.data },
				});
			} else if (providerTask.data.documentType?.key === 'onboardingQuestionnaire') {
				providerTask.data.childStepTasks = pendingTasks.providerTasks[getQuestionnairePosition()].questions;
				props.history.push({
					pathname: '/tabs/onb-questionnaire',
					state: { providerTask: providerTask.data },
				});
			} else if (providerTask.data.taskTemplate?.type === 'collectDocument') {
				props.history.push({
					pathname: '/tabs/collect-document',
					state: { providerTask: providerTask.data, task: documentType.data },
				});
			} else {
				setProvider(providerTask?.data);
			}
			setShowLoading(false);
		} catch (error) {
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
			setShowToastAttachments(true);
		}
	}
	const getQuestionnairePosition = () => {
		const result = pendingTasks.providerTasks.findIndex(function (element) {
			return element.taskTemplate?.id === '63e410071dc95f65341f3413';
		});
		return result;
	};
	//close and save the task in local storage
	async function closeTask(task: any, deleteData: boolean) {
		setShowLoading(true);
		setDocumentType(undefined);
		setSaveLocalStorage(false);
		setModalTask(false);
		setProvider(undefined);
		setViewMore(false);
		if (deleteData) {
			await sleep(1000);
			const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
			await dispatch(walletActions.authorization(tmpUserToken));
			await dispatch(PendingActionsActions.setPendingActions(data));
			orderTasksByStatus(task);
		}
		setShowLoading(false);
	}
	function sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	//handle when the user leave the view
	useIonViewWillLeave(() => {
		setSaveLocalStorage(true);
	});
	//return the number of pending tasks
	function getPendingTask() {
		let tasksNumber: number = 0;
		tasks.providerTasks.forEach((element) => {
			if (element.status !== 'done') {
				tasksNumber++;
			}
		});
		return tasksNumber;
	}
	function orderTasksByStatus(providerData: any) {
		let tasksOrder: PendingEntity = { providerTasks: [] };
		let data: ProviderTaskEntity[] = pendingTasks.providerTasks.filter(function (el) {
			return el.status!.toLowerCase().indexOf('done') == -1;
		});
		const dataDone: ProviderTaskEntity[] = pendingTasks.providerTasks.filter(function (el) {
			return el.status!.toLowerCase().indexOf('done') > -1;
		});
		dataDone.forEach((element) => {
			data.push(element);
		});
		if (providerData) {
			let result = data.findIndex(function (element) {
				return element.id === providerData?.id!;
			});
			let dataEdited = data[result];
			dataEdited.animate = 'css-animation';
			data.splice(result, 1);
			data.unshift(dataEdited);
		}
		tasksOrder.providerTasks = data;
		setTasks(tasksOrder);
		setProvider(undefined);
		setMoveTask(false);
		setViewMore(false);
	}
	onanimationend = () => {
		clearAnimationTask();
		orderTasksByStatus(null);
	};
	async function finishTask() {
		setShowLoading(true);
		let dataSend: any = { id: provider?.id, status: 'done' };
		await filesService.updateTasksStatus(user.id!, provider?.id!, dataSend);
		const data: ProviderTaskEntity[] = [...pendingTasks.providerTasks];
		const result = data.findIndex(function (element) {
			return element.id === provider?.id!;
		});
		data[result].status = 'done';
		dispatch(PendingActionsActions.updatePendingActions(data));
		clearFields();
	}
	function clearAnimationTask() {
		const data: ProviderTaskEntity[] = [...pendingTasks.providerTasks];
		data.forEach((element) => {
			element.animate = '';
		});
		dispatch(PendingActionsActions.updatePendingActions(data));
	}
	function closePopover() {
		setDocumentType(undefined);
		setSaveLocalStorage(false);
		setModalTask(false);
		setProvider(undefined);
		setViewMore(false);
	}
	function clearFields() {
		setModalTask(false);
		setDocumentType(undefined);
		setSaveLocalStorage(false);
		setProvider(undefined);
		setViewMore(false);
		setMoveTask(true);
		setShowLoading(false);
	}
	//show the modal for the generic task
	function getGenericModal() {
		return (
			<IonPopover isOpen={true} cssClass={'popover-css'} backdropDismiss={false}>
				<div className="">
					<div className="text-title">
						<IonText>{provider?.title}</IonText>
					</div>
					{provider?.instructions ? (
						<div className="text-message text-instructions-generic">
							<ReactMarkdown children={provider?.instructions} />
						</div>
					) : null}
					<div className="text-needed">
						<IonText>Why is this needed?</IonText>
					</div>
					<div className="section-btn">
						<IonRow className="text-do-it-later" onClick={() => closePopover()}>
							I'll do it later
						</IonRow>
						&nbsp;&nbsp;&nbsp;
						<IonButton onClick={() => finishTask()}>This is done</IonButton>
					</div>
				</div>
			</IonPopover>
		);
	}
	//return input of type date
	function getTaskView() {
		switch (provider?.taskTemplate?.type) {
			case 'generic':
				return getGenericModal();
			case 'scheduledReminder':
				return <ExpirationReminder provider={provider} clearFields={clearFields} />;
			default:
				return (
					<IonRow className="no-content-task-selected">
						<IonRow className="back-tasks">
							<IonIcon className="" icon={arrowBack} onClick={() => closePopover()}></IonIcon>
							<IonTitle className="ion-text-center title-text-tasks" size="small">
								Pending Tasks
							</IonTitle>
						</IonRow>
						<AllClearContent />
					</IonRow>
				);
		}
	} //get the view when exists tasks
	function getTasksToCompleteView() {
		return (
			<div>
				<IonToolbar className="ion-padding-bottom ion-padding-top">
					<div className="ion-padding-bottom generic-info-message">
						<span className="ion-text-center ion-padding-bottom">
							Below is information that we need in order to complete your credentialing
						</span>
					</div>
					<IonTitle className="ion-text-center" size="large">
						Pending Tasks
						<div id="row" className="number-circle-tasks">
							<span id="row">{getPendingTask()}</span>
						</div>
					</IonTitle>
				</IonToolbar>
				<IonCard className={`regular-card`}>
					<IonCardHeader className={`ion-text-center`}>
						<IonCardTitle className="wwn-title">What we need</IonCardTitle>
						<IonCardSubtitle className="wwn-subtitle">
							Please upload the following documents needed to complete credientialing
						</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<div className="tasks-title-section">
							<IonText>What we need</IonText>
							<IonText>By when</IonText>
						</div>
						<div>
							{tasks.providerTasks.map((element, index) =>
								index < 5 && !viewMore ? (
									<IonCardContent key={element.id} className={element.animate}>
										<NeedsTag
											key={element.id}
											props={props}
											name={element.title!}
											date={element.estimatedEndDate!}
											progress={'needed'}
											link="/caqh-login"
											icon="link"
											status={element.status!}
											onClickTag={onClickTag}
											id={element.id!}
										/>
									</IonCardContent>
								) : viewMore ? (
									<IonCardContent key={element.id} className={element.animate}>
										<NeedsTag
											key={element.id}
											props={props}
											name={element.title!}
											date={element.estimatedEndDate!}
											progress={'needed'}
											link="/caqh-login"
											icon="link"
											status={element.status!}
											onClickTag={onClickTag}
											id={element.id!}
										/>
									</IonCardContent>
								) : null
							)}
						</div>
						<IonRow className="row-footer-tasks" onClick={() => setViewMore(!viewMore)}>
							<IonText>{!viewMore ? `View all items` : `View less items`}</IonText>
						</IonRow>
					</IonCardContent>
				</IonCard>
			</div>
		);
	}
	const AllClearContent = () => {
		return (
			<IonRow className="center-no-info-card-tasks">
				<NoInfoCard textColor="text-color-black" blackIcon={true} cardType={'tasks'} />{' '}
			</IonRow>
		);
	};
	async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		await dispatch(walletActions.authorization(tmpUserToken));
		event.detail.complete();
	}

	return (
		<IonPage className="top-page">
			<IonContent className="max-550">
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{pendingTasks.providerTasks.length === 0 ? (
					<AllClearContent />
				) : !documentType && !provider ? (
					getTasksToCompleteView()
				) : (
					getTaskView()
				)}
				<IonLoading
					cssClass="spinner"
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					spinner="crescent"
					translucent={true}
				/>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
			</IonContent>
		</IonPage>
	);
};

export default PendingAction;
