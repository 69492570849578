import { IonLoading, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { AboutThatEntity } from '../../shared/entity/aboutThatEntity';
import './AboutThat.css';
const AboutThat: React.FC<AboutThatEntity> = ({
	title,
	description,
	onFlip,
	cssBackground,
	cssTextColor,
	cssTitleBg,
}) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<IonRow className={`card-about back-about ${cssBackground}`}>
			<IonRow className={`about-title ${cssTextColor} ${cssTitleBg}`}>{title}</IonRow>
			{description ? (
				<IonRow className="description-about">
					<IonText className={`description-title ${cssTextColor}`}>What is this?</IonText>
					<div className={`description-text ${cssTextColor}`}>
						<ReactMarkdown children={description} />
					</div>
				</IonRow>
			) : (
				<IonLoading cssClass={`spinner ${cssTextColor}`} isOpen={true} spinner="crescent" translucent={true} />
			)}
			<IonRow className={`about-close-card ${cssTextColor}`} id="row" onClick={() => onFlip()}>
				Close Card
			</IonRow>
		</IonRow>
	);
};

export default AboutThat;
