import { IonButton, IonCardSubtitle, IonCardTitle, IonIcon, IonLoading, IonPage, IonRow, IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import IconCamera from '../../img/icon-camera-white.svg';
import IconUpload from '../../img/icons/selfie_icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import { onBoardingPhotoActions } from '../../redux/actions/onBoardingPhotoActions';
import { userProfileActions } from '../../redux/actions/userActions';
import { filesService } from '../../services/files.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import { UserEntity } from '../../shared/entity/userEntity';
import './selfie.css';

const Selfie: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const wallet = useSelector<any, any>((state) => state.wallet.data!);
	const { photos, takePhoto, takeSelfie } = usePhotoGallery();
	const [front, setFront] = useState<any>();
	const resolved = useSelector<any, any>((state) => state.user.resolved!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	useEffect(() => {
		uploadProfilePhoto();
	}, [photos]);
	const uploadProfilePhoto = async () => {
		try {
			if (photos.length === 0 || photos[0]?.filepath === '') {
				return;
			}

			setShowLoading(true);
			const tenantId = wallet?.tenants[0]?.tenant.id;
			const uuid = uuidv4();
			const fileName = `${uuid}-profile.png`;

			const res = await filesService.credentials(tenantId!, fileName, 'contactPhoto');

			const formData = new FormData();
			formData.append('key', res.data.uploadCredentials?.fields?.key);
			formData.append('policy', res.data.uploadCredentials?.fields?.policy);
			formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
			formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
			formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
			formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
			formData.append('file', photos[0]?.blob, fileName);

			dispatch(onBoardingPhotoActions.setSelfie(photos[0]));

			await filesService.upload(res.data.uploadCredentials?.url, formData);

			const pic: any = {
				createdAt: new Date(),
				downloadUrl: res.data.downloadUrl,
				id: uuid,
				name: 'profile.png',
				privateUrl: res.data.privateUrl,
				publicUrl: null,
				new: true,
				size: photos[0].blob.size,
			};
			user.photo = pic;
			if (!user?.id) {
				user.id = tenantId;
			}
			if (!user?.contactId) {
				user.contactId = wallet?.tenants[0]?.provider?.contactInfo?.id;
			}
			dispatch(userProfileActions.updateUser(user));
			setShowLoading(false);
		} catch (error) {
			photos.shift();
			handleErrorMessage(error);
		}
	};
	const handleErrorMessage = (error: any) => {
		setShowLoading(false);
		console.log('Error sending selfie ' + error);
		setToast({
			color: 'danger',
			header: 'Failure',
			message: 'Oops an Error Ocurred, try again',
		});
		showToast(toast);
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};
	return (
		<IonPage>
			<IonCardTitle className="uploadTitle">Step 2</IonCardTitle>
			<IonCardSubtitle className="uploadSubTitle">Take a picture of yourself</IonCardSubtitle>

			<IonRow className="upload-onboarding-main-container ion-align-items-center">
				<IonRow className="ion-no-border row-pics">
					{!photos || !photos[0] ? (
						<div className="upload-media-container">
							<div className="upload-area">
								<img src={IconUpload} />
							</div>

							<IonButton color="primary" shape="round" onClick={() => takeSelfie()}>
								<IonIcon slot="start" icon={IconCamera} />
								Take Photo
							</IonButton>
						</div>
					) : (
						<div className="upload-media-container">
							<img src={photos[0].base64String} />
						</div>
					)}
				</IonRow>
			</IonRow>

			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
			/>
		</IonPage>
	);
};

export default Selfie;
