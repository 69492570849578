export default class WalletCardsNames {
    static label(value:any) {
      const item = this.forSelect.find(
        (item) => item.value === value,
      );
      if (!item) {
        return null;
      }
      return item.label;
    }  
    static get forSelect() {
      return [
        { value: 'mc', label: 'Medical Credientials',subTitle:'Includes: Medical Licenses, Specialties, Work History ',checked:false,isChecked:false },
        { value: 'pi', label: `Personal Information`,subTitle:'Includes: Drivers License, Passports',checked:false,isChecked:false },
        { value: 'pf', label: 'Provider Information ',subTitle:'Includes: Medicaid Number, DEA License, Payer Enrollments',checked:false,isChecked:false },
   ];
    }
    static get values() {
      return this.forSelect.map((item) => item);
    }
  }