import { isPlatform } from '@ionic/react';
import { AppVersionModule } from '../shared/entity/appVersion.module';
import API from '../shared/environement/api-routes';
import Interceptor from '../shared/environement/interceptor';

const validateAppVersion = async (version: string): Promise<boolean> => {
	try {
		const result = await Interceptor.get(`${API.APP_VERSION}`, {});
		const response: Array<AppVersionModule.AppVersion> = result.data;
		const numVersion = destructVersion(version);
		if (isPlatform('ios')) {
			const platformVersion = destructVersion(response[0].ios);
			return platformVersion > numVersion;
		} else {
			const platformVersion = destructVersion(response[0].android);
			return platformVersion > numVersion;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

const destructVersion = (version: string) => {
	return Number(version.replaceAll('.', ''));
};

export const appVersionService = {
	validateAppVersion,
};
