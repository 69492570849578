import { IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import QuestionnaireComponent from '../../components/Questionnaire/Questionnaire';
import arrowBack from '../../img/left-arrow-black.svg';
import { walletActions } from '../../redux/actions';
import { filesService } from '../../services/files.service';
import { ProviderTaskEntity } from '../../shared/entity/ProviderTaskEntity';
import { PendingEntity } from '../../shared/entity/pendingTaskEntity';
import { ReduxStateEntity } from '../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../shared/entity/userEntity';
import './Questionnaire.css';
interface ContainerProps {
	providerTask?: ProviderTaskEntity;
	closeTask?: (data: any, deleteData: boolean) => void;
	saveTaskLocal?: boolean;
	prop?: RouteComponentProps;
}
const Questionnaire: React.FC<ContainerProps> = (props) => {
	const dispatch = useDispatch();
	const { prop } = props;
	const [providerTask, setProviderTask] = useState<ProviderTaskEntity>();
	const wallet = useSelector<any, any>((state) => state.wallet.data!);
	const [showLoading, setShowLoading] = useState(false);
	const [progressCount, setProgressCount] = useState(0);
	const [progress, setProgress] = useState(progressCount);
	const [readySwiper, setReadySwiper] = useState(false);
	const user = useSelector<ReduxStateEntity, UserEntity>((state) => state.user!);
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [toastMessageError, setToastMessageError] = useState('');
	const [document, setDocument] = useState<any>(undefined);
	const pendingTasks = useSelector<ReduxStateEntity, PendingEntity>((state) => state.pendingTasks!);
	useEffect(() => {
		if (prop?.history?.location?.state !== null) {
			const data = prop?.history?.location?.state as any;
			setProviderTask(data?.providerTask);
			setReadySwiper(true);
		}
	}, [wallet]);
	useEffect(() => {
		if (providerTask) {
			const countData = 10 / providerTask?.childStepTasks!.length / 10;
			setProgressCount(countData);
			updateProgressBar(countData);
		}
	}, [providerTask]);
	useEffect(() => {
		if (wallet) {
			setDocument(questionnaireDocument());
		}
	}, [pendingTasks, wallet]);
	const questionnaireDocument = () => {
		let document = {};
		wallet.tenants[0].provider.professionalLicenses.forEach((element: any) => {
			if (element.type.key === 'onboardingQuestionnaire') {
				document = element;
				return;
			}
		});
		return document;
	};
	const tasksCompleted = () => {
		const count = providerTask?.childStepTasks!.filter((element: any) => element.status === 'done');
		return count?.length;
	};

	function getTasks(providerData: any) {
		providerData = orderTasksByStatus(providerData);
		return providerData;
	}
	function orderTasksByStatus(providerData: any) {
		let data: any[] = providerData.filter(function (element: any) {
			return element.status!.toLowerCase().indexOf('done') == -1;
		});
		const dataDone: any[] = providerData.filter(function (element: any) {
			return element.status!.toLowerCase().indexOf('done') > -1;
		});
		data.push.apply(data, dataDone);
		return data.slice();
	}
	const saveTask = (task: any, value: string) => {
		setShowLoading(true);
		let fields: any = Object.assign({}, document);
		fields[task.key] = value ? 'yes' : 'no';
		fields[task.key + 'Description'] = value;
		fields['type'] = task.documentType.id;
		fields['provider'] = wallet?.tenants[0]?.provider.id;
		delete fields['logs'];
		if (Object.keys(document).length === 0) {
			saveDocument(fields, task, value);
		} else {
			udaptedDocument(fields, task, value);
		}
	};
	const saveDocument = (fields: any, task: any, value: string) => {
		filesService.saveDocumentsTasks(user.id!, fields, providerTask?.id!).then(
			(res) => {
				if (res?.data) {
					updateWllet();
					document[task.key] = value ? 'yes' : 'no';
					document[task.key + 'Description'] = value;
					task.status = 'done';
					setShowLoading(false);
					if (tasksCompleted() == providerTask?.childStepTasks?.length) {
						setToastMessageError('Data saved successfully.');
						setShowToastAttachments(true);
						prop?.history.replace('/tabs/actions');
					}
					updateProgressBar(0);
				} else {
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			},
			(error) => {
				console.log(error);
				setShowLoading(false);
				setToastMessageError('Something went wrong,please try again.');
				setShowToastAttachments(true);
			}
		);
	};
	const udaptedDocument = (fields: any, task: any, value: string) => {
		filesService.updateDocuments(user.id!, fields, document.id).then(
			(res) => {
				if (res?.data) {
					updateWllet();
					document[task.key] = value ? 'yes' : 'no';
					document[task.key + 'Description'] = value;
					task.status = 'done';
					setShowLoading(false);
					if (tasksCompleted() == providerTask?.childStepTasks?.length) {
						setToastMessageError('Data saved successfully.');
						setShowToastAttachments(true);
						prop?.history.replace('/tabs/actions');
					}
					updateProgressBar(0);
				} else {
					setShowLoading(false);
					setToastMessageError('Something went wrong,please try again.');
					setShowToastAttachments(true);
				}
			},
			(error) => {
				console.log(error);
				setShowLoading(false);
				setToastMessageError('Something went wrong,please try again.');
				setShowToastAttachments(true);
			}
		);
	};
	const updateProgressBar = (countData: number) => {
		const count = tasksCompleted()!;
		if (countData > 0) {
			setProgress(countData * count);
		} else {
			setProgress(progressCount * (count == 0 ? 1 : count));
		}
	};
	const updateWllet = async () => {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		await dispatch(walletActions.authorization(tmpUserToken));
	};
	const swiperData = () => {
		return (
			<Swiper
				modules={[EffectCoverflow]}
				effect="coverflow"
				loop={true}
				initialSlide={1}
				coverflowEffect={{
					slideShadows: false,
				}}>
				{providerTask &&
					getTasks(providerTask?.childStepTasks).map((item: any, index: any) => (
						<SwiperSlide key={item.key}>
							<QuestionnaireComponent
								question={item}
								progressCount={progressCount}
								totalTasks={providerTask?.childStepTasks?.length}
								tasksCompleted={tasksCompleted()}
								saveTask={saveTask}
								progress={progress}
								document={document}
							/>
						</SwiperSlide>
					))}
			</Swiper>
		);
	};
	return (
		<IonPage
			className="top-page 
		ion-padding-bottom ion-padding-start ion-padding-end">
			<IonContent fullscreen className="max-550">
				<div className="ion-margin">
					<IonHeader className="ion-no-border">
						<IonToolbar className="no-background">
							<IonTitle className="ion-text-center title-missing-document" size="large">
								<IonIcon
									className="backArrowProfile"
									icon={arrowBack}
									onClick={() => prop?.history.replace('/tabs/actions')}></IonIcon>
								{'Questionnaire'}
							</IonTitle>
						</IonToolbar>
					</IonHeader>
					{swiperData()}
					<IonLoading
						cssClass="spinner"
						isOpen={showLoading}
						onDidDismiss={() => setShowLoading(false)}
						spinner="crescent"
						translucent={true}
					/>
					<IonToast
						isOpen={showToastAttachments}
						onDidDismiss={() => setShowToastAttachments(false)}
						message={toastMessageError}
						duration={3000}
					/>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Questionnaire;
