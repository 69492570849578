import { IonButton, IonContent, IonImg, IonModal, IonRow } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import closeIcon from '../../img/icon-close-modal.svg';
import './timer.css';
const Timer = (props: any) => {
	const { stopBenefit } = props;
	let initialTime = 5 * 60; // 5 minutes in seconds
	const [timeLeft, setTimeLeft] = useState(initialTime);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		const startIn: string = JSON.parse(localStorage.getItem('startIn')!);
		const timeDifference = moment().diff(startIn, 'minutes');
		if (timeDifference >= 5) {
			setTimeLeft(1);
		} else {
			initialTime = (5 - timeDifference) * 60;
			setTimeLeft(initialTime);
		}
		const intervalId = setInterval(() => {
			setTimeLeft((prevTime) => {
				if (prevTime === 60) {
					setShowModal(true);
				}
				if (prevTime - 1 === 0) {
					clearInterval(intervalId);
					stopBenefit();
				}
				return prevTime - 1;
			});
		}, 1000);
		return () => clearInterval(intervalId);
	}, []);

	const formatTime = (time: any) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	};

	const handleResetTimer = () => {
		setTimeLeft(initialTime);
		setShowModal(false);
	};

	const handleCloseModal = () => {
		setTimeLeft(59);
		setShowModal(false);
	};

	return (
		<div>
			<p className="timer-white">{formatTime(timeLeft)}</p>
			{showModal && (
				<IonModal id="app-version-modal" isOpen={true} backdropDismiss={false}>
					<IonContent className="app-version-modal">
						<div style={{ display: 'flex', justifyContent: 'end' }}>
							<div>
								<IonImg src={closeIcon} className="close-modal-img" onClick={() => setShowModal(false)} />
							</div>
						</div>
						<IonRow className="app-version-title">1:00 remaining</IonRow>
						<IonRow className="app-version-text">
							You have only 1 minute left to take a 20% on your account activation.
						</IonRow>
						<IonRow className="auto-center ion-padding add-padding">
							<IonButton
								onClick={() => handleResetTimer()}
								color={'primary'}
								shape="round"
								type="button"
								className="sign-in-width">
								I need more time
							</IonButton>
							<IonButton
								onClick={() => handleCloseModal()}
								color={'primary'}
								shape="round"
								type="button"
								className="sign-in-width">
								I'm fine
							</IonButton>
						</IonRow>
					</IonContent>
				</IonModal>
			)}
		</div>
	);
};

export default Timer;
