import moment from 'moment';
import { Dispatch } from 'redux';
import { WalletActivationConstants } from '../constants/walletActivationConstants';

export const WalletActivationActions = {
	setWalletActivation,
};

function setWalletActivation(data: any) {
	return async (dispatch: Dispatch) => {
		const payCompleted: boolean = JSON.parse(localStorage.getItem('payCompleted')!);
		let exclusions: any = {
			date: '',
			active: false,
			payCompleted: data?.tenants[0]?.provider?.npi != '' && payCompleted == null ? true : payCompleted,
		};
		if (data?.tenants[0]?.provider?.paymentStatus) {
			const monthsDifference = moment().diff(data?.tenants[0]?.provider?.paymentStatus?.updatedAt, 'months');
			exclusions = data?.tenants[0]?.provider?.paymentStatus;
			exclusions.approved =
				monthsDifference > data?.tenants[0]?.provider?.paymentStatus?.months ? false : exclusions.approved;
			exclusions.payCompleted = data?.tenants[0]?.provider?.npi != '' && payCompleted == null ? true : payCompleted;
		}
		if (exclusions) {
			dispatch(set(exclusions));
		}
	};

	function set(payload: any) {
		return { type: WalletActivationConstants.SET_WalletActivation, payload };
	}
}
