import { ROOT } from './api';

const API = {
	SIGN_IN: ROOT + '/auth/sign-in',
	AUTH_ME: ROOT + '/auth/me',
	TENANT: ROOT + '/tenant',
	CHANGE_PASSWORD: ROOT + '/auth/change-password',
	LOOK_UP_NPPES: ROOT + '/provider/npi-lookup',
	NPI_IN_USE: ROOT + '/provider/npi-in-use-organization',
	SIGN_UP: ROOT + '/auth/sign-up-individual',
	VERIFY_EMAIL: ROOT + '/auth/verify-email',
	RESEND_EMAIL: ROOT + '/auth/send-email-address-verification-code-email',
	TERMS: ROOT + '/document-type/terms-of-service',
	CONVERSATION: ROOT + '/conversation',
	MESSAGES: ROOT + '/message',
	PUSH_NOTIFICATION_TOKEN: ROOT + '/token',
	PUSH_NOTIFICATION: ROOT + '/notification',
	APP_VERSION: ROOT + '/app/version',
	APP_PAYMENT: ROOT + '/payment',
};

export default API;
