import { IonContent, IonImg, IonLoading, IonPage, IonRow, IonText, IonToolbar, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import buildingWalletImg from '../../img/Building_wallet.png';
import NucoLogo from '../../img/nuco-small-logo.svg';
import { messageService } from '../../services/message.service';
import { pushNotificationService } from '../../services/pushNotifications.service';
import { userService } from '../../services/user.service';
import { PushNotificationModule } from '../../shared/entity/pushNotification.module';
import BuildingWalletReady from '../buildingWalletReady/buildingWalletReady';
import './buildingWallet.css';
const BuildingWallet: React.FC<RouteComponentProps> = (props) => {
	const [showModal, setShowModal] = useState(false);
	const [present, dismiss] = useIonToast();
	let resolved = useSelector<any, any>((state) => state.login!);
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {
		const providerStage = JSON.parse(localStorage.getItem('provider-stage')!);
		if (providerStage && providerStage === '2_preEnrollment') {
			localStorage.setItem('WalletReadyPopUp', JSON.stringify(true));
			setShowModal(true);
		}
	}, []);
	useEffect(() => {
		try {
			const handleFocus = async () => {
				const providerStage = JSON.parse(localStorage.getItem('provider-stage')!);
				if (providerStage && providerStage != '2_preEnrollment') {
					setShowLoading(true);
					const token: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
					const res = await userService.userAuthorization(token);
					localStorage.setItem('provider-stage', JSON.stringify(res?.data?.tenants?.[0]?.provider?.stage));

					if (res?.data?.tenants?.[0]?.provider?.stage === '2_preEnrollment') {
						localStorage.setItem('WalletReadyPopUp', JSON.stringify(true));
						localStorage.setItem('provider-stage', JSON.stringify(res?.data?.tenants?.[0]?.provider?.stage));
						setShowModal(true);
					}
					setShowLoading(false);
				}
			};

			const handleBlur = () => {};

			document.addEventListener('visibilitychange', () => {
				if (document.visibilityState === 'visible') {
					handleFocus();
				} else {
					handleBlur();
				}
			});
			return () => {
				document.removeEventListener('visibilitychange', handleFocus);
				document.removeEventListener('visibilitychange', handleBlur);
			};
		} catch (error) {
			console.log('ERROR GETTING PROVIDER STAGE');
			console.log(error);
			setShowLoading(false);
		}
	}, []);

	useEffect(() => {
		pushNotificationService.pushNotificationsSubject$.subscribe((notification: any) => {
			showNotification(notification.title, notification.body, notification);
		});
		let walletReady: boolean = JSON.parse(localStorage.getItem('WalletReadyPopUp')!);
		if (walletReady) {
			setShowModal(true);
		}
	}, []);
	const showNotification = (title: string, message: string, notification: any) => {
		const handlePreResult = handleNotificationCountType(notification);
		handlePreResult.pre();

		present({
			buttons: [
				{
					side: 'start',
					handler: () => {
						handlePreResult.dismiss();
						dismiss();
					},
				},
			],
			header: title,
			message,
			position: 'top',
			duration: 5000,
			cssClass: 'pushNotification',
			onDidDismiss: () => {},
		});
	};
	const handleNotificationCountType = (notification: any) => {
		switch (notification.data.type) {
			case PushNotificationModule.NOTIFICATION_TYPES.WALLET_READY:
				messageService.messageNotificationSubject$.next(notification);
				localStorage.setItem('WalletReadyPopUp', JSON.stringify(true));
				localStorage.removeItem('NuCoUserStage');
				setShowModal(true);
				return { pre: doMessageNotificationAction, dismiss: onDismissMessageNotificationAction };
			default:
				return { pre: () => {}, dismiss: () => {} };
		}
	};
	const doMessageNotificationAction = () => {};

	const onDismissMessageNotificationAction = () => {
		localStorage.setItem('WalletReadyPopUp', JSON.stringify(true));
		setShowModal(true);
	};
	return (
		<IonPage className="loginPage ">
			{showModal && <BuildingWalletReady {...props}></BuildingWalletReady>}
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550 building-wallet-background ">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>

			<IonContent className="ion-padding max-550 building-wallet-background">
				<IonRow className="building-wallet">
					<IonRow className="building-wallet-main-text">
						<IonRow className="bw-img-row">
							<IonImg src={buildingWalletImg}></IonImg>
						</IonRow>
						<IonRow className="bw-column">
							<IonText className="bw-text">For support please contact us at</IonText>
							<IonText className="bw-text padding-top-20px underline">support@nucocred.com</IonText>
							<IonText
								onClick={() => {
									resolved.resolved = null;
									sessionStorage.setItem('backAction', 'true');
									props?.history.push('/');
								}}
								className="bw-text padding-top-20px underline">
								Sign Out
							</IonText>
						</IonRow>
					</IonRow>
				</IonRow>
			</IonContent>
			<IonLoading cssClass="spinner" isOpen={showLoading} spinner="crescent" translucent={true} />
		</IonPage>
	);
};
export default BuildingWallet;
