import { useState } from 'react';

import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { isPlatform } from '@ionic/react';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

export interface UserPhoto {
	filepath: string;
	base64String?: string;
	blob?: any;
	type?: string;
}
export interface UserLicenseData {
	address1: string;
	birthDate: string;
	expirationDate: string;
	firstName: string;
	lastName: string;
	middleName: string;
	number: string;
	state?: string;
}
export function usePhotoGallery() {
	const [photos, setPhotos] = useState<UserPhoto[]>([]);
	const [license, setLicense] = useState<UserLicenseData>();
	async function permissionGranted() {
		const permission = await Camera.checkPermissions();
		if (permission.camera === 'granted') {
			console.log('CAMERA PERMISSION GRANTED');
			return true;
		} else if (permission.camera === 'denied') {
			console.log('CAMERA PERMISSION DENIED');
			return false;
		} else if (permission.camera === 'limited') {
			console.log('CAMERA PERMISSION LIMITED');
			return false;
		} else {
			console.log('REQUEST CAMERA PERMISSION');
			return true;
		}
	}
	const openAppSettings = async () => {
		try {
			await NativeSettings.open({
				optionAndroid: AndroidSettings.ApplicationDetails,
				optionIOS: IOSSettings.App,
			});
		} catch (error) {
			console.error('CANT OPEN APP SETTINGS', error);
		}
	};
	const takePhoto = async (type: string) => {
		try {
			const permission = await permissionGranted();
			if (permission) {
				const cameraPhoto = await Camera.getPhoto({
					resultType: CameraResultType.Base64,
					source: CameraSource.Camera,
					quality: 100,
				});
				const fileName = new Date().getTime() + '.png';
				const base64 = 'data:image/jpeg;base64,' + cameraPhoto.base64String;
				const blob = getBlob(base64.split(',')[1], 'img/png');
				removePhoto(type);
				const newPhotos = [
					{
						filepath: fileName,
						base64String: base64,
						blob: blob,
						type: type,
					},
					...photos,
				];
				setPhotos(newPhotos);
			} else {
				openAppSettings();
			}
		} catch (error) {
			console.log('camera error');
			console.log(error);
		}
	};

	const takeSelfie = async () => {
		try {
			const permission = await permissionGranted();
			if (permission) {
				let cameraPhoto = null;
				if (isPlatform('ios')) {
					cameraPhoto = await Camera.getPhoto({
						resultType: CameraResultType.Base64,
						source: CameraSource.Camera,
						quality: 100,
						correctOrientation: false,
						direction: CameraDirection.Front,
					});
				} else {
					cameraPhoto = await Camera.getPhoto({
						resultType: CameraResultType.Base64,
						source: CameraSource.Camera,
						quality: 100,
						direction: CameraDirection.Front,
					});
				}

				const fileName = new Date().getTime() + '.png';
				const base64 = 'data:image/jpeg;base64,' + cameraPhoto.base64String;
				const blob = getBlob(base64.split(',')[1], 'img/png');
				const newPhotos = [
					{
						filepath: fileName,
						base64String: base64,
						blob: blob,
					},
					...photos,
				];
				setPhotos(newPhotos);
			} else {
				openAppSettings();
			}
		} catch (error) {
			console.log(error);
		}
	};
	const removePhoto = (type: string) => {
		let result = photos.findIndex(function (element) {
			return element.type === type;
		});
		if (result >= 0) {
			photos.splice(result, 1);
		}
	};
	const cleanPhotos = () => {
		setPhotos([]);
	};
	return {
		photos,
		takePhoto,
		takeSelfie,
		license,
		cleanPhotos,
	};
}
const getBlob = (b64Data?: string, contentType?: string, sliceSize: number = 512) => {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	let byteCharacters = atob(b64Data!);
	let byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		let slice = byteCharacters.slice(offset, offset + sliceSize);

		let byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		let byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}
	let blob = new Blob(byteArrays, { type: contentType });
	return blob;
};
