import API from '../shared/environement/api-routes';

import Interceptor from '../shared/environement/interceptor';
export const onBoardingService = {
	lookUpNppes,
	lookUpByNpi,
	npiInUse,
	createAccount,
	confirmEmail,
	uploadFile,
	updateGraduation,
	resendEmail,
	getPrivacyTerms,
	updateProviderOnbLastStep,
	buildWallet,
	buildWalletValidateFields,
	sendEmailRequestWalletActivationManually,
};

async function lookUpNppes(name: string, lastName: string, state: string) {
	try {
		return await Interceptor.get(API.LOOK_UP_NPPES + `/?first_name=${name}&last_name=${lastName}&state=${state}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}

async function lookUpByNpi(npi: string) {
	try {
		return await Interceptor.get(API.LOOK_UP_NPPES + `/?number=${npi}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}

async function npiInUse(npi: string) {
	try {
		return await Interceptor.get(API.NPI_IN_USE + `/?npi=${npi}`, {});
	} catch (error) {
		return Promise.reject(error);
	}
}

async function createAccount(email: string, password: string, npi: string, recaptchaToken: any) {
	try {
		return await Interceptor(API.SIGN_UP, {
			method: 'POST',
			data: { email: email, password: password, npi: npi, graduationYear: '', recaptchaToken },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function confirmEmail(code: string) {
	try {
		return await Interceptor(API.VERIFY_EMAIL, {
			method: 'PUT',
			data: { token: code },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function resendEmail(recaptchaToken: any) {
	try {
		return await Interceptor(API.RESEND_EMAIL, { method: 'POST', data: { recaptchaToken } });
	} catch (error) {
		return Promise.reject(error);
	}
}
async function uploadFile(id: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${id}/document`, {
			method: 'POST',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function updateGraduation(tenant: string, provider: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenant}/provider/${provider}`, {
			method: 'PUT',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}

async function getPrivacyTerms() {
	try {
		return await Interceptor.get(API.TERMS, {});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function updateProviderOnbLastStep(tenant: string, provider: string, data: any) {
	try {
		return await Interceptor(API.TENANT + `/${tenant}/provider/${provider}/mobile`, {
			method: 'PUT',
			data: data,
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function buildWallet(id: string, tenant: string) {
	try {
		return await Interceptor(API.TENANT + `/${tenant}/provider/${id}/build-wallet`, {
			method: 'POST',
			data: { id: id },
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function buildWalletValidateFields(
	npi: any,
	providerType: any,
	graduationYear: any,
	birthDate: any,
	socialSecurity: any,
	documentIds: any,
	tenant: string
) {
	try {
		return await Interceptor(API.TENANT + `/${tenant}/provider/wallet-validate-license`, {
			method: 'POST',
			data: {
				npi: npi,
				providerType: providerType,
				socialSecurity: socialSecurity,
				graduationYear: graduationYear,
				birthDate: birthDate,
				documentIds: documentIds,
			},
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
async function sendEmailRequestWalletActivationManually(provider: any, tenant: string) {
	try {
		return await Interceptor(API.TENANT + `/${tenant}/provider/request-wallet-activation-manually`, {
			method: 'POST',
			data: {
				provider: provider,
			},
		});
	} catch (error) {
		return Promise.reject(error);
	}
}
