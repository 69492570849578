import { IonAvatar, IonCol, IonRow, IonText } from '@ionic/react';
import { ChatToolbarModule as Module } from '../../../shared/entity/chatToolbar.module';
import './chatToolbar.css';

const ChatToolbar: React.FC<Module.ChatToolbar> = (props: Module.ChatToolbar) => {
	const chatToolbar: Module.ChatToolbar = props;

	const onBack = () => {
		chatToolbar.onBack();
	};

	return (
		<IonRow className="ChatToolbar">
			<IonCol size="2" className="backButton">
				{/* <IonButton
					onClick={() => {
						onBack();
					}}
					fill="clear">
					<IonIcon class="backIcon" icon={arrowLeft}></IonIcon>
				</IonButton> */}
			</IonCol>
			<IonCol size="1" className="avatarContainer">
				<IonAvatar className="avatarToolbar">
					<img src={chatToolbar.avatar} />
				</IonAvatar>
			</IonCol>
			<IonCol className="nameContainer">
				<IonText className="name">{chatToolbar.name}</IonText>
			</IonCol>
		</IonRow>
	);
};

export default ChatToolbar;
