import { ActionEntity } from "../../shared/entity/actionEntity";
import { PendingEntity } from "../../shared/entity/pendingTaskEntity";
import { PendingActionsConstants } from "../constants/PendingActionsConstants";

const initialUser: PendingEntity = {
    providerTasks: []
};
const pendingActionsReducer = (state: PendingEntity = initialUser, action: ActionEntity) => {
    switch (action.type) {
        case PendingActionsConstants.SET_PENDING_ACTIONS:
            return action.payload;
        case PendingActionsConstants.UPDATE_PENDING_ACTIONS:
            return {
                ...state,
                providerTasks: action.payload.providerTasks
            };     
        default:
            return state;
    }
}
export default pendingActionsReducer;