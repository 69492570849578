import { IonContent, IonMenuToggle, IonPage, isPlatform } from '@ionic/react';
import CSS from 'csstype';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { EffectCoverflow, Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import CAQHLogin from '../../pages/caqh-login/CAQHLogin';
import { tabsActions } from '../../redux/actions/tabsActions';
import { PropsDataEntity } from '../../shared/entity/propsData.module';
import move from '../../utils/move';
import ABMS from '../Cards/ABMS/ABMS';
import BoardActions from '../Cards/BoardActions/BoardActions';
import DEALicense from '../Cards/DEALicense/DEALicense';
import DriversLicense from '../Cards/DriversLicense/DriversLicense';
import LEIE from '../Cards/LEIE/LEIE';
import MedicaidPending from '../Cards/MedicaidCard/MedicaidPending';
import MedicalLicense from '../Cards/MedicalLicense/MedicalLicense';
import MedicarePending from '../Cards/MedicareCard/MedicarePending';
import NPPES from '../Cards/NPPES/NPPES';
import Passport from '../Cards/Passport/passport';
import PayerActions from '../Cards/Payer/Payer';
import SamExclusions from '../Cards/SamExclusions/SamExclusions';
import ShareWallet from '../Cards/ShareWallet/ShareWallet';

import { WalletActivationActions } from '../../redux/actions/walletActivationActions';
import DigitalSignature from '../Cards/DigitalSignature/DigitalSignature';
import './CredentialsWeb.css';
const CARD_OFFSET = 40;
const CARD_OFFSET_BOTTOM = 10;
const SCALE_FACTOR = 0;
const cardEvents = ['out'];
interface MyWalletProps extends RouteComponentProps {
	handleValueFromMyWallet: (value: number) => void;
}
const CredentialsWeb: React.FC<MyWalletProps> = ({ handleValueFromMyWallet, ...props }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const navigation = useSelector<any, any>((state) => state.tabs.wallet!);
	const cardSelected = useSelector<any, any>((state) => state.tabs.selected!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);

	const [canOpenApp, setCanOpenApp] = useState(true);
	const [openedApp, setOpenedApp] = useState<any>(null);
	const CARDS_INFO = [
		{
			id: '2234514754c26442953e982',
			name: 'dea-license',
			title: 'DEA License',
			zoom: false,
			props: props,
			class: 'dea-card',
		},
		{
			id: 'sdf3',
			name: 'drivers-license',
			title: "Driver's License",
			zoom: false,
			props: props,
			class: 'drv-license-card',
		},

		{
			id: 'sdfsd7878sdf',
			name: 'nppes',
			title: 'National Provider Identifier (NPI)',
			zoom: false,
			class: 'nppes-card',
		},

		{
			id: '611ff514754c26442953e982',
			name: 'passport',
			title: 'Passport',
			zoom: false,
			props: props,
			class: 'passport-card ',
		},
		{
			id: '21234523423S9431',
			name: 'caqh-login',
			title: 'Passwords',
			zoom: false,
			class: 'passwords-card',
		},
		{
			id: '145119745',
			name: 'sam-exclusion',
			title: 'SAM Exclusions',
			zoom: false,
			props: props,
			class: 'sam-exclusion-card',
		},
		{
			id: '21234523423S94312',
			name: 'signature-card',
			title: 'Digital Signature',
			zoom: false,
		},
		{
			id: '212345147SS982',
			name: 'abms',
			title: 'ABMS',
			zoom: false,
			class: 'abms-card',
		},
		{
			id: '21234523423S982',
			name: 'board-actions',
			title: 'Board Actions',
			zoom: false,
			class: 'board-actions-card',
		},
		{
			id: '12313',
			name: 'medical-license',
			title: 'Medical License(s)',
			zoom: false,
			class: 'medical-license-card',
		},
		{
			id: '21234523423S98243',
			name: 'leie',
			title: 'LEIE (OIG)',
			zoom: false,
			class: 'leie-card',
		},
		{
			id: '45718745',
			name: 'medicare-pending',
			title: 'Medicare',
			zoom: false,
			class: 'medicare-card',
		},

		{
			id: '14599745',
			name: 'medicaid-pending',
			title: 'Medicaid',
			zoom: false,
			class: 'medicaid-card',
		},

		{
			id: '21234523423S943',
			name: 'payer-actions',
			title: 'My Payors',
			zoom: false,
			class: 'payors-card',
		},
	];
	const [isVisible, setIsVisible] = useState(true);
	const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

	const [cards, setCards] = useState(CARDS_INFO);

	const [readySwiper, setReadySwiper] = useState(false);

	useEffect(() => {
		if (navigation) {
			cards.forEach((element) => {
				element.zoom = false;
			});
			dispatch(tabsActions.selected(false));
			dispatch(tabsActions.wallet(false));
			closeApp();
		}
	}, [navigation]);
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && data) {
				dispatch(WalletActivationActions.setWalletActivation(data));
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, [data]);
	const closeApp = () => {
		setOpenedApp(undefined);
		setSwiperInstance(undefined);
		setReadySwiper(false);
		setCanOpenApp(true);
	};

	const betweenApp = (card: any) => {
		setTimeout(() => {
			setCanOpenApp(true);
			setOpenedApp(card);
		}, 500);
	};
	const eventValidate = (id: string) => {
		const event = cardEvents.find((element) => element === id);
		if (event) {
			return false;
		} else {
			return true;
		}
	};
	const onDragEnd = (event: any, info: any, from: number) => {
		const valid = eventValidate(event.target.id);

		if (info.point.x > 100) {
			let x;
			let current = 0;
			cards.forEach((element, index) => {
				element.zoom = true;
				if (element.name === openedApp.name) {
					current = index;
				}
				if (current + 1 >= cards.length) {
					x = cards[0];
					x.zoom = true;
				} else {
					if (index === current + 1) {
						x = element;
						x.zoom = true;
					}
				}
			});

			closeApp();
			betweenApp(x);
		} else {
			if (info.point.x < 100) {
				console.log(info.point.x);
				let x;
				let current = 100000;
				cards.forEach((element, index) => {
					element.zoom = true;
					if (element.name === openedApp.name) {
						current = index;
					}
					if (current - 1 < 0) {
						x = cards[cards.length - 1];
						x.zoom = true;
					} else {
						if (cards.length - 1 >= current - 1) {
							x = cards[current - 1];
							x.zoom = true;
						}
					}
				});

				closeApp();
				betweenApp(x);
			} else {
				if (!valid) {
					cards.forEach((element) => {
						element.zoom = false;
					});
					dispatch(tabsActions.selected(false));
					closeApp();
				}
			}
		}
	};
	const onTapEnd = (event: any) => {
		const eventData = event.target.id ? event.target.id : event.target.name;
		const valid = eventValidate(eventData);
		if (!valid) {
			closeCards();
		}
	};
	const closeCards = () => {
		cards.forEach((element) => {
			element.zoom = false;
		});
		dispatch(tabsActions.selected(false));
		closeApp();
	};

	const moveToEnd = (info: any, from: any, item: any) => {
		if (info.point.y > 400) {
			console.log(info.point.y);
			setCards(move(cards, from, 0));
		}
		if (info.velocity.y > 300) {
			console.log(info.point.y);
			setCards(move(cards, from, 0));
		}
	};
	const showShareCard = () => {
		setOpenedApp({
			id: '21234523423S94314',
			name: 'share',
			title: 'Share Your Wallet',
			zoom: true,
		});
	};
	const CardType: React.FC<PropsDataEntity> = ({ data }) => {
		switch (data.name) {
			case 'medical-license':
				return !data.zoom ? <MedicalLicense title={data.title} zoom={data.zoom} /> : null;
			case 'drivers-license':
				return !data.zoom ? <DriversLicense title={data.title} zoom={data.zoom} /> : null;
			case 'passport':
				return !data.zoom ? <Passport title={data.title} zoom={data.zoom} /> : null;
			case 'nppes':
				return !data.zoom ? <NPPES title={data.title} zoom={data.zoom} /> : null;
			case 'dea-license':
				return !data.zoom ? (
					<DEALicense props={data.props} title={data.title} zoom={data.zoom} showShareCard={showShareCard} />
				) : null;
			case 'abms':
				return !data.zoom ? <ABMS title={data.title} zoom={data.zoom} showShareCard={showShareCard} /> : null;
			case 'board-actions':
				return !data.zoom ? <BoardActions title={data.title} zoom={data.zoom} showShareCard={showShareCard} /> : null;
			case 'payer-actions':
				return !data.zoom ? <PayerActions title={data.title} zoom={data.zoom} showShareCard={showShareCard} /> : null;
			case 'medicare-pending':
				return !data.zoom ? (
					<MedicarePending title={data.title} zoom={data.zoom} showShareCard={showShareCard} />
				) : null;
			case 'medicaid-pending':
				return !data.zoom ? (
					<MedicaidPending title={data.title} zoom={data.zoom} showShareCard={showShareCard} />
				) : null;
			case 'sam-exclusion':
				return !data.zoom ? (
					<SamExclusions title={data.title} zoom={data.zoom} props={data.props} showShareCard={showShareCard} />
				) : null;
			case 'leie':
				return !data.zoom ? <LEIE title={data.title} zoom={data.zoom} /> : null;
			case 'caqh-login':
				return !data.zoom ? (
					<CAQHLogin title={data.title} zoom={data.zoom} props={data.props} showShareCard={showShareCard} />
				) : null;
			case 'share':
				return !data.zoom ? <ShareWallet title={data.title} zoom={data.zoom} props={data.props} /> : null;
			case 'signature-card':
				return !data.zoom ? (
					<DigitalSignature props={data.props} title={data.title} zoom={data.zoom} showShareCard={showShareCard} />
				) : null;
			default:
				return null;
		}
	};
	const CardTypeZoomed: React.FC<PropsDataEntity> = ({ data }) => {
		switch (data.name) {
			case 'medical-license':
				return <MedicalLicense title={data.title} zoom={data.zoom} />;
			case 'drivers-license':
				return <DriversLicense title={data.title} zoom={data.zoom} props={data.props} />;
			case 'passport':
				return <Passport title={data.title} zoom={data.zoom} props={data.props} />;
			case 'nppes':
				return <NPPES title={data.title} zoom={data.zoom} />;
			case 'dea-license':
				return <DEALicense title={data.title} zoom={data.zoom} showShareCard={showShareCard} props={data.props} />;
			case 'abms':
				return <ABMS title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'board-actions':
				return <BoardActions title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'payer-actions':
				return <PayerActions title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'medicare-pending':
				return <MedicarePending title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'medicaid-pending':
				return <MedicaidPending title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'sam-exclusion':
				return <SamExclusions title={data.title} zoom={data.zoom} props={data.props} showShareCard={showShareCard} />;
			case 'leie':
				return <LEIE title={data.title} zoom={data.zoom} />;
			case 'caqh-login':
				return <CAQHLogin title={data.title} zoom={data.zoom} showShareCard={showShareCard} />;
			case 'share':
				return <ShareWallet title={data.title} zoom={data.zoom} props={data.props} />;
			case 'signature-card':
				return (
					<DigitalSignature title={data.title} zoom={data.zoom} showShareCard={showShareCard} props={data.props} />
				);
			default:
				return null;
		}
	};
	const changeSlide = (index: number) => {
		if (swiperInstance) {
			cards.forEach((element) => {
				if (cards[swiperInstance.realIndex].name === element.name) {
					element.zoom = true;
				} else {
					element.zoom = false;
				}
			});
			if (!readySwiper) {
				setReadySwiper(true);
			}
			setOpenedApp(cards[swiperInstance.realIndex]);
		}
	};

	function swiperData() {
		let index = 0;
		if (openedApp) {
			index = cards.findIndex(function (element) {
				return element.name === openedApp.name;
			});
		}
		return (
			<Swiper
				modules={[EffectCoverflow]}
				effect="coverflow"
				onSwiper={setSwiperInstance}
				initialSlide={index}
				loop={true}
				coverflowEffect={{
					rotate: 20,
					stretch: 25,
					depth: 250,
					modifier: 1,
					slideShadows: false,
				}}
				onSlideChange={(slide) => changeSlide(slide.realIndex)}>
				{cards.map((item: any) =>
					item.name === cards[index].name ? (
						swiperInstance && readySwiper ? (
							<SwiperSlide key={item.name}>
								<motion.div
									transition={{ duration: isPlatform('android') ? 1.9 : 0.5 }}
									exit={{ opacity: 0, y: '100vh' }}>
									<CardTypeZoomed data={item} />
								</motion.div>
							</SwiperSlide>
						) : (
							<SwiperSlide key={item.name}>
								<motion.div
									initial={{ y: '100vh', opacity: 0, scale: 0.5 }}
									animate={{ y: '0', x: 0, opacity: 1, scale: 1 }}
									transition={{ duration: 1 }}
									exit={{ opacity: 0, y: '100vh' }}>
									<CardTypeZoomed data={item} />
								</motion.div>
							</SwiperSlide>
						)
					) : (
						<SwiperSlide key={item.name}>
							<CardType data={item} />
						</SwiperSlide>
					)
				)}
			</Swiper>
		);
	}
	function showCardData() {
		let index = 0;
		if (openedApp) {
			index = cards.findIndex(function (element) {
				return element.name === openedApp.name;
			});
		}
		return (
			<motion.div initial={{ x: '100%' }} animate={{ x: 0 }} transition={{ duration: 1 }}>
				<div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
					<div style={{ margin: '20px' }}>
						<CardTypeZoomed data={cards[index]} />
					</div>
				</div>
			</motion.div>
		);
	}
	function optionTitle() {
		switch (props.location.pathname) {
			case '/tabs/wallet':
				return 'My Wallet';
			case 'actions':
				return 'My Tasks';
			case 'chat':
				return 'Ambassador';
			case 'history':
				return 'Calendar';

			default:
				return 'Settings';
		}
	}
	return (
		<IonPage id="main-content">
			<IonContent>
				<div className="content-menu-wallet">
					<div className="content-title-wallet">
						<span>My Wallet</span>
					</div>
					<div className="container-wallet-cards">
						{cards.map((item, index) =>
							item?.name == 'payer-actions' && !walletActivation?.payorEnrollmentStatus ? (
								<div
									className={` card-element ${item.class}`}
									key={index}
									onClick={() => history.push('/tabs/activate-payors')}>
									{item.title}
								</div>
							) : (
								<IonMenuToggle key={index} onClick={() => handleValueFromMyWallet(index)}>
									<div className={` card-element ${item.class}`} key={index}>
										{item.title}
									</div>
								</IonMenuToggle>
							)
						)}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

const cardStyle: CSS.Properties = {
	position: 'absolute',
	width: '92vw',
	maxWidth: '400px',
	height: '455px',
	transformOrigin: 'top center',
	listStyle: 'none',
};

const cardWrapStyle: CSS.Properties = {
	position: 'relative',
	width: '290px',
	display: 'flex',
	justifyContent: 'center',
	padding: 0,
};
const cardWrapStyleBottom: CSS.Properties = {
	position: 'relative',
	width: '100%',
	height: '190px',
	display: 'flex',
	justifyContent: 'center',
	overflow: 'hidden',
	padding: 0,
	marginBlockEnd: 0,
};

export default CredentialsWeb;
