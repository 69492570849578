import { IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import AccordionDea from '../../../components/AccordionDea/AccordionDea';
import PdfIcon from '../../../img/PDF_ICN.svg';
import editImgIcon from '../../../img/edit-image.svg';
import IconChangeImage from '../../../img/icon-change-img.svg';
import interrogation from '../../../img/icons/black-interrogation.svg';
import IconUpload from '../../../img/uploadfile_icon.svg';
import { DeaActions } from '../../../redux/actions/deaActions';
import { filesService } from '../../../services/files.service';
import AddressStates from '../../../shared/addressStates';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import AboutThat from '../../AboutThat/AboutThat';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import './DEALicense.css';
const DEALicense: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard, props }) => {
	const dispatch = useDispatch();
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const deaData = useSelector<ReduxStateEntity, any>((state) => state.dea!);
	const dea = useSelector<ReduxStateEntity, any[]>((state) => deaData.dea!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__dea-license');
	const [documentDescription, setDocumentDescription] = useState(deaData?.info);
	const [showDriversLicenseModal, setShowDriversLicenseModal] = useState(false);
	const [showBackImg, setShowBackImg] = useState(false);
	const [deaImage, setDeaImage] = useState<any>();
	useEffect(() => {
		if (data) {
			dispatch(DeaActions.setLicense(data, deaData));
		}
	}, [data]);
	useEffect(() => {
		if (deaData?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'dea-number').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								setDocumentDescription(res.data.description);
								dispatch(DeaActions.setLicense(data, { info: res.data.description }));
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [deaData]);
	useEffect(() => {
		if (deaData?.info !== '') {
			setDocumentDescription(deaData.info);
		}
	}, [deaData, documentDescription]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__dea-license');
		} else {
			setBack('card card__dea-license deaNoRotate');
		}
	};

	const openPdfSite = async (url: string) => {
		await window.open(url);
	};
	function expandImage(element: any) {
		setDeaImage(element);
		setShowDriversLicenseModal(true);
	}
	const AccordionContent = () => {
		return (
			<IonRow className="ion-margin  margin-top-dea-content ">
				{dea.length > 1 ? (
					dea?.map((element, index) => (
						<AccordionDea
							key={index}
							title={AddressStates.label(element.state)!}
							active={element.status!}
							lastItem={dea.length - 1 === index}>
							<IonCol size="7">
								<IonRow className="content-data-all-dea">
									<IonText className="text-title-content-dea-card">Dea Number</IonText>
									<IonRow>
										<IonText className="text-value-content-dea-card">{element.number}</IonText>
										<CopyClipboard text={element.number} blackIcon={true} />
									</IonRow>
									<IonText className="text-title-content-dea-card">Schedule:</IonText>
									<IonText className="text-value-content-dea-card">{element.deaSchedule}</IonText>
									<IonText className="text-title-content-dea-card">Expiration Date:</IonText>
									<IonText className="content-dea-card-exp-date">
										{moment(element.expirationDate).format('MMM DD YYYY')}
									</IonText>
								</IonRow>
							</IonCol>
							<IonCol size="5">
								<IonRow className="row-dea-one-card-img">
									{element.imgAttachment?.length === 0 && !element.pdfData ? (
										<div
											className="upload-media-container-no-image-dea"
											onClick={() =>
												props?.history.push({
													pathname: '/tabs/dea-license',
													state: { deaLicense: element },
												})
											}>
											<img className="ion-margin-bottom" src={IconUpload} />

											<IonText>Upload file</IonText>
										</div>
									) : (
										<div className="container-img-pdf-dea">
											{element.imgAttachment.length > 0 ? (
												<div key={index} className="container-no-border-dea-img">
													<img
														className="img-radius-dea"
														src={element.imgAttachment[0].downloadUrl}
														onClick={() => expandImage(element)}
													/>
												</div>
											) : null}

											{element.pdfData ? (
												<div
													key={index + 1}
													className="container-no-border-dea-img row-pics-dea-card"
													onClick={() => openPdfSite(element.pdfData.downloadUrl)}>
													<img className="ion-margin-bottom" src={PdfIcon} />
													<IonText className="overflow-text">{element.pdfData.name}</IonText>
												</div>
											) : null}
										</div>
									)}
									{element.imgAttachment?.length > 0 || element.pdfData ? (
										<div
											className="container-edit-dea-attachment"
											onClick={() =>
												props?.history.push({
													pathname: '/tabs/dea-license',
													state: { deaLicense: element },
												})
											}>
											<div className="edit-dea-attachment">
												<div className="div-edit-attachment-container">
													<IonText className="">Edit</IonText>
													<IonIcon icon={editImgIcon}></IonIcon>
												</div>
											</div>
										</div>
									) : null}
								</IonRow>
							</IonCol>
						</AccordionDea>
					))
				) : (
					<AccordionDea title={AddressStates.label(dea[0].state)!} active={dea[0].status!} lastItem={true} open={true}>
						<IonCol size="7">
							<IonRow className="content-data-all-dea">
								<IonText className="text-title-content-dea-card">Dea Number</IonText>
								<IonRow>
									<IonText className="text-value-content-dea-card">{dea[0].number}</IonText>
									<CopyClipboard text={dea[0].number} blackIcon={true} />
								</IonRow>
								<IonText className="text-title-content-dea-card">Schedule:</IonText>
								<IonText className="text-value-content-dea-card">{dea[0].deaSchedule}</IonText>
								<IonText className="text-title-content-dea-card">Expiration Date:</IonText>
								<IonText className="content-dea-card-exp-date">
									{moment(dea[0].expirationDate).format('MMM DD YYYY')}
								</IonText>
							</IonRow>
						</IonCol>
						<IonCol size="5">
							<IonRow className="row-dea-one-card-img">
								{dea[0].imgAttachment?.length === 0 && !dea[0].pdfData ? (
									<div
										className="upload-media-container-no-image-dea"
										onClick={() =>
											props?.history.push({
												pathname: '/tabs/dea-license',
												state: { deaLicense: dea[0] },
											})
										}>
										<img className="ion-margin-bottom" src={IconUpload} />

										<IonText>Upload file</IonText>
									</div>
								) : (
									<div className="container-img-pdf-dea">
										{dea[0].imgAttachment.length > 0 ? (
											<div className="container-no-border-dea-img">
												<img
													className="img-radius-dea"
													src={dea[0].imgAttachment[0].downloadUrl}
													onClick={() => expandImage(dea[0])}
												/>
											</div>
										) : null}

										{dea[0].pdfData ? (
											<div
												className="container-no-border-dea-img row-pics-dea-card"
												onClick={() => openPdfSite(dea[0].pdfData.downloadUrl)}>
												<img className="ion-margin-bottom" src={PdfIcon} />
												<IonText className="overflow-text">{dea[0].pdfData.name}</IonText>
											</div>
										) : null}
									</div>
								)}
								{dea[0].imgAttachment?.length > 0 || dea[0].pdfData ? (
									<div
										className="container-edit-dea-attachment"
										onClick={() =>
											props?.history.push({
												pathname: '/tabs/dea-license',
												state: { deaLicense: dea[0] },
											})
										}>
										<div className="edit-dea-attachment">
											<div className="div-edit-attachment-container">
												<IonText className="">Edit</IonText>
												<IonIcon icon={editImgIcon}></IonIcon>
											</div>
										</div>
									</div>
								) : null}
							</IonRow>
						</IonCol>
					</AccordionDea>
				)}
			</IonRow>
		);
	};

	return (
		<div className={back}>
			<div className="container-dea">
				<IonRow className={`card___face ${!flip ? 'wrapper-zoom-dea' : ''}`}>
					{!flip ? (
						<IonRow className="article-header-dea">
							<IonRow className="category-title">
								<IonText className="ion-padding-right">{title}</IonText>
								{!flip && dea.length > 0 ? (
									<IonText className="bubble-dea">
										<span>{dea.length}</span>
									</IonText>
								) : null}
							</IonRow>
							<IonIcon id="about" className="icon-about-dea" icon={interrogation} onClick={() => onFlip()}></IonIcon>
						</IonRow>
					) : null}
					{!flip && dea.length === 0 ? (
						<IonRow className="center-no-info-card">
							<NoInfoCard textColor="text-color-black" blackIcon={true} cardType="dea" />{' '}
						</IonRow>
					) : null}
					{!flip ? dea.length > 0 ? <AccordionContent /> : null : null}
				</IonRow>

				{flip ? (
					<AboutThat
						onFlip={onFlip}
						description={documentDescription}
						title={title}
						cssBackground="dea-background"
						cssTextColor="about-black"
					/>
				) : null}
				<Modal
					showCloseIcon={false}
					open={showDriversLicenseModal}
					onClose={() => setShowDriversLicenseModal(false)}
					modalId="modal"
					center>
					{showBackImg ? (
						<div>
							<img
								className="modal-img-license"
								src={deaImage?.imgAttachment[1].downloadUrl}
								onClick={() => setShowDriversLicenseModal(true)}
							/>
						</div>
					) : (
						<div>
							<img
								className="modal-img-license"
								src={deaImage?.imgAttachment[0].downloadUrl}
								onClick={() => setShowDriversLicenseModal(true)}
							/>
						</div>
					)}
					<IonRow className="ion-no-border modal-cred-footer">
						<img
							onClick={() => setShowBackImg(!showBackImg)}
							className="auto-center"
							src={IconChangeImage}
							style={{ width: 60 }}
						/>
					</IonRow>
				</Modal>
			</div>
		</div>
	);
};

export default DEALicense;
