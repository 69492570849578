import { IonCard } from "@ionic/react";
import { HistoryCardModule as Module } from "../../../shared/entity/historyCard.module";
import HistoryDetailRow from "./HistoryDetailRow";
import './HistoryCard.css';

const HistoryCard: React.FC<Module.HistoryCard> = (props: Module.HistoryCard) => {

  const historyCard: Module.HistoryCard = props;

  const hasRows = (): boolean => {
    return historyCard && historyCard.rows.length > 0;
  }

  return (
      <IonCard className="historyCard regular-card">
        { hasRows() && historyCard.rows.map((row, i) => {
            return <HistoryDetailRow 
                      key={i} 
                      id={row.id} 
                      date={row.date} 
                      topic={row.topic} 
                      status={row.status} 
                      description={row?.description}
                      showTimeline={historyCard?.showTimeline}
                      icon={row?.icon}
                    />
          })
        }      
      </IonCard>
  );
}

export default HistoryCard;
