import { IonCol, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

import { LeieFieldsEntity } from '../../shared/entity/leieFieldsEntity';
import './AccordionLeieActions.css';
const AccordionLeieActions: React.FC<LeieFieldsEntity> = (props) => {
	const { name, dob, address1, status, orderDate, number, type, attachments } = props;
	const [isOpen, setOpen] = useState(false);
	const [showImageModal, setShowImageModal] = useState(false);
	function dobNumber(params: String) {
		return 'DOB: ****' + params.slice(-4);
	}
	return (
		<div className="full-row-cols">
			<IonGrid className="full-row-cols">
				<IonRow className="row-content-exc">
					<IonCol size="1">
						<IonRow
							className={`count-exclusion ${type === 1 ? 'exc-possible-color' : ''}
		 	${type === 2 ? 'exc-found-color' : ''}${type === 3 ? 'exc-prev-match-color' : ''}
		 	`}>
							<span className="count-exclusion-circle">{number}</span>
						</IonRow>
					</IonCol>
					<IonCol size={`${type === 3 && attachments ? '7' : '11'} `}>
						<IonRow className="full-row-cols">
							<IonText className="exclusion-name-leie">{name}</IonText>
							<IonText className="exclusion-data-leie">{dobNumber(dob!)}</IonText>
							<IonText className="exclusion-data-leie">Address: {address1}</IonText>
							<IonText className="exclusion-data-leie exclusion-name-leie">
								{type === 1 ? 'Status: In Progress' : type === 2 ? 'Status:  Match Confirmed' : 'Status: No Match '}
							</IonText>
						</IonRow>
					</IonCol>
					{type === 3 && attachments ? (
						<IonCol size="4" onClick={() => setShowImageModal(true)}>
							<IonRow className="full-row-cols">
								<IonImg src={attachments.downloadUrl}></IonImg>
							</IonRow>
						</IonCol>
					) : null}
					{type === 3 ? (
						<IonCol size="11" offset="1">
							<IonRow className="full-row-cols">
								<IonText className="exclusion-data-leie">Verified on {orderDate}</IonText>
							</IonRow>
						</IonCol>
					) : null}
				</IonRow>
			</IonGrid>
			<Modal showCloseIcon={true} open={showImageModal} onClose={() => setShowImageModal(false)} modalId="modal" center>
				<IonImg className="modal-img-license" src={attachments?.downloadUrl} />
			</Modal>
		</div>
	);
};

export default AccordionLeieActions;
