import { IonIcon, IonImg, IonRow, IonText, IonToast, ToastOptions } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NPPESLogo from '../../../img/NPPES-logo.png';
import interrogation from '../../../img/icons/interrogation.svg';
import { NppesActions } from '../../../redux/actions';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { NppesEntity } from '../../../shared/entity/nppesEntity';
import AboutThat from '../../AboutThat/AboutThat';
import CopyClipboard from '../../CopyToClipboard/CopyToClipboard';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import './NPPES.css';

const NPPES: React.FC<CardInfoEntity> = ({ title, zoom }) => {
	const dispatch = useDispatch();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const nppes = useSelector<any, NppesEntity>((state) => state.nppes!);
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__nppes');
	useEffect(() => {
		if (data) {
			dispatch(NppesActions.setLicense(data));
		}
	}, [data]);

	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__nppes rotate');
		} else {
			setBack('card card__nppes noRotate');
		}
	};

	return (
		<div className={back}>
			{!flip ? (
				<IonRow className="card__face">
					<IonRow>
						<IonRow className="article-header">
							<IonRow className="category-title npi-title-width">{title}</IonRow>
							<IonIcon
								id="about"
								className="icon-about-sam-exclusion"
								icon={interrogation}
								onClick={() => onFlip()}></IonIcon>
						</IonRow>
						{JSON.stringify(nppes) !== '{}' && !flip ? (
							<IonRow className="content-card flex-column-nppes">
								<IonRow className="ion-padding-top ion-padding-bottom img-nppes">
									<IonImg src={NPPESLogo}></IonImg>
								</IonRow>
								<IonRow className="card__nppes-header ion-align-items-center ion-nowrap">
									<IonRow className="div">
										<IonText className="title-name">
											{nppes.name}, {nppes.role}
										</IonText>
									</IonRow>
								</IonRow>
								<IonRow className="card__nppes-body">
									<IonRow className="card__nppes-npi">
										<IonText>NPI:</IonText>
									</IonRow>
									<IonRow className="card__nppes-npi">
										<IonText className="span">
											{nppes.npiNumber}
											<CopyClipboard text={nppes.npiNumber} />
										</IonText>
									</IonRow>
									<IonRow className="card__nppes-status full-row-cols margin-bottom-issued-nppes">
										<IonText className="dateText">
											Issued: {nppes.issued ? moment(nppes.issued).format('MMM DD YYYY') : 'Not Available'}
										</IonText>
										<IonText className="dateText">
											Updated: {nppes.updatedAt ? moment(nppes.updatedAt).format('MMM DD YYYY') : 'Not Available'}
										</IonText>
									</IonRow>
								</IonRow>
							</IonRow>
						) : (
							<NoInfoCard textColor="text-color-white" />
						)}
					</IonRow>
				</IonRow>
			) : null}
			{flip ? (
				<AboutThat
					onFlip={onFlip}
					description={nppes.description}
					title={title}
					cssBackground="nppes-background"
					cssTextColor="about-white"
				/>
			) : null}
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
		</div>
	);
};

export default NPPES;
