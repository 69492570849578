import { IonRow, IonText } from '@ionic/react';
import { BubbleModule as Module } from '../../../shared/entity/bubble.module';
import './Bubble.css';

const Bubble: React.FC<Module.Bubble> = (props: Module.Bubble) => {
	const bubble: Module.Bubble = props;

	return (
		<div className={`bubble ${bubble.css} ${bubble.position}`}>
			<IonRow>
				<IonText className="datetime">{bubble.date}</IonText>
				<IonText className="datetime">{bubble.time}</IonText>
			</IonRow>
			<IonRow className={`view`}>
				<IonText className="text">{bubble.text}</IonText>
			</IonRow>
		</div>
	);
};

export default Bubble;
