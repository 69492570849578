import { ActionEntity } from "../../shared/entity/actionEntity";
import { UserEntity } from "../../shared/entity/userEntity";
import { LoginConstants } from "../constants/loginConstants";
const initialUser: UserEntity = {};
const loginReducer = (state: UserEntity = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case LoginConstants.LOGIN_REQUEST:
                return {
                    ...state,
                    email: action.payload.email,
                    password: action.payload.password,
                    loading: true,
                    resolved: null
                };
                case LoginConstants.LOGIN_SUCCESS:
                    return {
                        ...state,
                        token: action.payload.token,
                        loading: false,
                        resolved: true,
                        logged: true,
                    };
                    
                case LoginConstants.LOGIN_FAILURE:
                    return {
                        ...state,
                        loading: false,
                        resolved:false,
                        token: null,
                    };
                case LoginConstants.LOGOUT:
                    return {
                        ...state,
                        loading: false,
                        resolved:false,
                        logged: false,
                        token: 'x',
                    };        
        default:
            return state;
    }

}
export default loginReducer;