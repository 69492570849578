import { ActionEntity } from "../../shared/entity/actionEntity";
import { PassportEntity } from "../../shared/entity/passportEntity";
import { PassportConstants } from "../constants";


const initialUser: PassportEntity = {};
const passportReducer = (state: PassportEntity = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case PassportConstants.SET_PASSPORT:
                return action.payload;          
        default:
            return state;
    }
}
export default passportReducer;