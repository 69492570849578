import { ActionEntity } from "../../shared/entity/actionEntity";
import { AoaConstants } from "../constants";

const initialUser = {};
const aoaReducer = (state = initialUser,action: ActionEntity) =>{
    switch(action.type){
        case AoaConstants.SET_AOA:
                return action.payload;          
        default:
            return state;
    }
}
export default aoaReducer;